import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepWizard from '../../Components/WizardWrapper'
import { WizardNav } from '../../Components/WizardNav'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { Step6 } from './Step6'
import './DMARC.scss'
import { Step7 } from './Step7'
import { EmailModel } from '../Models'

export const DMARCGuide = () => {
  const[domain, setDomain] = useState('')
  const[emails, setEmails]= useState([
    new EmailModel(),
  ])
  const[forensicEmails, setForensicEmails] = useState([
    new EmailModel(),
  ])
  const[subdomainPolicy, setSubdomainPolicy] = useState('none')
  const[domainPolicy, setDomainPolicy] = useState('none')
  const[reportGen, setReportGen] = useState('0')
  const[alignmentModeDKIM, setAlignmentModeDKIM] = useState('r')
  const[alignmentModeSPF, setAlignmentModeSPF] = useState('r')
  const[reportFormat, setReportFormat] = useState('afrf')
  const[reportIntSec, setReportIntSec] = useState('86400')
  const[hoursToConvert, setHoursToConvert] = useState<number | undefined | null>(1)
  const[convertedSeconds, setConvertedSeconds] = useState<number | undefined | null>(1)
  const[percentage, setPercentage] = useState(100)

  const handleSetDomain = (newDomain: string) => {
    setDomain(newDomain)
  }
  
  const handleSetSubdomainPolicy = (newSubdomainPolicy: string ) => {
    setSubdomainPolicy(newSubdomainPolicy)
  } 
  const handleSetDomainPolicy = (newDomainPolicy: string ) => {
    setDomainPolicy(newDomainPolicy)
  } 
  const handleSetReportGen = (newReportGen: string ) => {
    setReportGen(newReportGen)
  }

  const handleSetAlignmentModeDKIM = (newAlignmentModeDKIM: string ) => {
    setAlignmentModeDKIM(newAlignmentModeDKIM)
  }

  const handleSetAlignmentModeSPF = (newAlignmentModeSPF: string ) => {
    setAlignmentModeSPF(newAlignmentModeSPF)
  }
  const handleSetReportFormat = (newReportFormat: string ) => {
    setReportFormat(newReportFormat)
  }
  const handleSetReportIntSec = (newReportIntSec: string ) => {
    setReportIntSec(newReportIntSec)
  }
  const handleSetHoursToConvert = (newHoursToConvert: number ) => {
    setHoursToConvert(newHoursToConvert)
    setConvertedSeconds(secondsFromHours(newHoursToConvert))
  }
  const handleSetPercentage = (newPercentage: number ) => {
    setPercentage(newPercentage)
  }

  const addEmail = () => {
    let email_array = [...emails]
    email_array.push(new EmailModel())
    setEmails(email_array) 
  }

  const addForensicEmail = () => {
    let forensic_email_array = [...forensicEmails]
    forensic_email_array.push(new EmailModel())
    setForensicEmails(forensic_email_array) 
  }


  const removeEmail = (email: EmailModel ) => {
    let email_array = [...emails]
    const index = email_array.findIndex((el) => el.id === email.id);
    if (index !== -1) {
      email_array.splice(index, 1);
    }
    setEmails(email_array);
  }


  const removeForensicEmail = (forensic_email: EmailModel ) => {
    let forensic_email_array = [...forensicEmails]
    const index = forensic_email_array.findIndex((el) => el.id === forensic_email.id);
    if (index !== -1) {
      forensic_email_array.splice(index, 1);
    }
    setForensicEmails(forensic_email_array);
  }



  const getRecord = () => {
    let i, rec = ''

    rec += '_dmarc.' + domain
    rec += '. IN TXT "v=DMARC1; '
    rec += 'p=' + domainPolicy

    if (emails.length) {
      rec += '; rua='
      for (i = emails.length; i--; ) {
        rec += 'mailto:' + emails[i].email
        if (i !== 0) {
          rec += ','
        }
      }
    }

    if (forensicEmails.length) {
      rec += '; ruf='
      for (i = forensicEmails.length; i--; ) {
        rec += 'mailto:' + forensicEmails[i].email
        if (i !== 0) {
          rec += ','
        }
      }
    }

    rec += '; sp=' + subdomainPolicy
    if (reportGen !== '0') {
      rec += '; fo=' + reportGen
    }

    if (alignmentModeSPF !== 'r') {
      rec += '; aspf=' + alignmentModeSPF
    }

    if (alignmentModeDKIM !== 'r') {
      rec += '; adkim=' + alignmentModeDKIM
    }

    if (percentage !== 100) {
      rec += '; pct=' + percentage
    }

    if (reportFormat !== 'afrf') {
      rec += '; rf=' + reportFormat
    }

    rec += '; ri=' + reportIntSec
    rec += '"'

    return rec
  }

  const secondsFromHours = (hours: number) => {
    return (hours || 0) * 3600
  }


  const handleStartOver = () => {
    setDomain('')
    setEmails([
      new EmailModel(),
    ])
    setForensicEmails([
        new EmailModel(),
      ])
    setSubdomainPolicy('none')
    setDomainPolicy('none')
    setReportGen('0')
    setAlignmentModeDKIM('r')
    setAlignmentModeSPF('r')
    setReportFormat('afrf')
    setReportIntSec('86400')
    setHoursToConvert(null)
    setConvertedSeconds(null)
    setPercentage(100)
  }

  const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
  }

  const { t } = useTranslation()

  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='wizard page-wrapper' data-section-bg-repeat='stretch'>
            <h1
              style={{
                textAlign: 'center',
                marginBottom: '30px',
                fontWeight: 'bold',
              }}
            >{t('DMARC.Home.Title')}</h1>
            <StepWizard
              nav={<WizardNav />}
              isHashEnabled
              transitions={transitions}
              isLazyMount
            >
              <Step1 domain={domain} handleSetDomain={handleSetDomain}/>
              <Step2 domainPolicy={domainPolicy} handleSetDomainPolicy={handleSetDomainPolicy}  />
              <Step3 emails={emails} addEmail={addEmail} removeEmail={removeEmail} />
              <Step4 forensicEmails={forensicEmails} addForensicEmail={addForensicEmail} removeForensicEmail={removeForensicEmail}/>
              <Step5  subdomainPolicy={subdomainPolicy} handleSetSubdomainPolicy={handleSetSubdomainPolicy} />
              <Step6  
              reportGen={reportGen} 
              handleSetReportGen={handleSetReportGen} 
              alignmentModeDKIM={alignmentModeDKIM} 
              handleSetAlignmentModeDKIM={handleSetAlignmentModeDKIM} 
              alignmentModeSPF={alignmentModeSPF} 
              handleSetAlignmentModeSPF={handleSetAlignmentModeSPF} 
              percentage={percentage} 
              handleSetPercentage={handleSetPercentage}
              reportFormat={reportFormat}
              handleSetReportFormat={handleSetReportFormat}
              reportIntSec={reportIntSec}
              handleSetReportIntSec={handleSetReportIntSec}
              hoursToConvert={hoursToConvert}
              handleSetHoursToConvert={handleSetHoursToConvert}
              convertedSeconds={convertedSeconds}
              />
              <Step7 getRecord={getRecord} handleStartOver={handleStartOver}/>
            </StepWizard>
          </div>
        </div>
      </div>
    </div>
  )
}
