import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../../Components/WizardButton'
import './Results.scss'

interface IProps {
  isActive: boolean,
  title: string,
  children?: any | null,
}

const Result = (props: IProps) => {
  return (
    <div className='res'>
      <div className='content'>
        <div className={`icon ${props.isActive ? 'checked' : ''}`}>
          {props.isActive ? (
            <i className='fas fa-check-circle' style={{ color: 'green' }} />
          ) : (
            <i className='fas fa-times-circle' />
          )}
        </div>
        <div className='header-container'>
          <h3 className='header'>{props.title}</h3>
        </div>
        <div className='details'>{props.children}</div>
      </div>
    </div>
  )
}

export const StepResults = (props: any) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState<Record<string, boolean>>({});
  const { handleSetDomain, handleSetDomains, results, toggleShare, share, handleResetBulkScanning, handleResetResults} = props

  /**
   * useEffect hook that updates the showAdvanced state based on the results array.
   * It initializes the showAdvanced object with each domain from the results array set to false.
   * Used for having unique toggles for the advanced section for each domain.
   * @param results - The array of results.
   */
  useEffect(() => {
    let updatedShowAdvanced = {};

    updatedShowAdvanced = results.reduce((acc: any, resultItem: any) => {
      acc[resultItem.scanResult?.domain] = false;
      return acc;
    }, {});
    setShowAdvanced(updatedShowAdvanced);
  }, [results]);
  
  /**
   * Checks if a field exists in an object based on a given field path.
   * Used for determining if a DNS record exists in our results.
   * @param obj - The object to check.
   * @param fieldPath - The field path to check for.
   * @returns True if the field exists, false otherwise.
   */
  function doesFieldExist(obj: any, fieldPath:string) {
    const keys = fieldPath.split('.');

    let currentObj = obj;
    for (const key of keys) {
      if (!currentObj || !currentObj.hasOwnProperty(key)) {
        return false;
      }
      currentObj = currentObj[key];
    }

    return true;
  }

  /**
   * Checks if the given record has SPF information.
   * @param record - The record to check.
   * @returns True if the record has SPF information, false otherwise.
   */
  const hasSPF = (record: any ) => {
    return doesFieldExist(record, 'scanResult.spf')
  }

  /**
   * Checks if the given record has DMARC information.
   * @param record - The record to check.
   * @returns True if the record has DMARC information, false otherwise.
   */
  const hasDMARC = (record: any ) => {
    return doesFieldExist(record, 'scanResult.dmarc')
  }

  /**
   * Checks if the given record has DKIM information.
   * @param record - The record to check.
   * @returns True if the record has DKIM information, false otherwise.
   */
  const hasDKIM = (record: any ) => {
    return doesFieldExist(record, 'scanResult.dkim')
  }

  /**
   * Checks if the given record has BIMI information.
   * @param record - The record to check.
   * @returns True if the record has BIMI information, false otherwise.
   */
  const hasBIMI = (record: any ) => {
    return doesFieldExist(record, 'scanResult.bimi')
  }

  /**
   * Checks if the given record has TLS information.
   * @param record - The record to check.
   * @returns True if the record has TLS information, false otherwise.
   */
  const hasTLS = (record: any ) => {
    return doesFieldExist(record, 'scanResult.mx')
  }

  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return ( 
    <div> 
      <div>
        { results.map((resultItem: any) => (
          <div style={{ paddingTop: '30px' }}>
            <h1 style={{ textAlign: 'center' }}>
              {t('ToolSelect.Main.Title')} <strong>{resultItem?.scanResult?.domain}</strong>
            </h1>
            {resultItem?.advice?.generalError && (
              <div>
              <br />
              <h2 style={{ textAlign: 'center', color: 'red' }}>
                {resultItem?.advice?.generalError.map((item: string) => toTitleCase(item)).join('\n\n')}
              </h2>
              <br />
              </div>
            )}
            { (resultItem?.advice?.spf || resultItem?.advice?.dmarc || resultItem?.advice?.dkim || resultItem?.advice?.bimi || resultItem?.advice?.tls) && (
            <div>
            <p dangerouslySetInnerHTML={{ __html: t('ToolSelect.Main.ResultStatus') }} />
            <p>{t('ToolSelect.Main.Protocol')}</p>
            <p>{t('ToolSelect.Main.ShareButton')}</p>
            <p>{t('ToolSelect.Main.Selecting')}</p>
            <div className='results'>
            {resultItem?.advice?.spf && (
              <Result title='SPF' isActive={hasSPF(resultItem)}>
                <textarea disabled name='' value={resultItem?.advice?.spf?.map((item: string) => `${item}`).join('\n\n') || ''} />
                <Link className='hs-button hs-blue'
                  to={'/spf/'}
                >
                  {t('SPF Guide')}
                </Link>
              </Result>
            )}
            {resultItem?.advice?.dmarc && (
              <Result title='DMARC' isActive={hasDMARC(resultItem)}>
                <textarea disabled name='' value={resultItem?.advice?.dmarc?.map((item: string) => `${item}`).join('\n\n') || ''} />
                <Link className='hs-button hs-blue' to={'/dmarc'}>
                  {t('DMARC Guide')}
                </Link>
              </Result>
            )}
            {resultItem?.advice?.dkim && (
              <Result title='DKIM' isActive={hasDKIM(resultItem)}>
                <textarea disabled name='' value={resultItem?.advice?.dkim?.map((item: string) => `${item}`).join('\n\n') || ''} />
                <Link className='hs-button hs-blue' to={'/dkim'}>
                  {t('DKIM Guide')}
                </Link>
              </Result>
            )}
            {resultItem?.advice?.bimi && (
              <Result title='BIMI' isActive={hasBIMI(resultItem)}>
                <textarea disabled name='' value={resultItem?.advice?.bimi?.map((item: string) => `${item}`).join('\n\n') || ''} />
                <Link className='hs-button hs-blue' to={'/bimi'}>
                  {t('BIMI Guide')}
                </Link>
              </Result>
            )}
            {resultItem?.advice?.tls && (
              <Result title='TLS' isActive={hasTLS(resultItem)}>
                <textarea disabled name='' value={resultItem?.advice?.tls?.map((item: string) => `${item}`).join('\n\n') || ''} />
                <Link className='hs-button hs-blue' to={'/tls'}>
                  {t('TLS Guide')}
                </Link>
              </Result>
            )}
            </div>
            <div className='advanced-section'>
              <h5>
                <span onClick={() => setShowAdvanced({ ...showAdvanced, [resultItem.scanResult?.domain]: !showAdvanced[resultItem.scanResult?.domain] })}>
                  Advanced <span style={{ marginLeft: '5px' }}>{showAdvanced[resultItem.scanResult?.domain] ? '▼' : '►'}</span>
                </span>
              </h5>
              {showAdvanced[resultItem.scanResult?.domain] && (
                <div className='advanced-content'>
                  {resultItem?.scanResult?.spf ? (
                    <div className='dns-record'>
                      <label htmlFor={`spfRecord-${resultItem?.scanResult?.domain}`}>SPF Record</label>
                      <input type='text' readOnly value={resultItem?.scanResult?.spf} />
                    </div>
                  ) : null}
                  {resultItem?.scanResult?.dmarc ? (
                    <div className='dns-record'>
                      <label htmlFor={`dmarcRecord-${resultItem?.scanResult?.domain}`}>DMARC Record</label>
                      <input type='text' readOnly value={resultItem?.scanResult?.dmarc} />
                    </div>
                  ) : null}
                  {resultItem?.scanResult?.dkim ? (
                    <div className='dns-record'>
                      <label htmlFor={`dkimRecord-${resultItem?.scanResult?.domain}`}>DKIM Record</label>
                      <input type='text' readOnly value={resultItem?.scanResult?.dkim} />
                    </div>
                  ) : null}
                  {resultItem?.scanResult?.bimi ? (
                    <div className='dns-record'>
                      <label htmlFor={`bimiRecord-${resultItem?.scanResult?.domain}`}>BIMI Record</label>
                      <input type='text' readOnly value={resultItem?.scanResult?.bimi} />
                    </div>
                  ) : null}
                  <div className='dns-record dual'>
                    {resultItem?.scanResult?.mx ? (
                      <div className='dns-record'>
                        <label htmlFor={`mxRecords-${resultItem?.scanResult?.domain}`}>MX Records</label>
                        <textarea disabled name='' value={resultItem?.scanResult?.mx?.map((item: string) => `${item}`).join('\n') || ''} />
                      </div>
                    ) : null}
                    {resultItem?.scanResult?.ns ? (
                      <div className='dns-record'>
                        <label htmlFor={`nsRecords-${resultItem?.scanResult?.domain}`}>NS Records</label>
                        <textarea disabled name='' value={resultItem?.scanResult?.ns?.map((item: string) => `${item}`).join('\n') || ''} />
                      </div>
                    ) : null}
                </div>
              </div>
              )}
            </div>
            </div>
            )}
          </div>
        ))}
      </div>
      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.StartOver')}
          onClick={() => {
            handleSetDomains('')
            handleSetDomain('')
            handleResetBulkScanning()
            handleResetResults()
            props.firstStep()
          }}
        />
        <WizardButton
          className='btn-green'
          onClick={() => toggleShare()}
          text={t('ToolSelect.Main.Share')}
        />
      </div>
      {share ? (
        <div className='copy-input'>
          <input type='text' readOnly value={window.location.href} />
          <WizardButton
            className={`${copied ? 'copied' : ''}`}
            text={copied ? t('SPF.Results.Copied') : t('SPF.Results.Copy')}
            onClick={() => {
              setCopied(true)
              navigator.clipboard.writeText(window.location.href)
              setTimeout(() => {
                setCopied(false)
              }, 2000)
            }}
          />
        </div>
      ) : null}
    </div>
  )
}
