let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC Rehberi`;

l.Header.Main.SetupDMARC        = `DMARC KURULUMU`;
l.Header.Main.HowItWorks        = `NASIL ÇALIŞIR`;
l.Header.Main.AboutDMARC        = `DMARC HAKKINDA`;
l.Header.Main.Resources         = `KAYNAKLAR`;
l.Header.Main.Contact           = `İLETİŞİM`;

l.Footer.Main.SetupDMARC        = `DMARC'ı Kur`;
l.Footer.Main.HowItWorks        = `Nasıl çalışır`;
l.Footer.Main.AboutDMARC        = `DMARC hakkında`;
l.Footer.Main.Resources         = `Kaynaklar`;
l.Footer.Main.Contact           = `İletişim`;
l.Footer.Main.Copyright         = `Telif Hakkı ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Gizlilik Politikası`;
l.Footer.Main.Sitemap           = `Site Haritası`;

l.Home.Main.EnterDomain         = `alan adınızı girin`;
l.Home.Main.ToStart             = `DMARC kurulumunu başlatmak için`;
l.Home.Main.TagLine             = `Bir Şey yapın. Ölçün.`;
l.Home.Main.Welcome             = `DMARC Kurulum Rehberine hoş geldiniz. Bu kurulum rehberinin amacı, bir DMARC politikası, aynı zamanda Gönderen Politikası Çerçevesi (SPF) ve Alan Adı Anahtarıyla Eposta Kimlik Doğrulaması (DKIM) politikası oluşturma sürecinde işletmenize rehberlik etmektir. Bu üç politikanın uygulanmasıyla, işletmeniz markasinin korunmasını sağlayacak daha güçlü bir e-posta doğrulama mekanizmasına sahip olacaktır.`;
l.Home.Main.FirstStep           = `İlk adımda işletmenizin bu üç adımdan herhangi birini kullanıp kullanmadığı teyit edilecektir.`;
l.Home.Main.InputWarning        = `Lütfen işletmenizin eposta adresinde geçen domain'i, tam olarak @ sembolünden sonra göründüğü şekliyle yazın. `;
l.Home.Main.InputExample        = `Örnek olarak info@globalcyberalliance.org eposta adresine göre metin kutusuna yazılacak alan adı, globalcyberalliance.org şeklindedir. `;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `Web sitesi adresi gerekiyor`;
l.Home.Main.valid               = `Web sitesi adresi geçersiz`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google Apps ve Office 365 Kullanıcıları:`;
l.Home.Main.UserWarningText1    = `SPF ve DKIM'i uygulamak için, lütfen Kaynaklar Sayfası'nda`;
l.Home.Main.UserWarningLink     = `Bulut Hizmeti Sağlayıcıları için verilen bilgileri takip edin.`;
l.Home.Main.UserWarningText2    = `Bu işlem tamamlandığında, DMARC politikasını uygulamaya başlamak için lütfen bu rehberi kullanın. `;
l.Home.Main.Submit              = `Gönder`;

l.ToolSelect.Main.Title         = `Şunun için sonuçlar`;
l.ToolSelect.Main.ResultStatus  = `Aşağıdaki tabloda hangi protokollerin uygulandığı <i class='fas fa-check-circle' style='color: green'></i> ya da uygulanmadığı <i class='fas fa-times-circle' style='color: red'></i> gösterilmektedir.`;
l.ToolSelect.Main.Protocol      = `Kurulum rehberini başlatmak için lütfen protokollerden birini seçin.`;
l.ToolSelect.Main.ShareButton   = `Sonuçları paylaşmak istiyorsanız, Paylaş düğmesine tıklamanız yeterlidir. Böylece not panosuna bir bağlantı kopyalanacaktır.`;
l.ToolSelect.Main.Selecting     = `Bir protokolün seçilmesiyle, varsa mevcut kayıt da gösterilecektir.`;
l.ToolSelect.Main.DKIMWarning   = `DKIM'in kontrol kısmı hala yapım aşamasındadır. Ancak DKIM sıra satırı seçildiğinde, DKIM Kurulum Rehberi yine de gösterilmektedir.`;
l.ToolSelect.Main.NoDKIM        = `Varsayılan seçiciler listesini kullanarak  domainnız ile ilişkili bir DKIM kaydı bulamadık.`;
l.ToolSelect.Main.NoDKIM1       = `Seçiciyi biliyorsanız, DKIM kaydı için buradan tarayabilirsiniz:`;
l.ToolSelect.Main.NoDKIM2       = `Veya bir eposta adresi bildirirseniz, söz konusu hesaba yanıtlayabileceğiniz bir eposta göndereceğiz ve DKIM kaydı oluşturmak için epostayı tarayacağız. `;
l.ToolSelect.Main.NoDKIMError   = `Eposta geçersiz ya da sunulan alan adına ait değil. `;
l.ToolSelect.Main.SelectorMsg   = `Seçici için taranıyor!`;
l.ToolSelect.Main.SelectorError = `Seçici bulunamadı.`;
l.ToolSelect.Main.EmailMsg      = `Eposta gönderildi! Lütfen epostaya cevap verin. Cevap gönderilir gönderilmez, bu sayfa yenilenecek ve seçici adı gösterilecektir.`;
l.ToolSelect.Main.InputSelector = `Seçici`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Gönder`;
l.ToolSelect.Main.Select        = `Seç`;
l.ToolSelect.Main.Next          = `Sonraki`;
l.ToolSelect.Main.Share         = `Paylaş`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `DMARC kurulumunu tamamladınız`;
l.Complete.Main.check           = `SPF, DKIM veya DMARC için başka bir domain kontrol edilsin mi?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `Web sitesi adresi gerekiyor`;
l.Complete.Main.valid           = `Web sitesi adresi geçerli değil`;
l.Complete.Main.Next            = `Sonraki`;
l.Complete.Main.More            = `DMARC hakkında daha fazlası`;

l.SPF.Home.Title                = `SPF nedir?`;
l.SPF.Home.SPF                  = `SPF'nin açılımı Gönderen Politikası Çerçevesi'dir. Bu politika,   domain adına hangi posta sunucularının posta gönderme yetkisinin bulunduğunu belirleyerek, gönderen adresinde sahtekarlık yapılmasını engellemekten sorumludur. Posta sunucusu SPF'de tanımlı değilse, bu durumda mesaj reddedilir ya da geri döner.`;
l.SPF.Home.Start                = `SPF Politikası oluşturma sürecini başlatmak için lütfen Sonraki düğmesine tıklayın. `;
l.SPF.Home.Next                 = `Sonraki`;

l.SPF.Authorization.Title       = `Aşağıda listeli sunucular işletmeye ait domain için eposta gönderme yetkisine sahip mi?`;
l.SPF.Authorization.First       = `Politikanın ilk kısmında, domain'e ait olup posta gönderme yetkisi bulunan gelen posta sunucularının (MX kayıtlarının) tanımlanması amaçlanmaktadır.  `;
l.SPF.Authorization.Current     = `Aşağıdaki liste, işletmenizle ilişkili posta sunucularına ait mevcut DNS kayıtlarını içermektedir. `;
l.SPF.Authorization.Recommended = `Yukarıdaki liste domaininiz için geçerli olan yetkili sunucuları içereceğinden, Evet cevabının seçilmesi şiddetle tavsiye edilir. Böylece, SPF politikasına mx etiketi eklenecektir. `;
l.SPF.Authorization.Yes         = `Evet (Önerilen)`;
l.SPF.Authorization.No          = `Hayır `;
l.SPF.Authorization.Missing     = `Eğer listede eksik olan sistemler varsa ya da standart posta sunucuları bulunmuyorsa, bu sistemleri bir sonraki seçenekte ekleyin.`;
l.SPF.Authorization.Next        = `Sonraki`;

l.SPF.Servers.Title             = `söz konusu domain için posta gönderme ya da iletme olasılığı olan ek sunucular var mı?`;
l.SPF.Servers.Possible          = ` DNS kayıtlarında işletmeye ait posta sunucularının hepsinin tanımlanmamış olması mümkündür. Bunlar,  yerleşik posta sistemlerine sahip test sunucuları ya da uygulamaları olabilir. Eğer böyleyse, o halde lütfen ilave domain sunucularını ya da IP adreslerini aşağıdaki kutucuklara ekleyin. `;
l.SPF.Servers.Others            = `Bu seçenek, ancak söz konusu domain için posta gönderme yetkisi bulunan, eposta sunucuları haricinde başka dahili sistemler varsa kullanılmalıdır.''`;
l.SPF.Servers.Added             = `Sistemlerin eklenmesi durumunda, SPF politikasına etiketi eklenecektir. `;
l.SPF.Servers.InputField        = `Sunucu Adı ya da IP Adresi`;
l.SPF.Servers.InputInvalid      = `Tam tanımlanmış bir Domain ya da IP adresi olmalıdır`;
l.SPF.Servers.Next              = `Sonraki`;

l.SPF.Domains.Title             = `İşletmeniz için posta gönderebilecek ya da iletebilecek dış etki alanları var mı?`;
l.SPF.Domains.Include           = `Bu bölüm,`;
l.SPF.Domains.Include2          = `SPF Politikasına ait `;
l.SPF.Domains.Include3          = `ekle etiketini ilave edecektir.`;
l.SPF.Domains.Trusted           = `Bu özellik, ancak isletmenizin domain'i için eposta iletisi gönderecek olan dış domain'e güveniliyorsa kullanılmalıdır. Örneğin; Müşteri İlişkileri Yönetim Sistemi, Bulut Posta Sağlayıcısı (Google, Office 365, Yahoo!) ya da Bulut Güvenlik Sağlayıcısı. `;
l.SPF.Domains.Note              = `Lütfen dikkate alın: Bir Bulut Hizmet Sağlayıcısı kullanıyorsanız, bu ayarla ilgili uygun değer için onlarla birlikte çalışmalısınız.`;
l.SPF.Domains.InputField        = `Domain ya da IP Adresi`;
l.SPF.Domains.InputInvalid      = `Tam tanımlanmış bir Domain ya da IP adresinden oluşmalıdır`;
l.SPF.Domains.Next              = `Sonraki `;

l.SPF.Strict.Title              = `Sunucular epostaları analiz ederken ne kadar katı olmalı?`;
l.SPF.Strict.Receiving          = `Bu, SPF politikasının alıcı konumundaki sunucuların epostaları analiz ederken ne kadar katı olmaları gerektiğini belirleyecek olan son bölümüdür. `;
l.SPF.Strict.SoftFail           = `Soft Fail (Önerilir)`;
l.SPF.Strict.Fail               = `Fail`;
l.SPF.Strict.Neutral            = `Neutral`;
l.SPF.Strict.AllowAll           = `Hepsine İzin Ver`;
l.SPF.Strict.Next               = `Sonraki`;
l.SPF.Strict.Options            = `Seçenekler:`;
l.SPF.Strict.FailEx             = `Fail ya da `;
l.SPF.Strict.FailEx2            = `söz konusu domain için sadece domain deki posta sunucularının (ayrıca a kaydı ve ekleme kısmındakilerin) posta göndermesine izin verildiği anlamına gelir. Diğer hiçbirine izin verilmez.`;
l.SPF.Strict.SoftEx             = `Soft Fail ya da`;
l.SPF.Strict.SoftEx2            = `söz konusu domain için sadece domain deki posta sunucularının (ayrıca "a" kaydı ve ekleme kısmındakilerin) posta göndermesine izin verildiği ancak geçiş aşamasında olduğu anlamına gelir. Diğer hiçbirine izin verimez.`;
l.SPF.Strict.NeutralEx          = `Neutral ya da `;
l.SPF.Strict.NeutralEx2         = `açık olarak geçerlilik konusunda hiçbir şeyin söylenemeyeceği anlamına gelir.`;
l.SPF.Strict.AllowEx            = `Tümüne İzin ver ya da `;
l.SPF.Strict.AllowEx2           = `söz konusu domain için tüm sistemler posta gönderebileceği anlamına gelir.`;
l.SPF.Strict.Warning1           = `Bu,`;
l.SPF.Strict.Warning2           = `asla`;
l.SPF.Strict.Warning3           = `kullanılmamalıdır.`;
l.SPF.Strict.Recommended        = `Soft Fail seçeneğinin kullanılması tavsiye edilir.`;
l.SPF.Strict.MarkMail           = `Bu, tanımlanan kriterleri karşılamaması halinde postanın uygun değil şeklinde işaretlenmesini sağlayacaktır. `;

l.SPF.Results.Title             = `Aşağıda, DNS sunucunuzda oluşturulması gereken SPF TXT kaydı verilmektedir. `;
l.SPF.Results.Copy              = `Kopyala`;
l.SPF.Results.Copied            = `KOPYALANDI!`;
l.SPF.Results.DMARCGuide        = `DMARC Rehberi'ne Geç`;
l.SPF.Results.DKIMGuide         = `DKIM Rehberi İle Devam Et`;
l.SPF.Results.OwnDNS            = `Kendi DNS sunucunuza sahipseniz, lütfen yukarıdaki SPF politikası için TXT kayıtları oluşturun.`;
l.SPF.Results.ThirdParty        = `Bir üçüncü taraf DNS sunucusu kullanıyorsanız, lütfen TXT kaydı oluşturmak için verdikleri talimatları takip edin.`;
l.SPF.Results.Reference         = `TXT kayıtlarının nasıl oluşturulacağı hakkında bilgi almak için `;
l.SPF.Results.ResourcesPage     = `Lütfen kaynaklar sayfasına`;
l.SPF.Results.Information       = `Başvurun.`;
l.SPF.Results.NotUnderstood     = `Bu rehberi takip etmeyi denediniz ancak atacağınız adımları ya da kullanılan terminolojiyi anlamadıysanız, daha fazla destek almak için Bilisim Teknolojileri destek organizasyonunuz, Domain kayit sirketiniz ya da `;
l.SPF.Results.Assistance        = `GCA danışma ile iletişime geçmeniz tavsiye edilir.`;
l.SPF.Results.Implemented       = `SPF kaydı tamamlandığında, lütfen aşağıdaki SPF doğrulama/denetleme sistemini kullanın:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Kontrol Et`;
l.SPF.Results.SuccessDKIM       = `DKIM'E GİT`;
l.SPF.Results.SuccessDMARC      = `DMARC'E GİT`;
l.SPF.Results.NotQuite          = `Henüz tamamlanmış sayılmaz.`;
l.SPF.Results.Wisdom            = `Tamamdır! Her eylemin nihai hedefi bilgeliktir. Lütfen yukarıda sunduğumuz kaynaklarımızdan dilediğinizce yararlanın ya da bize bir iki satır yazın ki tamamlamanıza yardımcı olalım. Biz bunun için buradayız. `;

l.DKIM.Home.Title               = `DKIM nedir?`;
l.DKIM.Home.Mechanism           = `DKIM'in açılımı Domain Anahtarıyla E-posta Kimlik Doğrulaması'dır. DKIM, posta iletisiyle ilişkili olan Domain kimliğinin doğrulanması amacıyla tasarlanmış bir mekanizmadır. Bu, açık anahtarlı şifrelemeyi kullanan kimlik doğrulama yöntemi ile sağlanmaktadır. Temel olarak DKIM, ileti başlığına dijital bir imza eklemek suretiyle posta iletisi için kimlik denetimi yapacaktır. DKIM'in posta iletilerini filtrelemediğini belirtmek önemlidir. Gönderilen posta iletisinin gerçekliğinin SPAM filtreleri tarafından belirlenmesine izin verecektir.  `;
l.DKIM.Home.ClickNext           = `DKIM'in açılımı Domain Anahtarıyla E-posta Kimlik Doğrulaması'dır. DKIM, posta iletisiyle ilişkili olan Domain kimliğinin doğrulanması amacıyla tasarlanmış bir mekanizmadır. Bu, açık anahtarlı şifrelemeyi kullanan kimlik doğrulama yöntemi ile sağlanmaktadır. Temel olarak DKIM, ileti başlığına dijital bir imza eklemek suretiyle posta iletisi için kimlik denetimi yapacaktır. DKIM'in posta iletilerini filtrelemediğini belirtmek önemlidir. Gönderilen posta iletisinin gerçekliğinin SPAM filtreleri tarafından belirlenmesine izin verecektir.  `;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Sonraki`;

l.DKIM.Selector.Title           = `Seçici`;
l.DKIM.Selector.Name            = `DKIM kaydının adı seçici ile tanımlanacaktır. Seçici adı her şey olabilir ancak aşağıda birkaç seçenek sunulmuştur.`;
l.DKIM.Selector.Define          = `Böylce, DKIM anahtarı için oluşturulacak TXT kaydının adı belirlenecektir. Bu ad, şu şekilde görünecektir: `;
l.DKIM.Selector.Example1        = `seçici`;
l.DKIM.Selector.Example2        = `alan adı`;
l.DKIM.Selector.Next            = `Sonraki`;

l.DKIM.Key.Title                = `Ortak Anahtar`;
l.DKIM.Key.Tag                  = `Bu, daha önce oluşturulan ortak anahtar dosyasındaki anahtar dizgeyi ifade eden 'p' etiketini ekleyecektir.`;
l.DKIM.Key.Keys                 = `Ortak anahtarları buraya yerleştirin`;
l.DKIM.Key.Next                 = `Sonraki`;
l.DKIM.Key.Creating             = `DKIM anahtarlarının oluşturulması:`;
l.DKIM.Key.TwoParts             = `DKIM özel anahtar ve ortak anahtar olmak üzere iki kısımdan oluşur. DKIM anahtarlarının oluşturulma şekli, işletmeniz için eposta hizmetlerinin kimin tarafından sağlandığına göre değişiklik gösterecektir. `;
l.DKIM.Key.External             = `Hem posta hem de DNS için harici bir hizmet kullanmaktaysanız, posta sağlayıcısının ortak anahtarı size sunması gerekecektir. Bu ortak anahtar daha sonra DNS dahilinde bir TXT kaydı olarak yayınlanır. Çoğu DNS sağlayıcısı TXT kaydının boyutunu 255 karakter ile sınırlamaktadır, bu yüzden TXT kaydını oluşturmak ya da boyutunu artırmak için sağlayıcı ile birlikte çalışmanız gerekebilir. Özel anahtar posta sağlayıcısı tarafından muhafaza edilir ve genellikle işletmeye verilmez. `;
l.DKIM.Key.Internal             = `İşletme kendi eposta sunucusuna sahipse, DKIM anahtarları BT personeli (ya da DKIM'in uygulanmasından sorumlu olan kişi) tarafından oluşturulmalıdır. İnternet üzerinde mevcut birçok DKIM anahtarı üreticisi bulunmaktadır. Bunlardan birini kullanmak isterseniz, sadece anahtar oluşturulduktan sonra silme seçeneğinizin olacağından ve sizdeki dosyalara kopyalandığından emin olun.`;
l.DKIM.Key.OpenDKIM             = `Üçüncü bir taraf olmadan anahtar oluşturmak için, opendkim adında açık kaynaklı bir proje bulunmaktadır. Opendkim`;
l.DKIM.Key.OpenDKIM2            = `DKIM anahtarı oluşturmanıza yardımcı olacak çeşitli araçlar içermektedir.`;
l.DKIM.Key.OpenSSL              = `Bir diğer seçenek de DKIM anahtarlarını oluşturmak üzere OpenSSL'yi kullanmaktır. Bu, OpenSSL'nin en son sürümü kullanılarak ve özel anahtar için aşağıdaki komutun yazılmasıyla yapılabilir:`;
l.DKIM.Key.OpenSSL2             = `Böylece şu isimde bir özel anahtar dosyası oluşturulacaktır `;
l.DKIM.Key.OpenSSL3             = `Sonraki adımda, ortak anahtarı oluşturmak üzere şu komutu yazın: `;
l.DKIM.Key.OpenSSL4             = `Bu komut, önceden oluşturulmuş olan özel anahtarı temel alarak `;
l.DKIM.Key.OpenSSL5             = `ortak anahtarı oluşturacaktır`;
l.DKIM.Key.OpenSSL6             = `DKIM Özel Anahtarını, DKIM kurulumunda belirtilen konuma taşıyın. Erişimi kısıtlı bir dosyada yer aldığından emin olun.`;

l.DKIM.Results.Title            = `Aşağıda, DNS sunucunuzda oluşturulması gereken DKIM TXT kaydı verilmektedir. `;
l.DKIM.Results.Copy             = `Kopyala`;
l.DKIM.Results.Copied           = `KOPYALANDI!`;
l.DKIM.Results.SPFGuide         = `SPF Rehberine Geri Dön`;
l.DKIM.Results.DMARCGuide       = `DMARC Rehberi İle Devam Et`;
l.DKIM.Results.OwnDNS           = `Kendi DNS sunucunuza sahipseniz, lütfen yukarıda belirtilen DKIM politikası için TXT kayıtları oluşturun.`;
l.DKIM.Results.ThirdParty       = `Bir üçüncü taraf DNS sağlayıcısı kullanıyorsanız, lütfen TXT kaydı oluşturmak için verdikleri talimatları takip edin.`;
l.DKIM.Results.Reference        = `TXT kayıtlarının nasıl oluşturulacağı hakkında bilgi almak için `;
l.DKIM.Results.ResourcesPage    = `Lütfen `;
l.DKIM.Results.Information      = `Kaynaklar sayfasına başvurun.`;
l.DKIM.Results.NotUnderstood    = `Bu rehberi takip etmeyi denediniz ancak atacağınız adımları ya da kullanılan terminolojiyi anlamadıysanız, daha fazla destek almak için BT destek organizasyonunuz, Domain kayit sirketiniz ya da `;
l.DKIM.Results.Assistance       = `GCA danışma ile iletişime geçmeniz tavsiye edilir.`;
l.DKIM.Results.Note             = `Lütfen dikkate alın:`;
l.DKIM.Results.Support          = `DKIM anahtarları artık 2048-bit anahtarı destekleyebiliyor. Ancak, DNS TXT kayıtları 255 karakterin üzerindeki değerleri destekleyemiyor. Bunu şu iki yöntemden biriyle aşabilirsiniz: `;
l.DKIM.Results.Support2         = `1024-bit anahtar kullanarak ya da `;
l.DKIM.Results.Support3         = `Çok satırlı bir TXT kaydı oluşturarak`;
l.DKIM.Results.Support4         = `Aşağıda çok satırlı kayıt için örnek verilmiştir (dizgeyi birkaç satıra bölmek üzere tırnak işaretleri eklenmektedir):`;

l.DMARC.Home.Title              = `DMARC nedir?`;
l.DMARC.Home.Final              = `Artık SPF ve DKIM politikaları oluşturulmuş ve uygulanmış durumdadır. Şimdi DMARC politikasını oluşturmak üzere son adıma geçebiliriz.`;
l.DMARC.Home.Protocol           = `DMARC (Domain ismi Temelli Mesaj Doğrulama, Raporlama ve Uygunluk), gönderenlerin ve alıcıların, hileli epostalara karşı domainnı daha iyi korumalarına ve izlemelerine olanak tanıyan bir raporlama işlevine sahip bir eposta kimlik doğrulama protokolüdür. DMARC'ın uygulanması spam posta gönderenlerin eposta iletilerindeki ''Gönderen'' adresi üzerinde adres sahtekarlığı yapmasını engelleyecektir. DMARC politika ayarlarına bağlı olmak üzere, yetkisiz eposta sunucusundan gelen tüm posta iletileri ya karantinaya alınacak ya da reddedilecektir. Bir işletmenin alan adını kullanan tüm spam mesajlar ve oltalama mesajları alış noktasına (son kullanıcıya) ulaşmadan önce karantinaya alınacak ya da silinecektir. Oluşturulan raporlar, eylemin suç unsuru içermesi durumunda, istihbarat amaçlı ya da muhtemelen kanunların uygulanması amacıyla kullanılabilir.  `;
l.DMARC.Home.Stronger           = `DMARC, işletmenizin domain için daha güçlü bir eposta kimlik doğrulama sistemi sağlamak amacıyla SPF ve DKIM'i bir arada kullanacaktır.`;
l.DMARC.Home.ClickNext          = `DMARC politikası oluşturma sürecini başlatmak üzere lütfen Sonraki düğmesine tıklayın.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Sonraki`;

l.DMARC.Policy.Title            = `DMARC için politika seviyesi ne olmalı?`;
l.DMARC.Policy.Levels           = `DMARC için ayarlanabilecek üç politika seviyesi vardır. Bu seviyeler şunlardır:`;
l.DMARC.Policy.NoneEx           = `Hiçbiri - Olası şüpheli mesajları rapor eder ama tüm postalar gelen kutusuna gönderilir`;
l.DMARC.Policy.QuarantineEx     = `Karantinaya Al - SPF/DKIM politikası başarısız olursa, mesaj SPAM/önemsiz posta dosyasına gönderilir`;
l.DMARC.Policy.RejectEx         = `Reddet - SPF/DKIM politikası başarısız olursa, mesaj iptal edilir ve hiç teslim edilmez`;
l.DMARC.Policy.None             = `Hiçbiri (Önerilmez)`;
l.DMARC.Policy.Quarantine       = `Karantinaya Al`;
l.DMARC.Policy.Reject           = `Reddet`;
l.DMARC.Policy.Next             = `Sonraki `;
l.DMARC.Policy.Recommended1     = `DMARC politikasını karantinaya al ya da `;
l.DMARC.Policy.Recommended2     = `reddet seçeneklerine ayarlamadan önce,`;
l.DMARC.Policy.Recommended3     = `oluşturulan raporları izlemeniz ve kurallara uygun postanın bloke edilip edilmeyeceğini belirlemeniz için zaman tanıyacağından Hiçbiri ile başlamanız şiddetle tavsiye edilir. `;
l.DMARC.Policy.Tag              = `Bu, etiketi DMARC kaydınıza `;
l.DMARC.Policy.Tag2             = `ekleyecektir.`;

l.DMARC.Aggregates.Title        = `Toplu raporlar nereye gönderilmeli?`;
l.DMARC.Aggregates.Opt          = `Bu isteğe bağlıdır ancak şiddetle tavsiye edilir ve `;
l.DMARC.Aggregates.Opt2         = `böylece politikaya toplu rapor etiketi eklenecektir.`;
l.DMARC.Aggregates.Opt3         = `Analiz ve gözlem amaçlı olarak `;
l.DMARC.Aggregates.Opt4         = `politikaya eklenmesi şiddetle tavsiye edilir. Raporlar birden fazla adrese gönderilebilir.`;
l.DMARC.Aggregates.InputField   = `Eposta Adresi`;
l.DMARC.Aggregates.InputInvalid = `Lütfen geçerli bir eposta adresi girin`;
l.DMARC.Aggregates.Next         = `Sonraki`;
l.DMARC.Aggregates.Reports      = `SPF ve DKIM karşısında hangi eposta mesajlarının kabul edildiği/reddedildiği konusunda bilgi içeren (XML formatında) toplu raporlar oluşturulacaktır. Böylece işletmenizin kimlik doğrulama ile ilgili olası sorunları ve/veya spam faaliyetini görebilmesi sağlanacaktır. `;
l.DMARC.Aggregates.Contains     = `Bu raporlar şunları içermektedir`;
l.DMARC.Aggregates.HowToRead    = `İlk DMARC Raporlarınızı Nasıl Okuyacaksınız?`;
l.DMARC.Aggregates.By           = `(1. Bölüm) Hazırlayan Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `ISP Bilgisi`;
l.DMARC.Aggregates.Desc         = `İşletmenizin DMARC kaydının satır satır tarifi `;
l.DMARC.Aggregates.Summary      = `Kimlik doğrulama sonuçlarının bir özeti`;
l.DMARC.Aggregates.Important    = `Bu raporların tek bir kaynaktan gelmeyeceğini belirtmek önemlidir.  Potansiyel olarak günde 10 ila 100 (muhtemelen binlerce) rapor alabilirsiniz (oluşturulan rapor sayısı gönderilen eposta miktarına bağlıdır).`;
l.DMARC.Aggregates.Difficult    = `Bu, özellikle de XML formatında olmaları dolayısıyla ve bazı raporlar uzun olabileceğinden, her bir raporun manüel olarak incelenmesini zorlaştıracaktır.  `;
l.DMARC.Aggregates.Readable     = `Bu raporlar bir XML dönüştürücüsü geliştirmek ya da bir ticari sağlayıcı ile çalışmak suretiyle okunabilir hale getirilebilir. Ticari sağlayıcıların çoğu arka uçta raporları okunabilir hale getiren bir veri havuzu, aynı zamanda da çeşitli seviyelerde analiz ve rehberlik hizmeti sunacaktır.  `;
l.DMARC.Aggregates.Vendors      = `DMARC Sağlayıcıları (GCA Destekcileri):`;

l.DMARC.Forensics.Title         = `Adli raporlar nereye gönderilmeli?`;
l.DMARC.Forensics.Opt           = `Bu isteğe bağlıdır ve politikaya `;
l.DMARC.Forensics.Opt2          = `toplu rapor etketini ekleyecektir. Bu XML raporları ancak alıcı tarafın bu tip raporlar oluşturması halinde hazırlanacaktır. Hiçbir adli rapor almama şansınız da vardır. Bu, uluslararası gizlilik ve veri paylaşım yönetmeliklerine bağlıdır.`;
l.DMARC.Forensics.InputField    = `Eposa Adresi`;
l.DMARC.Forensics.InputInvalid  = `Lütfen geçerli bir eposta adresi girin`;
l.DMARC.Forensics.Next          = `Sonraki`;

l.DMARC.Subdomains.Title        = `Alt domain ler için DMARC politikası nasıldır?`;
l.DMARC.Subdomains.Define       = `Bu, işletmenizin domainine ait alt etki alanları için geçerli olan politika seviyesini tanımlamaktadır. Bu etiket,`;
l.DMARC.Subdomains.Define2      = `mevcut değilse, politika, ilk adımda tanımlanan en üst seviyedeki domain için geçerli olan politikayı takip edecektir.`;
l.DMARC.Subdomains.None         = `Hiçbiri`;
l.DMARC.Subdomains.Quarantine   = `Karantinaya Al`;
l.DMARC.Subdomains.Reject       = `Reddet`;
l.DMARC.Subdomains.Next         = `Sonraki`;
l.DMARC.Subdomains.Policy       = `Alan adı için geçerli politika. Seçenekler:`;
l.DMARC.Subdomains.NoneEx       = `Hiçbiri - hiçbir eylemde bulunulmaz ancak DMARC raporunda kaydedilir.`;
l.DMARC.Subdomains.QuarantineEx = `Karantinaya Al - eposta mesajı spam olarak işaretlenir.`;
l.DMARC.Subdomains.RejectEx     = `Reddet - mesaj silinir.`;
l.DMARC.Subdomains.Recommended  = `Birden fazla alt domaine sahipseniz, Hiçbiri olarak ayarlanması önerilir. Tüm alt etki alanları DMARC politikası ile uyumlu hale gelene kadar her bir alt domain için ayrı bir DMARC politikası kurmak en iyisidir. Ancak, hiç alt domain yoksa, Reddetme seçeneğinin kullanılması önerilir.`;

l.DMARC.Options.Title           = `(İsteğe Bağlı) Kalan seçenekler gerekli olmasa da ihtiyaç duyulursa ayarlanabilir:`;
l.DMARC.Options.ReportGen       = `1. Hata raporu ne zaman oluşturulmalı?`;
l.DMARC.Options.Default         = `Varsayılan`;
l.DMARC.Options.ReportPol       = `Hata raporlama politikasını tanımlar.`;
l.DMARC.Options.DefaultsTo      = `Herhangi bir tanımlama yapılmadığında, varsayılan `;
l.DMARC.Options.AllChecks       = `Temel kontrollerden herhangi birinin başarısız olması halinde, gönderen konumundaki Posta Aktarımcısı için rapor oluşturmaktır`;
l.DMARC.Options.AnyChecks       = `Temel kontrollerden herhangi birinin başarısız olması halinde, gönderen konumundaki Posta Aktarımcısı için rapor oluşturmaktır`;
l.DMARC.Options.OtherValues     = `Diğer değerler: `;
l.DMARC.Options.DKIMFails       = `DKIM kontrolü başarısız olursa, bir rapor oluşturmak`;
l.DMARC.Options.SPFFails        = `SPF kontrolü başarısız olursa, bir rapor oluşturmak`;
l.DMARC.Options.Relaxed         = `Esnek`;
l.DMARC.Options.Strict          = `Katı`;
l.DMARC.Options.DKIMAlign       = `2. DKIM için sıralama modu.`;
l.DMARC.Options.DKIMAlignOpt    = `DKIM için sıralama modu. Seçenekler şunlardır:`;
l.DMARC.Options.DKIMRelaxed     = `(esnek) tanımlama yoksa, varsayılandır. DKIM başlığında tanımlı tüm alt etki alanlarına izin verir.`;
l.DMARC.Options.DKIMStrict      = `(katı) gönderenin alan adı, DKIM başlığındaki alan adı ile tam olarak eşleşmelidir. `;
l.DMARC.Options.SPFAlign        = `3. SPF için sıralama modu`;
l.DMARC.Options.SPFAlignOpt     = `SPF için sıralama modu: Seçenekler şunlardır:`;
l.DMARC.Options.SPFRelaxed      = `(esnek) - tanımlama yoksa, varsayılandır. Tüm alt etki alanlarına izin verir.`;
l.DMARC.Options.SPFStrict       = `(katı) - MAIL FROM komutundaki (SMTP deki) işletme alan adı, (posta ögesinde bulunan) from: başlığındaki ile tam olarak eşleşmelidir`;
l.DMARC.Options.Percentage      = `4. DMARC Politikasına tabi ileti yüzdesi nedir?`;
l.DMARC.Options.Filter          = `İletilerin % kadarı DMARC politikası tarafından filtrelenir. 1 ile 100 arası herhangi bir sayı olabilir. Varsayılan sayı tüm iletileri kapsamak üzere 100'dür. `;
l.DMARC.Options.Formats         = `5. Oluşturulan raporların formatı`;
l.DMARC.Options.FormatsEx       = `Oluşturulan raporların formatını tanımlar. Varsayılan değer `;
l.DMARC.Options.RFC             = `bir tanımlama yapılmamışsa, format değerleridir. Format RFC 5965 şeklinde tanımlanmaktadır.`;
l.DMARC.Options.RFC2            = `Format RFC 5070 şeklinde tanımlanmaktadır. `;
l.DMARC.Options.ReportInt       = `6. Raporlama aralığı (saniye)`;
l.DMARC.Options.SecondsDefault  = `saniye (varsayılan)`;
l.DMARC.Options.DigitsOnly      = `Lütfen sadece rakam girin`;
l.DMARC.Options.ReportIntEx     = `Raporlama aralıklarını saniye olarak tanımlar. Herhangi bir tanımlama yoksa, varsayılan 86400 saniye ya da 24 saat şeklindedir. Raporların alıcı Posta Aktarımcıları tarafından gönderileceğinin bir garantisinin olmadığını lütfen dikkate alın. Raporlar en iyi çaba ilkesi ile gönderilir.`;
l.DMARC.Options.HToS            = `Saati Saniyeye Dönüştürücü`;
l.DMARC.Options.Hours           = `24 Saat`;
l.DMARC.Options.To              = `karşılığı`;
l.DMARC.Options.Seconds         = `86400 saniye`;
l.DMARC.Options.Finish          = `Bitti`;

l.DMARC.Results.Title           = `Aşağıda, DNS sunucunuzda oluşturulması gereken DMARC TXT kaydı verilmektedir.`;
l.DMARC.Results.Display         = `DMARC TXT Kaydı Sonucunu Görüntüleyin`;
l.DMARC.Results.Copy            = `Kopyala`;
l.DMARC.Results.Copied          = `KOPYALANDI!`;
l.DMARC.Results.SPFGuide        = `SPF Rehberine Geri Dön`;
l.DMARC.Results.DKIMGuide       = `DKIM Rehberine Geri Dön`;
l.DMARC.Results.OwnDNS          = `Kendi DNS sunucunuza sahipseniz lütfen aşağıdaki DMARC politikası için TXT kayıtlarını oluşturun.`;
l.DMARC.Results.ThirdParty      = `Bir üçüncü taraf DNS sağlayıcısı kullanıyorsanız, lütfen TXT kaydı oluşturmak için verdikleri talimatları takip edin. Çoğu durumda ihtiyaç duyacakları tek şey ana sistem adı,  _dmarc.<alanadı> ve tırnak işaretleri arasında bulunan bilgilerden oluşan metin değeridir.`;
l.DMARC.Results.Reference       = `TXT kayıtlarının nasıl oluşturulacağı hakkında bilgi almak için `;
l.DMARC.Results.ResourcesPage   = `Lütfen `;
l.DMARC.Results.Information     = `Kaynaklar sayfasına başvurun.`;
l.DMARC.Results.NotUnderstood   = `Bu rehberi takip etmeyi denediniz ancak atacağınız adımları ya da kullanılan terminolojiyi anlamadıysanız, daha fazla destek almak için BT destek organizasyonunuz, Domain kayit sirketi ya da `;
l.DMARC.Results.Assistance      = `GCA danışma ile iletişime geçmeniz tavsiye edilir.`;
l.DMARC.Results.Complete        = `Tamamlandı`;
l.DMARC.Results.Implemented     = `DMARC kaydı tamamlandığında, lütfen aşağıdaki DMARC doğrulama/denetleme sistemini kullanın:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Henüz tamamlanmış sayılmaz.`;
l.DMARC.Results.Wisdom          = `Tamamdır! Her eylemin nihai hedefi bilgeliktir. Lütfen yukarıda sunduğumuz kaynaklarımızdan dilediğinizce yararlanın ya da bize bir iki satır yazın ki tamamlamanıza yardımcı olalım. Bizler bunun için buradayız. `;
l.DMARC.Results.Check           = `Kontrol Et`;
l.DMARC.Results.Congratulations = `Tebrikler`;

export default l;
