import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { RouteListenerProps } from './Models'

const GtmRouteListener: React.FC<RouteListenerProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        pagePath: location.pathname,
      },
      dataLayerName: 'PageDataLayer'
    });
  }, [location]);
  return <>{children}</>; 
};

export default GtmRouteListener;
