import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

export const Step6 = (props: any) => {
  const { t } = useTranslation()

  const {
    reportGen, 
    handleSetReportGen, 
    alignmentModeDKIM, 
    handleSetAlignmentModeDKIM, 
    alignmentModeSPF, 
    handleSetAlignmentModeSPF, 
    percentage, 
    handleSetPercentage,
    reportFormat,
    handleSetReportFormat,
    reportIntSec,
    handleSetReportIntSec,
    hoursToConvert,
    handleSetHoursToConvert,
    convertedSeconds
    } = props

  return (
    <Step>
      <h2>{t('DMARC.Options.Title')}</h2>
      <div>
        <p>{t('DMARC.Options.ReportGen')}</p>
      </div>

      <div>
        <h3>{t('DMARC.Options.ReportGen')}</h3>
        <div className='reportgen-inputs'>
          <div className='group'>
            <input
              type='radio'
              name='reportgen'
              value='0'
              id='reportgen-0'
              checked={reportGen === '0'}
              onChange={(e) => handleSetReportGen(e.target.value)}
            />
            <label htmlFor='reportgen-0'>0 {t('DMARC.Options.Default')}</label>
          </div>
          <div className='group'>
            <input
              type='radio'
              name='reportgen'
              value='1'
              id='reportgen-1'
              checked={reportGen === '1'}
              onChange={(e) => handleSetReportGen(e.target.value)}
            />
            <label htmlFor='reportgen-1'>1</label>
          </div>
          <div className='group'>
            <input
              type='radio'
              name='reportgen'
              value='2'
              id='reportgen-2'
              checked={reportGen === '2'}
              onChange={(e) => handleSetReportGen(e.target.value)}
            />
            <label htmlFor='reportgen-2'>2</label>
          </div>
          <div className='group'>
            <input
              type='radio'
              name='reportgen'
              value='3'
              id='reportgen-3'
              checked={reportGen === '3'}
              onChange={(e) => handleSetReportGen(e.target.value)}
            />
            <label htmlFor='reportgen-3'>3</label>
          </div>
        </div>
      </div>

      <div>
        <p>{t('DMARC.Options.ReportPol')}</p>
        <p>
          {t('DMARC.Options.DefaultsTo')} 0 ({t('DMARC.Options.AllChecks')})
        </p>
        <p>{t('DMARC.Options.OtherValues')}</p>
        <ul>
          <li>
            <strong>1</strong> - {t('DMARC.Options.AnyChecks')}
          </li>
          <li>
            <strong>d</strong> - {t('DMARC.Options.DKIMFails')}
          </li>
          <li>
            <strong>s</strong> - {t('DMARC.Options.SPFFails')}
          </li>
        </ul>
      </div>

      <div>
        <h3>{t('DMARC.Options.DKIMAlign')}</h3>
      </div>
      <div>
        <select
          name=''
          id=''
          style={{ cursor: 'pointer', marginTop: '10px' }}
          value={alignmentModeDKIM}
          onChange={(e) => handleSetAlignmentModeDKIM(e.target.value)}
        >
          <option value='r'>r - ({t('DMARC.Options.Relaxed')})</option>
          <option value='s'>s - ({t('DMARC.Options.Strict')})</option>
        </select>
      </div>
      <div>
        <p style={{ fontWeight: 'bold' }}>{t('DMARC.Options.DKIMAlignOpt')}</p>
        <ul>
          <li>
            <strong>r</strong> - {t('DMARC.Options.DKIMRelaxed')}
          </li>
          <li>
            <strong>s</strong> - {t('DMARC.Options.DKIMStrict')}
          </li>
        </ul>
      </div>

      <div>
        <h3>{t('DMARC.Options.SPFAlign')}</h3>
      </div>
      <div>
        <select
          name=''
          id=''
          style={{ cursor: 'pointer', marginTop: '10px' }}
          value={alignmentModeSPF}
          onChange={(e) => handleSetAlignmentModeSPF(e.target.value)}
        >
          <option value='r'>r - ({t('DMARC.Options.Relaxed')})</option>
          <option value='s'>s - ({t('DMARC.Options.Strict')})</option>
        </select>
      </div>
      <div>
        <p style={{ fontWeight: 'bold' }}>{t('DMARC.Options.SPFAlignOpt')}</p>
        <ul>
          <li>
            <strong>r</strong> - {t('DMARC.Options.SPFRelaxed')}
          </li>
          <li>
            <strong>s</strong> - {t('DMARC.Options.SPFStrict')}
          </li>
        </ul>
      </div>

      <div>
        <h3>{t('DMARC.Options.Percentage')}</h3>
      </div>
      <div>
        <div style={{ margin: '20px 0' }}>
          <Slider value={percentage} onChange={handleSetPercentage} step={5} />
          <p style={{ fontWeight: 'bold' }}>{percentage}</p>
        </div>
        <p>{t('DMARC.Options.Filter')}</p>
      </div>

      <div>
        <h3>{t('DMARC.Options.Formats')}</h3>
      </div>
      <div>
        <select
          name=''
          id=''
          style={{ cursor: 'pointer', marginTop: '10px' }}
          value={reportFormat}
          onChange={(e) => handleSetReportFormat(e.target.value)}
        >
          <option value='afrf'>afrf ({t('DMARC.Options.Relaxed')})</option>
          <option value='iodef'>iodef ({t('DMARC.Options.Strict')})</option>
        </select>
      </div>
      <div>
        <p>{t('DMARC.Options.FormatsEx')}</p>
        <ul>
          <li>
            <strong>afrf</strong> - {t('DMARC.Options.RFC')}
          </li>
          <li>
            <strong>iodef</strong> - {t('DMARC.Options.RFC2')}
          </li>
        </ul>
      </div>

      <div>
        <h3>{t('DMARC.Options.ReportInt')}</h3>
      </div>
      <div>
        <input
          type='text'
          placeholder={t('DMARC.Options.SecondsDefault')}
          value={reportIntSec}
          onChange={(e) => handleSetReportIntSec(e.target.value)}
        />
      </div>
      <div>
        <p>{t('DMARC.Options.ReportIntEx')}</p>
      </div>

      <div>
        <p>
          <strong>{t('DMARC.Options.HToS')}</strong>
        </p>
        <div className={'seconds-converter'}>
          <input
            type='text'
            placeholder={t('DMARC.Options.Hours')}
            value={hoursToConvert || undefined}
            onChange={(e) => handleSetHoursToConvert(e.target.value)}
          />
          <span>{t('DMARC.Options.To')}</span>
          <input
            type='text'
            readOnly
            value={convertedSeconds || ''}
            placeholder={t('DMARC.Options.Seconds')}
          />
        </div>
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('DMARC.Options.Finish')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
