let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC üzrə bələdçi`;

l.Header.Main.SetupDMARC        = `DMARC-IN QURAŞDIRILMASI`;
l.Header.Main.HowItWorks        = `İŞ PRİNSİPİ`;
l.Header.Main.AboutDMARC        = `DMARC HAQQINDA`;
l.Header.Main.Resources         = `RESURSLAR`;
l.Header.Main.Contact           = `ƏLAQƏ`;

l.Footer.Main.SetupDMARC        = `DMARC-ın quraşdırılması`;
l.Footer.Main.HowItWorks        = `İş prinsipi`;
l.Footer.Main.AboutDMARC        = `DMARC haqqında`;
l.Footer.Main.Resources         = `Resurslar`;
l.Footer.Main.Contact           = `Əlaqə`;
l.Footer.Main.Copyright         = `Copyright ¬¨¬© 2023 Qlobal Kiber İttifaq (Global Cyber Alliance)`;
l.Footer.Main.PrivacyPolicy     = `Məxfilik Siyasəti`;
l.Footer.Main.Sitemap           = `Saytın xəritəsi`;

l.Home.Main.EnterDomain         = `DMARC-ın quraşdırılmasına başlamaq üçün`;
l.Home.Main.ToStart             = `domeninizi daxil edin`;
l.Home.Main.TagLine             = `Bir şey edin. Onu ölçün.`;
l.Home.Main.Welcome             = `DMARC-ın quraşdırılmasına dair bələdçiyə xoş gəlmisiniz. Bu quraşdırma bələdçisinin məqsədi, DMARC siyasətinin, o cümlədən Sender Policy Framework (SPF) və DomainKeys Identified Mail (DKIM) üzrə siyasətlərin yaradılması prosesində təşkilatınıza bələdçilik etməkdir. Brendi qorumağa kömək etmək məqsədilə bu üç siyasətin hamısını həyata keçirməklə təşkilatınız daha güclü e-poçt autentifikasiya mexanizminə malik olacaq.`;
l.Home.Main.FirstStep           = `İlk mərhələdə təşkilatınız bu üç siyasətdən hər hansı birini tətbiq etmədiyini təsdiq etməlidir.`;
l.Home.Main.InputWarning        = `Lütfən təşkilatın e-poçt ünvanında "@" işarəsindən sonra gördüyünüz domeni olduğu kimi daxil edin.`;
l.Home.Main.InputExample        = `Məsələn, növbəti info@globalcyberalliance.org kimi e-poçt ünvanında, mətn qutusunda globalcyberalliance.org domeni daxil edilməlidir.`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `İnternet səhifəsinin ünvanı tələb olunur`;
l.Home.Main.valid               = `İnternet səhifəsinin ünvanı düzgün deyil`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google Tətbiqləri və Ofis 365 istifadəçiləri:`;
l.Home.Main.UserWarningText1    = `SPF və DKIM-i həyata keçirmək üçün Resurslar səhifəsində `;
l.Home.Main.UserWarningLink     = `Bulud Xidməti Provayederləri `;
l.Home.Main.UserWarningText2    = `üçün verilən məlumata əməl edin. Bitirdikdən sonra, DMARC siyasətini həyata keçirməyə davam etmək üçün bu bələdçidən istifadə edin.`;
l.Home.Main.Submit              = `Daxil et`;
l.Home.Main.MoreInfo            = `DMARC haqqında daha çox məlumat əldə etmək istəyirsinizsə, sizi aylıq DMARC seminar sessiyalarımızdan birində iştirak etməyə dəvət edirik. Həmin seminar DMARC-ın əsaslarına həsr olunur və GCA DMARC QURAŞDIRMA BƏLƏDÇİSİ haqqında qısa məlumat təmin edir.`;
l.Home.Main.Webinar             = `Aylıq DMARC seminar sessiyaları hər ayın üçüncü çərşənbə axşamı günü iki dəfə keçirilir. Aşağıdakı müvafiq keçidə klikləməklə sizə münasib olan tarix və vaxtda qeydiyyatdan keçin:`;
l.Home.Main.SelectDate          = `Bu tarixi seç`;

l.ToolSelect.Main.Title         = `Nəticələr:`;
l.ToolSelect.Main.ResultStatus  = `Aşağıdakı cədvəldə həyata keçirilən <i class='fas fa-check-circle' style='color: green'></i> və ya keçirilməyən <i class='fas fa-times-circle' style='color: red'></i> protokollar göstərilmişdir.`;
l.ToolSelect.Main.Protocol      = `Quraşdırma bələdçisinə başlamaq üçün protokollardan birini seçin. `;
l.ToolSelect.Main.ShareButton   = `Nəticələri paylaşmaq istəyirsinizsə, sadəcə Paylaş düyməsinə klikləyin ki, keçidin nüsxəsi mübadilə buferinə köçürülsün.`;
l.ToolSelect.Main.Selecting     = `Protokolun seçilməsi ilə mövcud olan cari yazı nümayiş olunacaq.`;
l.ToolSelect.Main.DKIMWarning   = `DKIM-in yoxlama hissəsi hazırlanma mərhələsindədir. Buna baxmayaraq, DKIM sırasını seçməklə DKIM Quraşdırma Bələdçisini əldə etmək mümkündür.`;
l.ToolSelect.Main.NoDKIM        = `Standart seçici siyahısına əsasən, sizin domenlə əlaqədar hər hansı DKIM yazısı tapa bilmədik.`;
l.ToolSelect.Main.NoDKIM1       = `Seçicini tanıyırsınızsa, DKIM yazısını buradan axtara bilərsiniz:`;
l.ToolSelect.Main.NoDKIM2       = `Həmçinin e-poçt ünvanınızı qeyd etsəniz, cavab məktubu yaza biləcəyiniz hesaba e-məktub göndərə bilərik. Bu yolla e-məktubda DKIM yazısını axtara bilərik.`;
l.ToolSelect.Main.NoDKIMError   = `E-poçt düzgün deyil və ya daxil edilən domenə aid deyil.`;
l.ToolSelect.Main.SelectorMsg   = `Seçici axtarılır!`;
l.ToolSelect.Main.SelectorError = `Seçici tapılmadı.`;
l.ToolSelect.Main.EmailMsg      = `E-məktub göndərildi! Lütfən həmin e-məktuba cavab məktubu göndərin. Cavab məktubu göndərildikdən sonra, bu səhifə yenilənəcək və seçicinin adını təmin edəcək.`;
l.ToolSelect.Main.InputSelector = `Seçici`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Daxil et`;
l.ToolSelect.Main.Select        = `Seç`;
l.ToolSelect.Main.Next          = `Növbəti`;
l.ToolSelect.Main.Share         = `Paylaş`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `DMARC-ın quraşdırılmasını başa çatdırdınız`;
l.Complete.Main.check           = `SPF, DKIM və ya DMARC üzrə digər domenləri yoxlayaq?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `İnternet səhifəsinin ünvanı tələb olunur`;
l.Complete.Main.valid           = `İnternet səhifəsinin ünvanı düzgün deyil`;
l.Complete.Main.Next            = `Növbəti`;
l.Complete.Main.More            = `DMARC haqqında daha ətraflı`;

l.SPF.Home.Title                = `SPF nədir?`;
l.SPF.Home.SPF                  = `SPF (Sender Policy Framework) Göndərənin Siyasət İnfrastrukturu deməkdir. Bu siyasət hansı poçt serverlərinin təşkilatın, Äö√Ñ√¥s domenin adından məktub göndərmək səlahiyyəti olduğunu müəyyən edərək, göndərənin ünvanının saxtalaşdırılmasının qarşısını alır. SPF poçt serverini müəyyən etmədiyi hallarda məktubun göndərilməsi rədd edilir və ya məktub geri göndərilir.`;
l.SPF.Home.Start                = `SPF Siyasətinin yaradılması prosesinə başlamaq üçün Növbəti düyməsinə klikləyin.`;
l.SPF.Home.Next                 = `Növbəti`;

l.SPF.Authorization.Title       = `Aşağıda adı çəkilən serverlərdən hansının təşkilatın domenindən e-məktub göndərmək səlahiyyəti var?`;
l.SPF.Authorization.First       = `Siyasətin ilk hissəsinin məqsədi, həmin domenin adından məktub göndərmək səlahiyyətinə malik domenin daxil olan poçt serverlərini (MX-lər) müəyyən etməkdir.`;
l.SPF.Authorization.Current     = `Aşağıdakı siyahıda təşkilatınızla əlaqədar poçt serverlərinə dair cari DNS yazıları var. `;
l.SPF.Authorization.Recommended = `Sizə təkidlə Bəli seçməyi tövsiyə edirik, çünki yuxarıda verilən siyahıda sizin domenin adından məktub göndərmək səlahiyyəti olan serverlər olmalıdır. Bu yolla "mx" etiketi SPF siyasətinə əlavə olunacaq.`;
l.SPF.Authorization.Yes         = `Bəli (Tövsiyə olunan)`;
l.SPF.Authorization.No          = `Xeyr`;
l.SPF.Authorization.Missing     = `Siyahıda qeyd olunmayan sistemlər varsa və ya standart poçt serverləri deyilsə, həmin sistemləri növbəti seçimlərdə daxil edin.`;
l.SPF.Authorization.Next        = `Növbəti`;

l.SPF.Servers.Title             = `Domenin daxilində bu domenin adından məktub ötürə və ya çatdıra bilən hər hansı əlavə serverlər var?`;
l.SPF.Servers.Possible          = `Təşkilatın bütün poçt serverlərinin təşkilatın, Äö√Ñ√¥s DNS yazılarında müəyyən olunmaması ehtimalı var. Bunlar quraşdırılmış poçt sisteminə malik test serverlər və ya tətbiqlər ola bilər. Belə olduğu halda, əlavə server domenləri və ya IP ünvanlarını aşağıdakı qutulara əlavə edin.`;
l.SPF.Servers.Others            = `Domenin adından məktub göndərmək səlahiyyəti olan poçt serverləri deyil, yalnız digər daxili sistemlərin olduğu hallarda bu funksiyadan istifadə edilməlidir.`;
l.SPF.Servers.Added             = `Sistemlər əlavə olunarsa, SPF siyasətinə ,Äö√Ñ√≤a‚Äö√Ñ√¥ etiketi əlavə olunacaq.`;
l.SPF.Servers.InputField        = `Serverin adı və ya IP`;
l.SPF.Servers.InputInvalid      = `Tamamilə uyğun domen adı və ya IP ünvanı olmalıdır`;
l.SPF.Servers.Next              = `Növbəti`;

l.SPF.Domains.Title             = `Təşkilatınızın adından məktub ötürə və ya çatdıra bilən hər hansı xarici domenlər var?`;
l.SPF.Domains.Include           = `Bu bölmə SPF siyasətinin`;
l.SPF.Domains.Include2          = `"daxil etmə"`;
l.SPF.Domains.Include3          = `etiketini əlavə edəcək.`;
l.SPF.Domains.Trusted           = `Bu funksiyadan yalnız təşkilatın‚Äö√Ñ√¥s domenin adından məktub göndərmək həvalə edilən xarici domenin olduğu halda istifadə edilməlidir. Məsələn, Müştəri Əlaqələrinin İdarəetmə Sistemi, Bulud Poçt Provayderi (Google, Office 365, Yahoo!), və ya Bulud Təhlükəsizliyi Provayderi.`;
l.SPF.Domains.Note              = `Lütfən nəzərə alın: Bulud Xidməti Provayderindən istifadə edirsinizsə, onlarla bu parametrə dair müvafiq dəyər üzrə işləməlisiniz. `;
l.SPF.Domains.InputField        = `Domenin adı və ya IP`;
l.SPF.Domains.InputInvalid      = `Tamamilə uyğun domen adı və ya IP ünvanı olmalıdır`;
l.SPF.Domains.Next              = `Növbəti`;

l.SPF.Strict.Title              = `Serverlər e-məktubların emal olunmasında nə dərəcədə sərt olmalıdır?`;
l.SPF.Strict.Receiving          = `Bu e-məktubların emal olunmasında qəbul edən serverlərin nə dərəcədə sərt olmasını müəyyən edən SPF siyasətinin son bölməsidir. `;
l.SPF.Strict.SoftFail           = `Proqram Xətası (Tövsiyə olunan)`;
l.SPF.Strict.Fail               = `Xəta`;
l.SPF.Strict.Neutral            = `Neytral`;
l.SPF.Strict.AllowAll           = `Hamısına icazə ver`;
l.SPF.Strict.Next               = `Növbəti`;
l.SPF.Strict.Options            = `Seçimlər:`;
l.SPF.Strict.FailEx             = `Xətadır və ya`;
l.SPF.Strict.FailEx2            = `yalnız domen poçt serverlərinin (və "a" və "daxil etmə" bölmələrindəkilər) domenin adından məktub göndərmək icazəsinin olduğu mənasını verir. Bütün digərləri qadağan edilmişdir.`;
l.SPF.Strict.SoftEx             = `Proqram Xətası və ya`;
l.SPF.Strict.SoftEx2            = `yalnız domen poçt serverlərinin (və "a" və "daxil etmə" bölmələrindəkilər) domenin adından məktub göndərmək icazəsinin olduğu mənasını verir, ancaq bu keçid mərhələsindədir. Bütün digərləri qadağan edilmişdir.`;
l.SPF.Strict.NeutralEx          = `Neytral və ya`;
l.SPF.Strict.NeutralEx2         = `açıq şəkildə etibarlılıq haqqında heç nə deməyin mümkün olmadığı deməkdir.`;
l.SPF.Strict.AllowEx            = `Hamısına icazə ver və ya `;
l.SPF.Strict.AllowEx2           = `istənilən hostun həmin domenin adından məktub göndərə bildiyi deməkdir.`;
l.SPF.Strict.Warning1           = `Bundan `;
l.SPF.Strict.Warning2           = `heç vaxt istifadə`;
l.SPF.Strict.Warning3           = `olunmamalıdır`;
l.SPF.Strict.Recommended        = `Proqram Xətasından istifadə olunması tövsiyə edilir`;
l.SPF.Strict.MarkMail           = `Bu yolla müəyyən edilmiş kriteriyaya cavab vermədiyi halda məktub qeyri-müvafiq kimi işarələnəcək. `;

l.SPF.Results.Title             = `Aşağıdakı SPF TXT yazısı DNS serverinizdə yaradılmalıdır.`;
l.SPF.Results.Copy              = `Nüsxələ`;
l.SPF.Results.Copied            = `NÜSXƏLƏNDİ!`;
l.SPF.Results.DMARCGuide        = `DMARC üzrə bələdçiyə keç`;
l.SPF.Results.DKIMGuide         = `DMARC üzrə bələdçi ilə davam et`;
l.SPF.Results.OwnDNS            = `Özünüzə aid DNS serveriniz varsa, yuxarıda göstərilən SPF siyasətinə dair TXT yazılar yaradın. `;
l.SPF.Results.ThirdParty        = `Üçüncü tərəfin DNS provayderindən istifadə edirsinizsə, TXT yazının yaradılmasına dair təlimatlara əməl edin.`;
l.SPF.Results.Reference         = `Lütfən TXT yazıların yaradılmasına dair məlumat üçün `;
l.SPF.Results.ResourcesPage     = `Resurslar səhifəsinə `;
l.SPF.Results.Information       = `istinad edin.`;
l.SPF.Results.NotUnderstood     = `Buna əməl etməyə cəhd etmisinizsə, amma lazımi mərhələləri və ya istifadə olunan terminləri başa düşməmisinizsə, daha ətraflı yardım məqsədilə İT dəstək təşkilatınız, domen qeydiyyatçı və ya GCA məlumat mərkəzi ilə əlaqə saxlamağınız `;
l.SPF.Results.Assistance        = `tövsiyə olunur.`;
l.SPF.Results.Implemented       = `SPF yazısı həyata keçirildikdən sonra, lütfən aşağıdakı SPF yoxlama/yoxlayandan istifadə edin:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Yoxla`;
l.SPF.Results.SuccessDKIM       = `DKIM-Ə KEÇ`;
l.SPF.Results.SuccessDMARC      = `DMARC-Ə KEÇ`;
l.SPF.Results.NotQuite          = `Hələ ora çatmamışam.`;
l.SPF.Results.Wisdom            = `Eybi yoxdur! Müdriklik hər bir fəaliyyətin son məqsədidir. Yuxarıda adı çəkilən resurslarımızdan istifadə edə bilərsiniz və ya bizə bir neçə kəlmə yazaraq onları həyata keçirməyinizə kömək edə bilərik. Bizim işimiz elə bundan ibarətdir.`;

l.DKIM.Home.Title               = `DKIM nədir?`;
l.DKIM.Home.Mechanism           = `DKIM (DomainKeys Identified Mail) Müəyyən Edilmiş Domen Açarlı Poçt deməkdir. DKIM poçt mesajı ilə əlaqədar domenin kimliyini təsdiq etmək məqsədilə işlənib hazırlanan mexanizmdir. Burada asimmetrik kriptoqrafiya tətbiq edən autentifikasiyadan istifadə edilir. Əsas etibarilə DKIM, mesaj başlığına rəqəmsal imza əlavə edərək poçt mesajının etibarlılığını təsdiq edir. DKIM-in poçt mesajlarını süzgəcdən keçirmədiyini qeyd etmək çox vacibdir. O SPAM süzgəclərinə göndərilən poçt mesajının etibarlılığını müəyyən etməyə imkan verir.`;
l.DKIM.Home.ClickNext           = `DKIM Siyasətinin yaradılması prosesinə başlamaq üçün Növbəti düyməsinə klikləyin.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Növbəti`;

l.DKIM.Selector.Title           = `Seçici`;
l.DKIM.Selector.Name            = `Seçici DKIM yazısının adını müəyyən edir. Seçicinin adının fərqi yoxdur, amma aşağıda seçmək üçün bir neçə variantlar var.`;
l.DKIM.Selector.Define          = `Bu yolla DKIM açarı üçün yaradılacaq TXT yazısının adı müəyyən ediləcək. Bu aşağıdakı kimi göstəriləcək:`;
l.DKIM.Selector.Example1        = `seçici`;
l.DKIM.Selector.Example2        = `domenin adı`;
l.DKIM.Selector.Next            = `Növbəti`;

l.DKIM.Key.Title                = `İctimaiyyətə açıq açar`;
l.DKIM.Key.Tag                  = `Bu yolla daha əvvəl yaradılmış ictimaiyyətə açıq açar fayldakı mühüm sətir olan "p" etiketi əlavə ediləcək.`;
l.DKIM.Key.Keys                 = `İctimaiyyətə açıq açarları buraya yerləşdirin`;
l.DKIM.Key.Next                 = `Növbəti`;
l.DKIM.Key.Creating             = `DKIM açarlarının yaradılması:`;
l.DKIM.Key.TwoParts             = `DKIM iki hissədən ibarətdir - məxfi açar və ictimaiyyətə açıq açar. DKIM üçün açarların yaradılması təşkilatınıza e-poçt xidmətlərinin kim tərəfindən təmin edilməsindən asılı olaraq dəyişir. `;
l.DKIM.Key.External             = `Həm poçt, həm də DNS üçün xarici xidmətdən istifadə edirsinizsə, poçt provayderi sizə ictimaiyyətə açıq açar verməlidir. Sonra bu ictimaiyyətə açıq açar DNS-də TXT yazısı kimi dərc edilir. DNS provayderlərinin əksəriyyəti TXT yazısının həcmini 255 simvola qədər məhdudlaşdırır. Beləcə, onun həcmini artırmaq və ya TXT yazısı yaratmaq üçün provayderinizlə işləyə bilərsiniz. Poçt provayderi məxfi açarı özündə saxlayır və adətən təşkilata vermir.`;
l.DKIM.Key.Internal             = `Təşkilatın özünə aid e-poçt serveri varsa, DKIM açarları İT işçiləri tərəfindən (və ya DKIM-in həyata keçirilməsinə məsul olan şəxs tərəfindən) yaradılmalıdır. İnternetdə çoxlu sayda DKIM açarları yaradan proqramlar mövcuddur. Onlardan birindən istifadə etmək istəsəniz, faylları kompüterinizdə yaratdıqdan və köçürdükdən sonra, silmək seçiminizin olduğundan əmin olun.`;
l.DKIM.Key.OpenDKIM             = `Üçüncü tərəfin iştirakı olmadan açarları yaratmaq üçün "opendkim"`;
l.DKIM.Key.OpenDKIM2            = `adlı açıq mənbə xarakterli layihə mövcuddur. Opendkim DKIM açarının yaradılmasına kömək etmək məqsədilə bir neçə müxtəlif alətdən ibarətdir.`;
l.DKIM.Key.OpenSSL              = `Başqa variant isə DKIM açarlarını yaratmaq üçün OpenSSL-dən istifadə etməkdir. OpenSSL-in ən son versiyasından istifadə etməklə məxfi açar üçün aşağıdakı əmri daxil etmək olar:`;
l.DKIM.Key.OpenSSL2             = `Bu yolla növbəti adda məxfi açar faylı yaranacaq:`;
l.DKIM.Key.OpenSSL3             = `Növbəti, ictimaiyyətə açıq açarı yaratmaq üçün aşağıdakı əmri yazın:`;
l.DKIM.Key.OpenSSL4             = `Bu əmr daha əvvəl yaradılmış məxfi açar əsasında`;
l.DKIM.Key.OpenSSL5             = `ictimaiyyətə açıq açarı yaradacaq`;
l.DKIM.Key.OpenSSL6             = `DKIM məxfi açarının yerini DKIM-in quraşdırılmasında göstərilən məkana dəyişin. Məhdud giriş imkanı olan bir qovluqda olduğundan əmin olun.`;

l.DKIM.Results.Title            = `Aşağıdakı DKIM TXT yazısı DNS serverinizdə yaradılmalıdır.`;
l.DKIM.Results.Copy             = `Nüsxələ`;
l.DKIM.Results.Copied           = `NÜSXƏLƏNDİ!`;
l.DKIM.Results.SPFGuide         = `SPF üzrə bələdçiyə geri qayıt`;
l.DKIM.Results.DMARCGuide       = `DMARC üzrə bələdçi ilə davam et`;
l.DKIM.Results.OwnDNS           = `Özünüzə aid DNS serveriniz varsa, yuxarıda göstərilən DKIM siyasətinə dair TXT yazılar yaradın. `;
l.DKIM.Results.ThirdParty       = `Üçüncü tərəfin DNS provayderindən istifadə edirsinizsə, TXT yazının yaradılmasına dair təlimatlara əməl edin.`;
l.DKIM.Results.Reference        = `Lütfən TXT yazıların yaradılmasına dair məlumat üçün `;
l.DKIM.Results.ResourcesPage    = `Resurslar səhifəsinə `;
l.DKIM.Results.Information      = `istinad edin.`;
l.DKIM.Results.NotUnderstood    = `Buna əməl etməyə cəhd etmisinizsə, amma lazımi mərhələləri və ya istifadə olunan terminləri başa düşməmisinizsə, daha ətraflı yardım məqsədilə İT dəstək təşkilatınız, domen qeydiyyatçı və ya GCA məlumat mərkəzi ilə əlaqə saxlamağınız `;
l.DKIM.Results.Assistance       = `tövsiyə olunur.`;
l.DKIM.Results.Note             = `Lütfən nəzərə alın:`;
l.DKIM.Results.Support          = `DKIM açarları hazırda 2048-bitlik açarları dəstəkləyir. Buna baxmayaraq, DNS TXT yazıları 255 simvoldan artıq dəyərləri dəstəkləyə bilmir. Bu prosesi iki yolla ötürə bilərsiniz: `;
l.DKIM.Results.Support2         = `1024-bitlik açardan istifadə edərək və ya`;
l.DKIM.Results.Support3         = `Çoxsətirli TXT yazısı yaradaraq`;
l.DKIM.Results.Support4         = `Çoxsətirli yazının hər hansı bir nümunəsini aşağıda görə bilərsiniz (sətiri çoxsaylı sətirlərə ayırmaq üçün dırnaq işarələri əlavə edilmişdir):`;

l.DMARC.Home.Title              = `DMARC nədir?`;
l.DMARC.Home.Final              = `Artıq SPF və DKIM siyasətləri yaradılmış və həyata keçirilmişdir. İndi son mərhələyə, yəni DMARC siyasətinin yaradılması mərhələsinə keçə bilərik.`;
l.DMARC.Home.Protocol           = `DMARC (Domain-based Message Authentication, Reporting & Conformance) Domen əsaslı Mesajların Autentifikasiyası, Hesabatlıq və Uyğunluq deməkdir və e-poçt autentifikasiya protokolu olaraq e-məktub göndərənlərə və qəbul edənlərə domeni saxta məktublardan qoruyan funksiyanı təkmilləşdirmək və nəzarət etmək imkanı verən hesabatlıq funksiyasına malikdir. DMARC-ın həyata keçirilməsi vasitəsilə spamçıların poçt mesajlarındakı "Kimdən" ünvanını saxtalaşdırmasına imkan verilmir. DMARC siyasətinin parametrlərindən asılı olaraq qanunsuz poçt serverinə məxsus istənilən poçt mesajları karantinə salınır və ya rədd edilir. Təşkilatın domen adından istifadə edən bütün spam və fişinq mesajlar mənzil başına çatmadan (sonuncu istifadəçi) karantinə salınır və ya silinir. Fəaliyyət cinayət xarakterli olarsa, yaradılan hesabatlar kəşfiyyat və ya mümkün olduğu hallarda hüquq-mühafizə məqsədləri üçün istifadə edilə bilər.`;
l.DMARC.Home.Stronger           = `Təşkilatın domeni üçün daha güclü e-poçt autentifikasiya sistemi yaratmaq məqsədilə DMARC, SPF və DKIM-in hər ikisindən istifadə edir.`;
l.DMARC.Home.ClickNext          = `DKIM Siyasətinin yaradılması prosesinə başlamaq üçün Növbəti düyməsinə klikləyin.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Növbəti`;

l.DMARC.Policy.Title            = `DMARC protokolu üçün siyasətin səviyyəsi necə olmalıdır? `;
l.DMARC.Policy.Levels           = `DMARC protokolu üçün üç siyasət səviyyəsi təyin edilə bilər. Onlar bunlardır:`;
l.DMARC.Policy.NoneEx           = `Heç biri - Mümkün şübhəli poçt mesajları haqqında məlumat verir, amma bütün məktublar Gələnlər qovluğuna göndərilir`;
l.DMARC.Policy.QuarantineEx     = `Karantin - Xəta SPF/DKIM siyasəti, mesaj SPAM/Zibil qovluğuna göndərilir`;
l.DMARC.Policy.RejectEx         = `İmtina et - Xəta SPF/DKIM siyasəti, mesaj atılır və göndərilmir`;
l.DMARC.Policy.None             = `Heç biri (Tövsiyə olunan)`;
l.DMARC.Policy.Quarantine       = `Karantin`;
l.DMARC.Policy.Reject           = `İmtina et`;
l.DMARC.Policy.Next             = `Növbəti`;
l.DMARC.Policy.Recommended1     = `Sizə`;
l.DMARC.Policy.Recommended2     = `Heç biri ilə başlamaq təkidlə tövsiyə olunur,`;
l.DMARC.Policy.Recommended3     = `çünki bu yolla yaradılan hesabatlara nəzarət etməyə və DMARC siyasətini Karantin və ya İmtina et seçimlərinə keçirməzdən əvvəl, qanuni məktubun blok olunacağını müəyyən etmək üçün vaxt verəcək.`;
l.DMARC.Policy.Tag              = `Bu DMARC yazısına `;
l.DMARC.Policy.Tag2             = `etiket əlavə edəcək.`;

l.DMARC.Aggregates.Title        = `Ümumi hesabatlar hara göndərilməlidir?`;
l.DMARC.Aggregates.Opt          = `Bu əlavə funksiyadır, amma təkidlə tövsiyə olunur və `;
l.DMARC.Aggregates.Opt2         = `siyasətə "rua" etiketi əlavə edəcək. Təhlil və monitorinq məqsədilə bunun`;
l.DMARC.Aggregates.Opt3         = `siyasətə əlavə `;
l.DMARC.Aggregates.Opt4         = `edilməsi təkidlə tövsiyə olunur. Hesabatlar çoxlu sayda ünvanlara göndərilə bilər.`;
l.DMARC.Aggregates.InputField   = `E-poçt ünvanı`;
l.DMARC.Aggregates.InputInvalid = `Lütfən düzgün e-poçt ünvanı daxil edin`;
l.DMARC.Aggregates.Next         = `Növbəti`;
l.DMARC.Aggregates.Reports      = `SPF və DKIM-lə bağlı hansı poçt mesajlarının buraxılması və ya buraxılmamasına dair məlumatdan ibarət ümumi hesabatlar (XML formatda) yaradılacaq. Bu yolla təşkilatınızla əlaqədar mümkün autentifikasiya problemlərinə və/və ya spam fəaliyyətinə baxış təmin ediləcək.`;
l.DMARC.Aggregates.Contains     = `Hesabatlarda aşağıdakılar var:`;
l.DMARC.Aggregates.HowToRead    = `İlk DMARC hesabatını necə oxumaq olar?`;
l.DMARC.Aggregates.By           = `(Hissə 1) Müəllif: Emi Qorel):`;
l.DMARC.Aggregates.Info         = `ISP məlumatı`;
l.DMARC.Aggregates.Desc         = `Təşkilatınızın DMARC yazısının sətirbəsətir təsviri`;
l.DMARC.Aggregates.Summary      = `Autentifikasiya nəticələrinin xülasəsi`;
l.DMARC.Aggregates.Important    = `Bu hesabatların cəmi bircə mənbədən gəlmədiyini qeyd etmək çox vacibdir. Sizə gündəlik 10-dan 100-ə qədər (ola bilsin ki, minlərlə) hesabat (yaradılan hesabatların sayı göndərilən e-məktubların sayından asılıdır) göndərilə bilər. `;
l.DMARC.Aggregates.Difficult    = `Bu hər bir hesabatı əl ilə nəzərdən keçirməyi çətinləşdirir, xüsusən hesabatlar XML formatında və bəzi hesabatların uzun olduğunu nəzərə alsaq. `;
l.DMARC.Aggregates.Readable     = `Bu hesabatları insan üçün oxunaqlı etmək üçün XML konverteri yaratmaq olar və ya bu sahədə xidmət göstərən özəl təchizatçı ilə işləmək olar. Özəl təchizatçıların əksəriyyəti hesabatları insan üçün oxunaqlı etmək, o cümlədən təhlil və təlimatların müxtəlif səviyyələrini təmin etmək məqsədilə son yaddaş təmin edir.`;
l.DMARC.Aggregates.Vendors      = `DMARC Satıcıları (GCA Tərəfdaşları):`;

l.DMARC.Forensics.Title         = `Məhkəmə xarakterli hesabatlar hara göndərilməlidir?`;
l.DMARC.Forensics.Opt           = `Bu əlavə funksiyadır, amma`;
l.DMARC.Forensics.Opt2          = `siyasətə "ruf" etiketi əlavə ediləcək. Bu XML hesabatlar yalnız qəbul edən tərəfin bu növ hesabatlar yaratdığı təqdirdə yaradılacaq. Ola bilsin ki, məhkəmə xarakterli hesabatlar qəbul etmədiniz. Buna səbəb ölkələr arasında məxfilik və məlumatların paylaşılmasına dair nizamnamələrdir. `;
l.DMARC.Forensics.InputField    = `E-poçt ünvanı`;
l.DMARC.Forensics.InputInvalid  = `Lütfən düzgün e-poçt ünvanı daxil edin`;
l.DMARC.Forensics.Next          = `Növbəti`;

l.DMARC.Subdomains.Title        = `Alt domenlər üçün DMARC siyasəti?`;
l.DMARC.Subdomains.Define       = `Bu yolla təşkilatınızın domeninin alt domenləri üçün siyasət səviyyəsi müəyyən ediləcək. Bu etiket`;
l.DMARC.Subdomains.Define2      = `mövcud deyilsə, siyasət ilkin mərhələdə müəyyən edilən ən yüksək səviyyəli domen üzrə siyasətə əməl edəcək.`;
l.DMARC.Subdomains.None         = `Heç biri`;
l.DMARC.Subdomains.Quarantine   = `Karantin`;
l.DMARC.Subdomains.Reject       = `İmtina et`;
l.DMARC.Subdomains.Next         = `Növbəti`;
l.DMARC.Subdomains.Policy       = `Domen üzrə siyasət: Seçimlər bunlardır:`;
l.DMARC.Subdomains.NoneEx       = `Heç biri - heç bir addım atılmır, amma DMARC hesabatında qeydə alınır.`;
l.DMARC.Subdomains.QuarantineEx = `Karantin - poçt mesajı spam kimi işarələnir.`;
l.DMARC.Subdomains.RejectEx     = `İmtina et - mesaj silinir.`;
l.DMARC.Subdomains.Recommended  = `Çoxlu sayda alt domenləriniz varsa, Heç biri seçilməsi tövsiyə edilir. Bütün alt domenlərin DMARC siyasəti ilə uyğunluğu təmin olunana qədər hər bir alt domen üzrə ayrıca DMARC siyasəti yaratmaq daha yaxşıdır. Buna baxmayaraq, alt domenlər mövcud olmadığı hallarda İmtina et seçilməsi tövsiyə edilir.`;

l.DMARC.Options.Title           = `(Əlavə) Yerdə qalan seçimlər tələb edilmir, ancaq lazım olduqda tənzimlənə bilər:`;
l.DMARC.Options.ReportGen       = `1. Xəta hesabatı nə vaxt yaradılmalıdır?`;
l.DMARC.Options.Default         = `Standart`;
l.DMARC.Options.ReportPol       = `Xəta haqqında məlumat verilməsi üzrə siyasəti müəyyən edir.`;
l.DMARC.Options.DefaultsTo      = `Müəyyən edilmədiyi hallarda standart təyinatlar`;
l.DMARC.Options.AllChecks       = `Hər hansı əsas yoxlama uğursuz olarsa, göndərən MTA-ya hesabat yarat`;
l.DMARC.Options.AnyChecks       = `Hər hansı əsas yoxlama uğursuz olarsa, göndərən MTA-ya hesabat yarat`;
l.DMARC.Options.OtherValues     = `Digər dəyərlər bunlardır:`;
l.DMARC.Options.DKIMFails       = `DKIM yoxlaması uğursuz olarsa, bir hesabat yarat`;
l.DMARC.Options.SPFFails        = `SPF yoxlaması uğursuz olarsa, bir hesabat yarat`;
l.DMARC.Options.Relaxed         = `Sərbəst`;
l.DMARC.Options.Strict          = `Sərt`;
l.DMARC.Options.DKIMAlign       = `2. DKIM üzrə düzləndirmə rejimi`;
l.DMARC.Options.DKIMAlignOpt    = `DKIM üzrə düzləndirmə rejimi. Seçimlər bunlardır:`;
l.DMARC.Options.DKIMRelaxed     = `(sərbəst) müəyyən edilməyibsə, standart təyinat. İstənilən alt domenə DKIM başlığında müəyyən olunmağa icazə verir. `;
l.DMARC.Options.DKIMStrict      = `(sərt) göndərənin domen adı DKIM başlığındakı domenlə tamamilə eyni olmalıdır.`;
l.DMARC.Options.SPFAlign        = `3. SPF üzrə düzləndirmə rejimi`;
l.DMARC.Options.SPFAlignOpt     = `SPF üzrə düzləndirmə rejimi. Seçimlər bunlardır:`;
l.DMARC.Options.SPFRelaxed      = `(sərbəst) müəyyən edilməyibsə, standart təyinat. İstənilən alt domenə icazə verir. `;
l.DMARC.Options.SPFStrict       = `(sərt) KİMDƏN MƏKTUB əmrindəki təşkilatın domen adı (SMTP) və Kimdən: başlığı (məktubda) tamamilə eyni olmalıdır.`;
l.DMARC.Options.Percentage      = `4. DMARC Siyasəti tərəfindən mesajların mövzusunun faiz nisbəti?`;
l.DMARC.Options.Filter          = `DMARC siyasəti tərəfindən süzgəc olunan mesajların %-i. 1-dən 100-ə qədər istənilən rəqəm ola bilər. Bütün mesajlar mənasını verən standart təyinat 100-dür. `;
l.DMARC.Options.Formats         = `5. Yaradılan hesabatların formatı`;
l.DMARC.Options.FormatsEx       = `Yaradılan hesabatların formatını müəyyən edir. Format dəyərləri bunlardır:`;
l.DMARC.Options.RFC             = `müəyyən edilməyibsə, standart təyinat. Format RFC 5965 ilə müəyyən olunur.`;
l.DMARC.Options.RFC2            = `Format RFC 5965 ilə müəyyən olunur.`;
l.DMARC.Options.ReportInt       = `6. Hesabatlar arasında fasilə (saniyə) `;
l.DMARC.Options.SecondsDefault  = `saniyə (standart)`;
l.DMARC.Options.DigitsOnly      = `Lütfən yalnız rəqəm daxil edin`;
l.DMARC.Options.ReportIntEx     = `Hesabatlar arasındakı fasiləni saniyə ilə müəyyən edir. Müəyyən olunmadığı hallarda standart təyinat 86400 saniyədir və ya 24 saatdır. Lütfən nəzərə alın ki, hesabatların qəbul edən MTA-lar tərəfindən göndərilməsinə zəmanət verilmir. Hesabatlar ən yaxşı səylər əsasında göndərilir.`;
l.DMARC.Options.HToS            = `Saat-saniyə konverteri`;
l.DMARC.Options.Hours           = `24 saatı`;
l.DMARC.Options.To              = `çevir`;
l.DMARC.Options.Seconds         = `86400 saniyəyə`;
l.DMARC.Options.Finish          = `Başa çatdır`;

l.DMARC.Results.Title           = `Aşağıdakı DMARC TXT yazısı DNS serverinizdə yaradılmalıdır.`;
l.DMARC.Results.Display         = `DMARC TXT Hesabat Nəticəsini göstər`;
l.DMARC.Results.Copy            = `Nüsxələ`;
l.DMARC.Results.Copied          = `NÜSXƏLƏNDİ!`;
l.DMARC.Results.SPFGuide        = `SPF üzrə bələdçiyə geri qayıt`;
l.DMARC.Results.DKIMGuide       = `DKIM üzrə bələdçiyə geri qayıt`;
l.DMARC.Results.OwnDNS          = `Öz DNS serveriniz varsa, xahiş edirik, aşağıdakı DMARC siyasəti üçün TXT qeydlərini yaradın.`;
l.DMARC.Results.ThirdParty      = `Üçüncü tərəfin DNS provayderindən istifadə edirsinizsə, TXT yazının yaradılmasına dair təlimatlara əməl edin.`;
l.DMARC.Results.Reference       = `Lütfən TXT yazıların yaradılmasına dair məlumat üçün `;
l.DMARC.Results.ResourcesPage   = `Resurslar səhifəsinə `;
l.DMARC.Results.Information     = `istinad edin.`;
l.DMARC.Results.NotUnderstood   = `Buna əməl etməyə cəhd etmisinizsə, amma lazımi mərhələləri və ya istifadə olunan terminləri başa düşməmisinizsə, daha ətraflı yardım məqsədilə İT dəstək təşkilatınız, domen qeydiyyatçı və ya GCA məlumat mərkəzi ilə əlaqə saxlamağınız `;
l.DMARC.Results.Assistance      = `tövsiyə olunur.`;
l.DMARC.Results.Complete        = `Başa çatdır`;
l.DMARC.Results.Implemented     = `DMARC yazısı həyata keçirildikdən sonra, lütfən aşağıdakı DMARC yoxlama/yoxlayandan istifadə edin:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Hələ ora çatmamışam.`;
l.DMARC.Results.Wisdom          = `Eybi yoxdur! Müdriklik hər bir fəaliyyətin son məqsədidir. Yuxarıda adı çəkilən resurslarımızdan istifadə edə bilərsiniz və ya bizə bir neçə kəlmə yazaraq onları həyata keçirməyinizə kömək edə bilərik. Bizim işimiz elə bundan ibarətdir.`;
l.DMARC.Results.Check           = `Yoxla`;
l.DMARC.Results.Congratulations = `Təbriklər!`;

export default l;
