import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

export const HeaderLeaderboard = () => {
  const { t } = useTranslation()

  return (
    <div
      id='av_section_1'
      className='alternate_color avia-section-large avia-no-border-styling  avia-full-stretch avia-bg-style-fixed  avia-builder-el-0  el_before_av_section  avia-builder-el-first  blue-gradient fullsize'
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
        position: 'relative',
      }}
      data-section-bg-repeat='stretch'
    >
      <div className='container'>
        <main
          role='main'
          itemProp='mainContentOfPage'
          className='template-page content  av-content-full alpha units'
        >
          <div className='post-entry post-entry-type-page post-entry-1776'>
            <div className='entry-content-wrapper clearfix'>
              <div className='flex_column_table   av-equal-height-column-flextable av-break-at-tablet-flextable flex-column-table-1 '>
                <div className='flex_column av_two_third  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-1  el_before_av_one_third  avia-builder-el-first  '>
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h1  blockquote modern-quote  avia-builder-el-2  el_before_av_textblock  avia-builder-el-first     '
                    >
                      <h1
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        {t('Global.Main.Title')}
                      </h1>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock  av_inherit_color    '
                        style={{ color: '#ffffff' }}
                        itemProp='text'
                      >
                        <p>{t('Home.Main.Welcome')}</p>
                      </div>
                    </section>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div className='flex_column av_one_third  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-4  el_after_av_two_third  avia-builder-el-last  '>
                  <div className='flex-column-inner'>
                    <div className='avia-button-wrap avia-button-center avia-button-tablet-left   avia-builder-el-5  avia-builder-el-no-sibling '>
                      <Link
                        to={'/dmarc'}
                        className='avia-button avia-icon_select-no avia-color-secondary avia-size-small avia-position-center'
                      >
                        <span className='avia_iconbox_title'>DMARC Guide</span>
                      </Link> 
                    </div>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
            </div>
          </div>
        </main>
        {/* close content main element */}
      </div>
    </div>
  )
}

export const NewsHeadlines = () => {
  return (
    <div
      id='av_section_2'
      className='avia-section main_color avia-section-large avia-no-border-styling  avia-full-stretch avia-bg-style-scroll  avia-builder-el-6  el_after_av_section  el_before_av_section  blue-radial-gradient  container_wrap fullsize'
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: 'center center',
      }}
      data-section-bg-repeat='stretch'
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='post-entry post-entry-type-page post-entry-1776'>
            <div className='entry-content-wrapper clearfix'>
              <div
                style={{ height: '50px' }}
                className='hr hr-invisible    avia-builder-el-7  el_before_av_one_full  avia-builder-el-first '
              >
                <span className='hr-inner'>
                  <span className='hr-inner-style' />
                </span>
              </div>
              <div
                className='flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-8  el_after_av_hr  el_before_av_hr  '
                id='select-your-toolkit'
              >
                <div className='flex-column-inner'>
                  <div
                    style={{ paddingBottom: '10px' }}
                    className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-9  avia-builder-el-no-sibling     '
                  >
                    <h2 className='av-special-heading-tag ' itemProp='headline'>
                      Explore Toolkits for Specialized Industries
                    </h2>
                    <div className='special-heading-border'>
                      <div className='special-heading-inner-border' />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ height: '30px' }}
                className='hr hr-invisible    avia-builder-el-10  el_after_av_one_full  el_before_av_one_third '
              >
                <span className='hr-inner '>
                  <span className='hr-inner-style' />
                </span>
              </div>
              <div className='flex_column_table  home--boxes av-equal-height-column-flextable -flextable flex-column-table-3 '>
                <div
                  className='flex_column av_one_third  avia-full-stretch flex_column_table_cell av-equal-height-column av-align-top first  avia-builder-el-11  el_after_av_hr  el_before_av_one_third  '
                  style={{
                    background:
                      'url(/images/GCA-Toolkit-Home-Industry-Small-Business@2x.jpg) top right no-repeat',
                  }}
                >
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h3  blockquote modern-quote  avia-builder-el-12  el_before_av_textblock  avia-builder-el-first     '
                    >
                      <h3
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Small Business
                      </h3>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock  av_inherit_color    '
                        style={{ color: '#ffffff' }}
                        itemProp='text'
                      >
                        <p>
                          The GCA Cybersecurity Toolkit for Small Business is
                          designed to help small to medium-sized businesses to
                          significantly reduce the cyber risks they face every
                          day.
                        </p>
                        <p>
                          <strong>
                            <a href='/fr/petites-entreprises/'>FR</a> |{' '}
                            <a href='/es/pequenas-empresas/'>ES</a> |{' '}
                            <a href='/de/kmu/'>DE</a> |{' '}
                            <a href='/id/umkm/'>ID</a>
                          </strong>
                        </p>
                      </div>
                    </section>
                    <div className='avia-button-wrap avia-button-left    avia-builder-el-14  el_after_av_textblock  avia-builder-el-last '>
                      <a
                        href='https://gcatoolkit.org/smallbusiness/'
                        className='avia-button   avia-icon_select-no avia-color-borderless avia-size-medium avia-position-left '
                      >
                        <span className='avia_iconbox_title'>
                          Explore the Toolkit
                          <br />
                          for <strong>Small Business</strong>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div
                  className='flex_column av_one_third  avia-full-stretch flex_column_table_cell av-equal-height-column av-align-top   avia-builder-el-15  el_after_av_one_third  el_before_av_one_third  '
                  style={{
                    background:
                      'url(/images/GCA-Toolkit-Home-Industry-Elections@2x-2.jpg) top center no-repeat',
                  }}
                >
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h3  blockquote modern-quote  avia-builder-el-16  el_before_av_textblock  avia-builder-el-first     '
                    >
                      <h3
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Elections
                      </h3>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock  av_inherit_color    '
                        style={{ color: '#ffffff' }}
                        itemProp='text'
                      >
                        <p>
                          Free and fair elections are a critical part of the
                          democratic process. The GCA Cybersecurity Toolkit for
                          Elections helps election officials mitigate cyber
                          risks, to keep elections secure.
                        </p>
                      </div>
                    </section>
                    <div className='avia-button-wrap avia-button-left    avia-builder-el-18  el_after_av_textblock  avia-builder-el-last '>
                      <a
                        href='https://gcatoolkit.org/elections/'
                        className='avia-button   avia-icon_select-no avia-color-borderless avia-size-medium avia-position-left '
                      >
                        <span className='avia_iconbox_title'>
                          Explore the Toolkit
                          <br />
                          for <strong>Elections</strong>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div
                  className='flex_column av_one_third  avia-full-stretch flex_column_table_cell av-equal-height-column av-align-top   avia-builder-el-19  el_after_av_one_third  avia-builder-el-last  '
                  style={{
                    background:
                      'url(/images/GCA-Toolkit-Home-Industry-Journalism@2x-2.jpg) center left no-repeat',
                  }}
                >
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h3  blockquote modern-quote  avia-builder-el-20  el_before_av_textblock  avia-builder-el-first     '
                    >
                      <h3
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Journalists
                      </h3>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock  av_inherit_color    '
                        style={{ color: '#ffffff' }}
                        itemProp='text'
                      >
                        <p>
                          The GCA Cybersecurity Toolkit for Journalists was
                          created to help defend press freedom by providing
                          journalists with tools to secure their online
                          accounts, data, and communications.
                        </p>
                      </div>
                    </section>
                    <div className='avia-button-wrap avia-button-left    avia-builder-el-22  el_after_av_textblock  avia-builder-el-last '>
                      <a
                        href='https://gcatoolkit.org/journalists/'
                        className='avia-button   avia-icon_select-no avia-color-borderless avia-size-medium avia-position-left '
                      >
                        <span className='avia_iconbox_title'>
                          Explore the Toolkit
                          <br />
                          for <strong>Journalists</strong>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
            </div>
          </div>
        </div>
        {/* close content main div */}
      </div>
    </div>
  )
}

export const ReachStats = () => {
  return (
    <div
      id='av_section_3'
      className='avia-section alternate_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-23  el_after_av_section  el_before_av_section   container_wrap fullsize'
      style={{ backgroundColor: '#2e8eff' }}
    >
      <div className='container' style={{ maxWidth: '1100px' }}>
        <div className='template-page content  av-content-full alpha units'>
          <div className='post-entry post-entry-type-page post-entry-1776'>
            <div className='entry-content-wrapper clearfix'>
              <div className='flex_column_table   av-equal-height-column-flextable -flextable flex-column-table-4 '>
                <div className='flex_column av_one_full  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-24  el_before_av_one_third  avia-builder-el-first  narrow '>
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-25  el_before_av_textblock  avia-builder-el-first     '
                    >
                      <h2
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Toolkit Reach
                      </h2>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock narrow narrower    '
                        itemProp='text'
                      >
                        <p style={{ textAlign: 'center' }}>
                          Measuring the impact of what we do is a core tenet of
                          GCA.
                        </p>
                        <p style={{ textAlign: 'center' }}>
                          Our cybersecurity toolkits have been developed with
                          the intention of providing the means for organizations
                          and individuals to{' '}
                          <b data-stringify-type='bold'>
                            <i data-stringify-type='italic'>take action</i>
                          </b>{' '}
                          to improve their cybersecurity posture.
                        </p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
              <div className='flex_column_table  home--stats av-equal-height-column-flextable -flextable flex-column-table-5 '>
                <div className='flex_column av_one_third  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-27  el_after_av_one_full  el_before_av_one_third  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock narrow    '
                        itemProp='text'
                      >
                        <p style={{ textAlign: 'center' }}>
                          <strong>MORE THAN</strong>
                        </p>
                      </div>
                    </section>
                    <div
                      className='avia-animated-number av-force-default-color  avia-color-  avia-builder-el-29  el_after_av_textblock  avia-builder-el-last  avia_animate_when_visible'
                      data-timer={3000}
                    >
                      <strong className='heading avia-animated-number-title'>
                        <span
                          className='avia-single-number __av-single-number'
                          data-number_format
                          data-number={480}
                          data-start_from={0}
                        >
                          480
                        </span>
                        <span className='avia-no-number'>K</span>
                      </strong>
                      <div className='avia-animated-number-content'>
                        <p>TOOLKIT VISITS</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div className='flex_column av_one_third  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-30  el_after_av_one_third  el_before_av_one_third  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock narrow    '
                        itemProp='text'
                      >
                        <p style={{ textAlign: 'center' }}>
                          <strong>VISITORS FROM</strong>
                        </p>
                      </div>
                    </section>
                    <div
                      className='avia-animated-number av-force-default-color  avia-color-  avia-builder-el-32  el_after_av_textblock  avia-builder-el-last  avia_animate_when_visible'
                      data-timer={3000}
                    >
                      <strong className='heading avia-animated-number-title'>
                        <span
                          className='avia-single-number __av-single-number'
                          data-number_format
                          data-number={200}
                          data-start_from={0}
                        >
                          200
                        </span>
                      </strong>
                      <div className='avia-animated-number-content'>
                        <p>COUNTRIES</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div className='flex_column av_one_third  flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-33  el_after_av_one_third  avia-builder-el-last  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div
                        className='avia_textblock narrow    '
                        itemProp='text'
                      >
                        <p style={{ textAlign: 'center' }}>
                          <strong>
                            SMALL BUSINESS TOOLKIT
                            <br />
                            AVAILABLE IN{' '}
                          </strong>
                        </p>
                      </div>
                    </section>
                    <div
                      className='avia-animated-number av-force-default-color  avia-color-  avia-builder-el-35  el_after_av_textblock  avia-builder-el-last  avia_animate_when_visible'
                      data-timer={3000}
                    >
                      <strong className='heading avia-animated-number-title'>
                        <span
                          className='avia-single-number __av-single-number'
                          data-number_format
                          data-number={5}
                          data-start_from={0}
                        >
                          5
                        </span>
                      </strong>
                      <div className='avia-animated-number-content'>
                        <p>LANGUAGES</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
            </div>
          </div>
        </div>
        {/* close content main div */}
      </div>
    </div>
  )
}

export const About = () => {
  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
      style={{}}
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='post-entry post-entry-type-page post-entry-1776'>
            <div className='entry-content-wrapper clearfix'>
              <div className='flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-37  el_before_av_one_third  avia-builder-el-first  '>
                <div className='flex-column-inner'>
                  <div
                    style={{ paddingBottom: '10px' }}
                    className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-38  el_before_av_hr  avia-builder-el-first     '
                  >
                    <h2 className='av-special-heading-tag ' itemProp='headline'>
                      About the Toolkit
                    </h2>
                    <div className='special-heading-border'>
                      <div className='special-heading-inner-border' />
                    </div>
                  </div>
                  <div className='hr hr-invisible  hr-responsive   avia-builder-el-39  el_after_av_heading  avia-builder-el-last '>
                    <div style={{ height: '50px' }} className='hr-desktop ' />
                    <div
                      style={{ height: '1px', marginTop: '0px' }}
                      className='hr-tablet hr-mobile'
                    />
                  </div>
                </div>
              </div>
              <div className='flex_column_table   av-equal-height-column-flextable av-break-at-tablet-flextable flex-column-table-7 '>
                <div className='flex_column av_one_third  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-40  el_after_av_one_full  el_before_av_two_third  column-top-margin'>
                  <div className='flex-column-inner'>
                    <div className='lottie-animation    avia-builder-el-41  avia-builder-el-no-sibling '>
                      <div
                        className='lottie-animation-inner'
                        id='lottie-animation-41'
                        data-loop='true'
                        data-json='/json/Home-About-Toolkit.json'
                        data-animtype='svg'
                        data-fallbackimage='/images/Home-About-Toolkit-Lottie-Fallback-Image@2x.png'
                      />
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div className='flex_column av_two_third  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-42  el_after_av_one_third  el_before_av_hr  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div className='avia_textblock     ' itemProp='text'>
                        <p>
                          <strong>
                            The GCA Cybersecurity Toolkits are designed to
                            address the most important cybersecurity challenges
                            that organizations and individuals face every day
                          </strong>
                          . Our recommendations are based on Center for Internet
                          Security Controls (
                          <a
                            href='https://www.cisecurity.org/controls/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            CIS Controls
                          </a>
                          ). The CIS Controls are a recommended set of actions,
                          built and continuously updated using current threat
                          information and expert guidance, for preventing and/or
                          reducing the most common attacks in today’s cyber
                          threat landscape. In fact, just addressing the first
                          five CIS Controls{' '}
                          <a
                            href='https://blog.qualys.com/news/2017/10/12/the-critical-security-controls-basic-cyber-security-hygiene-for-your-organization'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            can reduce your risk of cyber-attack by 85%
                          </a>
                          .
                        </p>
                        <p>
                          Most importantly, the tools contained here are free to
                          use, and don’t require advanced IT knowledge to
                          implement. Use the GCA Cybersecurity Toolkit to assess
                          your security posture, implement free tools, find
                          practical tips, and use free resources and guides to
                          improve your company’s cybersecurity readiness and
                          response. We also invite you to join our{' '}
                          <a
                            href='https://community.globalcyberalliance.org/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            community forum
                          </a>{' '}
                          to share your experience and discuss the toolkit with
                          peers and GCA staff.
                        </p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
              <div
                style={{ height: '80px' }}
                className='hr hr-invisible   av-mini-hide  avia-builder-el-44  el_after_av_two_third  el_before_av_hr '
              >
                <span className='hr-inner '>
                  <span className='hr-inner-style' />
                </span>
              </div>
              <div
                style={{ height: '20px' }}
                className='hr hr-invisible   av-desktop-hide av-medium-hide av-small-hide  avia-builder-el-45  el_after_av_hr  el_before_av_one_full '
              >
                <span className='hr-inner '>
                  <span className='hr-inner-style' />
                </span>
              </div>
              <div className='flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-46  el_after_av_hr  el_before_av_hr  '>
                <div className='flex-column-inner'>
                  <div
                    style={{ paddingBottom: '10px' }}
                    className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-47  avia-builder-el-no-sibling     '
                  >
                    <h2 className='av-special-heading-tag ' itemProp='headline'>
                      Toolkit Resources
                    </h2>
                    <div className='special-heading-border'>
                      <div className='special-heading-inner-border' />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ height: '70px' }}
                className='hr hr-invisible   av-small-hide av-mini-hide  avia-builder-el-48  el_after_av_one_full  el_before_av_one_half '
              >
                <span className='hr-inner '>
                  <span className='hr-inner-style' />
                </span>
              </div>
              <div className='flex_column_table   av-equal-height-column-flextable -flextable flex-column-table-9 '>
                <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-top first  avia-builder-el-49  el_after_av_hr  el_before_av_one_half  '>
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h4  blockquote modern-quote  avia-builder-el-50  el_before_av_hr  avia-builder-el-first     '
                    >
                      <h4
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Media Kit
                      </h4>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <div
                      style={{}}
                      className='hr hr-short hr-left     avia-builder-el-51  el_after_av_heading  el_before_av_textblock '
                    >
                      <span className='hr-inner '>
                        <span className='hr-inner-style' />
                      </span>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div className='avia_textblock     ' itemProp='text'>
                        <p>
                          Interested in learning more? Find useful resources,
                          marketing materials, logos and shareable content here
                          in the GCA Cybersecurity Toolkit Media Kit.
                        </p>
                      </div>
                    </section>
                    <div className='avia-button-wrap avia-button-left    avia-builder-el-53  el_after_av_textblock  avia-builder-el-last '>
                      <a
                        href='https://gcatoolkit.org/media-kit/'
                        className='avia-button   avia-icon_select-no avia-color-borderless avia-size-large avia-position-left '
                      >
                        <span className='avia_iconbox_title'>
                          View Media Kits
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='av-flex-placeholder' />
                <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-top   avia-builder-el-54  el_after_av_one_half  avia-builder-el-last  '>
                  <div className='flex-column-inner'>
                    <div
                      style={{ paddingBottom: '10px' }}
                      className='av-special-heading av-special-heading-h4  blockquote modern-quote  avia-builder-el-55  el_before_av_hr  avia-builder-el-first     '
                    >
                      <h4
                        className='av-special-heading-tag '
                        itemProp='headline'
                      >
                        Our Sponsors
                      </h4>
                      <div className='special-heading-border'>
                        <div className='special-heading-inner-border' />
                      </div>
                    </div>
                    <div
                      style={{}}
                      className='hr hr-short hr-left     avia-builder-el-56  el_after_av_heading  el_before_av_textblock '
                    >
                      <span className='hr-inner '>
                        <span className='hr-inner-style' />
                      </span>
                    </div>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div className='avia_textblock     ' itemProp='text'>
                        <p>
                          The GCA Cybersecurity Toolkits are made possible by
                          the support of our generous sponsors. Learn more about
                          sponsoring organizations, and find out how to become a
                          sponsor.
                        </p>
                      </div>
                    </section>
                    <div className='avia-button-wrap avia-button-left    avia-builder-el-58  el_after_av_textblock  avia-builder-el-last '>
                      <a
                        href='https://gcatoolkit.org/sponsors/'
                        className='avia-button   avia-icon_select-no avia-color-borderless avia-size-large avia-position-left '
                      >
                        <span className='avia_iconbox_title'>
                          Development Sponsors
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*close column table wrapper. Autoclose: 1 */}
              <p />
            </div>
          </div>
        </div>
        {/* close content main div */}
      </div>
    </div>
  )
}

export const Theme = () => {
  return (
    <div id='main'>
      <div
        id='av_section_5'
        className='avia-section main_color avia-section-large avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-59  el_after_av_section  avia-builder-el-last   container_wrap fullsize'
        style={{ backgroundColor: '#e8e8e8' }}
      >
        <div className='container'>
          <div className='template-page content  av-content-full alpha units'>
            <div className='post-entry post-entry-type-page post-entry-1776'>
              <div className='entry-content-wrapper clearfix'>
                <div className='flex_column_table   av-equal-height-column-flextable av-break-at-tablet-flextable flex-column-table-10 '>
                  <div className='flex_column av_one_half  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding first  avia-builder-el-60  el_before_av_one_half  avia-builder-el-first  '>
                    <div className='flex-column-inner'>
                      <div
                        style={{ paddingBottom: '10px' }}
                        className='av-special-heading av-special-heading-h2  blockquote modern-quote  avia-builder-el-61  el_before_av_textblock  avia-builder-el-first     '
                      >
                        <h2
                          className='av-special-heading-tag '
                          itemProp='headline'
                        >
                          Get the Latest News
                        </h2>
                        <div className='special-heading-border'>
                          <div className='special-heading-inner-border' />
                        </div>
                      </div>
                      <section
                        className='av_textblock_section '
                        itemType='https://schema.org/CreativeWork'
                      >
                        <div className='avia_textblock     ' itemProp='text'>
                          <p>
                            Sign up to receive news, events and other updates
                            from the GCA team. Use the checkboxes to customize
                            the news you receive from GCA. Your preferences can
                            be changed at anytime.
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='av-flex-placeholder' />
                  <div className='flex_column av_one_half  av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-63  el_after_av_one_half  avia-builder-el-last  '>
                    <div className='flex-column-inner'>
                      <section
                        className='avia_codeblock_section   avia_code_block_0'
                        itemType='https://schema.org/CreativeWork'
                      >
                        <div className='avia_codeblock' itemProp='text'>
                          <div
                            className='hbspt-form'
                            id='hbspt-form-1614349839000-5738478777'
                          />
                          <div className='only-ie'>
                            <h3>
                              Please visit{' '}
                              <a
                                href='https://share.hsforms.com/1KHJXiDHAR7qXOURTXBH2RA2bzpm'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                here
                              </a>{' '}
                              if form does not load.
                            </h3>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                {/*close column table wrapper. Autoclose: 1 */}
              </div>
            </div>
          </div>
          {/* close content main div */}{' '}
          {/* section close by builder template */}{' '}
        </div>
        {/*end builder template*/}
      </div>
      {/* close default .container_wrap element */}{' '}
    </div>
  )
}
