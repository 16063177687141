let l = {
  Global: { Main: {} },
  Header: { Main: {} },
  Footer: { Main: {} },
  Home: { Main: {} },
  ToolSelect: { Main: {}, DMARC: {} },
  Complete: { Main: {} },
  SPF: {
    Home: {},
    Authorization: {},
    Servers: {},
    Domains: {},
    Strict: {},
    Results: {},
  },
  DKIM: { Home: {}, Selector: {}, Key: {}, Results: {} },
  DMARC: {
    Home: {},
    Policy: {},
    Aggregates: {},
    Forensics: {},
    Subdomains: {},
    Options: {},
    Results: {},
  },
}

l.Global.Main.Title = `DMARC Guide`

l.Global.Type = `Type`
l.Global.Name = `Name`
l.Global.Content = `Content`
l.Header.Main.SetupDMARC = `SETUP DMARC`
l.Header.Main.HowItWorks = `HOW IT WORKS`
l.Header.Main.AboutDMARC = `ABOUT DMARC`
l.Header.Main.Resources = `RESOURCES`
l.Header.Main.Contact = `Contact`

l.Footer.Main.SetupDMARC = `Setup DMARC`
l.Footer.Main.HowItWorks = `How it works`
l.Footer.Main.AboutDMARC = `About DMARC`
l.Footer.Main.Resources = `Resources`
l.Footer.Main.Contact = `Contact`
l.Footer.Main.Copyright = `Copyright © 2023 Global Cyber Alliance`
l.Footer.Main.PrivacyPolicy = `Privacy Policy`
l.Footer.Main.Sitemap = `Sitemap`

l.Home.Main.EnterDomain = `Enter your domain`
l.Home.Main.ToStart = `to start the DMARC Setup`
l.Home.Main.TagLine = `Do Something. Measure It.`
l.Home.Main.Welcome = `Welcome to the DMARC Setup Guide. The purpose of this setup guide is to guide your organization through the process of creating a DMARC policy, as well as policies for Sender Policy Framework (SPF) and DomainKeys Identified Mail (DKIM). By implementing all three policies, your organization will have a stronger email authentication mechanism in place to help protect the brand.`
l.Home.Main.FirstStep = `The first step is confirming whether not your organization is using any of the three policies.`
l.Home.Main.InputWarning = `Please input the domain from your organization's email address, exactly as it appears after the @ symbol in the email address.`
l.Home.Main.InputExample = `For example, the following email address, <b>domainsecurityscanner@globalcyberalliance.org</b>, <br />the domain to input in the text box is <b>globalcyberalliance.org</b>`
l.Home.Main.InputDomain = `yourdomain.com`
l.Home.Main.Required = `The website address is required`
l.Home.Main.valid = `The website address is not valid`
l.Home.Main.PleaseNote = `PLEASE NOTE:`
l.Home.Main.UserWarningHeader = `Google Apps and Office 365 Users:`
l.Home.Main.UserWarningText1 = `Please follow the information provided on the Resources Page for`
l.Home.Main.UserWarningLink = `Cloud Service Providers`
l.Home.Main.UserWarningText2 = `to implement SPF and DKIM. Once completed, please use this guide to continue with the implementation of the DMARC policy.`
l.Home.Main.Submit = `Submit`

l.ToolSelect.Main.Title = `Results for`
l.ToolSelect.Main.ResultStatus = `The below table indicates which protocols have <i class='fas fa-check-circle' style='color: green'></i> or have not <i class='fas fa-times-circle' style='color: red'></i> been implemented.`
l.ToolSelect.Main.Protocol = `Please select one of the protocols to start the setup guide.`
l.ToolSelect.Main.ShareButton = `If you would like to share the results just click on the Share button, a link will be copied to clipboard.`
l.ToolSelect.Main.Selecting = `Selecting a protocol will also show the current record if available.`
l.ToolSelect.Main.DKIMWarning = `The DKIM checking portion is still under development. However the DKIM Setup Guide is still available by selecting the DKIM row.`
l.ToolSelect.Main.NoDKIM = `We couldn't find a DKIM record associated with your domain given a list of default selectors:`
l.ToolSelect.Main.NoDKIM1 = `If you know the selector you can scan for the DKIM record here:`
l.ToolSelect.Main.NoDKIM2 = `Or if you supply an email address we'll send an email to the account which you can reply to and we'll scan the email for a DKIM record.`
l.ToolSelect.Main.NoDKIMError = `Email is not valid or does not belong to the supplied domain.`
l.ToolSelect.Main.SelectorMsg = `Scanning for selector!`
l.ToolSelect.Main.SelectorError = `Selector not found.`
l.ToolSelect.Main.EmailMsg = `Email sent! Please reply to the email. Once the reply is sent, this page will refresh and provide the selector name.`
l.ToolSelect.Main.InputSelector = `Selector`
l.ToolSelect.Main.InputEmail = `user@example.com`
l.ToolSelect.Main.Submit = `Submit`
l.ToolSelect.Main.Select = `Select`
l.ToolSelect.Main.Next = `Next`
l.ToolSelect.Main.Share = `Share`

l.ToolSelect.DMARC.RedX = `DMARC has not been implemented on this email’s domain. Please press the ‘Next’ button below to start the DMARC Setup Guide.`
l.ToolSelect.DMARC.RedExcl = `Thank you for getting started with DMARC. You are currently at the lowest level, which is a great starting point. However, you must receive reports in order to determine if DMARC/SPF/DKIM are functioning correctly. Please add the ‘rua’ tag to your DMARC policy. Additional information about the ‘rua’ tag can be found <a class="link-blue" href="https://dmarc.globalcyberalliance.org/dmarc-reporting-key-benefits-takeaways/">here</a>.`
l.ToolSelect.DMARC.RedCheck = `Thank you for getting started with DMARC. You are currently at the lowest level and receiving reports, which is a great starting point. Please make sure to review the reports, make the appropriate adjustments, and move to either quarantine or reject soon. Additional information about reporting tools can be found <a class="link-blue" href="https://dmarc.globalcyberalliance.org/dmarc-reporting-key-benefits-takeaways/">here</a>.`
l.ToolSelect.DMARC.AmberExcl = `You are currently at the second level. However, you must receive reports in order to determine if DMARC/SPF/DKIM are functioning correctly and move to the highest level (reject). Please add the ‘rua’ tag to your DMARC policy. Additional information about the ‘rua’ tag can be found <a class="link-blue" href="https://dmarc.globalcyberalliance.org/dmarc-reporting-key-benefits-takeaways/">here</a>.`
l.ToolSelect.DMARC.AmberCheck = `You are currently at the second level and receiving reports. Please make sure to review the reports, make the appropriate adjustments, and move to reject soon. Additional information about reporting tools can be found <a class="link-blue" href="https://dmarc.globalcyberalliance.org/dmarc-reporting-key-benefits-takeaways/">here</a>.`
l.ToolSelect.DMARC.GreenCheck = `You are at the highest level! Please make sure to continue reviewing the reports and make the appropriate adjustments, if needed.`

l.Complete.Main.Title = `You have completed setting up DMARC`
l.Complete.Main.check = `Check another domain for SPF, DKIM, or DMARC?`
l.Complete.Main.InputDomain = `yourdomain.com`
l.Complete.Main.Required = `The website address is required`
l.Complete.Main.valid = `The website address is not valid`
l.Complete.Main.Next = `Next`
l.Complete.Main.More = `More about DMARC`

l.SPF.Home.Title = `What is SPF?`
l.SPF.Home.SPF = `SPF stands for Sender Policy Framework. This policy is responsible for preventing the sender address from being spoofed by defining which mail servers are authorized to send mail on behalf of the organization’s domain. If the mail server is not defined in SPF then the message is rejected or bounced.`
l.SPF.Home.Start = `Please click on the Next button to start the process of how to create the SPF Policy.`
l.SPF.Home.Next = `Next`
l.SPF.Home.Previous = `Previous`
l.SPF.Home.Restart = 'Start Over'
l.SPF.Home.SingleScan = 'Single Scan'
l.SPF.Home.BulkScan = 'Bulk Scan'
l.SPF.Home.StartOver = 'Start Over'

l.SPF.Authorization.Title = `Are servers listed below authorized to send email for the organization's domain?`
l.SPF.Authorization.First = `The first portion of the policy is to define the incoming mail servers (MXes) of the domain that are authorized to also send mail for that domain.`
l.SPF.Authorization.Current = `The list below contains the current DNS records for the mail servers associated with your organization.`
l.SPF.Authorization.Recommended = `It is strongly recommended that ‘Yes’ be selected as the list above should be the authorized servers for your domain. By doing so, the tag ‘mx’ will be added to the SPF policy.`
l.SPF.Authorization.Yes = `Yes (Recommended)`
l.SPF.Authorization.No = `No`
l.SPF.Authorization.Missing = `If there are systems missing from the list or are not the standard mail servers, then include those systems in the next option.`
l.SPF.Authorization.Next = `Next`

l.SPF.Servers.Title = `Are there any additional servers within the domain that may deliver or relay mail for this domain?`
l.SPF.Servers.Possible = `There is a possibility that not all the organization mail servers are defined in the organization’s DNS records. These could be test servers or applications with built-in mail systems. If that is the case, then please add the additional server domains or IP addresses in the boxes below.`
l.SPF.Servers.Others = `This should only be used if there are other internal systems, other than the mail servers, authorized to send mail for the domain.`
l.SPF.Servers.Added = `If systems are added, then this will add the ‘a’ tag to the SPF policy.`
l.SPF.Servers.InputField = `Server Name or IP`
l.SPF.Servers.InputInvalid = `Must be a fully qualifying Domain Name or IP address`
l.SPF.Servers.Next = `Next`

l.SPF.Domains.Title = `Are there any external domains that may deliver or relay mail for your organization?`
l.SPF.Domains.Include = `This section will add the`
l.SPF.Domains.Include2 = `include`
l.SPF.Domains.Include3 = `tag of the SPF Policy.`
l.SPF.Domains.Trusted = `This should only be used if an external domain is trusted to send mail message for your organization’s domain. For example, Customer Relations Management System, Cloud Mail Provider (Google, Office 365, Yahoo!), or Cloud Security Provider.`
l.SPF.Domains.Note = `Please note: If you are using a Cloud Service Provider, you must work with them for the appropriate value for this setting.`
l.SPF.Domains.InputField = `Domain Name or IP`
l.SPF.Domains.InputInvalid = `Must be a fully qualifying Domain Name or IP address`
l.SPF.Domains.Next = `Next`

l.SPF.Strict.Title = `How strict should the servers be treating the emails?`
l.SPF.Strict.Receiving = `This is the last section of the SPF policy which will define how strict the receiving servers should be when treating emails.`
l.SPF.Strict.SoftFail = `Soft Fail (Recommended)`
l.SPF.Strict.Fail = `Fail`
l.SPF.Strict.Neutral = `Neutral`
l.SPF.Strict.AllowAll = `Allow All `
l.SPF.Strict.Next = `Next`
l.SPF.Strict.Options = `Options:`
l.SPF.Strict.FailEx = `Fail or `
l.SPF.Strict.FailEx2 = `means only the domain’s mail servers (and those in the ‘a’ and ‘include’ sections) are allowed to send mail for the domain. All others are prohibited.</li>`
l.SPF.Strict.SoftEx = `Soft Fail or `
l.SPF.Strict.SoftEx2 = `means only the domain’s mail servers (and those in the ‘a’ and ‘include’ sections) are allowed to send mail for the domain, but it is in transition. All other are prohibited.</li>`
l.SPF.Strict.NeutralEx = `Neutral or `
l.SPF.Strict.NeutralEx2 = `means explicitly that nothing can be said about validity.`
l.SPF.Strict.AllowEx = `Allow All or`
l.SPF.Strict.AllowEx2 = `means that any host can send mail for the domain.`
l.SPF.Strict.Warning1 = `This should `
l.SPF.Strict.Warning2 = `never`
l.SPF.Strict.Warning3 = `be used`
l.SPF.Strict.Recommended = `It is recommended to use Soft Fail`
l.SPF.Strict.MarkMail = `This will mark mail as non-compliant if it does not meet the defined criteria.`

l.SPF.Results.Title = `Below is the SPF TXT record which must be created on your DNS server.`
l.SPF.Results.Copy = `Copy`
l.SPF.Results.Copied = `COPIED!`
l.SPF.Results.DMARCGuide = `Skip to DMARC Guide`
l.SPF.Results.DKIMGuide = `Continue to DKIM Guide`
l.SPF.Results.OwnDNS = `If you have your own DNS server, then please create TXT records for the above SPF policy.`
l.SPF.Results.ThirdParty = `If you are using a third party DNS provider, then please follow their instructions for creating a TXT record.`
l.SPF.Results.Reference = `Please reference the`
l.SPF.Results.ResourcesPage = `Resources page`
l.SPF.Results.Information = `for information on how to create TXT records.`
l.SPF.Results.NotUnderstood = `If you have tried to follow this but have not understood the steps to take or the terminology used, it is recommended that you contact your IT support organization, domain registrar, or GCA Info`
l.SPF.Results.Assistance = `for further assistance.`
l.SPF.Results.Implemented = `Once the SPF record has been implemented, please use the SPF validation/checker below:`
l.SPF.Results.YourDomain = `yourdomain.com`
l.SPF.Results.Check = `Check`
l.SPF.Results.SuccessDKIM = `GO TO DKIM`
l.SPF.Results.SuccessDMARC = `GO TO DMARC`
l.SPF.Results.NotQuite = `Not quite there yet.`
l.SPF.Results.Wisdom = `That's OK! Wisdom is the final goal of every action. Please feel free to make use of our resources, above, or drop us a line and we can help you implement. That's what we're here for.`

l.DKIM.Home.Title = `What is DKIM?`
l.DKIM.Home.Mechanism1 = `DKIM stands for DomainKeys Identified Mail. DKIM is a mechanism designed for the purpose of validating a domain’s identity that is associated with a mail message by using authentication that uses asymmetric cryptography. Basically, DKIM will authenticate a mail message by adding a digital signature to the message header which can be validated by the receiving mail server.`
l.DKIM.Home.Mechanism2 = `Every domain can have one or more DKIM records, each of which will have a unique name.  The number of records is dependent on the mail servers and 3rd party mail systems (salesforce, hubspot, mailchimp, etc.) being used.`
l.DKIM.Home.Mechanism3 = `To check for DKIM, you can send an email to <a href="mailto:domainsecurityscanner@globalcyberalliance.org">domainsecurityscanner@globalcyberalliance.org</a> from the tool you are using to send mail.`
l.DKIM.Home.Action = `If you know the record name (selector), you can input it here:`
l.DKIM.Home.YourDomain = `yourdomain.com`
l.DKIM.Home.Next = `Next`
l.DKIM.Home.Or = `Or`

l.DKIM.Selector.Title = `Selector`
l.DKIM.Selector.Title = `Selector`
l.DKIM.Selector.Name = `The selector will define the name of the DKIM record. The selector name can be anything, but below are a few options to choose from.`
l.DKIM.Selector.Define = `This will define the name of the TXT record to be created for the DKIM key. This will appear as follows:`
l.DKIM.Selector.Example1 = `selector`
l.DKIM.Selector.Example2 = `domain name`
l.DKIM.Selector.Next = `Next`

l.DKIM.Key.Title = `Public Key`
l.DKIM.Key.Tag = `This will add the 'p' tag, which is the key string in public key file generated previously.`
l.DKIM.Key.Keys = `Place public keys here`
l.DKIM.Key.Next = `Next`
l.DKIM.Key.Creating = `Creating the DKIM keys:`
l.DKIM.Key.TwoParts = `DKIM has two parts, the private key and public key. Creating the keys for DKIM will vary depending on who is providing email services to your organization.`
l.DKIM.Key.External = `If you are using an external service for both mail and DNS, then the mail provider will need to provide you with the public key. This public key is then published as a TXT record in DNS. Most DNS providers limit the size of the TXT record to 255 characters, so you may have to work with the provider to increase the size or create the TXT record. The private key is held by the mail provider and typically not provided to the organization.`
l.DKIM.Key.Internal = `If the organization has its own email server, then the DKIM keys must be generated by the IT staff (or the one responsible for DKIM implementation). There are multiple DKIM key generators available on the Internet. If you choose to use one of these, just make sure that you have the option to delete the key after it is generated and copied to files on your end.`
l.DKIM.Key.OpenDKIM = `To create the keys without a third party, an open source project called opendkim`
l.DKIM.Key.OpenDKIM2 = `is available. Opendkim contains various tools to assist with creating the DKIM key.`
l.DKIM.Key.OpenSSL = `Another option is to use OpenSSL to generate the DKIM keys. This can be done by using the most recent version of OpenSSL and typing the following command for the private key:`
l.DKIM.Key.OpenSSL2 = `This will generate a private key file called`
l.DKIM.Key.OpenSSL3 = `Next, type the following command to generate the public key:`
l.DKIM.Key.OpenSSL4 = `This command will generate the public key`
l.DKIM.Key.OpenSSL5 = `based off of the previously created private key`
l.DKIM.Key.OpenSSL6 = `Move the DKIM Private Key in the location specified by the DKIM installation. Make sure it is in a folder with restricted access.`

l.DKIM.Results.Title = `Below is the DKIM TXT record which must be created on your DNS server.`
l.DKIM.Results.Copy = `Copy`
l.DKIM.Results.Copied = `COPIED!`
l.DKIM.Results.SPFGuide = `Return to SPF Guide`
l.DKIM.Results.DMARCGuide = `Continue to DMARC Guide`
l.DKIM.Results.OwnDNS = `If you have your own DNS server, then please create TXT records for the above DKIM policy.`
l.DKIM.Results.ThirdParty = `If you are using a third party DNS provider, then please follow their instructions for creating a TXT record.`
l.DKIM.Results.Reference = `Please reference the`
l.DKIM.Results.ResourcesPage = `Resources page`
l.DKIM.Results.Information = `for information on how to create TXT records.`
l.DKIM.Results.NotUnderstood = `If you have tried to follow this but have not understood the steps to take or the terminology used, it is recommended that you contact your IT support organization, domain registrar, or GCA Info`
l.DKIM.Results.Assistance = `for further assistance.`
l.DKIM.Results.Note = `Please note:`
l.DKIM.Results.Support = `DKIM keys can now support 2048-bit keys. However, DNS TXT records cannot support values of over 255 characters. You can by-pass this by either:`
l.DKIM.Results.Support2 = `Using 1024-bit key or,`
l.DKIM.Results.Support3 = `Create a multi-line TXT record`
l.DKIM.Results.Support4 = `Any example of a multi-line record is below (quotation marks are added to break the string into multiple lines):`

l.DMARC.Home.Title = `What is DMARC?`
l.DMARC.Home.Final = `Now that the SPF and DKIM policies have been created and implemented. We can move on to the final step to create a DMARC policy. `
l.DMARC.Home.Protocol = `DMARC (Domain-based Message Authentication, Reporting & Conformance) is an email authentication protocol, which includes a reporting function that allows senders and receivers to improve and monitor protection of the domain from fraudulent email. Implementation of DMARC will prevent spammers from spoofing the From address on mail messages. Depending on the DMARC policy settings, any mail messages originating from an unauthorized mail server will be either quarantined or rejected. All spam and phishing messages using an organization's domain name will be quarantined or deleted before they reach their destination (the end user). The reports generated can be used for intelligence or possible for law enforcement purposes, if the activity is criminal in nature.`
l.DMARC.Home.Stronger = `DMARC will bring SPF and DKIM together to generate stronger email authentication for your organization's domain.`
l.DMARC.Home.ClickNext = `Please click on the Next button to start the process of how to create the DMARC policy.`
l.DMARC.Home.YourDomain = `yourdomain.com`
l.DMARC.Home.Next = `Next`

l.DMARC.Policy.Title = `What should be the policy level for DMARC?`
l.DMARC.Policy.Levels = `There are three policy levels which can be set for DMARC. Those are:`
l.DMARC.Policy.NoneEx = `None – Reports possible suspicious mail messages, but all mail is sent to inbox`
l.DMARC.Policy.QuarantineEx = `Quarantine – Fail SPF/DKIM policy, then message is sent to SPAM/Junk folder`
l.DMARC.Policy.RejectEx = `Reject – Fail SPF/DKIM policy, then message is dropped and not delivered at all`
l.DMARC.Policy.None = `None (Recommended)`
l.DMARC.Policy.Quarantine = `Quarantine`
l.DMARC.Policy.Reject = `Reject`
l.DMARC.Policy.Next = `Next`
l.DMARC.Policy.Recommended1 = `It is`
l.DMARC.Policy.Recommended2 = `strongly recommended`
l.DMARC.Policy.Recommended3 = `to start with None, as this will allow for time to monitor the reports generated and determine if legitimate mail will be blocked before switching the DMARC policy to Quarantine or Reject.`
l.DMARC.Policy.Tag = `This will add the`
l.DMARC.Policy.Tag2 = `tag to the DMARC record.`

l.DMARC.Aggregates.Title = `Where should aggregate reports be sent?`
l.DMARC.Aggregates.Opt = `This is optional, but is strongly recommended, and will add the`
l.DMARC.Aggregates.Opt2 = `rua tag to the policy. It is`
l.DMARC.Aggregates.Opt3 = `strongly recommended`
l.DMARC.Aggregates.Opt4 = `that this be added to the policy for analysis and monitoring purposes. Reports can be sent to multiple addresses.`
l.DMARC.Aggregates.InputField = `Email Address`
l.DMARC.Aggregates.InputInvalid = `Please enter a valid email address`
l.DMARC.Aggregates.Next = `Next`
l.DMARC.Aggregates.Reports = `The aggregate reports (XML format) will be generated containing information about which mail messages pass/fail against SPF and DKIM. This provides visibility into possible authentication issues and/or spam activity for your organization.`
l.DMARC.Aggregates.Contains = `The reports contains the following`
l.DMARC.Aggregates.HowToRead = `How to Read Your First DMARC Reports`
l.DMARC.Aggregates.By = `(Part 1) by Amy Gorrell):`
l.DMARC.Aggregates.Info = `ISP Information`
l.DMARC.Aggregates.Desc = `Line-by-line description of your organization's DMARC record`
l.DMARC.Aggregates.Summary = `Summary of authentication results`
l.DMARC.Aggregates.Important = `It is important to note these reports will not come from just one source. You could potentially receive 10 to 100 (possibly thousands) reports on a daily basis (number of reports generated is dependent on amount of emails sent).`
l.DMARC.Aggregates.Difficult = `This will make it difficult to manually review each report, especially since it is in XML format and some reports could be lengthy.`
l.DMARC.Aggregates.Readable = `These reports can be made human readable by developing an XML converter or by working with a commercial vendor. Most commercial vendors will provide a backend repository that will make the reports human readable as well as provide various levels of analysis and guidance.`
l.DMARC.Aggregates.Vendors = `DMARC Vendors (GCA Partners):`

l.DMARC.Forensics.Title = `Where should forensic reports be sent?`
l.DMARC.Forensics.Opt = `This is optional and will add the`
l.DMARC.Forensics.Opt2 = `ruf tag to the policy. These XML reports will only be generated if the receiving side generates these types of reports. There is a chance that you may not receive any forensic reports. This is due to privacy and data sharing regulations across nations.`
l.DMARC.Forensics.InputField = `Email Address`
l.DMARC.Forensics.InputInvalid = `Please enter a valid email address`
l.DMARC.Forensics.Next = `Next`

l.DMARC.Subdomains.Title = `DMARC policy for subdomains?`
l.DMARC.Subdomains.Define = `This will define the policy level for subdomains of your organization’s domain. If this tag`
l.DMARC.Subdomains.Define2 = `is not present, then the policy will follow the policy for the top level domain, which was defined in the first step.`
l.DMARC.Subdomains.None = `None`
l.DMARC.Subdomains.Quarantine = `Quarantine`
l.DMARC.Subdomains.Reject = `Reject`
l.DMARC.Subdomains.Next = `Next`
l.DMARC.Subdomains.Policy = `Policy for domain. Options are:`
l.DMARC.Subdomains.NoneEx = `None – no action taken, but recorded in the DMARC report.`
l.DMARC.Subdomains.QuarantineEx = `Quarantine – mail message is marked as spam.`
l.DMARC.Subdomains.RejectEx = `Reject – message is deleted.`
l.DMARC.Subdomains.Recommended = `It is recommended to set this to None if you have multiple subdomains. It is best to set up separate DMARC policies for each subdomain until all subdomains are compliant with the DMARC policy. However, if no subdomains exist, then it is recommended to use Reject.`

l.DMARC.Options.Title = `(Optional) The remaining options are not required but can be adjusted if necessary:`
l.DMARC.Options.ReportGen = `1. When should the error report be generated`
l.DMARC.Options.Default = `Default`
l.DMARC.Options.ReportPol = `Defines error reporting policy.`
l.DMARC.Options.DefaultsTo = `If not defined, defaults to`
l.DMARC.Options.AllChecks = `Generate report to the sending MTA if ALL underlying checks failed`
l.DMARC.Options.AnyChecks = `Generate report to the sending MTA if ANY underlying checks failed`
l.DMARC.Options.OtherValues = `Other values are:`
l.DMARC.Options.DKIMFails = `Generate a report if DKIM check fails`
l.DMARC.Options.SPFFails = `Generate a report if SPF check fails`
l.DMARC.Options.Relaxed = `Relaxed`
l.DMARC.Options.Strict = `Strict`
l.DMARC.Options.DKIMAlign = `2. Alignment mode for DKIM`
l.DMARC.Options.DKIMAlignOpt = `Alignment mode for DKIM. Options are:`
l.DMARC.Options.DKIMRelaxed = `(relaxed) default if not defined. Allows for any subdomain defined in the DKIM header.`
l.DMARC.Options.DKIMStrict = `(strict) the sender’s domain name must match the domain in the DKIM header exactly.`
l.DMARC.Options.SPFAlign = `3. Alignment mode for SPF`
l.DMARC.Options.SPFAlignOpt = `Alignment mode for SPF. Options are:`
l.DMARC.Options.SPFRelaxed = `(relaxed) – default if not defined.  Allows for any subdomain.`
l.DMARC.Options.SPFStrict = `(strict) – the organization domain name in the MAIL FROM command (in SMTP) and the from: header (in the mail item) must match exactly`
l.DMARC.Options.Percentage = `4. Percentage of message subject by DMARC Policy?`
l.DMARC.Options.Filter = `% of messages subjected to filtering by the DMARC policy.  Can be any number from 1 to 100. Default is 100, which is all messages.`
l.DMARC.Options.Formats = `5. Format of reports generated`
l.DMARC.Options.FormatsEx = `Defines the format of the reports generated.  Format values are:`
l.DMARC.Options.RFC = `default value if not defined.  Format is defined by RFC 5965.`
l.DMARC.Options.RFC2 = `Format is defined by RFC 5070.`
l.DMARC.Options.ReportInt = `6. Reporting interval (seconds)`
l.DMARC.Options.SecondsDefault = `seconds (default)`
l.DMARC.Options.DigitsOnly = `Please enter only digits`
l.DMARC.Options.ReportIntEx = `Defines the reporting intervals in seconds.  If not defined, the default is 86400 seconds, or 24 hours. Please note, that reports are not guaranteed to be sent by receiving MTAs.  Reports are sent on a best effort basis.`
l.DMARC.Options.HToS = `Hours to Seconds Converter`
l.DMARC.Options.Hours = `24 Hours`
l.DMARC.Options.To = `to`
l.DMARC.Options.Seconds = `86400 seconds`
l.DMARC.Options.Finish = `Finish`

l.DMARC.Results.Title = `Below is the DMARC TXT record which must be created on your DNS server.`
l.DMARC.Results.Display = `Display DMARC TXT Record Result`
l.DMARC.Results.Copy = `Copy`
l.DMARC.Results.Copied = `COPIED!`
l.DMARC.Results.SPFGuide = `Return to SPF Guide`
l.DMARC.Results.DKIMGuide = `Return to DKIM Guide`
l.DMARC.Results.OwnDNS = `If you have your own DNS server, then please create TXT records for the below DMARC policy.`
l.DMARC.Results.ThirdParty = `If you are using a third party DNS provider, then please follow their instructions for creating a TXT record. In most cases all they will need is the hostname, _dmarc.<domainname> and the text value, which is the information found between the quotation marks.`
l.DMARC.Results.Reference = `Please reference the`
l.DMARC.Results.ResourcesPage = `Resources page`
l.DMARC.Results.Information = `for information on how to create TXT records.`
l.DMARC.Results.NotUnderstood = `If you have tried to follow this but have not understood the steps to take or the terminology used, it is recommended that you contact your IT support organization, domain registrar, or GCA Info`
l.DMARC.Results.Assistance = `for further assistance.`
l.DMARC.Results.Complete = `Complete`
l.DMARC.Results.Implemented = `Once the DMARC record has been implemented, please use the DMARC validation/checker below:`
l.DMARC.Results.YourDomain = `yourdomain.com`
l.DMARC.Results.NotQuite = `Not quite there yet.`
l.DMARC.Results.Wisdom = `That's OK! Wisdom is the final goal of every action. Please feel free to make use of our resources, above, or drop us a line and we can help you implement. That's what we're here for.`
l.DMARC.Results.Check = `Check`
l.DMARC.Results.Congratulations = `Congratulations`

export default l
