import { useTranslation } from 'react-i18next'

export const TLSGuide = () => {
  const { t } = useTranslation()

  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
      style={{}}
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='post-entry post-entry-type-page page-wrapper'>
            <div className='entry-content-wrapper clearfix'>
              <div className='flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-37  el_before_av_one_third  avia-builder-el-first  '>
                <div className='flex-column-inner'>
                  <div
                    style={{ paddingBottom: '10px' }}
                    className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-38  el_before_av_hr  avia-builder-el-first     '
                  >
                    <h1
                      className='av-special-heading-tag'
                      style={{ fontWeight: 'bold' }}
                      itemProp='headline'
                    >
                      {t('What is TLS?')}
                    </h1>
                    <div className='special-heading-border'>
                      <div className='special-heading-inner-border' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex_column_table   av-equal-height-column-flextable av-break-at-tablet-flextable flex-column-table-7 '>
                <div className='flex_column av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-42  el_after_av_one_third  el_before_av_hr  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div className='avia_textblocks' itemProp='text'>
                        <p>
                        TLS, which stands for Transport Layer Security, is a cryptographic protocol designed to provide secure communication over a computer network. 
                        It ensures that data transmitted between two systems remains confidential and cannot be easily intercepted or tampered with by malicious actors. 
                        TLS is commonly used to secure sensitive information transmitted over the internet, such as login credentials, credit card details, and other private data.
                        </p>
                        <p>
                        When two systems, such as a web browser and a web server, establish a connection over the internet, TLS facilitates the secure exchange of data by using a combination of asymmetric and symmetric encryption techniques. 
                        </p>
                        <p>
                        The recommended versions of TLS are <code>TLS 1.2</code> and <code>TLS 1.3</code>.
                        While <code>TLS 1.2</code> provides robust encryption, it does have known vulnerabilities. 
                         <code>TLS 1.3</code>, being the latest version, addresses these weaknesses and enhances security and performance. 
                        <code>TLS 1.3</code> also discontinues support for outdated cryptographic algorithms, further enhancing data transmission confidentiality and integrity. 
                        Organizations should take these factors into account when evaluating their specific security requirements for communication protocols.
                        </p>
                        <p>
                        Configuring TLS is largely dependent on your system, platform, or general preferences regarding certificate authorities and libraries. 
                        While it is possible to generate self-signed certificates, for production environments it's recommended to purchase or request TLS certificates from a recognized CA like Let's Encrypt, DigiCert, or GlobalSign. 
                        Whether you're working with OpenSSL, NSS (Network Security Services), or libraries provided by your platform's SSL/TLS stack, it is best to consult the documentation and the technical support available for these platforms.
                        </p>
                        <p>
                        For any other questions regarding TLS, please contact your IT support organization.
                        </p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
