import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepWizard from '../../Components/WizardWrapper'
import { WizardNav } from '../../Components/WizardNav'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'

export const DKIMGuide = () => {
  const { t } = useTranslation()
  const[domain, setDomain] = useState("")
  const[selector, setSelector] = useState("")
  const[publicKey, setPublicKey] = useState("")

  const handleSetDomain = (newDomain: string) => {
    setDomain(newDomain)
  }

  const handleSetSelector = (newSelector: string) => {
    setSelector(newSelector)
  }

  const handleSetPublicKey = (newPublicKey: string) => {
    setPublicKey(newPublicKey)
  }

  const getRecord = () => {
    let rec = 'v=DKIM1; k=rsa; p=' + publicKey + ';'
 
    return rec
  }

  const handleStartOver = () => {
    setDomain('');
    setSelector('');
    setPublicKey('');
  }


  const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
  }

  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
      style={{}}
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='post-entry post-entry-type-page page-wrapper'>
            <div className='entry-content-wrapper clearfix'>
              <div className='flex_column av_one_full  flex_column_div av-zero-column-padding first  avia-builder-el-37  el_before_av_one_third  avia-builder-el-first  '>
                <div className='flex-column-inner'>
                  <div
                    style={{ paddingBottom: '10px' }}
                    className='av-special-heading av-special-heading-h2  blockquote modern-quote modern-centered  avia-builder-el-38  el_before_av_hr  avia-builder-el-first     '
                  >
                    <h1
                      className='av-special-heading-tag'
                      style={{ fontWeight: 'bold' }}
                      itemProp='headline'
                    >
                      {t('DKIM.Home.Title')}
                    </h1>
                    <div className='special-heading-border'>
                      <div className='special-heading-inner-border' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex_column_table   av-equal-height-column-flextable av-break-at-tablet-flextable flex-column-table-7 '>
                <div className='flex_column av-break-at-tablet flex_column_table_cell av-equal-height-column av-align-middle av-zero-column-padding   avia-builder-el-42  el_after_av_one_third  el_before_av_hr  column-top-margin'>
                  <div className='flex-column-inner'>
                    <section
                      className='av_textblock_section '
                      itemType='https://schema.org/CreativeWork'
                    >
                      <div className='avia_textblocks' itemProp='text'>
                      <StepWizard
                        nav={<WizardNav />}
                        isHashEnabled
                        transitions={transitions}
                      >
                        <Step1 />
                        <Step2  domain={domain} handleSetDomain={handleSetDomain} selector={selector} handleSetSelector={handleSetSelector} publicKey={publicKey} handleSetPublicKey={handleSetPublicKey} />
                        <Step3 selector={selector} domain={domain} handleStartOver={handleStartOver} getRecord={getRecord} /> 
                      </StepWizard>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
