let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `Guia DMARC`;

l.Header.Main.SetupDMARC        = `CONFIGURAÇÃO DMARC`;
l.Header.Main.HowItWorks        = `COMO FUNCIONA`;
l.Header.Main.AboutDMARC        = `SOBRE DMARC`;
l.Header.Main.Resources         = `RECURSOS`;
l.Header.Main.Contact           = `CONTACTO`;

l.Footer.Main.SetupDMARC        = `Configuração DMARC`;
l.Footer.Main.HowItWorks        = `Como funciona`;
l.Footer.Main.AboutDMARC        = `Sobre DMARC`;
l.Footer.Main.Resources         = `Recursos`;
l.Footer.Main.Contact           = `Contacto`;
l.Footer.Main.Copyright         = `Copyright ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Política de privacidade`;
l.Footer.Main.Sitemap           = `Mapa do site`;

l.Home.Main.EnterDomain         = `Insira o seu domínio`;
l.Home.Main.ToStart             = `para iniciar a configuração DMARC`;
l.Home.Main.TagLine             = `Faça algo. Meça-o.`;
l.Home.Main.Welcome             = `Bem-vindo ao Guia de configuração DMARC. O objetivo deste guia de instalação é orientar a sua organização através do processo de criação de uma política de DMARC, bem como políticas para o Sender Policy Framework (Enquadramento de Política de Remetente) (SPF) e E-mail Identificado DomainKeys (DKIM). Ao implementar as três políticas, a sua organização terá um mecanismo de autenticação de email mais forte no local para ajudar a proteger a marca.`;
l.Home.Main.FirstStep           = `O primeiro passo é confirmar se a sua organização não está a utilizar  nenhuma das três políticas.`;
l.Home.Main.InputWarning        = `Digite o domínio do endereço de e-mail da sua organização, exatamente como aparece após o símbolo @ no endereço de e-mail.`;
l.Home.Main.InputExample        = `Por exemplo, no seguinte endereço de e-mail, domainsecurityscanner@globalcyberalliance.org, o domínio a ser inserido na caixa de texto é globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `O endereço do site é obrigatório`;
l.Home.Main.valid               = `O endereço do site não é válido`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Utilizadores de Google Apps e do Office 365:`;
l.Home.Main.UserWarningText1    = `Siga as informações fornecidas na Página de Recursos para`;
l.Home.Main.UserWarningLink     = `provedores de serviços em nuvem`;
l.Home.Main.UserWarningText2    = `para implementar SPF e DKIM. Uma vez concluído, use este guia para continuar com a implementação da política do DMARC.`;
l.Home.Main.Submit              = `Submeter`;
l.Home.Main.MoreInfo            = `Se quiser mais informações sobre o DMARC, convidamo-lo a participar numa das nossas sessões mensais de Webinar DMARC. Este webinar irá cobrir os conceitos básicos do DMARC e fornecer uma breve visão geral do Guia de Configuração GCA DMARC.`;
l.Home.Main.Webinar             = `A sessão mensal de webinar DMARC do GCA ocorre duas vezes na terceira quarta-feira de cada mês. Por favor, registe-se para a data e a hora que lhe sejam convenientes clicando no link apropriado abaixo:`;
l.Home.Main.SelectDate          = `Selecione esta data`;

l.ToolSelect.Main.Title         = `Resultados para`;
l.ToolSelect.Main.ResultStatus  = `A tabela abaixo indica quais os protocolos que foram <i class='fas fa-check-circle' style='color: green'></i> ou não <i class='fas fa-times-circle' style='color: red'></i> implementados.`;
l.ToolSelect.Main.Protocol      = `Selecione um dos protocolos para iniciar o guia de configuração.`;
l.ToolSelect.Main.ShareButton   = `Se quiser compartilhar os resultados, basta clicar no botão Partilhar, um link será copiado para a área de transferência.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `A parte de verificação DKIM ainda está em desenvolvimento. No entanto, o Guia de Configuração do DKIM ainda está disponível selecionando a linha DKIM.`;
l.ToolSelect.Main.NoDKIM        = `Não foi possível encontrar um registo DKIM associado ao seu domínio, dada a lista de seletores padrão.`;
l.ToolSelect.Main.NoDKIM1       = `Se conhecer o seletor, pode procurar o registo DKIM aqui:`;
l.ToolSelect.Main.NoDKIM2       = `Ou se fornecer um endereço de e-mail, enviaremos um e-mail para a conta onde possa responder e analisaremos o e-mail para um registo DKIM.`;
l.ToolSelect.Main.NoDKIMError   = `O email não é válido ou não pertence ao domínio fornecido.`;
l.ToolSelect.Main.SelectorMsg   = `A pesquisar o seletor!`;
l.ToolSelect.Main.SelectorError = `Seletor não encontrado.`;
l.ToolSelect.Main.EmailMsg      = `E-mail enviado! Por favor, responda ao e-mail. Assim que a resposta seja enviada, esta página será atualizada e fornecerá o nome do seletor.`;
l.ToolSelect.Main.InputSelector = `Seletor`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Submeter`;
l.ToolSelect.Main.Select        = `Selecionar`;
l.ToolSelect.Main.Next          = `Próximo`;
l.ToolSelect.Main.Share         = `Partilhar`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Concluiu a configuração do DMARC`;
l.Complete.Main.check           = `Verificar outro domínio para SPF, DKIM ou DMARC?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `O endereço do site é obrigatório`;
l.Complete.Main.valid           = `O endereço do site não é válido`;
l.Complete.Main.Next            = `Próximo`;
l.Complete.Main.More            = `Mais sobre DMARC`;

l.SPF.Home.Title                = `O que é SPF?`;
l.SPF.Home.SPF                  = `SPF significa Sender Policy Framework (Enquadramento de Política de Remetente ). Esta política é responsável por impedir que o endereço do remetente seja falsificado ao definir quais servidores de correio estão autorizados a enviar o correio em nome do domínio da organização Äö√Ñ√¥s Se o servidor de correio não estiver definido no SPF, a mensagem será rejeitada ou devolvida.`;
l.SPF.Home.Start                = `Por favor clique no botão Avançar para iniciar o processo de como criar a Política SPF.`;
l.SPF.Home.Next                 = `Próximo`;

l.SPF.Authorization.Title       = `Os servidores listados abaixo são autorizados a enviar e-mails para o domínio da organização?`;
l.SPF.Authorization.First       = `A primeira parte da política é definir os servidores de correio recebidos (MXes) do domínio que estão autorizados a enviar correio para esse domínio também.`;
l.SPF.Authorization.Current     = `A lista abaixo contém os registos DNS atuais para os servidores de correio associados à sua organização.`;
l.SPF.Authorization.Recommended = `É altamente recomendável que o Sim seja selecionado visto a lista acima ser o servidor autorizado para o seu domínio. Ao fazê-lo, a tag  mx será adicionada à política SPF.`;
l.SPF.Authorization.Yes         = `Sim (recomendado)`;
l.SPF.Authorization.No          = `Não`;
l.SPF.Authorization.Missing     = `Se existirem sistemas ausentes da lista ou que não sejam servidores de correio padrão, inclua esses sistemas na próxima opção.`;
l.SPF.Authorization.Next        = `Próximo`;

l.SPF.Servers.Title             = `Existem servidores adicionais dentro do domínio que podem entregar ou transmitir mensagens para esse domínio?`;
l.SPF.Servers.Possible          = `Existe a possibilidade de que nem todos os servidores de correio da organização estejam definidos nos registos DNS da organização. Estes servidores poderiam ser de teste ou aplicativos com sistemas de correio integrados. Se for esse o caso, adicione os domínios de servidor adicionais ou endereços IP nas caixas abaixo.`;
l.SPF.Servers.Others            = `Tal só deve ser usado se houver outros sistemas internos, além dos servidores de correio, autorizados a enviar e-mails para o domínio. “`;
l.SPF.Servers.Added             = `Se os sistemas forem adicionados, isso irá adicionar a tag Äö√Ñ√≤a,Äö√Ñ√ ¥ à política SPF.`;
l.SPF.Servers.InputField        = `Nome do servidor ou IP`;
l.SPF.Servers.InputInvalid      = `Deve ser um nome de domínio ou endereço IP totalmente qualificado`;
l.SPF.Servers.Next              = `Próximo`;

l.SPF.Domains.Title             = `Existem domínios externos que podem enviar ou transmitir mensagens para a sua organização?`;
l.SPF.Domains.Include           = `Esta seção adicionará a`;
l.SPF.Domains.Include2          = `tag incluir`;
l.SPF.Domains.Include3          = `da Política SPF.`;
l.SPF.Domains.Trusted           = `Isso só deve ser usado se um domínio externo for confiável para enviar mensagem de email para o seu domínio de organização Ä √ ¥. Por exemplo, Sistema de Gestão de Relacionamento com Cliente, Provedor de email na nuvem (Google, Office 365, Yahoo!) ou Provedor de segurança da nuvem.`;
l.SPF.Domains.Note              = `Observe: Se estiver a usar um Provedor de serviço de nuvem, deve trabalhar com ele para o valor apropriado para esta configuração.`;
l.SPF.Domains.InputField        = `Nome de domínio ou IP`;
l.SPF.Domains.InputInvalid      = `Deve ser um nome de domínio ou endereço IP totalmente qualificado`;
l.SPF.Domains.Next              = `Próximo`;

l.SPF.Strict.Title              = `Quão rigorosamente devem os servidores tratar os e-mails?`;
l.SPF.Strict.Receiving          = `Esta é a última seção da política SPF que definirá a rigor dos servidores recetores no tratamento de e-mails.`;
l.SPF.Strict.SoftFail           = `Soft Fail (Recomendado)`;
l.SPF.Strict.Fail               = `Falha`;
l.SPF.Strict.Neutral            = `Neutro`;
l.SPF.Strict.AllowAll           = `Permitir todos `;
l.SPF.Strict.Next               = `Próximo`;
l.SPF.Strict.Options            = `Opções:`;
l.SPF.Strict.FailEx             = `Falha ou `;
l.SPF.Strict.FailEx2            = `Significa que apenas os servidores de correio de domínio (e aqueles nas seções a e incluir) podem enviar mensagens para o domínio. Todos os outros são proibidos.`;
l.SPF.Strict.SoftEx             = `Soft Fail ou `;
l.SPF.Strict.SoftEx2            = `Significa que apenas os servidores de correio de domínio (e aqueles nas seções a e incluem) podem enviar mensagens para o domínio, mas estão em transição. Todos os outros são proibidos.`;
l.SPF.Strict.NeutralEx          = `Neutros ou `;
l.SPF.Strict.NeutralEx2         = `significam explicitamente que nada pode ser dito sobre a validade.`;
l.SPF.Strict.AllowEx            = `Permitir tudo ou`;
l.SPF.Strict.AllowEx2           = `significa que qualquer host pode enviar e-mails para o domínio.`;
l.SPF.Strict.Warning1           = `Isto nunca `;
l.SPF.Strict.Warning2           = `deveria`;
l.SPF.Strict.Warning3           = `ser usado`;
l.SPF.Strict.Recommended        = `Recomenda-se usar Soft Fail`;
l.SPF.Strict.MarkMail           = `Isso marcará o correio como não conforme se não atender aos critérios definidos.`;

l.SPF.Results.Title             = `Abaixo está o registo SPF TXT, que deve ser criado no seu servidor DNS.`;
l.SPF.Results.Copy              = `Copiar`;
l.SPF.Results.Copied            = `COPIADO!`;
l.SPF.Results.DMARCGuide        = `Ir para o Guia do DMARC`;
l.SPF.Results.DKIMGuide         = `Continuar no Guia do DKIM`;
l.SPF.Results.OwnDNS            = `Se tiver o seu próprio servidor DNS, então crie registos TXT para a política SPF acima.`;
l.SPF.Results.ThirdParty        = `Se estiver a usar um provedor de DNS de terceiros, siga as instruções para criar um registo TXT.`;
l.SPF.Results.Reference         = `Por favor, consulte a`;
l.SPF.Results.ResourcesPage     = `Página de recursos`;
l.SPF.Results.Information       = `para obter informações sobre como criar registos TXT.`;
l.SPF.Results.NotUnderstood     = `Se tentou seguir isso, mas não entendeu as etapas a serem tomadas ou a terminologia utilizada, é recomendável que entre em contato com a organização de suporte de TI, o registador de domínio ou a Info GCA`;
l.SPF.Results.Assistance        = `para mais assistência.`;
l.SPF.Results.Implemented       = `Uma vez que o registo SPF seja implementado, use a validação / verificador SPF abaixo:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Verificar`;
l.SPF.Results.SuccessDKIM       = `IR PARA DKIM`;
l.SPF.Results.SuccessDMARC      = `IR PARA DMARC`;
l.SPF.Results.NotQuite          = `Ainda não chegou.`;
l.SPF.Results.Wisdom            = `Está OK! A sabedoria é o objetivo final de cada ação. Por favor, fique à vontade para usar os nossos recursos acima, ou  escreva-nos e podemos ajudá-lo a implementar. É para isso que estamos aqui.`;

l.DKIM.Home.Title               = `O que é DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM significa DomainKeys Identified Mail (Email identificado DomainKeys).  DKIM é um mecanismo concebido para validar a identidade de um domínio que está associada a uma mensagem de correio. Isso é realizado usando a autenticação que usa criptografia assimétrica. Basicamente, o DKIM autenticará uma mensagem de email adicionando uma assinatura digital ao cabeçalho da mensagem. É importante notar que o DKIM não filtra mensagens de correio. Permitirá que os filtros SPAM determinem a autenticidade da mensagem de email que está a ser enviada.`;
l.DKIM.Home.ClickNext           = `Clique no botão Avançar para iniciar o processo de como criar a política DKIM.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Próximo`;

l.DKIM.Selector.Title           = `Seletor`;
l.DKIM.Selector.Name            = `O seletor irá definir o nome do registo DKIM. O nome do seletor pode ser qualquer um, mas abaixo estão algumas opções para escolher.`;
l.DKIM.Selector.Define          = `Isso definirá o nome do registo TXT a ser criado para a chave DKIM. Aparecerá da seguinte maneira:`;
l.DKIM.Selector.Example1        = `seletor`;
l.DKIM.Selector.Example2        = `nome de domínio`;
l.DKIM.Selector.Next            = `Próximo`;

l.DKIM.Key.Title                = `Chave pública`;
l.DKIM.Key.Tag                  = `Isso irá adicionar a tag 'p', que é a cadeia de caracteres no arquivo de chave pública gerado anteriormente.`;
l.DKIM.Key.Keys                 = `Insira aqui as chaves públicas.`;
l.DKIM.Key.Next                 = `Próximo`;
l.DKIM.Key.Creating             = `Criar as chaves DKIM:`;
l.DKIM.Key.TwoParts             = `DKIM tem duas partes, a chave privada e a chave pública. Criar as chaves para o DKIM variará de acordo com quem está a fornecer os serviços de e-mail para a sua organização.`;
l.DKIM.Key.External             = `Se estiver a usar um serviço externo para correio e DNS, o provedor de email terá de fornecer a chave pública. Esta chave pública é então publicada como um registo TXT no DNS. A maioria dos provedores de DNS limita o tamanho do registo TXT a 255 caracteres, então pode ter que trabalhar com o provedor para aumentar o tamanho ou criar o registo TXT. A chave privada é realizada pelo provedor de correio e normalmente não é fornecida à organização.`;
l.DKIM.Key.Internal             = `Se a organização tiver o seu próprio servidor de e-mail, as chaves DKIM devem ser geradas pela equipa de TI (ou a responsável pela implementação do DKIM). Existem vários geradores de chaves DKIM disponíveis na Internet. Se optar por usar um desses, certifique-se apenas que tem a opção de excluir a chave depois de ser gerada e copiada para arquivos no seu final.`;
l.DKIM.Key.OpenDKIM             = `Para criar as chaves sem um terceiro, está disponível um projeto de código aberto chamado opendkim `;
l.DKIM.Key.OpenDKIM2            = `O Opendkim contém várias ferramentas para ajudar na criação da chave DKIM.`;
l.DKIM.Key.OpenSSL              = `Outra opção é usar o OpenSSL para gerar as chaves DKIM. Pode ser feito usando a versão mais recente do OpenSSL e digitando o seguinte comando para a chave privada:`;
l.DKIM.Key.OpenSSL2             = `Isso gerará um arquivo de chave particular chamado`;
l.DKIM.Key.OpenSSL3             = `Em seguida, digite o seguinte comando para gerar a chave pública:`;
l.DKIM.Key.OpenSSL4             = `Este comando gerará a chave pública`;
l.DKIM.Key.OpenSSL5             = `com base na chave privada criada anteriormente`;
l.DKIM.Key.OpenSSL6             = `Mova a chave privada DKIM no local especificado pela instalação do DKIM. Verifique se está numa pasta com acesso restrito.`;

l.DKIM.Results.Title            = `Abaixo está o registo DKIM TXT, que deve ser criado no seu servidor DNS.`;
l.DKIM.Results.Copy             = `Copiar`;
l.DKIM.Results.Copied           = `COPIADO!`;
l.DKIM.Results.SPFGuide         = `Voltar ao Guia SPF`;
l.DKIM.Results.DMARCGuide       = `Continue para o Guia DMARC`;
l.DKIM.Results.OwnDNS           = `Se possuir o seu próprio servidor DNS, então crie registos TXT para a política DKIM acima.`;
l.DKIM.Results.ThirdParty       = `Se estiver a usar um provedor de DNS de terceiros, siga as instruções para criar um registo TXT.`;
l.DKIM.Results.Reference        = `Por favor, consulte a`;
l.DKIM.Results.ResourcesPage    = `página de recursos`;
l.DKIM.Results.Information      = `para obter informações sobre como criar registos TXT.`;
l.DKIM.Results.NotUnderstood    = `Se tentou seguir isso, mas não entendeu as etapas a seguir ou a terminologia utilizada, é recomendável que entre em contato com a organização de suporte de TI, o registador de domínio ou a Info GCA`;
l.DKIM.Results.Assistance       = `para mais assistência.`;
l.DKIM.Results.Note             = `Por favor note:`;
l.DKIM.Results.Support          = `As chaves DKIM agora podem suportar chaves de 2048 bits. No entanto, os registos DNS TXT não podem suportar valores de mais de 255 caracteres. Pode ignorar isso ao:`;
l.DKIM.Results.Support2         = `Utilizar a chave de 1024 bits ou,`;
l.DKIM.Results.Support3         = `Criar uma gravação TXT multilinha`;
l.DKIM.Results.Support4         = `Abaixo encontra exemplos de uma gravação de várias linhas (aspas são adicionadas para quebrar a cadeia em várias linhas):`;

l.DMARC.Home.Title              = `O que é DMARC?`;
l.DMARC.Home.Final              = `Agora que as políticas SPF e DKIM foram criadas e implementadas. Podemos avançar para o passo final para criar uma política DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (autenticação de mensagens baseadas em domínio, relatórios e conformidade) é um protocolo de autenticação de e-mail, que inclui uma função de relatório que permite aos remetentes e recetores melhorar e monitorar a proteção do domínio a partir de e-mails fraudulentos. A implementação do DMARC impedirá os spammers de falsificar o endereço "De" nas mensagens de correio. Dependendo das configurações de política do DMARC, todas as mensagens enviadas a partir de um servidor de correio não autorizado ficarão em quarentena ou serão rejeitadas. Todas as mensagens de spam e phishing que utilizem o nome de domínio de uma organização serão colocadas em quarentena ou excluídas antes de chegarem ao seu destino (o utilizador final). Os relatórios gerados podem ser usados para inteligência ou possíveis para fins de aplicação da lei, se a atividade for de natureza criminal.`;
l.DMARC.Home.Stronger           = `O DMARC juntará SPF e DKIM para gerar autenticação de e-mail mais forte para o domínio da sua organização.`;
l.DMARC.Home.ClickNext          = `Clique no botão Avançar para iniciar o processo de como criar a política DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Próximo`;

l.DMARC.Policy.Title            = `Qual deve ser o nível de política para o DMARC?`;
l.DMARC.Policy.Levels           = `Existem três níveis de política que podem ser definidos para o DMARC. São:`;
l.DMARC.Policy.NoneEx           = `Nenhum - Relata possíveis mensagens de correio suspeitas, mas todo o correio é enviado para a caixa de entrada`;
l.DMARC.Policy.QuarantineEx     = `Quarentena - Falha da política SPF / DKIM, então a mensagem é enviada para a pasta SPAM / Lixo`;
l.DMARC.Policy.RejectEx         = `Rejeitar - Falha da política SPF / DKIM, então a mensagem é descartada e não é entregue de forma alguma`;
l.DMARC.Policy.None             = `Nenhum (recomendado)`;
l.DMARC.Policy.Quarantine       = `Quarentena`;
l.DMARC.Policy.Reject           = `Rejeitar`;
l.DMARC.Policy.Next             = `Próximo`;
l.DMARC.Policy.Recommended1     = `É`;
l.DMARC.Policy.Recommended2     = `fortemente recomendado`;
l.DMARC.Policy.Recommended3     = `começar com Nenhum, pois isso dará tempo para monitorar os relatórios gerados e determinar se o correio legítimo será bloqueado antes de mudar a política DMARC para Quarentena ou Rejeitar.`;
l.DMARC.Policy.Tag              = `Isso irá adicionar o`;
l.DMARC.Policy.Tag2             = `tag do registo DMARC.`;

l.DMARC.Aggregates.Title        = `Para onde devem ser enviados relatórios agregados?`;
l.DMARC.Aggregates.Opt          = `Isso é opcional, mas é fortemente recomendado, e adicionará o`;
l.DMARC.Aggregates.Opt2         = `tag rua à política. É`;
l.DMARC.Aggregates.Opt3         = `fortemente recomendado`;
l.DMARC.Aggregates.Opt4         = `que seja adicionado à política para propósito de análise e monitorização. Os relatórios podem ser enviados para múltiplos endereços.`;
l.DMARC.Aggregates.InputField   = `Endereço de email`;
l.DMARC.Aggregates.InputInvalid = `Por favor insira um endereço de email válido`;
l.DMARC.Aggregates.Next         = `Próximo`;
l.DMARC.Aggregates.Reports      = `Os relatórios agregados (formato XML) serão gerados contendo informações sobre quais as mensagens de correio passam / falham contra SPF e DKIM. Isso fornece visibilidade de possíveis problemas de autenticação e / ou atividade de spam para a sua organização.`;
l.DMARC.Aggregates.Contains     = `Os relatórios contêm o seguinte`;
l.DMARC.Aggregates.HowToRead    = `Como ler o seu primeiro relatório DMARC`;
l.DMARC.Aggregates.By           = `(Parte 1) por Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `Informação do ISP`;
l.DMARC.Aggregates.Desc         = `Descrição linha-por-linha do registo DMARC da sua organização`;
l.DMARC.Aggregates.Summary      = `Resumo dos resultados de autenticação`;
l.DMARC.Aggregates.Important    = `É importante notar que esses relatórios não virão de apenas uma fonte. Poderia potencialmente receber 10 a 100 (possivelmente milhares) relatórios diariamente (o número de relatórios gerados depende da quantidade de e-mails enviados).`;
l.DMARC.Aggregates.Difficult    = `Isso dificulta a revisão manual de cada relatório, especialmente porque está em formato XML e alguns relatórios podem ser longos.`;
l.DMARC.Aggregates.Readable     = `Esses relatórios podem ser tornados legíveis por humanos, desenvolvendo um conversor XML ou trabalhando com um fornecedor comercial. A maioria dos vendedores comerciais fornecerá um repositório backend que tornará os relatórios legíveis por humanos, além de fornecer vários níveis de análise e orientação.`;
l.DMARC.Aggregates.Vendors      = `Fornecedores DMARC (Parceiros GCA):`;

l.DMARC.Forensics.Title         = `Para onde devem ser enviados os relatórios forenses?`;
l.DMARC.Forensics.Opt           = `Isso é opcional e adicionará a`;
l.DMARC.Forensics.Opt2          = `tag ruf à política. Esses relatórios XML só serão gerados se o lado de recebimento gerar esses tipos de relatórios. Existe a hipótese de não receber nenhum relatório forense. Deve-se a uma regulamentações de privacidade e partilha de dados em todas as nações.`;
l.DMARC.Forensics.InputField    = `Endereço de email`;
l.DMARC.Forensics.InputInvalid  = `Por favor insira um endereço de email válido`;
l.DMARC.Forensics.Next          = `Próximo`;

l.DMARC.Subdomains.Title        = `Política DMARC para subdomínios?`;
l.DMARC.Subdomains.Define       = `Isso definirá o nível de política para subdomínios do domínio da sua organização. Se esta tag`;
l.DMARC.Subdomains.Define2      = `não estiver presente, a política seguirá a política para o domínio de nível superior, que foi definida na primeira etapa.`;
l.DMARC.Subdomains.None         = `Nenhum`;
l.DMARC.Subdomains.Quarantine   = `Quarentena`;
l.DMARC.Subdomains.Reject       = `Rejeitar`;
l.DMARC.Subdomains.Next         = `Próximo`;
l.DMARC.Subdomains.Policy       = `Política para o domínio. As opções são:`;
l.DMARC.Subdomains.NoneEx       = `Nenhum - nenhuma ação tomada, mas registada no relatório do DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `Quarentena - a mensagem de correio está marcada como spam.`;
l.DMARC.Subdomains.RejectEx     = `Rejeitar - a mensagem é excluída.`;
l.DMARC.Subdomains.Recommended  = `É recomendável definir para Nenhum se tiver vários subdomínios. É melhor configurar políticas separadas do DMARC para cada subdomínio até que todos os subdomínios sejam compatíveis com a política do DMARC. No entanto, se nenhum subdomínio existir, então é recomendável usar Rejeitar.`;

l.DMARC.Options.Title           = `(Opcional) As opções restantes não são necessárias, mas podem ser ajustadas, se necessário:`;
l.DMARC.Options.ReportGen       = `1. Quando é que o relatório de erro deve ser gerado`;
l.DMARC.Options.Default         = `Padrão`;
l.DMARC.Options.ReportPol       = `Define a política de relatórios de erros.`;
l.DMARC.Options.DefaultsTo      = `Se não for definido, o padrão é`;
l.DMARC.Options.AllChecks       = `Gerar relatório para o MTA de envio se alguma verificação subjacente falhar`;
l.DMARC.Options.AnyChecks       = `Gerar relatório para o MTA de envio se alguma verificação subjacente falhar`;
l.DMARC.Options.OtherValues     = `Outros valores são:`;
l.DMARC.Options.DKIMFails       = `Gerar um relatório se a verificação do DKIM falhar`;
l.DMARC.Options.SPFFails        = `Gerar um relatório se a verificação SPF falhar`;
l.DMARC.Options.Relaxed         = `Relaxado`;
l.DMARC.Options.Strict          = `Rigoroso`;
l.DMARC.Options.DKIMAlign       = `2. Modo de alinhamento para DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Modo de alinhamento para DKIM As opções são:`;
l.DMARC.Options.DKIMRelaxed     = `(descontraído) padrão se não definido. Permite qualquer subdomínio definido no cabeçalho DKIM.`;
l.DMARC.Options.DKIMStrict      = `(restrito) o nome do domínio do remetente deve coincidir exatamente com o domínio no cabeçalho DKIM.`;
l.DMARC.Options.SPFAlign        = `3. Modo de alinhamento para SPF`;
l.DMARC.Options.SPFAlignOpt     = `Modo de alinhamento para SPF As opções são:`;
l.DMARC.Options.SPFRelaxed      = `(descontraído) - padrão se não definido.  Permite qualquer subdomínio.`;
l.DMARC.Options.SPFStrict       = `(restrito) - o nome de domínio da organização no comando MENSAGEM DE (em SMTP) e o de: cabeçalho (no item de correio) deve coincidir exatamente`;
l.DMARC.Options.Percentage      = `4. Percentagem do assunto da mensagem pela Política DMARC?`;
l.DMARC.Options.Filter          = `% de mensagens sujeitas a filtragem pela política do DMARC.  Pode ser qualquer número de 1 a 100. O padrão é 100, que são todas as mensagens.`;
l.DMARC.Options.Formats         = `5. Formato dos relatórios gerados`;
l.DMARC.Options.FormatsEx       = `Define o formato dos relatórios gerados.  Os valores de formato são:`;
l.DMARC.Options.RFC             = `valor padrão se não for definido.  O formato é definido pelo RFC 5965.`;
l.DMARC.Options.RFC2            = `O formato é definido pelo RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Intervalo de relatórios (segundos)`;
l.DMARC.Options.SecondsDefault  = `segundos (padrão)`;
l.DMARC.Options.DigitsOnly      = `Por favor insira apenas dígitos`;
l.DMARC.Options.ReportIntEx     = `Define os intervalos de relatórios em segundos.  Se não for definido, o padrão é 86400 segundos ou 24 horas. Por favor, note que não se garante que os relatórios sejam enviados ao receber MTAs.  Os relatórios são enviados com base no melhor esforço.`;
l.DMARC.Options.HToS            = `Conversor de horas para segundos`;
l.DMARC.Options.Hours           = `24 horas`;
l.DMARC.Options.To              = `a`;
l.DMARC.Options.Seconds         = `86400 segundos`;
l.DMARC.Options.Finish          = `Finalizar`;

l.DMARC.Results.Title           = `Abaixo está o registo DMARC TXT que deve ser criado no seu servidor DNS.`;
l.DMARC.Results.Display         = `Mostrar o resultado de registo DMARC TXT`;
l.DMARC.Results.Copy            = `Cópia`;
l.DMARC.Results.Copied          = `COPIADO!`;
l.DMARC.Results.SPFGuide        = `Voltar ao Guia SPF`;
l.DMARC.Results.DKIMGuide       = `Voltar ao Guia do DKIM`;
l.DMARC.Results.OwnDNS          = `Se tiver o seu próprio servidor DNS, por favor, crie registos TXT para a política DMARC abaixo.`;
l.DMARC.Results.ThirdParty      = `Se estiver a usar um provedor de DNS de terceiros, siga as instruções para criar um registo TXT. Na maioria dos casos, tudo o que é necessário é o nome do host, _dmarc. <nomedomínio> e o valor do texto, que é a informação encontrada entre as aspas.`;
l.DMARC.Results.Reference       = `Por favor, consulte a`;
l.DMARC.Results.ResourcesPage   = `página de recursos`;
l.DMARC.Results.Information     = `para obter informações sobre como criar registos TXT.`;
l.DMARC.Results.NotUnderstood   = `Se tentou seguir isto, mas não entendeu as etapas a serem tomadas ou a terminologia utilizada, é recomendável que entre em contato com a organização de suporte de TI, o registador de domínio ou a Info GCA`;
l.DMARC.Results.Assistance      = `para mais assistência.`;
l.DMARC.Results.Complete        = `Completo`;
l.DMARC.Results.Implemented     = `Assim que o registo DMARC seja implementado, use a verificação/verificador DMARC abaixo:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Ainda não chegou.`;
l.DMARC.Results.Wisdom          = `Está OK! A sabedoria é o objetivo final de cada ação. Por favor, fique à vontade para usar os nossos recursos acima, ou  escreva-nos e podemos ajudá-lo a implementar. É para isso que estamos aqui.`;
l.DMARC.Results.Check           = `Verificar`;
l.DMARC.Results.Congratulations = `Parabéns`;

export default l;
