import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

export const TranslateBar = (props: any, lang:string) => {
  const { t } = useTranslation()
 

  const {changeLanguage} = props;

  const CssItemPointer = {
    cursor: 'pointer',
    marginLeft: '2px',
    marginRight: '2px',
  }

  return (
    <div className='translate-bar'>
      <div className='flags'>
        <img src='langs/us.png' onClick={() => changeLanguage('en')} alt='en' style={CssItemPointer} />
        <img src='langs/es.png' onClick={() => changeLanguage('es')} alt='es' style={CssItemPointer} />
        <img src='langs/fr.png' onClick={() => changeLanguage('fr')} alt='fr' style={CssItemPointer} />
        <img src='langs/de.png' onClick={() => changeLanguage('de')} alt='de' style={CssItemPointer} />
        <img src='langs/pt.png' onClick={() => changeLanguage('pt')} alt='pt' style={CssItemPointer} />
      </div>
      <div className='select'>
        <select
          className='lang-select'
          onChange={(e) => changeLanguage(e.target.value)}
          defaultValue={lang || ''}
          style={{
            background: 'transparent',
            border: '1px solid #ffffff',
            color: '#ffffff',
            fontSize: '14px',
          }}
        >
          <option value=''>{t('Select Language')}</option>
          <option value='ar'>Arabic</option>
          <option value='az'>Azerbaijani</option>
          <option value='bg'>Bulgarian</option>
          <option value='zh-CN'>Chinese (Cantonese)</option>
          <option value='zh-TW'>Chinese (Mandarin)</option>
          <option value='en'>English</option>
          <option value='es'>Spanish</option>
          <option value='fr'>French</option>
          <option value='de'>German</option>
          <option value='he'>Hebrew</option>
          <option value='hi'>Hindi</option>
          <option value='ja'>Japanese</option>
          <option value='ko'>Korean</option>
          <option value='lv'>Latvian</option>
          <option value='ms'>Malay</option>
          <option value='pt'>Portuguese</option>
          <option value='ru'>Russian</option>
          <option value='tr'>Turkish</option>
        </select>
      </div>
    </div>
  )
}
