import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step1 = (props: any) => {
  const { t } = useTranslation()

  const { domain, handleSetDomain} = props

  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('What is BIMI?')}</h2>
        <p>
            BIMI stands for Brand Indicators for Message Identification. 
            BIMI is a standard designed to enhance email security and brand recognition by displaying verified brand logos alongside email messages. 
            It enables email receivers (such as email clients and providers) to validate the authenticity of the sender's domain and display a brand's logo in the email client interface.
        </p>
        <p>
            To use BIMI, a domain must have a valid Domain-based Message Authentication, Reporting, and Conformance (DMARC) policy in place and publish a BIMI record in its DNS (Domain Name System). 
            The BIMI record contains the location of the brand's logo image and includes cryptographic signatures to ensure its authenticity.
        </p>
        <p>
            When an email is sent from a domain with BIMI enabled, the recipient's email client checks for the presence of a BIMI record and validates it against the DMARC policy. 
            If everything checks out, the brand's logo is displayed next to the email in the recipient's inbox, increasing brand visibility and establishing trust.
        </p>
        <p>
            To get started, enter your domain below.
        </p>
        <input
            type='text'
            placeholder={t('yourdomain.com')}
            value={domain}
            onChange={(e) => handleSetDomain(e.target.value)}
            />
        <div>
            <WizardButton
            text={t('Next')}
            onClick={() => props.nextStep()}
            />
        </div>
    </Step>
  )
}