import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'
import {  Domain} from '../Models'


export const Step4 = (props: any) => {
  const { t } = useTranslation()

  const { domains, addDomain, removeDomain} = props;


  return (
    <Step>
      <h2>{t('SPF.Domains.Title')}</h2>
      <div>
        {t('SPF.Domains.Include')} <b>{t('SPF.Domains.Include2')}</b>{' '}
        {t('SPF.Domains.Include3')}
      </div>
      <div>{t('SPF.Domains.Trusted')}</div>

      <div>{t('SPF.Domains.Note')}</div>

      {domains.map((el: Domain) => (
        <div className='domains' key={`${el.id}`}>
          <input
            type='text'
            onChange={(e) => el.update(e.target.value)}
            placeholder={t('SPF.Domains.InputField')}
          />
          <button
            className='add hs-button primary large'
            onClick={() =>removeDomain(el)}
          >
            -
          </button>
        </div>
      ))}
      <div className='domains'>
        <div></div>
        <button
          className='add hs-button primary large'
          onClick={() => addDomain()}
        >
          +
        </button>
      </div>

      <div style={{ marginTop: '20px' }}>{t('SPF.Authorization.Missing')}</div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
