import React, {useState} from 'react'
import './Header.scss'
import { TranslateBar } from './TranslateBar/TranslateBar'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import i18n from 'i18next'
import { DEFAULT_LANGUAGE } from '../i18n'

/* MenuItem.jsx*/
class MenuItem extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  handleHover() {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    const styles = {
      container: {
        opacity: 0,
        animation: '1s appear forwards',
        animationDelay: this.props.delay,
      },
      menuItem: {
        fontFamily: `'Open Sans', sans-serif`,
        fontSize: '1.2rem',
        padding: '1rem 0',
        margin: '0 5%',
        cursor: 'pointer',
        color: this.state.hover ? 'gray' : '#fafafa',
        transition: 'color 0.2s ease-in-out',
        animation: '0.5s slideIn forwards',
        animationDelay: this.props.delay,
      },
      line: {
        width: '90%',
        height: '1px',
        background: 'gray',
        margin: '0 auto',
        animation: '0.5s shrink forwards',
        animationDelay: this.props.delay,
      },
    }

    return (
      <div style={styles.container}>
        <div
          style={styles.menuItem}
          onMouseEnter={() => {
            this.handleHover()
          }}
          onMouseLeave={() => {
            this.handleHover()
          }}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </div>
        <div style={styles.line} />
      </div>
    )
  }
}

/* Menu.jsx */
const Menu = ({
  open,
  toggleMenu,
  children,
}: {
  open: boolean
  toggleMenu: Function
  children?: React.ReactNode
}) => {
  const styles = {
    container: {
      position: 'fixed',
      display: 'flex',
      height: open ? '100%' : 0,
      width: '100vw',
      flexDirection: 'column',
      background: '#243D68',
      opacity: 0.95,
      color: '#fafafa',
      transition: 'height 0.3s ease',
      zIndex: 2,
    },
    menuList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }

  return (
    <div style={styles.container as React.CSSProperties}>
      {open ? (
        <div style={styles.menuList as React.CSSProperties}>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/font-awesome@4.7.0/css/font-awesome.min.css" />
          <div className='top'>{children}</div>
          <div className='bottom'>
            <div className='social'>
              <i className='fa fa-facebook'></i>
            </div>
            <div className='social'>
              <i className='fa fa-twitter' aria-hidden='true'></i>
            </div>
            <div className='social'>
              <i className='fa fa-linkedin' aria-hidden='true'></i>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

/* MenuButton.jsx */
class MenuButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : 'black',
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const styles = {
      line: {
        height: '2px',
        width: '20px',
        background: this.state.color,
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)' : 'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0 : 1,
        transform: this.state.open ? 'translateX(-16px)' : 'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)' : 'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },
    }
    return (
      <div
        className='menu-button'
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick()
              }
        }
      >
        <div style={{ ...styles.line, ...styles.lineTop }} />
        <div style={{ ...styles.line, ...styles.lineMiddle }} />
        <div style={{ ...styles.line, ...styles.lineBottom }} />
      </div>
    )
  }
}

export const Header = (props:any) => {
  const[isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const lang = ''
  const changeLanguage = (lang: string) => {
    if (!lang) {
      i18n.changeLanguage(DEFAULT_LANGUAGE)
    } else {
      i18n.changeLanguage(lang)
    }
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  
  return (
    <div id='header' className='header'>
      <div className='header-content'>
        <TranslateBar lang={lang} changeLanguage={changeLanguage} />
        <div className='header-content-inner'>
          <div className='header-inner'>
            <div className='header-logo'>
              <Link className='logo-container' to='/'>
                <div className='logo'>
                  <svg
                    className='logo-img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 129.41 22.3'
                  >
                    <defs></defs>
                    <title>DMARC-logo-white</title>
                    <g id='Layer_2' data-name='Layer 2'>
                      <g id='Layer_1-2' data-name='Layer 1'>
                        <path
                          className='cls-1'
                          d='M60.17,19.77,67.28,1.33A1.68,1.68,0,0,1,69,.17h2.15a1.7,1.7,0,0,1,1.71,1.16l7.2,18.44c.36,1-.06,1.67-1.16,1.67h-2a1.66,1.66,0,0,1-1.7-1.15l-1.07-2.8H66l-1,2.77a1.65,1.65,0,0,1-1.67,1.18H61.33C60.23,21.44,59.81,20.77,60.17,19.77Zm13-6.08-3.1-8.57-3.19,8.57Z'
                        />
                        <path
                          className='cls-2'
                          d='M51.05,21.59a1.88,1.88,0,0,1-1.87-1.88V17.86h-19v1.85a1.88,1.88,0,0,1-3.75,0h0a1.88,1.88,0,0,0,1.88,1.87H51.05a1.88,1.88,0,0,0,1.88-1.87h0A1.87,1.87,0,0,1,51.05,21.59Z'
                        />
                        <path
                          className='cls-2'
                          d='M26.47,1.64s0-.06,0-.09a.29.29,0,0,0,0-.09l0-.09a.16.16,0,0,1,0-.09s0-.06,0-.08l0-.09a.3.3,0,0,0,0-.08A.56.56,0,0,1,26.71,1a.35.35,0,0,1,.05-.08.35.35,0,0,1,0-.08l.05-.07.08-.08,0-.05h0L27.07.5l.06,0a.27.27,0,0,1,.08-.07l.06,0,.08,0,.08,0,.09,0,.07,0,.15-.05h0a.61.61,0,0,1,.13,0l.05,0H28l.08,0h.47l.09,0,.09,0,.09,0,.09,0,.08,0,.09,0,.08,0,.08,0,.08,0,.08.06.06,0,.09.07,0,0,3.31,3.21H46.4L49.76.54s0,0,0,0l.08-.07.07,0,.08,0,.08,0,.08-.05.08,0,.08,0,.09,0,.09,0,.09,0,.09,0h.55l.09,0h.1l.07,0,.1,0,.08,0,.09,0,.09,0a.2.2,0,0,0,.08,0L52,.3l.07,0a.18.18,0,0,1,.07.05l.08.06.06,0,.08.08h0l0,.05.07.08a.18.18,0,0,1,0,.07l.06.08,0,.07a.59.59,0,0,0,0,.08l0,.09a.59.59,0,0,1,0,.08l0,.09,0,.08s0,.06,0,.09,0,.06,0,.09a.31.31,0,0,1,0,.1.22.22,0,0,1,0,.08v0A1.88,1.88,0,0,0,51.05,0H28.33a1.88,1.88,0,0,0-1.88,1.74h0A.28.28,0,0,1,26.47,1.64Z'
                        />
                        <path
                          className='cls-1'
                          d='M26.45,1.91h0V1.74h0a.28.28,0,0,1,0-.09s0-.06,0-.09a.29.29,0,0,0,0-.09l0-.09a.16.16,0,0,1,0-.09s0-.06,0-.08l0-.09a.3.3,0,0,0,0-.08A.56.56,0,0,1,26.71,1a.35.35,0,0,1,.05-.08.35.35,0,0,1,0-.08l.05-.07.08-.08,0-.05h0L27.07.5l.06,0a.27.27,0,0,1,.08-.07l.06,0,.08,0,.08,0,.09,0,.07,0,.15-.05h0a.61.61,0,0,1,.13,0l.05,0H28l.08,0h.47l.09,0,.09,0,.09,0,.09,0,.08,0,.09,0,.08,0,.08,0,.08,0,.08.06.06,0,.09.07,0,0,3.31,3.21,6.69,6.47L46.4,3.76h0L49.76.54s0,0,0,0l.08-.07.07,0,.08,0,.08,0,.08-.05.08,0,.08,0,.09,0,.09,0,.09,0,.09,0h.55l.09,0h.1l.07,0,.1,0,.08,0,.09,0,.09,0a.2.2,0,0,0,.08,0L52,.3l.07,0a.18.18,0,0,1,.07.05l.08.06.06,0,.08.08h0l0,.05.07.08a.18.18,0,0,1,0,.07l.06.08,0,.07a.59.59,0,0,0,0,.08l0,.09a.59.59,0,0,1,0,.08l0,.09,0,.08s0,.06,0,.09,0,.06,0,.09a.31.31,0,0,1,0,.1.22.22,0,0,1,0,.08v.15h0V19.73h0v0a1.88,1.88,0,0,1-3.76,0l0-13.43-8.26,7.9a1.87,1.87,0,0,1-2.6,0L30.2,6.33v13.4a1.88,1.88,0,0,1-3.76,0l0-17.84'
                        />
                        <path
                          className='cls-1'
                          d='M0,20.51V2.15A1.34,1.34,0,0,1,1.49.69h7A10.48,10.48,0,0,1,19.39,11.33,10.47,10.47,0,0,1,8.51,22h-7A1.34,1.34,0,0,1,0,20.51Zm8-2.62a6.19,6.19,0,0,0,6.51-6.56A6.17,6.17,0,0,0,8,4.77H4.83V17.89Z'
                        />
                        <path
                          className='cls-1'
                          d='M110.55,11.33c0-5.93,4.46-11,11.67-11a10.86,10.86,0,0,1,6.5,2,1.3,1.3,0,0,1,.3,2l-.76.94c-.54.76-1.21.76-2,.28a8.28,8.28,0,0,0-4-1.07,6.52,6.52,0,0,0-6.84,6.78,6.52,6.52,0,0,0,6.84,6.78,8.28,8.28,0,0,0,4-1.07c.82-.48,1.49-.48,2,.28l.76.94c.55.79.52,1.46-.15,1.94a10.84,10.84,0,0,1-6.65,2.1C115,22.3,110.55,17.26,110.55,11.33Z'
                        />
                        <path
                          className='cls-1'
                          d='M100,13.85a6.2,6.2,0,0,0,3.92-6.08,6.72,6.72,0,0,0-7-7.08H88.72a1.34,1.34,0,0,0-1.49,1.46V20.51A1.34,1.34,0,0,0,88.72,22H90.6a1.32,1.32,0,0,0,1.46-1.46V14.37h2.42l4.86,6.91a1.33,1.33,0,0,0,2,.34l1.55-1.09a1.31,1.31,0,0,0,.35-2Zm-8-9.08h4.16a2.86,2.86,0,0,1,2.83,3.1,2.82,2.82,0,0,1-2.83,3H92.06Z'
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </Link>
            </div>
            <div className='hamburger-content'>
              <div className='hamburger-content-inner'>
                <div className='main-menu-content'>
                  <div className='menu-item'>
                    <Link
                      to='/spf'
                      className='menu-item'
                      // activeClassName='active'
                    >
                      {t('SPF Guide')}
                    </Link>
                  </div>
                  <div className='menu-item'>
                    <Link
                        to='/dkim'
                        className='menu-item'
                        // activeClassName='active'
                    >
                      {t('DKIM Guide')}
                    </Link>
                  </div>
                  <div className='menu-item'>
                    <Link
                      to='/dmarc'
                      className='menu-item'
                      // activeClassName='active'
                    >
                      {t('DMARC Guide')}
                    </Link>
                  </div>
                  <div className='menu-item'>
                    <Link
                      to='/bimi'
                      className='menu-item'
                      // activeClassName='active'
                    >
                      {t('BIMI Guide')}
                    </Link>
                  </div>
                  <div className='menu-item'>
                    <Link
                      to='/tls'
                      className='menu-item'
                      // activeClassName='active'
                    >
                      {t('TLS Guide')}
                    </Link>
                  </div>
                  <div className='menu-item'>
                    <a
                      href='https://www.globalcyberalliance.org/contact/'
                      className='menu-item'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('Header.Main.Contact')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <MenuButton
              open={isOpen}
              onClick={() =>{toggleMenu()}}
              color='white'
            />
          </div>
          <Menu open={isOpen} toggleMenu={()=>{toggleMenu()}}>
            <MenuItem delay={`${1 * 0.1}s`}>
              <Link
                to='/spf'
                className='menu-item'
                // activeClassName='active'
                onClick={() =>{toggleMenu()}}
              >
                {t('SPF Guide')}
              </Link>
            </MenuItem>
            <MenuItem delay={`${2 * 0.1}s`}>
              <Link
                  to='/dkim'
                  className='menu-item'
                  // activeClassName='active'
                  onClick={() =>{toggleMenu()}}
              >
                {t('DKIM Guide')}
              </Link>
            </MenuItem>
            <MenuItem delay={`${3 * 0.1}s`}>
              <Link
                to='/dmarc'
                className='menu-item'
                // activeClassName='active'
                onClick={() =>{toggleMenu()}}
              >
                {t('DMARC Guide')}
              </Link>
            </MenuItem>
            <MenuItem delay={`${4 * 0.1}s`}>
              <Link
                  to='/bimi'
                  className='menu-item'
                  // activeClassName='active'
                  onClick={() =>{toggleMenu()}}
              >
                {t('BIMI Guide')}
              </Link>
            </MenuItem>
            <MenuItem delay={`${5 * 0.1}s`}>
              <Link
                  to='/tls'
                  className='menu-item'
                  // activeClassName='active'
                  onClick={() =>{toggleMenu()}}
              >
                {t('TLS Guide')}
              </Link>
            </MenuItem>
            <MenuItem delay={`${6 * 0.1}s`}>
              <a
                href='https://www.globalcyberalliance.org/contact/'
                className='menu-item'
                target='_blank'
                rel='noreferrer'
                onClick={() =>{toggleMenu()}}
              >
                {t('Header.Main.Contact')}
              </a>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  )
}
