import React, { useEffect, FunctionComponent, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';

interface WizardWrapperProps extends StepWizardProps {};

const WizardWrapper: FunctionComponent<WizardWrapperProps> = (props) => {
    const [wizardInstance, setWizardInstance] = useState<any>(null);

    useEffect(() => {
        const handleEnterKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            wizardInstance?.nextStep?.();
        }
        };
        window.addEventListener('keydown', handleEnterKeyPress);
        return () => {
            // Cleanup listener
            window.removeEventListener('keydown', handleEnterKeyPress);
        };
    }, [wizardInstance]);

    return <StepWizard instance={setWizardInstance} {...props} />;
};

export default WizardWrapper;