import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../../Components/WizardButton'
import '../transitions.scss'
import '../Wizard.scss'
import '../loader.scss'


export const Step1 = (props: any) => {
    const[hasErrors, setHasErrors] = useState(false)
    const{ domains, handleSetDomains, getResults, bulkScanning, toggleBulkScanning } = props
    const { t } = useTranslation()

    const handleSubmit = useCallback(async() =>{
      if (!domains) {
        setHasErrors(true)
        return false
      }

      setHasErrors(false)
      props.nextStep()
      const results = await getResults(domains)
      if(results){
        props.nextStep()
      }
      return true
    }, [domains, getResults, props])

    const handleKeyPress = useCallback(async(event: any) =>{
      if (event.key === 'Enter' && !event.shiftKey) {
        handleSubmit()
      }
    },[handleSubmit])

    useEffect(() => {
      const handleKeyPressEvent = (event: any) => {
        handleKeyPress(event);
      };

      document.addEventListener('keydown', handleKeyPressEvent);
      return () => {
        //cleanup listener
        document.removeEventListener('keydown', handleKeyPressEvent);
      };
    }, [handleKeyPress]);

    return (
      <div>
        <h1 style={{ textAlign: 'center' }}>{t('Home.Main.EnterDomain')}</h1>
        <p>{t('Home.Main.FirstStep')}</p>
        <p>{t('Home.Main.InputWarning')}</p>
        <div
          className={`${hasErrors ? 'error' : ''}`}
          style={{ margin: '0 auto', textAlign: 'center' }}
        >
          <div className='input-domains'>
            {bulkScanning ? (
              <textarea
                className='bulk'
                placeholder='list of domains'
                value={domains || ''}
                onChange={(e) => handleSetDomains(e.target.value)}
              />
            ) : (
              <input
                type='text'
                value={domains || ''}
                placeholder={t('Home.Main.InputDomain')}
                onChange={(e) => handleSetDomains(e.target.value)}
              />
            )}
            <div>
              <WizardButton
                className='toggle-scan'
                text={
                  bulkScanning
                    ? t('SPF.Home.SingleScan')
                    : t('SPF.Home.BulkScan')
                }
                onClick={() => toggleBulkScanning()}
              />
            </div>
          </div>
  
          <div>
            <p>
              <small
                dangerouslySetInnerHTML={{ __html: t('Home.Main.InputExample') }}
              />
            </p>
          </div>
  
          <div className='buttons'>
            <div></div>
            <WizardButton
              text={t('SPF.Home.Next')}
              onClick={async () =>{
                await handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    )
  }