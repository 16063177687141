import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step1 = (props: any) => {
  const { t } = useTranslation()

  const { domain, handleSetDomain} = props

  return (
    <Step>
      <h2 style={{ fontWeight: 'bold' }}>{t('DMARC.Home.Title')}</h2>
      <div>{t('DMARC.Home.Final')}</div>
      <div>{t('DMARC.Home.Protocol')}</div>
      <div>{t('DMARC.Home.Stronger')}</div>
      <div style={{ marginTop: '20px' }}>{t('DMARC.Home.ClickNext')}</div>
      <div>
        <input
          type='text'
          className=''
          placeholder={t('DMARC.Home.YourDomain')}
          value={domain}
          onChange={(e) => handleSetDomain(e.target.value)}
        />
      </div>
      <div className='buttons'>
        <div></div>
        <WizardButton
          text={t('DMARC.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
