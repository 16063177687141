import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step7 = (props: any) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const {getRecord, handleStartOver} = props
  return (
    <Step>
      <h2>{t('DMARC.Results.Title')}</h2>

      <div>
        <p>{t('DMARC.Results.OwnDNS')}</p>
        <p>{t('DMARC.Results.ThirdParty')}</p>
        <p>
          {t('DMARC.Results.Reference')}{' '}
          <a
            href='https://dmarc.globalcyberalliance.org/resources.html'
            target='_blank'
            rel='noreferrer'
          >
            {t('DMARC.Results.ResourcesPage')}
          </a>{' '}
          {t('DMARC.Results.Information')}
        </p>
        <p>
          {t('DMARC.Results.NotUnderstood')}(
          <a href='mailto:domainsecurityscanner@globalcyberalliance.org'>
            domainsecurityscanner@globalcyberalliance.org
          </a>
          ) {t('DMARC.Results.Assistance')}
        </p>
      </div>

      <div className='results-input'>
        <textarea readOnly value={getRecord()} />
        <div>
          <button
            className={`hs-button ${copied ? 'copied' : ''}`}
            onClick={() => {
              setCopied(true)
              navigator.clipboard.writeText(window.location.href)
              setTimeout(() => {
                setCopied(false)
              }, 2000)
            }}
          >
            {copied ? t('DMARC.Results.Copied') : t('DMARC.Results.Copy')}
          </button>
        </div>
      </div>
      <div>
        <p>{t('DMARC.Results.Implemented')}</p>
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Restart')}
          onClick={() =>{
            handleStartOver()
            props.firstStep()}}
        />
      </div>
    </Step>
  )
}
