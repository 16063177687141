
interface IProps {
  index?: number | null,
  title?: string | null,
  children?: any | null,
}

export const Step = (props: IProps) => {
  return (
    <div style={{ margin: '0 auto' }} className='step'>
      {props.title ? (
        <h1 style={{ textAlign: 'center' }}>
          {props.index && `${props.index}. `}
          {props.title}
        </h1>
      ) : null}
      <div style={{ marginTop: '30px' }}>{props.children}</div>
    </div>
  )
}