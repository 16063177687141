let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC Ръководство`; 

l.Header.Main.SetupDMARC        = `Конфигуриране на DMARC`;
l.Header.Main.HowItWorks        = `Как работи`;
l.Header.Main.AboutDMARC        = `За DMARC`;
l.Header.Main.Resources         = `Ресурси `;
l.Header.Main.Contact           = `За контакти`;

l.Footer.Main.SetupDMARC        = `Конфигуриране на DMARC`;
l.Footer.Main.HowItWorks        = `Как работи`;
l.Footer.Main.AboutDMARC        = `За DMARC`;
l.Footer.Main.Resources         = `Ресурси `;
l.Footer.Main.Contact           = `За контакти`;
l.Footer.Main.Copyright         = `Всички права запазени ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Политика за защита на личните данни`;
l.Footer.Main.Sitemap           = `Карта на сайта`;

l.Home.Main.EnterDomain         = `Въведете  Вашия домейн`;
l.Home.Main.ToStart             = `за да започнете конфигурирането на DMARC`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `"Добре дошли в Ръководството за конфигуриране на DMARC. Целта на това ръководство е да напътства Вашата организация в процеса по създаването на DMARC механизъм и правила за Sender Policy Framework (SPF) и DomainKeys Identified Mail (DKIM). Прилагайки и трите системи, Вашата организация ще има по-силен механизъм за валидиране на имейл съобщения, за да Ви помогне да защитите името и репутацията си."`;
l.Home.Main.FirstStep           = `Първата стъпка е да разберете дали вашата организация  вече прилага някоя от трите системи.`;
l.Home.Main.InputWarning        = `Моля въведете домейна на Вашата организация точно както е изписан след '@' символа в имейл адреса.`;
l.Home.Main.InputExample        = `"Например, в следния имейл адрес, domainsecurityscanner@globalcyberalliance.org, домейнът, който трябва да въведете в полето е globalcyberalliance.org"`;
l.Home.Main.InputDomain         = `вашиятдомейн.com`;
l.Home.Main.Required            = `Адресът на уебсайта е необходим`;
l.Home.Main.valid               = `Адресът на уебсайта е невалиден`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google Apps и Office 365 потребители:`;
l.Home.Main.UserWarningText1    = `Моля следвайте инструкциите предоставени на страницата с ресурси за`;
l.Home.Main.UserWarningLink     = `доставчици на облачни услуги`;
l.Home.Main.UserWarningText2    = `"за да внедрите SPF и DKIM. След като приключите, моля ползвайте това ръководство  за да продължите с внедриването на DMARC политиката."`;
l.Home.Main.Submit              = `Изпрати`;
l.Home.Main.MoreInfo            = `"Ако се нуждаете от повече информация за DMARC, каним Ви да посетите един от нашите месечни DMARC  уебинари. Този уебинар покрива основите на DMARC и предлага кратък преглед на наръчника за конфигуриране на GCA DMARC."`;
l.Home.Main.Webinar             = `"Месечният GCA DMARC уебинар се състои два пъти на третата сряда от всеки месец. Моля регистрирайте се в удобни за Вас датата и време,  следвайки съответния линк по-долу:"`;
l.Home.Main.SelectDate          = `Изберете дата`;

l.ToolSelect.Main.Title         = `Резултати за`;
l.ToolSelect.Main.ResultStatus  = `Таблицата по-долу  показва кои протоколи са <i class='fas fa-check-circle' style='color: green'></i> и кои не са <i class='fas fa-times-circle' style='color: red'></i> били внедрени.`;
l.ToolSelect.Main.Protocol      = `"Моля изберете един от протоколите, за да стартирате ръководството за конфигурация."`;
l.ToolSelect.Main.ShareButton   = `Ако бихте желали да споделите резултатите кликнете на бутона 'Сподели' и линкът ще бъде копиран в клипборда.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `Приложението проверяващо DKIM е все още в процес на разработка. Ръководството за инсталиране на DKIM  е налично избирайки DKIM реда.`;
l.ToolSelect.Main.NoDKIM        = `Не можахме да намерим DKIM запис свързан с Вашия домейн в списък със селектори по подразбиране`;
l.ToolSelect.Main.NoDKIM1       = `Ако знаете селектора можете да потърсите DKIM запис тук:`;
l.ToolSelect.Main.NoDKIM2       = `"Или ако предоставите имейл адрес, ние ще Ви пратим имейл съобщение на акаунта, на което можете да отговорите и ние ще сканираме имейла за DKIM запис."`;
l.ToolSelect.Main.NoDKIMError   = `Имейлът не е валиден и не принадлежи на предоставения домейн.`;
l.ToolSelect.Main.SelectorMsg   = `Сканиране за селектор!`;
l.ToolSelect.Main.SelectorError = `Селектор не беше намерен.`;
l.ToolSelect.Main.EmailMsg      = `"Имейлът е изпратен! Моля отговорете на този имейл. Веднага след като отговорът е изпратен, страницата ще презареди и предостави името на селектора."`;
l.ToolSelect.Main.InputSelector = `Селектор`;
l.ToolSelect.Main.InputEmail    = `потребител@пример.com`;
l.ToolSelect.Main.Submit        = `Изпрати`;
l.ToolSelect.Main.Select        = `Избери`;
l.ToolSelect.Main.Next          = `Следващ`;
l.ToolSelect.Main.Share         = `Сподели`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Вие завършихте конфигурирането на DMARC`;
l.Complete.Main.check           = `"Проверете друг домейн за SPF, DKIM или DMARC?"`;
l.Complete.Main.InputDomain     = `вашиятдомейн.com`;
l.Complete.Main.Required        = `Адресът на уебсайта е необходим`;
l.Complete.Main.valid           = `Адресът на уебсайта е невалиден`;
l.Complete.Main.Next            = `Следващ`;
l.Complete.Main.More            = `Допълнително за DMARC`;

l.SPF.Home.Title                = `Какво е SPF?`;
l.SPF.Home.SPF                  = `"SPF е съкращение от Sender Policy Framework. Тази система предпазва  подателския адрес от фалшифициране, дефинирайки кои мейл сървъри са упълномощени да изпращат имейли от името на организацията,Äö√Ñ√¥s домейн. Ако мейл сървърът не е дефиниран в SPF, тогава съобщението ще бъде отказано или недоставено."`;
l.SPF.Home.Start                = `Моля кликнете на бутона 'Следващ' за да започнете процеса по внедряването на SPF система.`;
l.SPF.Home.Next                 = `Следващ`;

l.SPF.Authorization.Title       = `Упълномощени ли са изброени по-долу сървъри да изпращат имейли от домейна на организацията?`;
l.SPF.Authorization.First       = `"Първата част от системата е да дефинира входящите мейл сървъри (MXes) на домейна, които са упълномощени също и да изпращат имейли от този домейн."`;
l.SPF.Authorization.Current     = `Списъкът по-долу съдържа настоящите DNS записи за имейл сървърите числяши се към Вашата организация.`;
l.SPF.Authorization.Recommended = `"Силно препоръчително е да изберете 'Да', тъй като списъкът по-горе трябва да съдържа упълномощените сървъри за Вашия домейн. По този начин тагът mx ще бъде добавен към SPF политика."`;
l.SPF.Authorization.Yes         = `Да (Препоръчително)`;
l.SPF.Authorization.No          = `Не`;
l.SPF.Authorization.Missing     = `"Ако има системи липсващи в списъка  или такива, които не са стандартните мейл сървъри, тогава моля прибавете тези системи под следващата опция"`;
l.SPF.Authorization.Next        = `Следващ`;

l.SPF.Servers.Title             = `"Има ли допълнителни сървъри в домейна, които могат да доставят или препращат имейли за този домейн?"`;
l.SPF.Servers.Possible          = `"Възможно е не всички мейл сървъри на организацията да са дефинирани в DNS записи на организацията. Това може да са тест сървъри или приложения с вградени мейл системи. Ако това е така, моля добавете допълнителните сървър домейни или IP адреси в полетата по-долу. "`;
l.SPF.Servers.Others            = `"Тази опция трябва да се използва само в случай, че има други вътрешни системи , различни от мейл сървърите, упълномощени да изпращат имейли от домейна. "`;
l.SPF.Servers.Added             = `"Ако системите са добавени, тогава това ще добави ‚Äö√Ñ√≤a‚Äö√Ñ√¥  таг към SPF политиката."`;
l.SPF.Servers.InputField        = `Име на сървър или IP `;
l.SPF.Servers.InputInvalid      = `Трябва да е напълно квалифицирано домейн име (FQDN) или IP адрес`;
l.SPF.Servers.Next              = `Следващ`;

l.SPF.Domains.Title             = `"Има ли външни домейни, които могат да доставят или препращат имейли за Вашата организация?"`;
l.SPF.Domains.Include           = `Този раздел ще добави `;
l.SPF.Domains.Include2          = `включи`;
l.SPF.Domains.Include3          = `тагът на SPF политиката.`;
l.SPF.Domains.Trusted           = `"Това трябва да се използва само ако външният домейн е доверен да изпраща имейл съобщения за вашата организация, Äö√Ñ√¥s домейн. Например, система за управление на връзки с клиентите (Customer Relations Management System), доставчик на облачна поща (Cloud Mail Provider - Google, Office 365, Yahoo!) или доставчик на облачна сигурност (Cloud Security Provider)."`;
l.SPF.Domains.Note              = `"Моля обърнете внимание: ако използвате доставчик на облачни услуги, трябва да работите заедно с тях за да определите подходящата стойност за тази настройка. "`;
l.SPF.Domains.InputField        = `Име на домейна или IP `;
l.SPF.Domains.InputInvalid      = `Трябва да бъде напълно квалифицирано домейн име (FQDN) или IP адрес`;
l.SPF.Domains.Next              = `Следващ`;

l.SPF.Strict.Title              = `Колко стриктно трябва сървърите да обработват имейлите?`;
l.SPF.Strict.Receiving          = `"Това е последният раздел на SPF механизма, който ще определи колко стриктно получаващите сървъри трябва да обработват имейлите. "`;
l.SPF.Strict.SoftFail           = `Soft Fail (Препоръчително)`;
l.SPF.Strict.Fail               = `Fail`;
l.SPF.Strict.Neutral            = `Neutral`;
l.SPF.Strict.AllowAll           = `Allow All `;
l.SPF.Strict.Next               = `Следващ`;
l.SPF.Strict.Options            = `Опции:`;
l.SPF.Strict.FailEx             = `Fail или`;
l.SPF.Strict.FailEx2            = `"означава, че само на домейн мейл сървърите (и тези в ––– и включващи раздели) им е разрешено да изпращат имейли за домейна. На всички други това е забранено. "`;
l.SPF.Strict.SoftEx             = `Soft Fail или`;
l.SPF.Strict.SoftEx2            = `"означава, че само на домейн мейл сървърите (и тези в ––– и включващи раздели) им е разрешено да изпращат имейли за домейна, но е в преход. На всички други е забранено. "`;
l.SPF.Strict.NeutralEx          = `Neutral или `;
l.SPF.Strict.NeutralEx2         = `"означава изрично, че нищо не може да бъде казано относно валидност. "`;
l.SPF.Strict.AllowEx            = `Allow All или`;
l.SPF.Strict.AllowEx2           = `"означава, че всеки хост може да праща имейли за домейна. "`;
l.SPF.Strict.Warning1           = `Този опция`;
l.SPF.Strict.Warning2           = `не трябва никога`;
l.SPF.Strict.Warning3           = `да бъде използвана`;
l.SPF.Strict.Recommended        = `Препоръчва се да се използва Soft Fail`;
l.SPF.Strict.MarkMail           = `"Това ще маркира имайла като несъответстващ, ако не отговаря на определените критерии. "`;

l.SPF.Results.Title             = `"По-долу е SPF TXT записа, който трябва да бъде създаден на вашия DNS сървър. "`;
l.SPF.Results.Copy              = `Копирай`;
l.SPF.Results.Copied            = `КОПИРАНО!`;
l.SPF.Results.DMARCGuide        = `Премини към DMARC ръководството `;
l.SPF.Results.DKIMGuide         = `Продължи към DKIM ръководството `;
l.SPF.Results.OwnDNS            = `"Ако имате свой собствен DNS сървър, моля създайте TXT запис за горепосочената SPF система."`;
l.SPF.Results.ThirdParty        = `"Ако използвате външен доставчик на DNS, моля следвайте техните инструкции за създаване на TXT запис."`;
l.SPF.Results.Reference         = `Моля консултирайте се със `;
l.SPF.Results.ResourcesPage     = `страницата с ресурси`;
l.SPF.Results.Information       = `за информация относно как се създават TXT записи. `;
l.SPF.Results.NotUnderstood     = `"Ако сте се опитали да следвате тези инструкции, но не сте разбрали стъпките, които трябва да предприемете или използваната терминология, препоръчително е да се свържете с вашата организация за IT поддръжка, домейн регистратор или GCA Info "`;
l.SPF.Results.Assistance        = `за допълнителна помощ.`;
l.SPF.Results.Implemented       = `"Щом SPF записът е внедрен, моля използвайте  SPF контрольора по-долу: "`;
l.SPF.Results.YourDomain        = `вашиятдомейн.com`;
l.SPF.Results.Check             = `Провери`;
l.SPF.Results.SuccessDKIM       = `ПРЕМИНЕТЕ КЪМ DKIM`;
l.SPF.Results.SuccessDKIM       = `ПРЕМИНЕТЕ КЪМ DMARC`;
l.SPF.Results.NotQuite          = `Все още не сме там.`;
l.SPF.Results.Wisdom            = `Всичко е наред! Мъдростта е крайната цел на всяко действие. Моля не се колебайте да се възползвате от нашите ресурси  по-горе или да ни пишете и ние може да Ви помогнем с внедряването. Затова сме тук. `;

l.DKIM.Home.Title               = `Какво е DKIM?`;
l.DKIM.Home.Mechanism           = `"DKIM е съкращение от DomainKeys Identified Mail. DKIM е механизъм за удостоверяване на самоличността на домейна свързан с имейл съобщението. Това се постига чрез отентикация използваща асиметрична криптография. DKIM ще удостовери автентичността на имейл съобщението чрез прибавянето на дигитален подпис към хедъра на съобщението.  Важно е да се отбележи, че DKIM не филтрира имейл съобщенията. Само ще позволи на спам филтрите да определят автентичността на имейл съобщението, което се изпраща. "`;
l.DKIM.Home.ClickNext           = `"Моля кликнете на 'Следващ', за да започнете процеса по създаването на DKIM система."`;
l.DKIM.Home.YourDomain          = `вашиятдомейн.com`;
l.DKIM.Home.Next                = `Следващ`;

l.DKIM.Selector.Title           = `Селектор`;
l.DKIM.Selector.Name            = `"Селекторът ще определи името на DKIM записа. Името на селектора може да бъде всичко, но по-долу са изброени няколко опции от които можете да избирате."`;
l.DKIM.Selector.Define          = `"Това ще определи името на  TXT записа, които ще бъде създаден за DKIM ключа. Това ще изглежда по следния начин:"`;
l.DKIM.Selector.Example1        = `селектор`;
l.DKIM.Selector.Example2        = `име на домейна`;
l.DKIM.Selector.Next            = `Следващ`;

l.DKIM.Key.Title                = `Публичен ключ`;
l.DKIM.Key.Tag                  = `"Това ще добави 'p' тага, който е ключовата поредица (key string) в публичния ключов файл генериран по-рано.   "`;
l.DKIM.Key.Keys                 = `Поставете публичния ключ тук `;
l.DKIM.Key.Next                 = `Следващ`;
l.DKIM.Key.Creating             = `Създаването на DKIM ключове:`;
l.DKIM.Key.TwoParts             = `DKIM   се състои от две части: частен ключ и публичен ключ. Създаването на ключове за DKIM ще варира според това кой предоставя имейл услуги на Вашата организация.  `;
l.DKIM.Key.External             = `"Ако използвате външни услуги за имейл и DNS, то тогава доставчикът на мейл услуги ще трябва да Ви предостави публичния ключ. Този публичен ключ се публикува като TXT запис в DNS. Повечето DNS доставчици ограничават размера на  TXT записа до 255 знака, затова може да се наложи да работите заедно с доставчика, за да увеличи размера или да създаде TXT записа. Частният ключ се държи от доставчика на мейл услуги и обикновено не се предоставя на организацията. "`;
l.DKIM.Key.Internal             = `"Ако организацията има свой собствен имейл сървър, тогава DKIM  ключът трябва да бъде генериран от IT персонала (или отговорните за внедряването на DKIM лица). Има множество генератори за DKIM ключове налични в интернет. Ако решите да използвате някой от тези, уверете се, че имате опцията да изтриете ключа след като е генериран и копиран във файловете Ви. "`;
l.DKIM.Key.OpenDKIM             = `Ако желаете да създадете ключовете без външна намеса съществува open-source проект на име opendkim.`;
l.DKIM.Key.OpenDKIM2            = `Opendkim съдържа различни инструменти за създаването на DKIM ключа.`;
l.DKIM.Key.OpenSSL              = `Друга опция е използването на OpenSSL за генериране на DKIM ключовете. Това може да бъде постигнато използвайки последната версия на OpenSSL и въвеждайки следната команда за личния ключ:`;
l.DKIM.Key.OpenSSL2             = `Това ще генерира частен ключов файл на име`;
l.DKIM.Key.OpenSSL3             = `"След това въведете следната команда, за да генерирате публичния ключ:"`;
l.DKIM.Key.OpenSSL4             = `Тази команда ще генерира публичния ключ`;
l.DKIM.Key.OpenSSL5             = `базиран на вече създадения частен ключ`;
l.DKIM.Key.OpenSSL6             = `"Преместете личния DKIM ключ на мястото упоменато в DKIM инсталацията. Уверете се, че е в папка с ограничен достъп. "`;

l.DKIM.Results.Title            = `"По-долу е DKIM TXT записът, който трябва да бъде създаден на Вашия DNS сървър."`;
l.DKIM.Results.Copy             = `Копирай`;
l.DKIM.Results.Copied           = `КОПИРАНО!`; 
l.DKIM.Results.SPFGuide         = `Върнете се към SPF ръководството  `;
l.DKIM.Results.DMARCGuide       = `Продължете към DMARC ръководството`;
l.DKIM.Results.OwnDNS           = `"Ако имате собствен DNS, тогава моля създайте TXT записи за гореспоменатата DKIM система."`;
l.DKIM.Results.ThirdParty       = `"Ако използвате външен доставчик на DNS услуги, тогава моля следвайте техните инструкции за създаването на TXT запис. "`;
l.DKIM.Results.Reference        = `Моля консултирайте се със `;
l.DKIM.Results.ResourcesPage    = `страницата с ресурси `;
l.DKIM.Results.Information      = `за информация относно как се създават TXT записи. `;
l.DKIM.Results.NotUnderstood    = `"Ако сте се опитали да следвате тези инструкции, но не сте разбрали стъпките, които трябва да предприемете или използваната терминология, препоръчително е да се свържете с вашата организация за IT поддръжка, домейн регистратор или GCA Info "`;
l.DKIM.Results.Assistance       = `за допълнителна помощ.`;
l.DKIM.Results.Note             = `Моля отбележете:`;
l.DKIM.Results.Support          = `"DKIM ключовете сега могат да поддържат 2048-битови ключове. Въпреки това, DNS TXT записите не поддържат стойности от над 255 знака. Можете да заобиколите това чрез:"`;
l.DKIM.Results.Support2         = `използването  на 1024-битов ключ или`;
l.DKIM.Results.Support3         = `създаването на многоредов TXT запис`;
l.DKIM.Results.Support4         = `Пример на многоредов запис може да бъде видян по-долу (кавички са добавени за да разделят  поредицата (string) на множество редове):`;

l.DMARC.Home.Title              = `Какво е DMARC?`;
l.DMARC.Home.Final              = `"След като SPF и DKIM системите са създадени и приложени, можем да продължим към последната стъпка по създаването на DMARC механизъм."`;
l.DMARC.Home.Protocol           = `"DMARC (Domain-based Message Authentication, Reporting & Conformance)DMARC (Domain-based Message Authentication, Reporting & Conformance) е протокол за валидиране на имейли, включващ функция за доклади, която позволява на подателите и получателите да подобрят и наблюдават защитата на домейна от подправени имейл съобщения. Имплементацията на DMARC предотвратява фалшифицирането на 'От' адреса в имейл съобщенията от спамъри. В зависимост от настройките на DMARC механизма, всички имейл съобщения идващи от неупълномощен  мейл сървър  ще бъдат поставени под карантина или отхвърлени.  Всички спам и фишинг имейл съобщения използващи домейн името на организация ще бъдат поставени под карантина или изтрити преди да достигнат дестинацията си (крайния потребител). Създадените доклади могат да бъдат използвани за разузнаване или от силите на реда в случай, че действията са от криминален характер. "`;
l.DMARC.Home.Stronger           = `"DMARC ще събере SPF и DKIM в едно, за да създаде по-силна имейл валидация за домейна на Вашата организация."`;
l.DMARC.Home.ClickNext          = `"Моля кликнете на 'Следващ', за да започнете процеса по създаването на DMARC механизъм."`;
l.DMARC.Home.YourDomain         = `вашиятдомейн.com`;
l.DMARC.Home.Next               = `Следващ`;

l.DMARC.Policy.Title            = `На какво ниво е необходимо да бъде DMARK политиката?`;
l.DMARC.Policy.Levels           = `"Има три възможни политики, на които DMARC може да бъде настроен. Те са:"`;
l.DMARC.Policy.NoneEx           = `"None (никаква) - докладва потенциално подозрителните имейл съобщения, но всички имейли са доставени в пощенската кутия"`;
l.DMARC.Policy.QuarantineEx     = `Quarantine (постави под карантина) - Fail на SPF/DKIM механизмите (имейлите са невалидирани) и са изпратени в Spam или Junк папката `;
l.DMARC.Policy.RejectEx         = `"Reject (отхвърли) - провал на SPF/DKIM механизмите, в следствие имейл съобщенията са отхвърлени и недоставени"`;
l.DMARC.Policy.None             = `None (никаква) (Препоръчително)`;
l.DMARC.Policy.Quarantine       = `Quarantine (постави под карантина)`;
l.DMARC.Policy.Reject           = `Reject (oтхвърли) `;
l.DMARC.Policy.Next             = `Следващ`;
l.DMARC.Policy.Recommended1     = `Силно`;
l.DMARC.Policy.Recommended2     = `препоръчително`;
l.DMARC.Policy.Recommended3     = `"е да започнете с None, тъй като това ще Ви предостави време и ще Ви позволи да наблюдавате генерираните доклади  и да прецените дали легитимни имейли ще бъдат блокирани  преди да смените DMARC механизма на Quarantine ('постави под карантина') или Reject ('отхвърли'). "`;
l.DMARC.Policy.Tag              = `Това ще добави `;
l.DMARC.Policy.Tag2             = `тага към DMARC записа.`;

l.DMARC.Aggregates.Title        = `Къде да се пращат обобщените доклади?`;
l.DMARC.Aggregates.Opt          = `"Това е по избор, но е силно препоръчително и ще добави "`;
l.DMARC.Aggregates.Opt2         = `rua' таг към механизма. Силно`;
l.DMARC.Aggregates.Opt3         = `препоръчително `;
l.DMARC.Aggregates.Opt4         = `е това да бъде добавено към механизма с цел анализ и наблюдение. Докладите могат да бъдат изпращани на множество адреси. `;
l.DMARC.Aggregates.InputField   = `Имейл адрес`;
l.DMARC.Aggregates.InputInvalid = `Моля въведете валиден имейл адрес`;
l.DMARC.Aggregates.Next         = `Следващ`;
l.DMARC.Aggregates.Reports      = `Обобщените доклади (в XML формат) ще се генерират включвайки информация за това кои имейл съобщения са преминали или не през SPF и DKIM. Това дава видимост върху възможни проблеми свързани с валидиране и/или спам активност за Вашата организация.`;
l.DMARC.Aggregates.Contains     = `Докладите съдържат следното`;
l.DMARC.Aggregates.HowToRead    = `Как да прочетете Вашия пръв DMARC доклад `;
l.DMARC.Aggregates.By           = `(Част 1) от Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `ISP информация`;
l.DMARC.Aggregates.Desc         = `Ред-по-ред описание на DMARC записа на Вашата организация`;
l.DMARC.Aggregates.Summary      = `Обобщение на резултатите от валидирането `;
l.DMARC.Aggregates.Important    = `"Важно е да се отбележи, че тези доклади не идват само от един източник. Възможно е  да получите от 10 до 100 (дори хиляди) доклади ежедневно (броят на генерираните доклади зависи от количеството на пратени имейли).  "`;
l.DMARC.Aggregates.Difficult    = `"Това ще затрудни ръчното преглеждане на всеки доклад , особено след като са в XML формат, а някои доклади могат да бъдат твърде дълги."`;
l.DMARC.Aggregates.Readable     = `"Тези доклади могат да станат четими разработвайки XML конвертор или работейки с доставчик. Повечето доставчици ще предоставят  хранилище, което ще направи докладите четими, както различни нива на анализ и  напътствия.  "`;
l.DMARC.Aggregates.Vendors      = `DMARC (GCA партньори):`;

l.DMARC.Forensics.Title         = `Къде трябва да бъдат изпращани компютърните експертизи?`;
l.DMARC.Forensics.Opt           = `Това е по избор и ще добави`;
l.DMARC.Forensics.Opt2          = `ruf' тага към механизма. Тези XML доклади ще бъдат генерирани само ако получателят генерира този тип доклади. Възможно е да не получавате такива доклади. Това се дължи на установените закони за защита поверителността и споделянето на данни в съответните държави.`;
l.DMARC.Forensics.InputField    = `Имейл адрес`;
l.DMARC.Forensics.InputInvalid  = `Моля въведете валиден имейл адрес `;
l.DMARC.Forensics.Next          = `Следващ`;

l.DMARC.Subdomains.Title        = `DMARC механизъм за събдомейни?`;
l.DMARC.Subdomains.Define       = `Това ще установи нивото на политика за събдомейните на домейна във Вашата организация. Ако този таг`;
l.DMARC.Subdomains.Define2      = `"не съществува , тогава политиката ще следва политиката за домейна от първо ниво, който беше дефиниран в първата стъпка."`;
l.DMARC.Subdomains.None         = `None (никаква) `;
l.DMARC.Subdomains.Quarantine   = `Quarantine (постави под карантина)`;
l.DMARC.Subdomains.Reject       = `Reject (oтхвърли) `;
l.DMARC.Subdomains.Next         = `Следващ`;
l.DMARC.Subdomains.Policy       = `Политика за домейна. Опциите са:`;
l.DMARC.Subdomains.NoneEx       = `"None (никаква) - не се предприемат никакви действия, но се записват в DMARC доклада. "`;
l.DMARC.Subdomains.QuarantineEx = `Quarantine (постави под карантина) - имейл съобщението се маркира като спам `;
l.DMARC.Subdomains.RejectEx     = `Reject (oтхвърли) -  съобщението е изтрито.`;
l.DMARC.Subdomains.Recommended  = `"Препоръчително е да изберете 'None' ако имате множество събдомейни. Най-добре е да създадете отделни DMARC механизми за всеки събдомейн, докато всички събдомейни са съвместими с DMARC механизма. В случай, че не съществуват събдомейни, препоръчително е да се използва 'Reject'. "`;

l.DMARC.Options.Title           = `"(По избор) Останалите опции не са задължителни, но могат да бъдат настроени при нужда:"`;
l.DMARC.Options.ReportGen       = `1. Кога трябва да се генерира доклад за грешка?`;
l.DMARC.Options.Default         = `По подразбиране`;
l.DMARC.Options.ReportPol       = `Дефинира механизма за докладване на грешки`;
l.DMARC.Options.DefaultsTo      = `"Ако не е дефиниран, тогава по подразбиране:"`;
l.DMARC.Options.AllChecks       = `"Генерирай доклад до изпращащия Mail Transfer Agent (MTA) в случай, че основните проверки са несполучливи."`;
l.DMARC.Options.AnyChecks       = `"Генерирай доклад до изпращащия Mail Transfer Agent (MTA) в случай, че основните проверки са несполучливи."`;
l.DMARC.Options.OtherValues     = `Други стойности са:`;
l.DMARC.Options.DKIMFails       = `Генерирай доклад ако DKIM проверката е несполучлива`;
l.DMARC.Options.SPFFails        = `Генерирай доклад ако SPF проверката е несполучлива`;
l.DMARC.Options.Relaxed         = `Relaxed`;
l.DMARC.Options.Strict          = `Strict`;
l.DMARC.Options.DKIMAlign       = `2. Режим на групиране за DKIM `;
l.DMARC.Options.DKIMAlignOpt    = `Режим на подравняване/изравняване/япдреждане/групиране за DKIM. Опциите са: `;
l.DMARC.Options.DKIMRelaxed     = `"(relaxed) по подразбиране, ако не са дефинирани. Позволява всеки събдомейн дефиниран в DKIM хедъра."`;
l.DMARC.Options.DKIMStrict      = `(strict) домейнът на подателя трябва точно да съответства на домейна в DKIM  хедъра.`;
l.DMARC.Options.SPFAlign        = `3. Режим на групиране за  SPF `;
l.DMARC.Options.SPFAlignOpt     = `Режим на групиране за  SPF. Опциите са: `;
l.DMARC.Options.SPFRelaxed      = `"(relaxed) по подразбиране, ако не са дефинирани. Позволява всеки събдомейн."`;
l.DMARC.Options.SPFStrict       = `(strict) домейнът на организацията в MAIL FROM командата (в SMTP) и 'from:' хедъра (в имейла) трябва да пасват точно`;
l.DMARC.Options.Percentage      = `4. Процент на съобщенията подчинени на DMARC механизма?`;
l.DMARC.Options.Filter          = `"Процентът имейл съобщения подложени на филтриране от DMARC механизма. Стойността може да е всяко число от 1 до 100. По подразбиране стойността е 100, което означава всички съобщения.   "`;
l.DMARC.Options.Formats         = `5. Формат на генерираните доклади`;
l.DMARC.Options.FormatsEx       = `Oпределя формата на генерираните доклади. Стойностите за форматиране са:`;
l.DMARC.Options.RFC             = `"стойност по подразбиране, ако не e дефиниранa. Форматът се дефинира от RFC 5965.  "`;
l.DMARC.Options.RFC2            = `Форматът се дефинира от RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Интервал на докладване (в секунди)`;
l.DMARC.Options.SecondsDefault  = `секунди (по подразбиране)`;
l.DMARC.Options.DigitsOnly      = `Моля въведете само цифри`;
l.DMARC.Options.ReportIntEx     = `"Oпределя интервала за докладване в секунди. В случай, че не е дефиниран, интервалът е 86400 секунди или 24 часа по подразбиране. Моля отбележете, че не е гарантирано, че тези доклади ще  бъдат изпратени от получаващите МТА. Докладите се пращат на основата на добри намерения."`;
l.DMARC.Options.HToS            = `Конвертор от часове в секунди`;
l.DMARC.Options.Hours           = `24 часа`;
l.DMARC.Options.To              = `в`;
l.DMARC.Options.Seconds         = `86400 секунди`;
l.DMARC.Options.Finish          = `Завърши`;

l.DMARC.Results.Title           = `"По-долу е DMARC TXT записът, който трябва да бъде създаден на вашия DNS сървър."`;
l.DMARC.Results.Display         = `Покажи резултата за DMARC TXT  запис`;
l.DMARC.Results.Copy            = `Копирай`;
l.DMARC.Results.Copied          = `КОПИРАНО!`;
l.DMARC.Results.SPFGuide        = `Върни се към SPF ръководството`;
l.DMARC.Results.DKIMGuide       = `Върни се към DKIM ръководството`;
l.DMARC.Results.OwnDNS          = `Ако имате собствен DNS сървър, моля, създайте TXT записи за DMARC политиката по-долу.`;
l.DMARC.Results.ThirdParty      = `"Ако използвате външен DNS доставчик, тогава моля следвайте техните инструкции за създаването на TXT запис. В повечето случаи всичко, от което ще се нуждаят е името на хоста (hostname),  _dmarc.<domainname> и стойността, което е информацията намираща се между кавичките."`;
l.DMARC.Results.Reference       = `Моля консултирайте се със `;
l.DMARC.Results.ResourcesPage   = `страницата с ресурси`;
l.DMARC.Results.Information     = `за информация относно как се създават TXT записи. `;
l.DMARC.Results.NotUnderstood   = `"Ако сте се опитали да следвате тези инструкции, но не сте разбрали стъпките, които трябва да предприемете или използваната терминология, препоръчително е да се свържете с вашата организация за IT поддръжка, домейн регистратор или GCA Info "`;
l.DMARC.Results.Assistance      = `за допълнителна помощ.`;
l.DMARC.Results.Complete        = `завършвам`;
l.DMARC.Results.Implemented     = `"Щом DMARC механизмът е имплементиран, моля използвайте DMARC контрольора по-долу:"`;
l.DMARC.Results.YourDomain      = `вашиятдомейн.com`;
l.DMARC.Results.NotQuite        = `Все още не сме приключили`;
l.DMARC.Results.Wisdom          = `Всичко е наред! Мъдростта е крайната цел на всяко действие. Моля не се колебайте да се възползвате от нашите ресурси  по-горе или да ни пишете и ние може да Ви помогнем с внедряването. Затова сме тук. `;
l.DMARC.Results.Check           = `Провери`;
l.DMARC.Results.Congratulations = `Поздравления`;

export default l;
