
interface IProps {
  onClick: Function,
  text: string,
  className?: string | null,
}

export const WizardButton = (props: IProps) => {
  return (
    <button
      style={{ cursor: 'pointer' }}
      className={`avia-button avia-icon_select-no avia-color-secondary avia-size-small avia-position-center ${props.className}`}
      onClick={() => props.onClick()}
    >
      {props.text}
    </button>
  )
}
