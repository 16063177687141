let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `Panduan DMARC`;

l.Header.Main.SetupDMARC        = `TETAPKAN DMARC`;
l.Header.Main.HowItWorks        = `BAGAIMANA IA BERFUNGSI`;
l.Header.Main.AboutDMARC        = `TENTANG DMARC`;
l.Header.Main.Resources         = `SUMBER`;
l.Header.Main.Contact           = `HUBUNGAN`;

l.Footer.Main.SetupDMARC        = `Tetapkan DMARC`;
l.Footer.Main.HowItWorks        = `Bagaimana ia berfungsi`;
l.Footer.Main.AboutDMARC        = `Tentang DMARC`;
l.Footer.Main.Resources         = `Sumber`;
l.Footer.Main.Contact           = `Hubungan`;
l.Footer.Main.Copyright         = `Hak Cipta ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Dasar Privasi`;
l.Footer.Main.Sitemap           = `Peta Laman`;

l.Home.Main.EnterDomain         = `Masukkan domain anda`;
l.Home.Main.ToStart             = `untuk memulakan penetapan DMARC`;
l.Home.Main.TagLine             = `Lakukan Sesuatu. Ukur Ia.`;
l.Home.Main.Welcome             = `Selamat Datang Ke Panduan Penetapan DMARC. Tujuan panduan penetapan ini ialah untuk membimbing organisasi anda melalui proses mencipta dasar DMARC, serta dasar untuk Rangka Kerja Dasar Penghantar (SPF) dan Mel Yang Dikenal Pasti Kunci Domain (DKIM). Dengan melaksanakan kesemua tiga dasar, organisasi anda akan mendapat mekanisme pengesahan e-mel yang lebih kukuh, tersedia untuk melindungi jenama.`;
l.Home.Main.FirstStep           = `Langkah pertama ialah mengesahkan sama ada organisasi anda menggunakan mana-mana daripada tiga dasar ini.`;
l.Home.Main.InputWarning        = `Sila masukkan domain daripada alamat e-mel organisasi anda, betul-betul sama seperti yang tertulis selepas simbol @ dalam alamat e-mel.`;
l.Home.Main.InputExample        = `Sebagai contoh, dalam alamat e-mel berikut, info@globalcyberalliance.org, domain yang diinputkan ke dalam kotak teks ialah globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `Alamat laman web diperlukan`;
l.Home.Main.valid               = `Alamat laman web tidak sah`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Pengguna Google Apps dan Office 365:`;
l.Home.Main.UserWarningText1    = `Sila ikuti maklumat yang disediakan dalam Halaman Sumber untuk`;
l.Home.Main.UserWarningLink     = `Penyedia Perkhidmatan Awan`;
l.Home.Main.UserWarningText2    = `untuk melaksanakan SPF dan DKIM. Sebaik sahaja dilengkapkan, sila gunakan panduan ini untuk meneruskan dengan pelaksanaan dasar DMARC.`;
l.Home.Main.Submit              = `Serah`;

l.ToolSelect.Main.Title         = `Keputusan untuk`;
l.ToolSelect.Main.ResultStatus  = `Jadual di bawah menunjukkan protokol yang telah <i class='fas fa-check-circle' style='color: green'></i> atau belum <i class='fas fa-times-circle' style='color: red'></i> dilaksanakan.`;
l.ToolSelect.Main.Protocol      = `Sila pilih satu daripada protokol untuk memulakan panduan penetapan.`;
l.ToolSelect.Main.ShareButton   = `Sekiranya anda ingin mengongsikan keputusan sila klik pada butang Kongsi, pautan akan disalinkan ke papan klip.`;
l.ToolSelect.Main.Selecting     = `Memilih protokol juga akan menunjukkan rekod semasa sekiranya tersedia.`;
l.ToolSelect.Main.DKIMWarning   = `Bahagian menyemak DKIM masih dalam pembangunan. Walau bagaimanapun, Panduan Penetapan DKIM masih tersedia dengan memilih barisan DKIM.`;
l.ToolSelect.Main.NoDKIM        = `Kami tidak dapat mencari rekod DKIM berkaitan domain anda setelah senarai pemilih lalai diberi.`;
l.ToolSelect.Main.NoDKIM1       = `Sekiranya anda tahu pemilih tersebut, anda boleh mengimbas untuk rekod DKIM di sini:`;
l.ToolSelect.Main.NoDKIM2       = `Atau sekiranya anda menyediakan alamat e-mel, kami akan hantar e-mel kepada akaun di mana anda boleh membalasnya dan kami akan mengimbas e-mel itu untuk rekod DKIM.`;
l.ToolSelect.Main.NoDKIMError   = `E-mel tidak sah atau tidak dimiliki oleh domain yang disediakan.`;
l.ToolSelect.Main.SelectorMsg   = `Mengimbas pemilih!`;
l.ToolSelect.Main.SelectorError = `Pemilih tidak ditemui.`;
l.ToolSelect.Main.EmailMsg      = `E-mel telah dihantar! Sila balas e-mel. Sebaik sahaja balasan dihantar, halaman ini akan dimuatkan semula dan nama pemilih akan disediakan.`;
l.ToolSelect.Main.InputSelector = `Pemilih`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Serah`;
l.ToolSelect.Main.Select        = `Pilih`;
l.ToolSelect.Main.Next          = `Seterusnya`;
l.ToolSelect.Main.Share         = `Kongsi`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Anda telah melengkapkan penetapan DMARC`;
l.Complete.Main.check           = `Semak domain lain untuk SPF, DKIM, atau DMARC?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `Alamat laman web diperlukan`;
l.Complete.Main.valid           = `Alamat laman web tidak sah`;
l.Complete.Main.Next            = `Seterusnya`;
l.Complete.Main.More            = `Lagi tentang DMARC`;

l.SPF.Home.Title                = `Apakah itu SPF?`;
l.SPF.Home.SPF                  = `SPF bermaksud Rangka Kerja Dasar Penghantar. Dasar ini bertanggungjawab untuk mencegah alamat penghantar daripada diolok-olokkan dengan menafsirkan pelayan mel mana yang dibenarkan untuk menghantar mel bagi pihak domain organisasi. Sekiranya pelayan mel tidak ditafsirkan dalam SPF maka mesej akan ditolak atau dinafikan.`;
l.SPF.Home.Start                = `Sila klik pada butang seterusnya untuk memulakan proses bagaimana hendak mencipta Dasar SPF`;
l.SPF.Home.Next                 = `Seterusnya`;

l.SPF.Authorization.Title       = `Adakah pelayan yang disenaraikan di bawah disahkan untuk menghantar e-mel untuk domain organisasi?`;
l.SPF.Authorization.First       = `Bahagian pertama dasar ialah untuk menakrifkan pelayan mel masuk (MX) daripada domain yang juga diberi kebenaran untuk menghantar mel untuk domain itu.`;
l.SPF.Authorization.Current     = `Senarai di bawah mengandungi rekod DNS semasa untuk pelayan mel yang berkaitan dengan organisasi anda.`;
l.SPF.Authorization.Recommended = `Adalah amat disyorkan agar Ya dipilih kerana senarai di atas sepatutnya ialah pelayan yang diberi kebenaran untuk domain anda. Selepas ia dilakukan, tag mx akan ditambah kepada dasar SPF.`;
l.SPF.Authorization.Yes         = `Ya (Disyorkan)`;
l.SPF.Authorization.No          = `Tidak`;
l.SPF.Authorization.Missing     = `Sekiranya terdapat sistem yang tiada dalam senarai atau bukan pelayan mel standard, maka sertakan sistem-sistem tersebut dalam pilihan seterusnya.`;
l.SPF.Authorization.Next        = `Seterusnya`;

l.SPF.Servers.Title             = `Adakah terdapat pelayan tambahan dalam domain yang mungkin menghantar atau menyampaikan mel untuk domain ini?`;
l.SPF.Servers.Possible          = `Ada kemungkinan bahawa tidak semua pelayan mel organisasi ditakrifkan dalam rekod DNS organisasi. Ini mungkin ialah pelayan ujian atau aplikasi dengan sistem mel sedia terbina. Sekiranya begitu, maka sila tambah domain pelayan tambahan atau alamat IP dalam petak di bawah.`;
l.SPF.Servers.Others            = `Ini hendaklah digunakan hanya sekiranya terdapat sistem dalaman lain, selain daripada pelayan mel, yang diberi kebenaran untuk menghantar mel untuk domain."`;
l.SPF.Servers.Added             = `Sekiranya sistem ditambah, maka ini akan menambah tag 'a' kepada dasar SPF.`;
l.SPF.Servers.InputField        = `Nama Pelayan atau IP`;
l.SPF.Servers.InputInvalid      = `Mestilah Nama Domain atau alamat IP yang layak sepenuhnya`;
l.SPF.Servers.Next              = `Seterusnya`;

l.SPF.Domains.Title             = `Adakah terdapat mana-mana domain luaran yang mungkin menghantar atau menyampaikan mel kepada organisasi anda?`;
l.SPF.Domains.Include           = `Seksyen ini akan menambah`;
l.SPF.Domains.Include2          = `(translation for this is appropriately inserted in the string below)`;
l.SPF.Domains.Include3          = `tag yang termasuk dalam dasar SPF.`;
l.SPF.Domains.Trusted           = `Ini hendaklah digunakan hanya sekiranya domain luaran diberi kepercayaan untuk menghantar mesej mel untuk domain organisasi anda. Sebagai contoh, Sistem Pengurusan Perhubungan Pelanggan, Penyedia Mel Awan (Google, Office 365, Yahoo!), atau Penyedia Keselamatan Awan.`;
l.SPF.Domains.Note              = `Harap maklum: Sekiranya anda menggunakan Penyedia Perkhidmatan Awan, anda mestilah bekerjasama dengan mereka untuk nilai sesuai untuk tetapan ini.`;
l.SPF.Domains.InputField        = `Nama Domain atau IP`;
l.SPF.Domains.InputInvalid      = `Mestilah Nama Domain atau alamat IP yang layak sepenuhnya`;
l.SPF.Domains.Next              = `Seterusnya`;

l.SPF.Strict.Title              = `Setakat manakah ketatnya pelayan ini patut melayani e-mel?`;
l.SPF.Strict.Receiving          = `Ini ialah bahagian terakhir dasar SPF yang akan menakrifkan secara wajarnya sejauh mana ketatnya pelayan yang menerima apabila melayani mel.`;
l.SPF.Strict.SoftFail           = `Kegagalan Lemah (Disyorkan)`;
l.SPF.Strict.Fail               = `Gagal`;
l.SPF.Strict.Neutral            = `Neutral`;
l.SPF.Strict.AllowAll           = `Benarkan Semua`;
l.SPF.Strict.Next               = `Seterusnya`;
l.SPF.Strict.Options            = `Pilihan:`;
l.SPF.Strict.FailEx             = `Gagal atau`;
l.SPF.Strict.FailEx2            = `bermakna hanya pelayan mel domain (dan yang berada dalam a dan bahagian yang termasuk) dibenarkan untuk menghantar mel untuk domain. Semua yang lain adalah dilarang.`;
l.SPF.Strict.SoftEx             = `Kegagalan Lemah atau`;
l.SPF.Strict.SoftEx2            = `bermakna hanya pelayan mel domain (dan yang berada dalam a dan bahagian yang termasuk) dibenarkan untuk menghantar mel untuk domain, tetapi ia di dalam transisi. Semua yang lain adalah dilarang.`;
l.SPF.Strict.NeutralEx          = `Neutral atau`;
l.SPF.Strict.NeutralEx2         = `bermakna secara khususnya tidak ada apa-apa yang boleh dikatakan mengenai kesahihan.`;
l.SPF.Strict.AllowEx            = `Benarkan Semua atau`;
l.SPF.Strict.AllowEx2           = `bermakna bahawa mana-mana hos boleh menghantar mel untuk domain.`;
l.SPF.Strict.Warning1           = `Ini hendaklah`;
l.SPF.Strict.Warning2           = `jangan sekali-kali`;
l.SPF.Strict.Warning3           = `digunakan`;
l.SPF.Strict.Recommended        = `Adalah disyorkan agar Kegagalan Lemah digunakan`;
l.SPF.Strict.MarkMail           = `Ini akan menandakan mel sebagai tidak mematuhi sekiranya ia tidak memenuhi kriteria yang ditakrifkan.`;

l.SPF.Results.Title             = `Di bawah ini ialah rekod TXT SPF yang mestilah dicipta pada pelayan DNS anda.`;
l.SPF.Results.Copy              = `Salin`;
l.SPF.Results.Copied            = `DISALIN!`;
l.SPF.Results.DMARCGuide        = `Langkau ke Panduan DMARC`;
l.SPF.Results.DKIMGuide         = `Teruskan ke Panduan DKIM`;
l.SPF.Results.OwnDNS            = `Sekiranya anda mempunyai pelayan DNS anda sendiri, maka sila cipta rekod TXT untuk dasar SPF di atas.`;
l.SPF.Results.ThirdParty        = `Sekiranya anda menggunakan penyedia DNS pihak ketiga, maka sila ikuti arahan mereka untuk mencipta rekod TXT.`;
l.SPF.Results.Reference         = `Sila rujuk kepada`;
l.SPF.Results.ResourcesPage     = `halaman Sumber`;
l.SPF.Results.Information       = `untuk maklumat bagaimana hendak mencipta rekod TXT.`;
l.SPF.Results.NotUnderstood     = `Sekiranya anda telah cuba untuk mengikuti ini tetapi masih tidak memahami langkah yang perlu diambil atau terminologi yang digunakan, adalah disyorkan agar anda menghubungi organisasi sokongan IT, pendaftar domain atau Maklumat GCA anda`;
l.SPF.Results.Assistance        = `untuk bantuan lanjut.`;
l.SPF.Results.Implemented       = `Sebaik sahaja rekod SPF telah dilaksanakan, sila gunakan pengesahan/penyemak SPF di bawah:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Semak`;
l.SPF.Results.SuccessDKIM       = `PERGI KE DKIM`;
l.SPF.Results.SuccessDMARC      = `PERGI KE DMARC`;
l.SPF.Results.NotQuite          = `Masih belum siap lagi.`;
l.SPF.Results.Wisdom            = `Tidak mengapa! Kewajaran ialah matlamat mutakhir setiap tindakan. Jangan ragu-ragu untuk menggunakan sumber-sumber kami, di atas, atau hubungi kami dan kami akan membantu anda melaksanakannya. Kami di sini sedia membantu.`;

l.DKIM.Home.Title               = `Apakah itu DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM membawa maksud Mel Yang Dikenal Pasti Oleh Kunci Domain. DKIM ialah mekanisme yang direka bentuk dengan tujuan untuk mengesahkan identiti domain yang berkaitan dengan mesej mel. Ini dilaksanakan dengan menggunakan pengesahan yang menggunakan kriptografi tidak simetri. Secara umumnya, DKIM akan mengesahkan mesej mel dengan menambah tandatangan digital kepada pengepala mesej. Amat penting untuk mengetahui bahawa DKIM tidak menapis mana-mana mesej mel. Ia akan membenarkan penyaring SPAM untuk menentukan kesahihan mesej mel yang dihantar.`;
l.DKIM.Home.ClickNext           = `Sila klik pada butang Seterusnya untuk memulakan proses bagaimana hendak mencipta dasar DKIM.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Seterusnya`;

l.DKIM.Selector.Title           = `Pemilih`;
l.DKIM.Selector.Name            = `Pemilih akan menakrifkan nama rekod DKIM. Nama pemilih boleh apa-apa sahaja, tetap di bawah ialah beberapa pilihan untuk dipilih daripadanya.`;
l.DKIM.Selector.Define          = `Ini akan menakrifkan nama rekod TXT yang akan dicipta untuk kekunci DKIM. Ini akan tertera seperti berikut:`;
l.DKIM.Selector.Example1        = `pemilih`;
l.DKIM.Selector.Example2        = `nama domain`;
l.DKIM.Selector.Next            = `Seterusnya`;

l.DKIM.Key.Title                = `Kunci Awam`;
l.DKIM.Key.Tag                  = `Ini akan menambah tag 'p', yang mana ialah rentetan kunci dalam fail kunci awam yang dijanakan sebelumnya.`;
l.DKIM.Key.Keys                 = `Letakkan kunci awam di sini`;
l.DKIM.Key.Next                 = `Seterusnya`;
l.DKIM.Key.Creating             = `Mencipta kunci DKIM`;
l.DKIM.Key.TwoParts             = `DKIM mempunyai dua bahagian, kunci persendirian dan kunci awam. Mencipta kunci untuk DKIM berbeza-beza bergantung kepada siapa yang menyediakan perkhidmatan mel kepada organisasi anda.`;
l.DKIM.Key.External             = `Sekiranya anda menggunakan perkhidmatan luaran untuk kedua-dua mel dan DNS, maka penyedia mel perlu menyediakan kunci awam kepada anda. Kunci awam ini kemudiannya diterbitkan sebagai rekod TXT dalam DNS. Kebanyakan penyedia DNS mengehadkan saiz rekod TXT kepada 255 aksara, jadi anda mungkin terpaksa bekerjasama dengan penyedia untuk meningkatkan saiz atau mencipta rekod TXT. Kunci persendirian dipegang oleh penyedia mel dan lazimnya tidak disediakan kepada organisasi.`;
l.DKIM.Key.Internal             = `Sekiranya organisasi mempunyai pelayan e-mel mereka sendiri, maka kunci-kunci DKIM mestilah dijanakan oleh staf IT (atau salah seorang yang bertanggungjawab dalam pelaksanaan DKIM). Terdapat berbilang penjana kunci DKIM tersedia dalam Internet. Sekiranya anda memilih untuk menggunakan satu daripada yang ini, sila pastikan bahawa anda mempunyai pilihan untuk memadam kunci selepas ia dijanakan dan disalinkan kepada fail di pihak anda.`;
l.DKIM.Key.OpenDKIM             = `Untuk mencipta kunci tanpa pihak ketiga, sebuah projek sumber terbuka yang dipanggil opendkim`;
l.DKIM.Key.OpenDKIM2            = `tersedia. Opendkim mengandungi pelbagai alat untuk membantu mencipta kunci DKIM.`;
l.DKIM.Key.OpenSSL              = `Satu lagi pilihan lain ialah untuk menggunakan OpenSSL untuk menjanakan kunci DKIM. Ini boleh dilakukan dengan menggunakan versi OpenSSL yang paling terkini dan menaip perintah berikut untuk kunci persendirian:`;
l.DKIM.Key.OpenSSL2             = `Ini akan menjanakan fail kunci persendirian yang dipanggil`;
l.DKIM.Key.OpenSSL3             = `Seterusnya, taipkan perintah berikut untuk menjanakan kunci awam:`;
l.DKIM.Key.OpenSSL4             = `Perintah ini akan menjanakan kunci awam`;
l.DKIM.Key.OpenSSL5             = `berdasarkan kunci persendirian yang telah diciptakan sebelumnya`;
l.DKIM.Key.OpenSSL6             = `Alihkan Kunci Persendirian DKIM ke lokasi yang ditentukan oleh pemasangan DKIM. Pastikan ia berada dalam folder dengan akses yang terhad.`;

l.DKIM.Results.Title            = `Di bawah ini ialah rekod TXT DKIM yang mestilah dicipta dalam pelayan DKIM anda.`;
l.DKIM.Results.Copy             = `Salin`;
l.DKIM.Results.Copied           = `DISALIN!`;
l.DKIM.Results.SPFGuide         = `Kembali ke Panduan SPF`;
l.DKIM.Results.DMARCGuide       = `Teruskan ke Panduan DMARC`;
l.DKIM.Results.OwnDNS           = `Sekiranya anda mempunyai pelayan DNS anda sendiri, maka sila cipta rekod TXT untuk dasar DKIM di atas.`;
l.DKIM.Results.ThirdParty       = `Sekiranya anda menggunakan penyedia DNS pihak ketiga, maka sila ikuti arahan mereka untuk mencipta rekod YXY.`;
l.DKIM.Results.Reference        = `Sila rujuk kepada`;
l.DKIM.Results.ResourcesPage    = `halaman Sumber`;
l.DKIM.Results.Information      = `untuk maklumat bagaimana hendak mencipta rekod TXT.`;
l.DKIM.Results.NotUnderstood    = `Sekiranya anda telah cuba untuk mengikuti ini tetapi masih tidak memahami langkah yang perlu diambil atau terminologi yang digunakan, adalah disyorkan agar anda menghubungi organisasi sokongan IT, pendaftar domain atau Maklumat GCA anda`;
l.DKIM.Results.Assistance       = `untuk bantuan lanjut.`;
l.DKIM.Results.Note             = `Harap maklum:`;
l.DKIM.Results.Support          = `Kunci DKIM kini boleh menyokong kunci 2048 bit. Walau bagaimanapun, rekod TXT DNS tidak boleh menyokong nilai lebih daripada 255 aksara. Anda boleh melangkaui ini dengan cara sama ada:`;
l.DKIM.Results.Support2         = `Menggunakan kunci 1024 bit atau,`;
l.DKIM.Results.Support3         = `Mencipta rekod TXT berbilang barisan.`;
l.DKIM.Results.Support4         = `Mana-mana contoh rekod berbilang garisan di bawah (tanda pembuka dan penutup kata ditambah untuk mengasingkan rentetan kepada berbilang barisan):`;

l.DMARC.Home.Title              = `Apakah itu DMARC?`;
l.DMARC.Home.Final              = `Dasar SPF dan DKIM telah pun dicipta dan dilaksanakan akhirnya. Kita boleh beralih kepada langkah terakhir untuk mencipta dasar DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (Pengesahan, Pelaporan & Pematuhan Mesej Berasaskan Domain) ialah protokol pengesahan e-mel, yang termasuk fungsi pelaporan yang membenarkan penghantar dan penerima untuk menambah baik dan memantau perlindungan domain daripada e-mel penipuan. Pelaksanaan DMARC akan mencegah spammer daripada mengolok-olokkan alamat "Daripada" dalam mesej mel. Bergantung kepada tetapan dasar DMARC, mana-mana mesej mel yang berasal daripada pelayan mel yang tidak diberi kebenaran sama ada akan dikuarantinkan atau ditolak. Semua mesej spam dan pemancingan data menggunakan nama domain organisasi akan dikuarantinkan atau dipadamkan sebelum ia sampai ke destinasinya (pengguna akhir). Laporan yang dijanakan boleh digunakan untuk tujuan kecerdasan atau mungkin untuk penguatkuasaan undang-undang, sekiranya aktiviti bersifat jenayah.`;
l.DMARC.Home.Stronger           = `DMARC akan membawakan SPF dan DKIM bersama-sama untuk menjanakan pengesahan e-mel yang lebih kuat untuk domain organisasi anda.`;
l.DMARC.Home.ClickNext          = `Sila klik pada butang Seterusnya untuk memulakan proses bagaimana hendak mencipta dasar  DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Seterusnya`;

l.DMARC.Policy.Title            = `Apakah yang seharusnya menjadi tahap dasar untuk DMARC?`;
l.DMARC.Policy.Levels           = `Terdapat tiga tahap dasar yang boleh ditetapkan untuk DMARC, iaitu:`;
l.DMARC.Policy.NoneEx           = `Tiada - Melaporkan mesej mel mencurigakan, tetapi semua mel dihantar ke peti masuk`;
l.DMARC.Policy.QuarantineEx     = `Kuarantin - Gagal dasar SPF/DKIM, mesej kemudiannya dihantar ke folder SPAM/Sarap`;
l.DMARC.Policy.RejectEx         = `Tolak - Gagal dasar SPF/DKIM, mesej kemudiannya digugurkan dan tidak dihantar langsung`;
l.DMARC.Policy.None             = `Tiada (Disyorkan)`;
l.DMARC.Policy.Quarantine       = `Kuarantin`;
l.DMARC.Policy.Reject           = `Tolak`;
l.DMARC.Policy.Next             = `Seterusnya`;
l.DMARC.Policy.Recommended1     = `Adalah`;
l.DMARC.Policy.Recommended2     = `sangat disyorkan`;
l.DMARC.Policy.Recommended3     = `untuk bermula dengan Tiada, kerana ini akan membenarkan masa untuk memantau laporan yang dijanakan dan menentukan sama ada mel sahih akan disekat sebelum bertukar kepada dasar DMARC untuk Kuarantin atau Tolak.`;
l.DMARC.Policy.Tag              = `Ini akan menambah`;
l.DMARC.Policy.Tag2             = `tag kepada rekod DMARC.`;

l.DMARC.Aggregates.Title        = `Ke manakah laporan agregat seharusnya dihantar?`;
l.DMARC.Aggregates.Opt          = `Ini ialah pilihan, tetapi amat disyorkan, dan akan menambah `;
l.DMARC.Aggregates.Opt2         = `tag rua kepada dasar. Ia`;
l.DMARC.Aggregates.Opt3         = `amat disyorkan`;
l.DMARC.Aggregates.Opt4         = `bahawa ini ditambah kepada dasar untuk tujuan analisis dan pemantauan. Laporan boleh dihantar kepada berbilang alamat.`;
l.DMARC.Aggregates.InputField   = `Alamat E-mel`;
l.DMARC.Aggregates.InputInvalid = `Sila masukkan alamat e-mel yang sah`;
l.DMARC.Aggregates.Next         = `Seterusnya`;
l.DMARC.Aggregates.Reports      = `Laporan agregat (format XML) akan dijanakan, mengandungi maklumat tentang mesej mel mana yang lulus/gagal dalam SPF dan DKIM. Ini akan menyediakan kebolehnampakan terhadap kemungkinan isu-isu pengesahan dan/atau aktiviti spam untuk organisasi anda.`;
l.DMARC.Aggregates.Contains     = `Laporan mengandungi yang berikut`;
l.DMARC.Aggregates.HowToRead    = `Bagaimana Hendak Membaca Laporan DMARC Pertama Anda`;
l.DMARC.Aggregates.By           = `(Bahagian 1) oleh Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `Maklumat ISP`;
l.DMARC.Aggregates.Desc         = `Perihalan baris demi baris rekod DMARC organisasi anda`;
l.DMARC.Aggregates.Summary      = `Ringkasan keputusan pengesahan`;
l.DMARC.Aggregates.Important    = `Anda penting untuk tahu bahawa laporan ini tidak akan datang dari satu sumber sahaja. Anda berkemungkinan akan menerima 10 hingga 100 (mungkin beribu-ribu) laporan setiap hari (bilangan laporan yang dijanakan bergantung kepada jumlah e-mel yang dihantar).`;
l.DMARC.Aggregates.Difficult    = `Ini akan menjadikannya sukar untuk mengkaji semua setiap laporan secara manual, terutamanya kerana ia dalam format XML dan sesetengah laporan mungkin berjela-jela panjangnya.`;
l.DMARC.Aggregates.Readable     = `Laporan ini boleh dibuat agar boleh dibaca oleh manusia dengan membangunkan penukar XML atau dengan bekerjasama dengan vendor komersial. Kebanyakan vendor komersial akan menyediakan depositori bahagian belakang yang akan menjadikan laporan boleh dibaca oleh manusia serta menyediakan pelbagai tahap analisis dan panduan.`;
l.DMARC.Aggregates.Vendors      = `Vendor-vendor DMARC (Rakan Niaga GCA):`;

l.DMARC.Forensics.Title         = `Ke manakah laporan forensik sepatutnya dihantar?`;
l.DMARC.Forensics.Opt           = `Ini ialah pilihan dan akan menambah`;
l.DMARC.Forensics.Opt2          = `tag ruf kepada dasar. Laporan XML ini hanya akan dijanakan sekiranya pihak penerima menjanakan laporan jenis ini. Ada kemungkinan bahawa anda mungkin tidak menerima laporan forensik. Ini disebabkan dasar privasi dan pengawalaturan perkongsian data antara negara-negara.`;
l.DMARC.Forensics.InputField    = `Alamat E-mel`;
l.DMARC.Forensics.InputInvalid  = `Sila masukkan alamat e-mel yang sah`;
l.DMARC.Forensics.Next          = `Seterusnya`;

l.DMARC.Subdomains.Title        = `Dasar DMARC untuk subdomain?`;
l.DMARC.Subdomains.Define       = `Ini akan menakrifkan tahap dasar untuk subdomain domain organisasi anda. Sekiranya tag ini`;
l.DMARC.Subdomains.Define2      = `tidak wujud, maka dasar akan mengikuti dasar untuk domain tahap teratas, yang telah ditakrifkan dalam langkah pertama.`;
l.DMARC.Subdomains.None         = `Tiada`;
l.DMARC.Subdomains.Quarantine   = `Kuarantin`;
l.DMARC.Subdomains.Reject       = `Tolak`;
l.DMARC.Subdomains.Next         = `Seterusnya`;
l.DMARC.Subdomains.Policy       = `Dasar untuk domain. Pilihan ialah:`;
l.DMARC.Subdomains.NoneEx       = `Tiada - tiada tindakan yang diambil, tetapi direkodkan dalam laporan DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `Kuarantin - mesej mel ditandakan sebagai spam.`;
l.DMARC.Subdomains.RejectEx     = `Tolak - mesej dipadamkan.`;
l.DMARC.Subdomains.Recommended  = `Adalah disyorkan untuk ini ditetapkan sebagai Tiada sekiranya anda mempunyai berbilang subdomain. Yang terbaik adalah untuk mengasingkan dasar-dasar DMARC untuk setiap subdomain sehingga semua mematuhi dasar DMARC. Walau bagaimanapun, sekiranya tiada subdomain yang wujud, maka adalah disyorkan untuk menggunakan Tolak.`;

l.DMARC.Options.Title           = `(Pilihan) Baki pilihan yang ada tidak diperlukan tetapi boleh dilaraskan sekiranya diperlukan:`;
l.DMARC.Options.ReportGen       = `1. Bilakah laporan ralat sepatutnya dijanakan`;
l.DMARC.Options.Default         = `Lalai`;
l.DMARC.Options.ReportPol       = `Menakrifkan dasar pelaporan ralat.`;
l.DMARC.Options.DefaultsTo      = `Sekiranya tidak ditakrifkan, lalai ke`;
l.DMARC.Options.AllChecks       = `Janakan laporan kepada MTA yang menghantar sekiranya mana-mana semakan tersedia gagal`;
l.DMARC.Options.AnyChecks       = `Janakan laporan kepada MTA yang menghantar sekiranya mana-mana semakan tersedia gagal`;
l.DMARC.Options.OtherValues     = `Nilai-nilai lain ialah:`;
l.DMARC.Options.DKIMFails       = `Janakan laporan sekiranya semakan DKIM gagal`;
l.DMARC.Options.SPFFails        = `Janakan laporan sekiranya semakan SPF gagal`;
l.DMARC.Options.Relaxed         = `Dilonggarkan`;
l.DMARC.Options.Strict          = `Ketat`;
l.DMARC.Options.DKIMAlign       = `2. Mod jarajan untuk DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Mod jajaran untuk DKIM. Pilihan ialah:`;
l.DMARC.Options.DKIMRelaxed     = `(dilonggarkan) lalai sekiranya tidak ditakrifkan. Benarkan mana-mana subdomain ditakrifkan dalam pengepala DKIM.`;
l.DMARC.Options.DKIMStrict      = `(ketat) nama domain penghantar mestilah betul-betul sama dengan domain dalam pengepala DKIM.`;
l.DMARC.Options.SPFAlign        = `3. Mod jajaran untuk SPF`;
l.DMARC.Options.SPFAlignOpt     = `Mod jajaran untuk SPF. Pilihan ialah:`;
l.DMARC.Options.SPFRelaxed      = `(dilonggarkan) lalai sekiranya tidak ditakrifkan. Benarkan mana-mana subdomain.`;
l.DMARC.Options.SPFStrict       = `(ketat) - nama domain organisasi dalam perintah MEL DARIPADA (dalam SMTP) dan pengepala daripada: (dalam item mel) mestilah betul-betul sama`;
l.DMARC.Options.Percentage      = `4. Peratusan mesej tertakluk kepada dasar DMARC?`;
l.DMARC.Options.Filter          = `% mesej tertakluk kepada penyaringan oleh dasar DMARC.  Boleh menjadi apa-apa nombor dari 1 hingga 100. Lalai ialah 100, iaitu semua mesej.`;
l.DMARC.Options.Formats         = `5. Format laporan yang dijanakan`;
l.DMARC.Options.FormatsEx       = `Menakrifkan format laporan yang dijanakan. Nilai format ialah:`;
l.DMARC.Options.RFC             = `nilai lalai sekiranya tidak ditakrifkan. Format ditakrifkan oleh RFC 5965.`;
l.DMARC.Options.RFC2            = `Format ditakrifkan oleh RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Melaporkan selang masa (saat)`;
l.DMARC.Options.SecondsDefault  = `saat (lalai)`;
l.DMARC.Options.DigitsOnly      = `Sila masukkan angka sahaja`;
l.DMARC.Options.ReportIntEx     = `Menakrifkan selang masa pelaporan dalam saat.  Sekiranya tidak ditakrifkan, lalai ialah 86,400 saat, atau 24 jam. Harap maklum bahawa laporan tidak dijamin akan dihantar oleh MTA yang menerima.  Laporan yang dihantar adalah atas dasar usaha terbaik.`;
l.DMARC.Options.HToS            = `Penukar Jam kepada Saat`;
l.DMARC.Options.Hours           = `24 Jam`;
l.DMARC.Options.To              = `kepada`;
l.DMARC.Options.Seconds         = `86,400 saat`;
l.DMARC.Options.Finish          = `Selesai`;

l.DMARC.Results.Title           = `Di bawah ialah rekod TXT DMARC yang mestilah dicipta dalam pelayan DNS anda.`;
l.DMARC.Results.Display         = `Paparkan Keputusan Rekod TXT DMARC`;
l.DMARC.Results.Copy            = `Salin`;
l.DMARC.Results.Copied          = `DISALIN!`;
l.DMARC.Results.SPFGuide        = `Kembali ke Panduan SPF`;
l.DMARC.Results.DKIMGuide       = `Teruskan ke Panduan DMARC`;
l.DMARC.Results.OwnDNS          = `Sekiranya anda mempunyai pelayan DNS anda sendiri, sila cipta rekod TXT untuk dasar DMARC di bawah.`;
l.DMARC.Results.ThirdParty      = `Sekiranya anda menggunakan penyedia DNS pihak ketiga, maka sila ikuti arahan mereka untuk mencipta rekod TXT. Dalam kebanyakan kes, apa yang mereka perlukan hanyalah nama hos, _dmarc.<domainname> dan nilai teks, iaitu maklumat yang terisi di antara pembuka dan penutup kata.`;
l.DMARC.Results.Reference       = `Sila rujuk kepada`;
l.DMARC.Results.ResourcesPage   = `halaman Sumber`;
l.DMARC.Results.Information     = `untuk maklumat bagaimana hendak mencipta rekod TXT.`;
l.DMARC.Results.NotUnderstood   = `Sekiranya anda telah cuba untuk mengikuti ini tetapi masih tidak memahami langkah yang perlu diambil atau terminologi yang digunakan, adalah disyorkan agar anda menghubungi organisasi sokongan IT, pendaftar domain atau Maklumat GCA anda`;
l.DMARC.Results.Assistance      = `untuk bantuan lanjut.`;
l.DMARC.Results.Complete        = `Lengkap`;
l.DMARC.Results.Implemented     = `Sebaik sahaja rekod DMARC telah dilaksanakan, sila gunakan pengesahan/penyemak DMARC di bawah:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Masih belum siap lagi.`;
l.DMARC.Results.Wisdom          = `Tidak mengapa! Kewajaran ialah matlamat mutakhir setiap tindakan. Jangan ragu-ragu untuk menggunakan sumber-sumber kami, di atas, atau hubungi kami dan kami akan membantu anda melaksanakannya. Kami di sini sedia membantu.`;
l.DMARC.Results.Check           = `Semak`;
l.DMARC.Results.Congratulations = `Tahniah`;

export default l;
