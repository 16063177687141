export const Footer = () => {
  return (
    <div
      className='container_wrap footer-page-content footer_color'
      id='footer-page'
    >
      <div
        id='footer'
        className='avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-0  el_before_av_section  avia-builder-el-first  footer-section  container_wrap fullsize'
        style={{}}
      >
        <div className='container'>
          <main
            role='main'
            itemProp='mainContentOfPage'
            className='template-page content  av-content-full alpha units'
          >
            <div className='post-entry post-entry-type-page post-entry-1776'>
              <div className='entry-content-wrapper clearfix'>
                <div className='flex_column_table  footer--main-row av-equal-height-column-flextable -flextable flex-column-table-11 '>
                  <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-middle first  avia-builder-el-1  el_before_av_one_half  avia-builder-el-first  footer--left-column '>
                    <div className='flex-column-inner'>
                      <section className='avia_codeblock_section footer--logo  avia_code_block_1'>
                        <div className='avia_codeblock' itemProp='text'>
                          <a href='https://www.globalcyberalliance.org/'>
                            <svg
                              className='gca-logo'
                              xmlns='http://www.w3.org/2000/svg'
                              width='222.134'
                              height='74.755'
                              viewBox='0 0 222.134 74.755'
                            >
                              <g
                                id='Group_1098'
                                data-name='Group 1098'
                                transform='translate(-180.911 -231.255)'
                              >
                                <path
                                  id='Path_833'
                                  data-name='Path 833'
                                  className='cls-1'
                                  d='M342.426,260.334a8.2,8.2,0,0,0,2.75,1.55,10.536,10.536,0,0,0,3.65.6,13.521,13.521,0,0,0,2.6-.25,16.593,16.593,0,0,0,2.05-.65,7.442,7.442,0,0,0,1.55-.8c.45-.25.8-.5,1.1-.7.05,0,.05-.05.05-.1v-5.451a.159.159,0,0,0-.15-.15h-6.6a.159.159,0,0,0-.15.15v2.25a.159.159,0,0,0,.15.15h4v1.8a14.491,14.491,0,0,1-2.2.9,8.29,8.29,0,0,1-2.45.35,8.69,8.69,0,0,1-2.65-.4,5.36,5.36,0,0,1-1.9-1.05,4.647,4.647,0,0,1-1.15-1.5,4.03,4.03,0,0,1,0-3.5,4.642,4.642,0,0,1,1.15-1.5,5.179,5.179,0,0,1,1.95-1.05,8.77,8.77,0,0,1,2.7-.4,7.7,7.7,0,0,1,2.8.5,15.106,15.106,0,0,1,2.55,1.25h.05c.05,0,.1,0,.1-.05l1.4-1.8c0-.05.05-.05,0-.1,0-.05,0-.05-.05-.1q-.3-.225-.9-.6a11.858,11.858,0,0,0-1.4-.75,8.661,8.661,0,0,0-2-.6,13.518,13.518,0,0,0-2.6-.25,10.934,10.934,0,0,0-3.75.6,8.931,8.931,0,0,0-2.8,1.6,7.034,7.034,0,0,0-1.75,2.3,6.259,6.259,0,0,0-.6,2.7,6.481,6.481,0,0,0,.6,2.75,10.6,10.6,0,0,0,1.9,2.3'
                                  transform='translate(-79.637 -8.428)'
                                />
                                <path
                                  id='Path_834'
                                  data-name='Path 834'
                                  className='cls-1'
                                  d='M394.35,262.336v-2.25a.16.16,0,0,0-.15-.15h-8.651v-10.9a.159.159,0,0,0-.15-.15h-2.451a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15H394.2q.15-.075.15-.15'
                                  transform='translate(-101.109 -8.829)'
                                />
                                <path
                                  id='Path_835'
                                  data-name='Path 835'
                                  className='cls-1'
                                  d='M429.35,252.483a7.053,7.053,0,0,0-1.75-2.3,8.852,8.852,0,0,0-2.8-1.55,10.964,10.964,0,0,0-3.75-.55,12.332,12.332,0,0,0-3.75.55,8.849,8.849,0,0,0-2.8,1.55,6.6,6.6,0,0,0-2.35,5.1,6.7,6.7,0,0,0,.6,2.8,6.562,6.562,0,0,0,1.8,2.3,8.862,8.862,0,0,0,2.8,1.55,10.961,10.961,0,0,0,3.75.55,12.333,12.333,0,0,0,3.75-.55,8.862,8.862,0,0,0,2.8-1.55,6.6,6.6,0,0,0,2.35-5.1,6.761,6.761,0,0,0-.65-2.8m-2.55,4.551a5.674,5.674,0,0,1-1.2,1.5,6.078,6.078,0,0,1-1.95,1.05,8.438,8.438,0,0,1-2.65.4,8.677,8.677,0,0,1-2.65-.4,5.593,5.593,0,0,1-1.95-1.05,4.2,4.2,0,0,1-1.2-1.5,3.662,3.662,0,0,1-.4-1.8,4.262,4.262,0,0,1,.4-1.75,5.666,5.666,0,0,1,1.2-1.5,6.079,6.079,0,0,1,1.95-1.05,8.441,8.441,0,0,1,2.65-.4,8.677,8.677,0,0,1,2.65.4,5.594,5.594,0,0,1,1.95,1.05,4.191,4.191,0,0,1,1.2,1.5,3.6,3.6,0,0,1,.4,1.75,3.188,3.188,0,0,1-.4,1.8'
                                  transform='translate(-115.808 -8.428)'
                                />
                                <path
                                  id='Path_836'
                                  data-name='Path 836'
                                  className='cls-1'
                                  d='M469.578,262.186a4.469,4.469,0,0,0,1.75-.9,3.653,3.653,0,0,0,1-1.35,4.669,4.669,0,0,0,.3-1.7,3.289,3.289,0,0,0-.2-1.25,3.758,3.758,0,0,0-.4-.9,3.655,3.655,0,0,0-.5-.65l-.4-.4a4.5,4.5,0,0,0,.25-.4c.1-.15.2-.35.3-.5a2.455,2.455,0,0,0,.2-.65,4.87,4.87,0,0,0,.05-.9,3.17,3.17,0,0,0-.55-1.9,3.92,3.92,0,0,0-1.3-1.1,5.949,5.949,0,0,0-1.6-.5,8.1,8.1,0,0,0-1.5-.1h-9.1a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15h9.1a16.768,16.768,0,0,0,2.6-.4m-9.151-10.751h6.55a4.764,4.764,0,0,1,1.65.25,1.027,1.027,0,0,1,.55,1.05,1,1,0,0,1-.6,1,3.808,3.808,0,0,1-1.6.3h-6.55Zm0,5.151h6.65a8.884,8.884,0,0,1,1.2.05,2.373,2.373,0,0,1,.9.25,1.083,1.083,0,0,1,.5.55,2.711,2.711,0,0,1,.2,1,1.277,1.277,0,0,1-.75,1.25,5.066,5.066,0,0,1-2.15.35h-6.55Z'
                                  transform='translate(-138.634 -8.879)'
                                />
                                <path
                                  id='Path_837'
                                  data-name='Path 837'
                                  className='cls-1'
                                  d='M508.989,262.386c0,.05.05.05.1.05h2.9c.05,0,.1,0,.1-.05v-.15l-7.851-13.3c0-.05-.05-.05-.1-.05h-2.45c-.05,0-.1,0-.1.05l-7.8,13.3v.15c0,.05.05.05.1.05h2.8c.05,0,.1,0,.1-.05l1.75-3.051h8.651Zm-9-5.55,2.9-5,2.9,5Z'
                                  transform='translate(-156.694 -8.829)'
                                />
                                <path
                                  id='Path_838'
                                  data-name='Path 838'
                                  className='cls-1'
                                  d='M549.715,262.336v-2.25a.16.16,0,0,0-.15-.15h-8.651v-10.9a.159.159,0,0,0-.15-.15h-2.451a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15h11.251q.15-.075.15-.15'
                                  transform='translate(-178.918 -8.829)'
                                />
                                <path
                                  id='Path_839'
                                  data-name='Path 839'
                                  className='cls-1'
                                  d='M394.494,288.953h-3.1c-.05,0-.05,0-.1.05l-4.3,5.05-4.3-5.05c0-.05-.05-.05-.1-.05h-3.1a.174.174,0,0,0-.1.05v.15l6.3,7.3v5.95a.16.16,0,0,0,.15.15h2.45a.16.16,0,0,0,.15-.15v-5.95l6.3-7.3a.094.094,0,0,0,0-.15,1.016,1.016,0,0,1-.25-.05'
                                  transform='translate(-99.403 -28.896)'
                                />
                                <path
                                  id='Path_840'
                                  data-name='Path 840'
                                  className='cls-1'
                                  d='M431.358,296.153a3.651,3.651,0,0,0-.5-.65l-.4-.4a4.627,4.627,0,0,0,.25-.4c.1-.15.2-.35.3-.5a2.5,2.5,0,0,0,.2-.65,4.864,4.864,0,0,0,.05-.9,3.172,3.172,0,0,0-.55-1.9,3.918,3.918,0,0,0-1.3-1.1,5.959,5.959,0,0,0-1.6-.5,8.1,8.1,0,0,0-1.5-.1h-9.1a.16.16,0,0,0-.15.15v13.3a.16.16,0,0,0,.15.15h9.1a7.981,7.981,0,0,0,2.6-.35,4.456,4.456,0,0,0,1.75-.9,3.663,3.663,0,0,0,1-1.35,4.669,4.669,0,0,0,.3-1.7,4.764,4.764,0,0,0-.15-1.25,5.693,5.693,0,0,0-.45-.95m-11.551-4.65h6.55a4.778,4.778,0,0,1,1.65.25,1.027,1.027,0,0,1,.55,1.05,1,1,0,0,1-.6,1,3.8,3.8,0,0,1-1.6.3h-6.55v-2.6Zm8.7,8.2a5.066,5.066,0,0,1-2.15.35h-6.55v-3.4h6.65a8.873,8.873,0,0,1,1.2.05,1.9,1.9,0,0,1,.85.25,1.082,1.082,0,0,1,.5.55,2.723,2.723,0,0,1,.2,1,1.109,1.109,0,0,1-.7,1.2'
                                  transform='translate(-118.266 -28.946)'
                                />
                                <path
                                  id='Path_841'
                                  data-name='Path 841'
                                  className='cls-1'
                                  d='M468.574,299.954h-10.4v-3.2h5.5a.159.159,0,0,0,.15-.15v-2.25a.159.159,0,0,0-.15-.15h-5.5v-2.8h10.1a.159.159,0,0,0,.15-.15V289a.159.159,0,0,0-.15-.15h-12.7a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15h12.951a.159.159,0,0,0,.15-.15v-2.25a.107.107,0,0,0-.1-.1'
                                  transform='translate(-137.48 -28.846)'
                                />
                                <path
                                  id='Path_842'
                                  data-name='Path 842'
                                  className='cls-1'
                                  d='M506.385,302.3l-4.05-5.351a5.577,5.577,0,0,0,1.15-.3,6.306,6.306,0,0,0,1.3-.65,3.274,3.274,0,0,0,.95-1.2,4.277,4.277,0,0,0,.4-1.9,3.686,3.686,0,0,0-.45-1.9,3.591,3.591,0,0,0-1.25-1.2,5.851,5.851,0,0,0-1.8-.65,9.345,9.345,0,0,0-2.1-.2h-8.9a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15h2.45a.159.159,0,0,0,.15-.15v-5.35h4.851l4.1,5.4c0,.05.05.05.1.05h3.051a.174.174,0,0,0,.1-.05c0-.05-.05-.1-.05-.15m-12.251-7.751v-3.1h6.45a9.557,9.557,0,0,1,1.25.05,1.934,1.934,0,0,1,.85.2.9.9,0,0,1,.5.45,1.7,1.7,0,0,1,.2.8,1.317,1.317,0,0,1-.7,1.25,4.391,4.391,0,0,1-1.95.35Z'
                                  transform='translate(-155.54 -28.896)'
                                />
                                <path
                                  id='Path_843'
                                  data-name='Path 843'
                                  className='cls-1'
                                  d='M355.527,298.152c0-.05-.05-.05-.1-.05h-.1c-.2.1-.45.3-.8.5a10.735,10.735,0,0,1-1.3.6,12.819,12.819,0,0,1-1.8.55,10.677,10.677,0,0,1-2.25.25,8.975,8.975,0,0,1-2.8-.4,6.343,6.343,0,0,1-2-1.05,4.135,4.135,0,0,1-1.55-3.1,4.547,4.547,0,0,1,.4-1.85,4.428,4.428,0,0,1,1.2-1.5,5.354,5.354,0,0,1,1.9-1.05,8.026,8.026,0,0,1,2.55-.4,7.535,7.535,0,0,1,1.95.2,12.026,12.026,0,0,1,1.6.5c.45.2.9.45,1.35.65l1.2.75h.05c.05,0,.1,0,.1-.05l1.3-1.9a.124.124,0,0,0-.05-.2c-.3-.2-.65-.45-1.1-.75a11.616,11.616,0,0,0-1.6-.85,15.537,15.537,0,0,0-2.15-.7,11.474,11.474,0,0,0-2.75-.25,12.412,12.412,0,0,0-3.4.5,9.893,9.893,0,0,0-2.8,1.45,6.85,6.85,0,0,0-1.85,2.3,6.368,6.368,0,0,0-.7,2.9,6.533,6.533,0,0,0,.65,2.95,7.007,7.007,0,0,0,1.9,2.3,9.856,9.856,0,0,0,2.9,1.45,12.163,12.163,0,0,0,3.651.5,16.521,16.521,0,0,0,2.9-.25c.85-.2,1.6-.4,2.2-.6a8.53,8.53,0,0,0,1.5-.7,2.706,2.706,0,0,1,.8-.45c.05-.05.1-.1.05-.15Z'
                                  transform='translate(-79.687 -28.444)'
                                />
                                <path
                                  id='Path_844'
                                  data-name='Path 844'
                                  className='cls-1'
                                  d='M350.677,327.168c0-.05-.05-.05-.1-.05h-2.451c-.05,0-.1,0-.1.05l-7.8,13.3v.15c0,.05.05.05.1.05h2.8c.05,0,.1,0,.1-.05l1.75-3.05h8.651l1.8,3.05c0,.05.05.05.1.05h2.9c.05,0,.1,0,.1-.05v-.15Zm-4.251,7.9,2.9-5,2.9,5Z'
                                  transform='translate(-79.788 -48.01)'
                                />
                                <path
                                  id='Path_845'
                                  data-name='Path 845'
                                  className='cls-1'
                                  d='M396,338.169h-8.651v-10.9a.159.159,0,0,0-.15-.15h-2.45a.159.159,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15H396a.159.159,0,0,0,.15-.15v-2.25q-.075-.15-.15-.15'
                                  transform='translate(-102.012 -48.01)'
                                />
                                <path
                                  id='Path_846'
                                  data-name='Path 846'
                                  className='cls-1'
                                  d='M428.358,338.169h-8.651v-10.9a.159.159,0,0,0-.15-.15h-2.45a.16.16,0,0,0-.15.15v13.3a.159.159,0,0,0,.15.15h11.251a.159.159,0,0,0,.15-.15v-2.25a.132.132,0,0,0-.15-.15'
                                  transform='translate(-118.216 -48.01)'
                                />
                                <path
                                  id='Path_847'
                                  data-name='Path 847'
                                  className='cls-1'
                                  d='M450.71,327.118h-2.45a.159.159,0,0,0-.15.15v13.3a.16.16,0,0,0,.15.15h2.45a.16.16,0,0,0,.15-.15v-13.3a.133.133,0,0,0-.15-.15'
                                  transform='translate(-133.818 -48.01)'
                                />
                                <path
                                  id='Path_848'
                                  data-name='Path 848'
                                  className='cls-1'
                                  d='M471.834,327.168c0-.05-.05-.05-.1-.05h-2.5c-.05,0-.1,0-.1.05l-7.8,13.3v.15c0,.05.05.05.1.05h2.8c.05,0,.1,0,.1-.05l1.75-3.05h8.65l1.8,3.05c0,.05.05.05.1.05h2.9c.05,0,.1,0,.1-.05v-.15Zm-4.3,7.9,2.9-5,2.9,5Z'
                                  transform='translate(-140.44 -48.01)'
                                />
                                <path
                                  id='Path_849'
                                  data-name='Path 849'
                                  className='cls-1'
                                  d='M519.759,327.118h-2.45a.16.16,0,0,0-.15.15v9.551l-8.75-9.651c0-.05-.05-.05-.1-.05h-2.45a.16.16,0,0,0-.15.15v13.3a.16.16,0,0,0,.15.15h2.45a.16.16,0,0,0,.15-.15v-9.551l8.8,9.651c0,.05.05.05.1.05h2.45a.159.159,0,0,0,.15-.15v-13.3c-.05-.1-.1-.15-.2-.15'
                                  transform='translate(-162.664 -48.01)'
                                />
                                <path
                                  id='Path_850'
                                  data-name='Path 850'
                                  className='cls-1'
                                  d='M558.173,336.368c0-.05-.05-.05-.1-.05h-.1c-.2.1-.45.3-.8.5a10.84,10.84,0,0,1-1.3.6,11.092,11.092,0,0,1-1.8.55,10.669,10.669,0,0,1-2.25.25,8.975,8.975,0,0,1-2.8-.4,5.847,5.847,0,0,1-2-1.05,4.137,4.137,0,0,1-1.55-3.1,4.547,4.547,0,0,1,.4-1.85,4.428,4.428,0,0,1,1.2-1.5,5.358,5.358,0,0,1,1.9-1.05,8.026,8.026,0,0,1,2.55-.4,7.554,7.554,0,0,1,1.95.2,12.074,12.074,0,0,1,1.6.5c.45.2.9.45,1.35.65l1.2.75h.05c.05,0,.1,0,.1-.05l1.3-1.9c.05-.05,0-.15-.05-.2-.3-.2-.65-.45-1.1-.75a11.637,11.637,0,0,0-1.6-.85,10.916,10.916,0,0,0-2.15-.65,11.449,11.449,0,0,0-2.75-.25,12.407,12.407,0,0,0-3.4.5,9.9,9.9,0,0,0-2.8,1.45,6.856,6.856,0,0,0-1.85,2.3,6.363,6.363,0,0,0-.7,2.9,6.533,6.533,0,0,0,.65,2.95,7,7,0,0,0,1.9,2.3,9.271,9.271,0,0,0,2.9,1.45,12.163,12.163,0,0,0,3.651.5,16.524,16.524,0,0,0,2.9-.25c.85-.2,1.6-.4,2.2-.6a8.567,8.567,0,0,0,1.5-.7,3.518,3.518,0,0,1,.8-.45c.05-.05.1-.1.05-.15Z'
                                  transform='translate(-181.176 -47.609)'
                                />
                                <path
                                  id='Path_851'
                                  data-name='Path 851'
                                  className='cls-1'
                                  d='M597.894,338.169h-10.4v-3.2h5.5a.16.16,0,0,0,.15-.15v-2.25a.16.16,0,0,0-.15-.15h-5.5v-2.75h10.1a.16.16,0,0,0,.15-.15v-2.25a.16.16,0,0,0-.15-.15h-12.7a.16.16,0,0,0-.15.15v13.3a.16.16,0,0,0,.15.15h12.951a.159.159,0,0,0,.15-.15v-2.25c.05-.1-.05-.15-.1-.15'
                                  transform='translate(-202.246 -48.01)'
                                />
                                <path
                                  id='Path_852'
                                  data-name='Path 852'
                                  className='cls-2'
                                  d='M227.079,376.5a2.25,2.25,0,1,1,0-4.5h17.551a2.25,2.25,0,1,1,0,4.5Z'
                                  transform='translate(-21.995 -70.485)'
                                />
                                <path
                                  id='Path_853'
                                  data-name='Path 853'
                                  className='cls-3'
                                  d='M222.586,297.288a24.2,24.2,0,1,1,0-48.4,2.25,2.25,0,1,1,0,4.5,19.651,19.651,0,1,0,19.651,19.651,2.25,2.25,0,1,1,4.5,0,24.157,24.157,0,0,1-24.151,24.252'
                                  transform='translate(-8.751 -8.829)'
                                />
                                <path
                                  id='Path_854'
                                  data-name='Path 854'
                                  className='cls-2'
                                  d='M263.241,302.2a2.315,2.315,0,0,1-1.6-.65,2.191,2.191,0,0,1-.65-1.6,2.313,2.313,0,0,1,.65-1.6,10.833,10.833,0,0,0,2.5-11.4l3.6-3.1a15.621,15.621,0,0,1-2.9,17.751,2.518,2.518,0,0,1-1.6.6'
                                  transform='translate(-40.105 -26.337)'
                                />
                                <path
                                  id='Path_855'
                                  data-name='Path 855'
                                  className='cls-2'
                                  d='M213.835,297.21a32.9,32.9,0,0,1-32.9-31.7,33.01,33.01,0,0,1,7.9-22.7l.2-.25-3.05-3.05a2.189,2.189,0,0,1-.65-1.6,2.311,2.311,0,0,1,.65-1.6,2.187,2.187,0,0,1,1.6-.65,2.313,2.313,0,0,1,1.6.65l3.05,3.05.2-.2a32.925,32.925,0,0,1,21.451-7.9,2.25,2.25,0,1,1,0,4.5,28.094,28.094,0,0,0-20.1,8.35,28.44,28.44,0,1,0,42,1.95l-.2-.25-29.452,29.352a2.189,2.189,0,0,1-1.6.65,2.311,2.311,0,0,1-1.6-.65,15.418,15.418,0,0,1,19.851-23.452l.2.15,10.9-10.9a2.189,2.189,0,0,1,1.6-.65,2.311,2.311,0,0,1,1.6.65,32.947,32.947,0,0,1,1.75,44.7l-.2.25,3.05,3.05a2.187,2.187,0,0,1,.65,1.6,2.315,2.315,0,0,1-.65,1.6,2.189,2.189,0,0,1-1.6.65,2.315,2.315,0,0,1-1.6-.65l-3.05-3.051-.25.2a32.666,32.666,0,0,1-21.352,7.9m0-43.8a10.889,10.889,0,0,0-9.351,16.451l.2.35,15.051-15.051-.35-.2a10.344,10.344,0,0,0-5.55-1.55'
                                />
                                <g
                                  id='Group_1097'
                                  data-name='Group 1097'
                                  transform='translate(398.191 290.182)'
                                >
                                  <path
                                    id='Path_856'
                                    data-name='Path 856'
                                    className='cls-1'
                                    d='M618.217,349.3v.3h-.846v2.212h-.344V349.6h-.846v-.3Z'
                                    transform='translate(-616.182 -349.302)'
                                  />
                                  <path
                                    id='Path_857'
                                    data-name='Path 857'
                                    className='cls-1'
                                    d='M621.078,349.3h.488l.722,2.123L623,349.3h.484v2.51h-.324V350.33c0-.051,0-.136,0-.254s0-.246,0-.381l-.717,2.118h-.337l-.723-2.118v.077q0,.092.005.282c0,.126.005.218.005.277v1.482h-.325Z'
                                    transform='translate(-618.634 -349.302)'
                                  />
                                </g>
                              </g>
                            </svg>
                          </a>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='av-flex-placeholder' />
                  <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-middle   avia-builder-el-3  el_after_av_one_half  avia-builder-el-last  footer--right-column '>
                    <div className='flex-column-inner'>
                      <div className='ep-custom-menu-element ep-custom-menu-style-vertical   avia-builder-el-4  avia-builder-el-no-sibling  footer--nav'>
                        <ul
                          id='menu-gca-cybersecurity-toolkit-footer'
                          className='menu'
                        >
                          <li
                            id='menu-item-1227'
                            className='menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-1'
                          >
                            <a
                              target='_blank'
                              href='https://www.globalcyberalliance.org/donate/'
                              itemProp='url'
                              rel='noopener noreferrer'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  Invest in Us
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id='menu-item-6480'
                            className='menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-2'
                          >
                            <a
                              target='_blank'
                              href='https://community.globalcyberalliance.org/'
                              itemProp='url'
                              rel='noopener noreferrer'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  Community Forum
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id='menu-item-12394'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-top-level menu-item-top-level-3'
                          >
                            <a
                              href='https://gcatoolkit.org/about-the-gca-cybersecurity-toolkits/'
                              itemProp='url'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  About
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id='menu-item-8551'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-top-level menu-item-top-level-4'
                          >
                            <a
                              href='https://gcatoolkit.org/application-for-inclusion-in-the-gca-cybersecurity-toolkit/'
                              itemProp='url'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  Submit a Tool
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id='menu-item-19468'
                            className='menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-5'
                          >
                            <a
                              target='_blank'
                              href='https://www.globalcyberalliance.org/privacy-policy/'
                              itemProp='url'
                              rel='noopener noreferrer'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  Privacy Policy &amp; <br />
                                  Notice to Users
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id='menu-item-19469'
                            className='menu-item menu-item-type-custom menu-item-object-custom menu-item-top-level menu-item-top-level-6'
                          >
                            <a
                              target='_blank'
                              href='https://www.globalcyberalliance.org/terms-of-service/'
                              itemProp='url'
                              rel='noopener noreferrer'
                            >
                              <span className='avia-bullet' />
                              <span className='avia-menu-text'>
                                <span className='avia-menu-text-inner'>
                                  Terms of Service
                                </span>
                              </span>
                              <span className='avia-menu-fx'>
                                <span className='avia-arrow-wrap'>
                                  <span className='avia-arrow' />
                                </span>
                              </span>
                            </a>
                          </li>
                        </ul>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div
        id='av_section_7'
        className='avia-section main_color avia-section-default avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-5  el_after_av_section  avia-builder-el-last  footer--socket-section  container_wrap fullsize'
        style={{}}
      >
        <div className='container'>
          <div className='template-page content  av-content-full alpha units'>
            <div className='post-entry post-entry-type-page post-entry-1776'>
              <div className='entry-content-wrapper clearfix'>
                <div className='flex_column_table  footer--socket-row av-equal-height-column-flextable -flextable flex-column-table-12 '>
                  <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-top first  avia-builder-el-6  el_before_av_one_half  avia-builder-el-first  footer--socket-copyright '>
                    <div className='flex-column-inner'>
                      <section className='av_textblock_section '>
                        <div className='avia_textblock     ' itemProp='text'>
                          <p>
                            Copyright @ 2023 Global Cyber Alliance
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='av-flex-placeholder' />
                  <div className='flex_column av_one_half  flex_column_table_cell av-equal-height-column av-align-top   avia-builder-el-8  el_after_av_one_half  avia-builder-el-last  footer--socket-logos '>
                    <div className='flex-column-inner'>
                      <div
                        className='avia-image-container  av-styling-      avia-builder-el-9  el_before_av_image  avia-builder-el-first  avia-align-center '
                        itemProp='image'
                      >
                        <div className='avia-image-container-inner'>
                          <div className='avia-image-overlay-wrap'>
                            <a
                              href='https://iasme.co.uk/'
                              className='avia_image'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {' '}
                              <picture>
                                <source srcSet='/images/GCA-Toolkit-Footer-IASME-Consortium-Logo.png 1x, /images/GCA-Toolkit-Footer-IASME-Consortium-Logo@2x.png 2x' />
                                <img
                                  width={119}
                                  height={64}
                                  className='wp-image-12690 avia-img-lazy-loading-not-12690 avia_image'
                                  src='/images/GCA-Toolkit-Footer-IASME-Consortium-Logo.png'
                                  alt=''
                                  title='GCA Toolkit-Footer-IASME Consortium Logo'
                                  itemProp='thumbnailUrl'
                                />
                              </picture>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className='avia-image-container  av-styling-      avia-builder-el-10  el_after_av_image  avia-builder-el-last  avia-align-center '
                        itemProp='image'
                      >
                        <div className='avia-image-container-inner'>
                          <div className='avia-image-overlay-wrap'>
                            <a
                              href='https://www.ncsc.gov.uk/cyberessentials/overview'
                              className='avia_image'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <picture>
                                <img
                                  width={80}
                                  src='/images/GCA-Toolkit-Footer-Cyber-Essentials-logo@2x.png'
                                  alt=''
                                  title='GCA Toolkit-Footer-Cyber Essentials Logo'
                                  itemProp='thumbnailUrl'
                                />
                              </picture>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*close column table wrapper. Autoclose: 1 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
