import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'
import { EmailModel } from '../Models'

export const Step4 = (props: any) => {
  const { t } = useTranslation()
  const {forensicEmails, addForensicEmail, removeForensicEmail} = props
  return (
    <Step>
      <h2>{t('DMARC.Forensics.Title')}</h2>
      <div>
        {t('DMARC.Forensics.Opt')} <strong>'ruf'</strong>{' '}
        {t('DMARC.Forensics.Opt2')}{' '}
      </div>

      <div>
        {forensicEmails.map((el: EmailModel) => (
          <div className='servers' key={el.id}>
            <input
              type='text'
              onChange={(e) => el.setEmail(e.target.value)}
              placeholder={el.email||t('DMARC.Forensics.InputField')}
            />
            <button
              className='add hs-button primary'
              onClick={() =>removeForensicEmail(el)}
            >
              -
            </button>
          </div>
        ))}
        <div className='servers'>
          <div />
          <button
            className='add hs-button primary'
            onClick={() => addForensicEmail()}
          >
            +
          </button>
        </div>
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
