import languages from './translations'

export const DEFAULT_LANGUAGE = 'en'

// the translations
// (tip move them in a JSON file and import them)
export const langObj = Object.keys(languages).reduce((acc, curr) => {
  acc[curr] = {
    translation: languages[curr].data,
  }

  return acc
}, {})
