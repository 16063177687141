import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step2 = (props: any) => {
  const { t } = useTranslation()
  const { handleSetAuthorized, authorized } = props;

  return (
    <Step>
      <h2>{t('SPF.Authorization.Title')}</h2>
      <div>{t('SPF.Authorization.First')}</div>
      <div>{t('SPF.Authorization.Current')}</div>
      <div>{t('SPF.Authorization.Recommended')}</div>

      <div className='servers__select'>
        <select name='' id='' value={authorized.toString()} onChange={(e) => handleSetAuthorized(e.target.value)} >
          <option value={'true'}>{t('SPF.Authorization.Yes')}</option>
          <option value={'false'}>{t('SPF.Authorization.No')}</option>
        </select>
      </div>

      <div style={{ marginTop: '20px' }}>{t('SPF.Authorization.Missing')}</div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
