import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step4 = (props: any) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const { domain, getRecord, handleStartOver} = props

  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('Below is the BIMI TXT record which must be created on your DNS server.')}</h2>
        <div className='results-input'>
        <div className='results-row h'>
          <div>{t('Global.Type')}</div>
          <div>{t('Global.Name')}</div>
          <div>{t('Global.Content')}</div>
        </div>
        <div className='results-row'>
          <div>
            <input type='text' readOnly value={'TXT'} />
          </div>
          <input type='text' readOnly value={domain} />
          <input type='text' disabled value={getRecord()} />
          <div style={{ alignItems: 'center' }}>
            <button
              className={`hs-button ${copied ? 'copied' : 'copy'}`}
              onClick={() => {
                setCopied(true)
                navigator.clipboard.writeText(getRecord())
                setTimeout(() => {
                  setCopied(false)
                }, 2000)
              }}
            >
              {copied ? t('COPIED!') : t('Copy')}
            </button>
          </div>
        </div>
      </div>

      <div>If you have your own DNS server, then please create TXT records for the above BIMI policy.</div>
      <div>If you are using a third party DNS provider, then please follow their instructions for creating a TXT record.</div>
      <div>
      If you have tried to follow this but have not understood the steps to take or the terminology used, it is recommended that you contact your IT support organization.
      </div>
      <div className='buttons'>
        <WizardButton
          text={'Previous'}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={'Start Over'}
          onClick={() =>{
            handleStartOver()
            props.firstStep()}}
        />
      </div>
    </Step>
  )
}