let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `Инструкция по настройке DMARC`;

l.Header.Main.SetupDMARC        = `НАСТРОИТЬ DMARC`;
l.Header.Main.HowItWorks        = `КАК ЭТО РАБОТАЕТ`;
l.Header.Main.AboutDMARC        = `Что такое DMARC`;
l.Header.Main.Resources         = `РЕСУРСЫ`;
l.Header.Main.Contact           = `Как с нами связаться`;

l.Footer.Main.SetupDMARC        = `Настроить DMARC`;
l.Footer.Main.HowItWorks        = `Как это работает`;
l.Footer.Main.AboutDMARC        = `Что такое DMARC`;
l.Footer.Main.Resources         = `Ресурсы`;
l.Footer.Main.Contact           = `Как с нами связаться`;
l.Footer.Main.Copyright         = `© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Защита персональной информации`;
l.Footer.Main.Sitemap           = `Карта сайта`;

l.Home.Main.EnterDomain         = `Введите свой домен`;
l.Home.Main.ToStart             = `Для запуска установки DMARC`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `Добро пожаловать в Руководство по настройке DMARC. Цель этого руководства по настройке - познакомить вашу организацию с процессoм настройки DMARC, а также Policy Policy Framework (SPF) и DomainKeys Identified Mail (DKIM). Внедряя их, ваша организация будет иметь механизм проверки подлинности электронной почты, чтобы защитить ваш бренд.`;
l.Home.Main.FirstStep           = `Первый шаг подтверждает, не использует ли ваша организация одну из трех политик.`;
l.Home.Main.InputWarning        = `Пожалуйста, введите домен с адреса электронной почты вашей организации, точно так же, как он появляется после символа @ в адресе электронной почты.`;
l.Home.Main.InputExample        = `Например, вот адрес электронной почты: domainsecurityscanner@globalcyberalliance.org, домен для ввода в текстовое поле - globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `Требуется адрес веб-сайта`;
l.Home.Main.valid               = `Адрес веб-сайта недействителен`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Пользователи Google Apps и Office 365:`;
l.Home.Main.UserWarningText1    = `Пожалуйста, следуйте инструкциям приведенным на странице ресурсов информации`;
l.Home.Main.UserWarningLink     = `Поставщики облачных услуг`;
l.Home.Main.UserWarningText2    = `для реализации SPF и DKIM. После завершения, пожалуйста, используйте это руководство, чтобы продолжить внедрение DMARC.`;
l.Home.Main.Submit              = `Отправить`;
l.Home.Main.MoreInfo            = `Если вам нужна дополнительная информация о DMARC, мы приглашаем вас посетить один из наших ежемесячных веб-семинаров DMARC. Этот веб-семинар будет посвящен основам DMARC и предоставит краткий обзор Руководства по установке DMARC GCA.`;
l.Home.Main.Webinar             = `Ежемесячный сеанс веб-семинара GCA DMARC проводится дважды в третью среду каждого месяца. Пожалуйста, зарегистрируйтесь на дату и время, которые подходят для вас, нажав соответствующую ссылку ниже:`;
l.Home.Main.SelectDate          = `Выберите эту дату`;

l.ToolSelect.Main.Title         = `Результаты`;
l.ToolSelect.Main.ResultStatus  = `В приведенной ниже таблице указаны, какие протоколы были реализованы <i class='fas fa-check-circle' style='color: green'></i> или не были реализованы <i class='fas fa-times-circle' style='color: red'></i>`;
l.ToolSelect.Main.Protocol      = `Пожалуйста, выберите один из протоколов, чтобы запустить руководство по настройке.`;
l.ToolSelect.Main.ShareButton   = `Если вы хотите поделиться результатами, просто нажмите кнопку Share «Поделиться», ссылка будет скопирована в буфер обмена.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `Часть проверки DKIM все еще находится в разработке. Однако Руководство по установке DKIM по-прежнему доступно через строку DKIM.`;
l.ToolSelect.Main.NoDKIM        = `Мы не смогли найти запись DKIM, связанную с вашим доменом, с учетом списка селекторов по умолчанию.`;
l.ToolSelect.Main.NoDKIM1       = `Если вы знаете селектор, вы можете сканировать запись DKIM здесь:`;
l.ToolSelect.Main.NoDKIM2       = `Или, если вы укажете адрес электронной почты, мы отправим электронное письмо на учетную запись, на которую вы можете ответить, и мы сканируем электронную почту для записи DKIM.`;
l.ToolSelect.Main.NoDKIMError   = `Электронная почта недействительна или не соответствует предоставленному домену.`;
l.ToolSelect.Main.SelectorMsg   = `Сканирование для селектора!`;
l.ToolSelect.Main.SelectorError = `Селектор не найден.`;
l.ToolSelect.Main.EmailMsg      = `Письмо отправлено! Пожалуйста, ответьте на письмо. После отправки ответа эта страница обновится и предоставит имя селектора.`;
l.ToolSelect.Main.InputSelector = `Селектор`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Отправить`;
l.ToolSelect.Main.Select        = `Выбрать`;
l.ToolSelect.Main.Next          = `Следующий`;
l.ToolSelect.Main.Share         = `Поделиться`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Вы завершили настройку DMARC`;
l.Complete.Main.check           = `Проверить другой домен для SPF, DKIM или DMARC?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `Требуется адрес веб-сайта`;
l.Complete.Main.valid           = `Адрес веб-сайта недействителен`;
l.Complete.Main.Next            = `Следующий`;
l.Complete.Main.More            = `Подробнее о DMARC`;

l.SPF.Home.Title                = `Что такое SPF?`;
l.SPF.Home.SPF                  = `SPF означает платформа политики отправителя. Эта политика отвечает за предотвращение подмены адреса отправителя путем определения того, какие почтовые серверы имеют право отправлять почту от имени организации. Если почтовый сервер не определен в SPF, сообщение отклоняется или отскакивается.`;
l.SPF.Home.Start                = `Нажмите кнопку «Следующий», чтобы начать процесс создания политики SPF.`;
l.SPF.Home.Next                 = `Следующий`;

l.SPF.Authorization.Title       = `Разрешены ли перечисленные ниже серверы для отправки электронной почты для домена организации?`;
l.SPF.Authorization.First       = `Первая часть политики - определить входящие почтовые серверы (MXes) домена, которым разрешено также отправлять почту для этого домена.`;
l.SPF.Authorization.Current     = `В приведенном ниже списке содержатся текущие записи DNS для почтовых серверов, связанных с вашей организацией.`;
l.SPF.Authorization.Recommended = `Настоятельно рекомендуется, чтобы «Да» был выбран, поскольку приведенный выше список должен быть авторизованным сервером для вашего домена. Таким образом, тег mx будет добавлен в политику SPF.`;
l.SPF.Authorization.Yes         = `Да (рекомендуется)`;
l.SPF.Authorization.No          = `Нет`;
l.SPF.Authorization.Missing     = `Если в списке отсутствуют системы или не являются стандартными почтовыми серверами, включите эти системы в следующий параметр.`;
l.SPF.Authorization.Next        = `Следующий`;

l.SPF.Servers.Title             = `Есть ли дополнительные серверы в домене, которые могут доставлять или ретранслировать почту для этого домена?`;
l.SPF.Servers.Possible          = `Существует вероятность того, что не все почтовые серверы организации определены в DNS-записи организации. Это могут быть тестовые серверы или приложения со встроенными почтовыми системами. Если это так, добавьте дополнительные домены сервера или IP-адреса в приведенных ниже полях.`;
l.SPF.Servers.Others            = `Это следует использовать, только если есть другие внутренние системы, кроме почтовых серверов, уполномоченных отправлять почту для домена ».`;
l.SPF.Servers.Added             = `Если системы добавлены, то это добавит тег, Äö√Ñ√≤a,Äö√Ñ√ ¥ в политику SPF.`;
l.SPF.Servers.InputField        = `Имя сервера или IP-адрес`;
l.SPF.Servers.InputInvalid      = `Должно быть полное доменное имя или IP-адрес`;
l.SPF.Servers.Next              = `Следующий`;

l.SPF.Domains.Title             = `Существуют ли какие-либо внешние домены, которые могут доставлять или ретранслировать почту для вашей организации?`;
l.SPF.Domains.Include           = `Этот раздел добавит`;
l.SPF.Domains.Include2          = `включают`;
l.SPF.Domains.Include3          = `Тег политики SPF.`;
l.SPF.Domains.Trusted           = `Это следует использовать только в том случае, если для внешнего домена доверено отправлять почтовое сообщение для домена вашей организации. Например, система управления отношениями с клиентами, поставщик облачной почты (Google, Office 365, Yahoo!) или поставщик облачной безопасности.`;
l.SPF.Domains.Note              = `Обратите внимание: если вы используете поставщика облачных услуг, вы должны работать с ними для соответствующего значения для этого параметра.`;
l.SPF.Domains.InputField        = `Доменное имя или IP-адрес`;
l.SPF.Domains.InputInvalid      = `Должно быть полное доменное имя или IP-адрес`;
l.SPF.Domains.Next              = `Следующий`;

l.SPF.Strict.Title              = `Насколько строги должны быть серверы для обработки писем?`;
l.SPF.Strict.Receiving          = `Это последний раздел политики SPF, который определит, насколько строгими должны быть принимающие серверы при обработке электронных писем.`;
l.SPF.Strict.SoftFail           = `Мягкий сбой (рекомендуется)`;
l.SPF.Strict.Fail               = `Отказать`;
l.SPF.Strict.Neutral            = `Нейтральный`;
l.SPF.Strict.AllowAll           = `Позволять все`;
l.SPF.Strict.Next               = `Следующий`;
l.SPF.Strict.Options            = `Опции:`;
l.SPF.Strict.FailEx             = `Сбой или`;
l.SPF.Strict.FailEx2            = `Означает, что только почтовые серверы домена (и те, что входят в разделы a и include) могут отправлять почту для домена.`;
l.SPF.Strict.SoftEx             = `Мягкий сбой или`;
l.SPF.Strict.SoftEx2            = `означает, что только почтовые серверы домена (и те, что входят в разделы a и include) могут отправлять почту для домена, но она находится в процессе перехода.`;
l.SPF.Strict.NeutralEx          = `Нейтральный или`;
l.SPF.Strict.NeutralEx2         = `Означает, что ничего нельзя сказать о действительности.`;
l.SPF.Strict.AllowEx            = `Разрешить все или`;
l.SPF.Strict.AllowEx2           = `Означает, что любой хост может отправлять почту для домена.`;
l.SPF.Strict.Warning1           = `Это должно`;
l.SPF.Strict.Warning2           = `никогда`;
l.SPF.Strict.Warning3           = `использоваться`;
l.SPF.Strict.Recommended        = `Рекомендуется использовать Soft Fail`;
l.SPF.Strict.MarkMail           = `Это будет отмечать почту как несоответствующую, если она не соответствует определенным критериям.`;

l.SPF.Results.Title             = `Ниже приведена запись SPF TXT, которая должна быть создана на вашем DNS-сервере.`;
l.SPF.Results.Copy              = `Копировать`;
l.SPF.Results.Copied            = `СКОПИРОВАНО!`;
l.SPF.Results.DMARCGuide        = `Перейти к руководству DMARC`;
l.SPF.Results.DKIMGuide         = `Продолжить руководство DKIM`;
l.SPF.Results.OwnDNS            = `Если у вас есть собственный DNS-сервер, создайте записи TXT для вышеуказанной политики SPF.`;
l.SPF.Results.ThirdParty        = `Если вы используете сторонний DNS-провайдер, следуйте их инструкциям по созданию записи TXT.`;
l.SPF.Results.Reference         = `Пожалуйста, обратитесь к`;
l.SPF.Results.ResourcesPage     = `Страница ресурсов`;
l.SPF.Results.Information       = `для получения информации о том, как создавать записи TXT.`;
l.SPF.Results.NotUnderstood     = `Если вы попытались следовать этому, но не поняли, какие шаги предпринять или использовать терминологию, рекомендуется связаться с вашей организацией поддержки ИТ, регистратором домена или GCA Info`;
l.SPF.Results.Assistance        = `для дальнейшей помощи.`;
l.SPF.Results.Implemented       = `После того как запись SPF будет реализована, пожалуйста, используйте проверку SPF ниже:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Проверьте`;
l.SPF.Results.SuccessDKIM       = `ПЕРЕДИТЕ К DKIM`;
l.SPF.Results.SuccessDMARC      = `ПЕРЕДИТЕ К DMARC`;
l.SPF.Results.NotQuite          = `Не совсем там.`;
l.SPF.Results.Wisdom            = `Ничего страшного! Мудрость - конечная цель каждого действия. Пожалуйста, не стесняйтесь использовать наши ресурсы выше, или напишите нам, и мы можем помочь вам реализовать. Для этого мы здесь.`;

l.DKIM.Home.Title               = `Что такое DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM означает DomainKeys Identified Mail. DKIM - это механизм, предназначенный для проверки подлинности домена, связанного с почтовым сообщением. Это достигается с помощью аутентификации, использующей асимметричную криптографию. В принципе, DKIM будет аутентифицировать почтовое сообщение, добавив цифровую подпись в заголовок сообщения. Важно отметить, что DKIM не фильтрует почтовые сообщения. Это позволит фильтрам SPAM определять подлинность отправляемого почтового сообщения.`;
l.DKIM.Home.ClickNext           = `Нажмите кнопку «Следующий», чтобы начать процесс создания политики DKIM.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Следующий`;

l.DKIM.Selector.Title           = `Селектор`;
l.DKIM.Selector.Name            = `Селектор определит имя записи DKIM. Имя селектора может быть любым, но ниже представлено несколько вариантов.`;
l.DKIM.Selector.Define          = `Это определит имя записи TXT, которая будет создана для ключа DKIM. Это будет выглядеть следующим образом:`;
l.DKIM.Selector.Example1        = `селектор`;
l.DKIM.Selector.Example2        = `доменное имя`;
l.DKIM.Selector.Next            = `Следующий`;

l.DKIM.Key.Title                = `Открытый ключ`;
l.DKIM.Key.Tag                  = `Это добавит тег 'p', который является ключевой строкой в файле открытого ключа, сгенерированной ранее.`;
l.DKIM.Key.Keys                 = `Разместить открытые ключи здесь`;
l.DKIM.Key.Next                 = `Следующий`;
l.DKIM.Key.Creating             = `Создание ключей DKIM:`;
l.DKIM.Key.TwoParts             = `DKIM состоит из двух частей: закрытого ключа и открытого ключа. Создание ключей для DKIM будет зависеть от того, кто предоставляет почтовые службы вашей организации.`;
l.DKIM.Key.External             = `Если вы используете внешнюю службу как для почты, так и для DNS, поставщик почты должен предоставить вам открытый ключ. Этот открытый ключ затем публикуется как запись TXT в DNS. Большинство провайдеров DNS ограничивают размер записи TXT до 255 символов, поэтому вам может потребоваться работать с поставщиком, чтобы увеличить размер или создать запись TXT. Закрытый ключ хранится почтовым провайдером и обычно не предоставляется организации.`;
l.DKIM.Key.Internal             = `Если организация имеет свой собственный почтовый сервер, то ключи DKIM должны генерироваться ИТ-персоналом (или ответственным за реализацию DKIM). В Интернете есть несколько генераторов ключей DKIM. Если вы решите использовать один из них, просто убедитесь, что у вас есть возможность удалить ключ после его создания и скопировать в файлы на вашем конце.`;
l.DKIM.Key.OpenDKIM             = `Чтобы создать ключи без третьей стороны, проект с открытым исходным кодом под названием opendkim`;
l.DKIM.Key.OpenDKIM2            = `доступен. Opendkim содержит различные инструменты для создания ключа DKIM.`;
l.DKIM.Key.OpenSSL              = `Другой вариант - использовать OpenSSL для генерации ключей DKIM. Это можно сделать, используя самую последнюю версию OpenSSL и набрав следующую команду для закрытого ключа:`;
l.DKIM.Key.OpenSSL2             = `Это приведет к созданию файла закрытого ключа, называемого`;
l.DKIM.Key.OpenSSL3             = `Затем введите следующую команду для создания открытого ключа:`;
l.DKIM.Key.OpenSSL4             = `Эта команда будет генерировать открытый ключ`;
l.DKIM.Key.OpenSSL5             = `основанный на ранее созданном закрытом ключе`;
l.DKIM.Key.OpenSSL6             = `Переместите закрытый ключ DKIM в место, указанное установкой DKIM. Убедитесь, что он находится в папке с ограниченным доступом.`;

l.DKIM.Results.Title            = `Ниже приведена запись DKIM TXT, которая должна быть создана на вашем DNS-сервере.`;
l.DKIM.Results.Copy             = `Копировать`;
l.DKIM.Results.Copied           = `СКОПИРОВАНО!`;
l.DKIM.Results.SPFGuide         = `Вернуться в руководство SPF`;
l.DKIM.Results.DMARCGuide       = `Продолжить руководство DMARC`;
l.DKIM.Results.OwnDNS           = `Если у вас есть собственный DNS-сервер, пожалуйста, создайте записи TXT для вышеуказанной политики DKIM.`;
l.DKIM.Results.ThirdParty       = `Если вы используете сторонний DNS-провайдер, следуйте их инструкциям по созданию записи TXT.`;
l.DKIM.Results.Reference        = `Пожалуйста, обратитесь к`;
l.DKIM.Results.ResourcesPage    = `Страница ресурсов`;
l.DKIM.Results.Information      = `для получения информации о том, как создавать записи TXT.`;
l.DKIM.Results.NotUnderstood    = `Если вы попытались следовать этому, но не поняли, какие шаги предпринять или использовать терминологию, рекомендуется связаться с вашей организацией поддержки ИТ, регистратором домена или GCA Info`;
l.DKIM.Results.Assistance       = `для дальнейшей помощи.`;
l.DKIM.Results.Note             = `Пожалуйста, обратите внимание:`;
l.DKIM.Results.Support          = `Теперь ключи DKIM поддерживают 2048-битные ключи. Однако записи DNS TXT не могут поддерживать значения более 255 символов. Вы можете обходить это:`;
l.DKIM.Results.Support2         = `Использование 1024-битного ключа или,`;
l.DKIM.Results.Support3         = `Создание многострочной записи TXT`;
l.DKIM.Results.Support4         = `Любой пример многострочной записи ниже (кавычки добавляются, чтобы разбить строку на несколько строк):`;

l.DMARC.Home.Title              = `Что такое DMARC?`;
l.DMARC.Home.Final              = `Теперь, когда были созданы и реализованы политики SPF и DKIM. Мы можем перейти на заключительный шаг для создания политики DMARC.`;
l.DMARC.Home.Protocol           = `DMARC (аутентификация сообщений на основе доменов, отчетность и соответствие) - это протокол проверки подлинности электронной почты, который включает функцию отчетности, которая позволяет отправителям и получателям улучшать и контролировать защиту домена от мошеннической электронной почты. Внедрение DMARC не позволит спамерам обманывать адрес «От» по почтовым сообщениям. В зависимости от параметров политики DMARC любые почтовые сообщения, созданные с несанкционированного почтового сервера, будут либо помещены на карантин, либо отклонены. Все сообщения о спаме и фишинге с использованием имени домена организации будут помещены в карантин или удалены до того, как они достигнут конечного пользователя (конечного пользователя). Сгенерированные отчеты могут использоваться для разведки или возможны для правоохранительных целей, если деятельность носит криминальный характер.`;
l.DMARC.Home.Stronger           = `DMARC объединяет SPF и DKIM, чтобы генерировать более надежную аутентификацию по электронной почте для домена вашей организации.`;
l.DMARC.Home.ClickNext          = `Нажмите кнопку «Следующий», чтобы начать процесс создания политики DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Следующий`;

l.DMARC.Policy.Title            = `Каким должен быть уровень политики для DMARC?`;
l.DMARC.Policy.Levels           = `Существует три уровня политики, которые могут быть установлены для DMARC. Это:`;
l.DMARC.Policy.NoneEx           = `Нет. Сообщает о возможных подозрительных почтовых сообщениях, но вся почта отправляется во входящие`;
l.DMARC.Policy.QuarantineEx     = `Quarantine - Fail SPF / DKIM, затем сообщение отправляется в папку SPAM / Junk`;
l.DMARC.Policy.RejectEx         = `Reject - Fail SPF / DKIM, тогда сообщение отбрасывается и вообще не доставляется`;
l.DMARC.Policy.None             = `Нет (рекомендуется)`;
l.DMARC.Policy.Quarantine       = `Карантин`;
l.DMARC.Policy.Reject           = `Отклонить`;
l.DMARC.Policy.Next             = `Следующий`;
l.DMARC.Policy.Recommended1     = `Это`;
l.DMARC.Policy.Recommended2     = `настоятельно рекомендуется`;
l.DMARC.Policy.Recommended3     = `начать с None, поскольку это позволит время отслеживать созданные отчеты и определять, будет ли законная почта заблокирована, прежде чем переключать политику DMARC на Карантин или Отклонить.`;
l.DMARC.Policy.Tag              = `Это добавит`;
l.DMARC.Policy.Tag2             = `тег в запись DMARC.`;

l.DMARC.Aggregates.Title        = `Где следует отправлять совокупные отчеты?`;
l.DMARC.Aggregates.Opt          = `Это необязательно, но настоятельно рекомендуется, и это добавит`;
l.DMARC.Aggregates.Opt2         = `ruf тэг для политики. Это`;
l.DMARC.Aggregates.Opt3         = `настоятельно рекомендуется`;
l.DMARC.Aggregates.Opt4         = `что это будет добавлено в политику для целей анализа и мониторинга. Отчеты можно отправлять по нескольким адресам.`;
l.DMARC.Aggregates.InputField   = `Адрес электронной почты`;
l.DMARC.Aggregates.InputInvalid = `Пожалуйста, введите действительный адрес электронной почты`;
l.DMARC.Aggregates.Next         = `Следующий`;
l.DMARC.Aggregates.Reports      = `Совокупные отчеты (формат XML) будут сгенерированы, содержащие информацию о том, какие почтовые сообщения проходят / терпят неудачу с SPF и DKIM. Это обеспечивает видимость возможных проблем с проверкой и / или спама для вашей организации.`;
l.DMARC.Aggregates.Contains     = `Отчеты содержат следующее`;
l.DMARC.Aggregates.HowToRead    = `Как читать ваши первые отчеты DMARC`;
l.DMARC.Aggregates.By           = `(Часть 1) Эми Горрелл - Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `Информация об ISP`;
l.DMARC.Aggregates.Desc         = `Пошаговое описание записи DMARC вашей организации`;
l.DMARC.Aggregates.Summary      = `Сводка результатов проверки подлинности`;
l.DMARC.Aggregates.Important    = `Важно отметить, что эти отчеты будут получены не только из одного источника. Вы можете ежедневно получать от 10 до 100 (возможно, тысяч) отчетов (количество создаваемых отчетов зависит от количества отправленных писем).`;
l.DMARC.Aggregates.Difficult    = `Это затруднит просмотр каждого отчета вручную, тем более, что он находится в формате XML, и некоторые отчеты могут быть длинными.`;
l.DMARC.Aggregates.Readable     = `Эти отчеты можно сделать читаемыми человеком, разработав конвертер XML или работая с коммерческим поставщиком. Большинство коммерческих поставщиков предоставят бэкэнд-репозиторий, который сделает отчеты доступными для человека, а также предоставит различные уровни анализа и руководства.`;
l.DMARC.Aggregates.Vendors      = `Поставщики DMARC (партнеры GCA):`;

l.DMARC.Forensics.Title         = `Где следует отправлять судебно-компьютерный отчеты?`;
l.DMARC.Forensics.Opt           = `Это необязательно и добавит`;
l.DMARC.Forensics.Opt2          = `ruf тэг для политики. Эти отчеты XML будут генерироваться только в том случае, если принимающая сторона создает эти типы отчетов. Существует вероятность, что вы не можете получать какие-либо судебные отчеты. Это связано с правилами конфиденциальности и обмена данными между странами.`;
l.DMARC.Forensics.InputField    = `Адрес электронной почты`;
l.DMARC.Forensics.InputInvalid  = `Пожалуйста, введите действительный адрес электронной почты`;
l.DMARC.Forensics.Next          = `Следующий`;

l.DMARC.Subdomains.Title        = `политика DMARC для субдоменов?`;
l.DMARC.Subdomains.Define       = `Это определит уровень политики для поддоменов домена вашей организации. Если этот тег`;
l.DMARC.Subdomains.Define2      = `отсутствует, тогда политика будет следовать политике для домена верхнего уровня, который был определен на первом шаге.`;
l.DMARC.Subdomains.None         = `Никто`;
l.DMARC.Subdomains.Quarantine   = `Карантин`;
l.DMARC.Subdomains.Reject       = `Отклонить`;
l.DMARC.Subdomains.Next         = `Следующий`;
l.DMARC.Subdomains.Policy       = `политика для домена. Возможные варианты:`;
l.DMARC.Subdomains.NoneEx       = `Нет - никаких действий не предпринято, но записано в отчете DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `Карантин - почтовое сообщение помечено как спам.`;
l.DMARC.Subdomains.RejectEx     = `Отклонение - сообщение удаляется.`;
l.DMARC.Subdomains.Recommended  = `Рекомендуется установить значение «Нет», если у вас несколько поддоменов. Лучше всего настроить отдельные политики DMARC для каждого поддомена, пока все поддомены не будут соответствовать политике DMARC. Однако, если не существует субдоменов, рекомендуется использовать «Отклонить».`;

l.DMARC.Options.Title           = `(Необязательно) Остальные параметры не требуются, но при необходимости могут быть скорректированы:`;
l.DMARC.Options.ReportGen       = `1. Когда должен генерироваться отчет об ошибке`;
l.DMARC.Options.Default         = `По умолчанию`;
l.DMARC.Options.ReportPol       = `Определяет политику отчетов об ошибках.`;
l.DMARC.Options.DefaultsTo      = `Если не определено, значения по умолчанию`;
l.DMARC.Options.AllChecks       = `Сгенерировать отчет для отправляющего MTA, если какие-либо основные проверки не пройдены`;
l.DMARC.Options.AnyChecks       = `Сгенерировать отчет для отправляющего MTA, если какие-либо основные проверки не пройдены`;
l.DMARC.Options.OtherValues     = `Другие значения:`;
l.DMARC.Options.DKIMFails       = `Создать отчет, если проверка DKIM не выполняется`;
l.DMARC.Options.SPFFails        = `Создать отчет, если проверка SPF не выполняется`;
l.DMARC.Options.Relaxed         = `Расслабленный`;
l.DMARC.Options.Strict          = `Строгий`;
l.DMARC.Options.DKIMAlign       = `2. Режим выравнивания для DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Режим выравнивания для DKIM. Возможные варианты:`;
l.DMARC.Options.DKIMRelaxed     = `(расслабленный) по умолчанию, если не определено. Позволяет выбрать любой субдомен, определенный в заголовке DKIM.`;
l.DMARC.Options.DKIMStrict      = `(строгий) доменное имя отправителя должно точно соответствовать домену в заголовке DKIM.`;
l.DMARC.Options.SPFAlign        = `3. Режим выравнивания для SPF`;
l.DMARC.Options.SPFAlignOpt     = `Режим выравнивания для SPF. Возможные варианты:`;
l.DMARC.Options.SPFRelaxed      = `(расслабленный) - по умолчанию, если не определено. Позволяет использовать любой поддомен.`;
l.DMARC.Options.SPFStrict       = `(строгий) - имя домена организации в команде MAIL FROM (в SMTP), а заголовок from: (в элементе почты) должен точно соответствовать`;
l.DMARC.Options.Percentage      = `4. Процент темы сообщения по политике DMARC?`;
l.DMARC.Options.Filter          = `% сообщений, подвергнутых фильтрации политикой DMARC. Может быть любое число от 1 до 100. По умолчанию 100, это все сообщения.`;
l.DMARC.Options.Formats         = `5. Формат сформированных отчетов`;
l.DMARC.Options.FormatsEx       = `Определяет формат генерируемых отчетов. Значения формата:`;
l.DMARC.Options.RFC             = `значение по умолчанию, если оно не определено. Формат определяется RFC 5965.`;
l.DMARC.Options.RFC2            = `Формат определяется RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Интервал отчетности (в секундах)`;
l.DMARC.Options.SecondsDefault  = `секунд (по умолчанию)`;
l.DMARC.Options.DigitsOnly      = `Пожалуйста, вводите только цифры`;
l.DMARC.Options.ReportIntEx     = `Определяет интервалы отчетности в секундах. Если не определено, значение по умолчанию составляет 86400 секунд или 24 часа. Обратите внимание, что отчеты не могут быть отправлены при получении MTA. Отчеты отправляются на основе лучших усилий.`;
l.DMARC.Options.HToS            = `Конвертер часов в секундах`;
l.DMARC.Options.Hours           = `преобразователь часов в секунды`;
l.DMARC.Options.To              = `к`;
l.DMARC.Options.Seconds         = `86400 секунд`;
l.DMARC.Options.Finish          = `закончить`;

l.DMARC.Results.Title           = `Ниже приведена запись DMARC TXT, которая должна быть создана на вашем DNS-сервере.`;
l.DMARC.Results.Display         = `Отобразить результат записи DMARC TXT`;
l.DMARC.Results.Copy            = `Копировать`;
l.DMARC.Results.Copied          = `СКОПИРОВАНО!`;
l.DMARC.Results.SPFGuide        = `Вернуться в руководство SPF`;
l.DMARC.Results.DKIMGuide       = `Вернуться в руководство DKIM`;
l.DMARC.Results.OwnDNS          = `Если у вас есть собственный DNS-сервер, создайте TXT-записи для политики DMARC ниже.`;
l.DMARC.Results.ThirdParty      = `Если вы используете сторонний DNS-провайдер, следуйте их инструкциям по созданию записи TXT. В большинстве случаев все, что им потребуется, это имя хоста, _dmarc. <Domainname> и текстовое значение, которое является информацией, найденной между кавычками.`;
l.DMARC.Results.Reference       = `Пожалуйста, обратитесь к`;
l.DMARC.Results.ResourcesPage   = `Страница ресурсов`;
l.DMARC.Results.Information     = `для получения информации о том, как создавать записи TXT.`;
l.DMARC.Results.NotUnderstood   = `Если вы попытались следовать этому, но не поняли, какие шаги предпринять или использовать терминологию, рекомендуется связаться с вашей организацией поддержки ИТ, регистратором домена или GCA Info`;
l.DMARC.Results.Assistance      = `для дальнейшей помощи.`;
l.DMARC.Results.Complete        = `Окончить`;
l.DMARC.Results.Implemented     = `После того, как запись DMARC будет реализована, используйте следующую процедуру проверки DMARC:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Не совсем там.`;
l.DMARC.Results.Wisdom          = `Ничего страшного! Мудрость - конечная цель каждого действия. Пожалуйста, не стесняйтесь использовать наши ресурсы выше, или напишите нам, и мы можем помочь вам реализовать. Для этого мы здесь.`;
l.DMARC.Results.Check           = `Проверьте`;
l.DMARC.Results.Congratulations = `Поздравляeм`;

export default l;
