import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step2 = (props: any) => {
  const { t } = useTranslation()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [svgError, setSvgError] = useState('')
  const [missingLogo, setMissingLogo] = useState(false)

  const { logoPath, handleSetLogoPath, isValid, handleSetIsValid, startVerify, handleSetStartVerify} = props

  const verifySvgDimensions = async () => {
    try {
      const response = await fetch(logoPath);
      const svgContent = await response.text();

      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
      const svgElement = svgDoc.documentElement;

      const pathElements = svgElement.querySelectorAll('path');

      let minX = Infinity;
      let minY = Infinity;
      let maxX = -Infinity;
      let maxY = -Infinity;
    
      pathElements.forEach((path) => {
        const pathLength = path.getTotalLength();
        for (let i = 0; i < pathLength; i += 1) {
          const point = path.getPointAtLength(i);
          minX = Math.min(minX, point.x);
          minY = Math.min(minY, point.y);
          maxX = Math.max(maxX, point.x);
          maxY = Math.max(maxY, point.y);
        }
      });

      const width = Math.round(maxX - minX);
      const height = Math.round(maxY - minY);

      if (!isFinite(width) || !isFinite(height)) {
          setSvgError('Invalid SVG.')
          handleSetIsValid(false);
          handleSetStartVerify(true)
      }
      else{
        const svgIsSquare = Math.abs(width - height) < 1;
        setDimensions({width, height})

        if(!svgIsSquare) {
            setSvgError('Invalid Dimensions: ' + width +'x' + height +'.')
        }
        handleSetIsValid(svgIsSquare);
        handleSetStartVerify(true)
      }
    } catch (error) {
      console.error('Error fetching or parsing SVG:', error);
      handleSetIsValid(false);
      handleSetStartVerify(true);
      setSvgError('Invalid SVG.')
    }
  };

  const handleNextClick = () => {
    if(logoPath.length === 0){
      setMissingLogo(true)
    }
    else {
      setMissingLogo(false)
      props.nextStep()
    }
  }

  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('Where can we find your logo?')}</h2>
        <p>
        Design your organization's logo if you don't already have one. Make sure it's in SVG (Scalable Vector Graphics) format. 
        SVG files are preferred for BIMI as they can be scaled without loss of quality. Ensure that your SVG logo has square dimensions and uses clean and concise lines and shapes.
        Ideally this logo would be 128x128 pixels and would be a simple, recognizable representation of your brand.
        </p>
        <p>
        Make sure that your logo is available via a public URL, either within a directory on your web server or via an image hosting site.
        </p>
       
        <input
            type='text'
            placeholder={t('yourdomain.com/public-directory/your-logo.svg')}
            value={logoPath}
            onChange={(e) => handleSetLogoPath(e.target.value)}
            />
        <div style={{ display: 'flex' }}> 
        <div style={{height:'50px'}}>
            <button  className={`hs-button`} onClick={verifySvgDimensions}> Verify </button>
        </div>
            {startVerify && (
                <div>
                {isValid ? (<p style={{ color: 'green',  marginLeft: '10px', marginTop: '8px' }} >Valid Dimensions: {dimensions.width}x{dimensions.height}</p>) 
                :(<p style={{ color: 'red', marginLeft: '10px',  marginTop: '8px' }} > {svgError} </p>)}
                </div>
            )}
        </div>
        <div>
            <WizardButton
            text={'Previous'}
            onClick={() => props.previousStep()}
            />
            <WizardButton
            text={t('Next')}
            onClick={() => handleNextClick()}
            />
        </div>
        <div>
          {(
            <div>
            {missingLogo ? (<p style={{ color: 'red',  marginLeft: '10px', marginTop: '8px' }} >Please specify an SVG before proceeding.</p>) 
            :<p></p>}
            </div>
          )}
        </div>
        </Step>
  )
}