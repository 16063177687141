import Arabic from './arabic'
import Azerbaijani from './azerbaijani'
import Bulgarian from './bulgarian'
import Cantonese from './cantonese'
import English from './english'
import French from './french'
import German from './german'
import Hebrew from './hebrew'
import Hindi from './hindi'
import Japanese from './japanese'
import Korean from './korean'
import Latvian from './latvian'
import Malay from './malay'
import Mandarin from './mandarin'
import Portuguese from './portuguese'
import Russian from './russian'
import Spanish from './spanish'
import Turkish from './turkish'

const languages = {
  'ar': { name: 'Arabic', right: false, data: Arabic },
  'az': { name: 'Azerbaijani', right: false, data: Azerbaijani },
  'bg': { name: 'Bulgarian', right: false, data: Bulgarian },
  'zh-CN': { name: 'Chinese (Cantonese)', right: false, data: Cantonese },
  'zh-TW': { name: 'Chinese (Mandarin)', right: false, data: Mandarin },
  'en': { name: 'English', flag: 'US', right: false, data: English },
  'es': { name: 'Spanish', flag: 'ES', right: false, data: Spanish },
  'fr': { name: 'French', flag: 'FR', right: false, data: French },
  'de': { name: 'German', flag: 'DE', right: false, data: German },
  'he': { name: 'Hebrew', right: true, data: Hebrew },
  'hi': { name: 'Hindi', right: false, data: Hindi },
  'ja': { name: 'Japanese', right: false, data: Japanese },
  'ko': { name: 'Korean', right: false, data: Korean },
  'lv': { name: 'Latvian', right: false, data: Latvian },
  'ms': { name: 'Malay', right: false, data: Malay },
  'pt': { name: 'Portuguese', flag: 'PT', right: false, data: Portuguese },
  'ru': { name: 'Russian', right: false, data: Russian },
  'tr': { name: 'Turkish', right: false, data: Turkish },
}

export default languages
