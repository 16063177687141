import './App.scss'
import { Footer } from './pages/Footer'
import { Header } from './pages/Header'
import { BIMIGuide } from './pages/BIMI/BIMIGuide'
import { DKIMGuide } from './pages/DKIM/DKIMGuide'
import { DMARCGuide } from './pages/DMARC/DMARCGuide'
import { HomePage } from './pages/Home'
import { SPFGuide } from './pages/SPF/SPFGuide'
import { TLSGuide } from './pages/TLS/TLSGuide'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import i18n from 'i18next'
import { DEFAULT_LANGUAGE, langObj } from './i18n'
import { initReactI18next } from 'react-i18next';
import TagManager from 'react-gtm-module'
import GtmRouteListener from './utils/GtmRouteListener';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-MR93DX9'
  }

  if(typeof(window) !== 'undefined') {
      TagManager.initialize(tagManagerArgs)
  }
  
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: langObj,
        lng: '',
        fallbackLng: DEFAULT_LANGUAGE,
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

    return (
        <BrowserRouter>
          <ScrollToTop>
            <GtmRouteListener>
                <Header/>
                <Routes>
                    <Route path="/" element={<HomePage/>} />
                    <Route path="/bimi" element={<BIMIGuide/>} />
                    <Route path="/dkim" element={<DKIMGuide/>} />
                    <Route path="/dmarc" element={<DMARCGuide/>} />
                    <Route path="/spf" element={<SPFGuide/>} />
                    <Route path="/tls" element={<TLSGuide/>} />
                    <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
                <Footer/>
            </GtmRouteListener>
          </ScrollToTop>
        </BrowserRouter >
    )
}

export default App
