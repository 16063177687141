import { WizardButton } from '../../../Components/WizardButton'
import { useTranslation } from 'react-i18next'

export const Step2 = (props: any) => {
  const { resultError, bulkScanning, handleSetDomains, handleSetDomain, handleResetBulkScanning, handleResetResults } = props
  const { t } = useTranslation()

  if (!resultError) {
    return (
        <div className='loader'>Loading...</div>
    )
  }
  else {
    return (
      <div>
        <h1 style={{ textAlign: 'center', color: '#CD5C5C' }}>
          An error occurred while processing  your { bulkScanning ? "domains" : "domain" }.
        </h1>
        <div className='button'>
        <WizardButton
          text={t('SPF.Home.StartOver')}
          onClick={() => {
          handleSetDomains('')
          handleSetDomain('')
          handleResetBulkScanning()
          handleResetResults()
          props.firstStep()
          }}
        />
        </div>
      </div>
    )
  }
}