import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step5 = (props: any) => {
  const { t } = useTranslation()
  const {subdomainPolicy, handleSetSubdomainPolicy} = props
  return (
    <Step>
      <h2>{t('DMARC.Subdomains.Title')}</h2>
      <div>
        {t('DMARC.Subdomains.Define')} <strong>('sp')</strong>{' '}
        {t('DMARC.Subdomains.Define2')}
      </div>

      <div className='levels__select' style={{ marginTop: '10px' }}>
        <select
          name=''
          id=''
          style={{ cursor: 'pointer' }}
          onChange={(e) => handleSetSubdomainPolicy(e.target.value)}
          value={subdomainPolicy}
        >
          <option value='none'>{t('DMARC.Subdomains.None')}</option>
          <option value='quarantine'>{t('DMARC.Subdomains.Quarantine')}</option>
          <option value='reject'>{t('DMARC.Subdomains.Reject')}</option>
        </select>
      </div>
      <div>
        <p>{t('DMARC.Subdomains.Policy')}</p>
        <ul>
          <li>{t('DMARC.Subdomains.NoneEx')}</li>
          <li>{t('DMARC.Subdomains.QuarantineEx')}</li>
          <li>{t('DMARC.Subdomains.RejectEx')}</li>
        </ul>
        <p>{t('DMARC.Subdomains.Recommended')}</p>
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}