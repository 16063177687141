import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step3 = (props: any) => {
  const { t } = useTranslation()
  const [missingCert, setMissingCert] = useState(false)

  const { certPath, handleSetCertPath} = props

  const handleNextClick = () => {
    if(certPath.length === 0){
      setMissingCert(true)
    }
    else {
      setMissingCert(false)
      props.nextStep()
    }
  }

  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('Where can we find your VMC?')}</h2>
        <p>
        A VMC is a digital certificate issued by an authorized Certificate Authority (CA) to verify the authenticity of your brand's logo.
        It essentially serves as a digital seal of approval, confirming that the logo displayed in your emails is indeed associated with your organization.
        This certificate acts as a critical link between your brand and the BIMI standard, ensuring that only legitimate senders can display their logos in email clients that support BIMI.
        While a VMC is not required to set a BIMI record, many email providers will block logos that lack a certificate to avoid fraud.
        </p>
        <p>
        To generate a VMC, you'll need to follow the instructions provided by your email service provider or work with a Certificate Authority such as Digicert.
        This process often involves providing information about your organization, domain, and undergoing verification to prove your ownership and legitimacy to receive the VMC once it's approved.
        The exact steps may differ based on which option you may choose, so it's best to check their documentation or contact their support for guidance.
        </p>
        <p>
            Once this cert is approved and hosted in a publicly available place, it can be used as part of the BIMI record and can be specified below.
        </p>
        <input
            type='text'
            placeholder={t('yourdomain.com/public-directory/your-cert.pem')}
            value={certPath}
            onChange={(e) => handleSetCertPath(e.target.value)}
            />
        <div>
        <WizardButton
          text={'Previous'}
          onClick={() => props.previousStep()}
        />
        <WizardButton
        text={t('Next')}
        onClick={() => handleNextClick()}
        />
        </div>
        <div>
          {(
            <div>
            {missingCert ? (<p style={{ color: 'red',  marginLeft: '10px', marginTop: '8px' }} >Please specify a VMC before proceeding.</p>) 
            :<p></p>}
            </div>
          )}
        </div>

    </Step>
  )
}