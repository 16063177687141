import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'


export const Step2 = (props: any) => {
  const { t } = useTranslation()
  const {domainPolicy, handleSetDomainPolicy} = props
  return (
    <Step>
      <h2>{t('DMARC.Policy.Title')}</h2>
      <div>{t('DMARC.Policy.Levels')}</div>
      <div>
        <ul style={{ marginTop: '10px' }}>
          <li style={{ color: '#1bb28c' }}>{t('DMARC.Policy.NoneEx')}</li>
          <li style={{ color: '#b9892d' }}>{t('DMARC.Policy.QuarantineEx')}</li>
          <li style={{ color: 'rgb(185, 137, 45)' }}>
            {t('DMARC.Policy.RejectEx')}
          </li>
        </ul>
      </div>

      <div className='levels__select'>
        <select
          name=''
          id=''
          style={{ cursor: 'pointer' }}
          value={domainPolicy}
          onChange={(e) => handleSetDomainPolicy(e.target.value)}
        >
          <option value='none'>{t('DMARC.Policy.None')}</option>
          <option value='quarantine'>{t('DMARC.Policy.Quarantine')}</option>
          <option value='reject'>{t('DMARC.Policy.Reject')}</option>
        </select>
      </div>

      <div style={{ marginTop: '20px' }}>
        {t('DMARC.Policy.Recommended1')}{' '}
        <strong style={{ textDecoration: 'underline' }}>
          {t('DMARC.Policy.Recommended2')}
        </strong>{' '}
        {t('DMARC.Policy.Recommended3')}{' '}
      </div>
      <div style={{ marginTop: '10px' }}>
        {t('DMARC.Policy.Tag')} <strong>'p'</strong> {t('DMARC.Policy.Tag2')}
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
