let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `מדריך DMARC`;

l.Header.Main.SetupDMARC        = `הגדרת DMARC`;
l.Header.Main.HowItWorks        = `איך זה עובד`;
l.Header.Main.AboutDMARC        = `אודות DMARC`;
l.Header.Main.Resources         = `משאבים`;
l.Header.Main.Contact           = `צרו קשר`;

l.Footer.Main.SetupDMARC        = `הגדרת DMARC`;
l.Footer.Main.HowItWorks        = `איך זה עובד`;
l.Footer.Main.AboutDMARC        = `אודות DMARC`;
l.Footer.Main.Resources         = `משאבים`;
l.Footer.Main.Contact           = `צרו קשר`;
l.Footer.Main.Copyright         = `מוגן בזכויות יוצרים ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `מדיניות פרטיות`;
l.Footer.Main.Sitemap           = `מפת האתר`;

l.Home.Main.EnterDomain         = `הזינו את כתובת הדומיין שלכם`;
l.Home.Main.ToStart             = `כדי להתחיל בהגדרת DMARC`;
l.Home.Main.TagLine             = `בצעו פעולה. תמדדו אותה.`;
l.Home.Main.Welcome             = `ברוכים הבאים למדריך ההגדרה של DMARC. מדריך זה ינחה את הארגון שלכם בתהליך היצירה של מדיניות DMARC, וכן של הגדרת מדיניות עבור SPF (מסגרת מדיניות השולח) ו-DKIM (פרוטוקול DomainKeys Identified Mail). הטמעה של כל שלושת סוגי המדיניות תחזק את מנגנון האימות של הארגון שלכם ותסייע להגן על המותג שלכם.`;
l.Home.Main.FirstStep           = `השלב הראשון הוא לבדוק האם הארגון שלכם כבר משתמש במדיניות כלשהי מבין השלוש.`;
l.Home.Main.InputWarning        = `אנא הזינו את שם הדומיין שלכם מתוך כתובת הדוא"ל של הארגון שלכם, בדיוק כפי שהוא מופיע לאחר הסמל @ שבכתובת הדוא"ל.`;
l.Home.Main.InputExample        = `למשל, עבור כתובת הדוא"ל info@globalcyberalliance.org, שם הדומיין שיש להזין בתיבת הטקסט הוא globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `חובה להזין את כתובת אתר האינטרנט`;
l.Home.Main.valid               = `כתובת אתר האינטרנט אינה חוקית`;
l.Home.Main.PleaseNote          = `שימו לב:`;
l.Home.Main.UserWarningHeader   = `משתמשי Google Apps ו-Office 365:`;
l.Home.Main.UserWarningText1    = `אנא הטמיעו את ה-SPF וה-DKIM בהתאם למידע שבדף המשאבים עבור`;
l.Home.Main.UserWarningLink     = `ספקי שירות ענן`;
l.Home.Main.UserWarningText2    = `שלכם, ולאחר מכן השתמשו במדריך זה כדי להמשיך בהטמעת מדיניות ה-DMARC.`;
l.Home.Main.Submit              = `שלח`;

l.ToolSelect.Main.Title         = `תוצאות עבור`;
l.ToolSelect.Main.ResultStatus  = `בטבלה שלהלן תוכלו לראות אילו פרוטוקולים הוטמעו <i class='fas fa-check-circle' style='color: green'></i> או לא הוטמעו <i class='fas fa-times-circle' style='color: red'></i>.`;
l.ToolSelect.Main.Protocol      = `אנא בחרו את הפרוטוקול הרצוי כדי להפעיל את אשף ההגדרה.`;
l.ToolSelect.Main.ShareButton   = `אם תרצו לשתף את התוצאות פשוט לחצו על הכפתור "שיתוף" והקישור ללוח ההעתקה שלכם.`;
l.ToolSelect.Main.Selecting     = `לחיצה על פרוטוקול תציג גם את הרשומה הנוכחית, אם היא זמינה.`;
l.ToolSelect.Main.DKIMWarning   = `סעיף בדיקת ה-DKIM עדיין בפיתוח. עם זאת, תוכלו לבחור בשורת ה-DKIM כדי להגיע למדריך ההגדרה של ה-DKIM.`;
l.ToolSelect.Main.NoDKIM        = `בדקנו את רשימת הבוררים (selectors) ברירת המחדל אך לא הצלחנו למצוא רשומת DKIM המשויכת לדומיין שלכם.`;
l.ToolSelect.Main.NoDKIM1       = `אם שם הבורר (selector) ידוע לכם, תוכלו לבצע לחפש את רשומת ה-DKIM כאן:`;
l.ToolSelect.Main.NoDKIM2       = `לחלופין, כתבו לנו את כתובת הדוא"ל שלכם ואנו נשלח הודעת דוא"ל לחשבון שלכם, השיבו להודעה שתקבלו ואנו נבדוק האם קיימת רשומת DKIM בדוא"ל שלכם.`;
l.ToolSelect.Main.NoDKIMError   = `דואר אלקטרוני לא חוקי או לא שייך לדומיין שציינתם.`;
l.ToolSelect.Main.SelectorMsg   = `מחפש בורר!`;
l.ToolSelect.Main.SelectorError = `לא נמצא בורר.`;
l.ToolSelect.Main.EmailMsg      = `הדוא"ל נשלח! אנא השיבו לדוא"ל. דף זה ייטען מחדש לאחר שתשלחו את התשובה ויציג לכם את שם הבורר.`;
l.ToolSelect.Main.InputSelector = `בורר (selector)`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `שלח`;
l.ToolSelect.Main.Select        = `בחר`;
l.ToolSelect.Main.Next          = `הבא`;
l.ToolSelect.Main.Share         = `שיתוף`;

l.ToolSelect.DMARC.RedX         = `לא הוטמע פרוטוקול DMARC בדומיין של כתובת הדוא"ל הזו. אנא לחצו על הכפתור 'הבא' שלהלן כדי להפעיל את אשף ההגדרה של ה-DMARC.`;
l.ToolSelect.DMARC.RedExcl      = `תודה לכם על תחילת העבודה עם DMARC. אתם נמצאים כרגע ברמה הנמוכה ביותר, וזו נקודת התחלה מצוינת. עם זאת, עליכם לקבל דוחות כדי לקבוע האם הפרוטוקולים DMARC/SPF/DKIM פועלים כראוי. אנא הוסיפו את התגית ‘rua’ למדיניות ה-DMARC שלכם. לחצו כאן למידע נוסף על התגית ‘rua’.`;
l.ToolSelect.DMARC.RedCheck     = `תודה לכם על תחילת העבודה עם DMARC. אתם נמצאים כרגע ברמה הנמוכה ביותר ומקבלים דוחות, וזו נקודת התחלה מצוינת. אנא הקפידו לעיין בדוחות, לבצע את ההתאמות המתאימות ולהעביר בהקדם להסגר (quarantine) או לדחייה (reject). לחצו כאן למידע נוסף על כלי הדיווח.`;
l.ToolSelect.DMARC.AmberExcl    = `אתם כרגע ברמה השנייה. עם זאת, עליכם לקבל דוחות כדי לקבוע האם הפרוטוקולים DMARC/SPF/DKIM פועלים כראוי ולעבור לרמה הגבוהה ביותר (דחייה). אנא הוסיפו את התגית ‘rua’ למדיניות ה-DMARC שלכם. לחצו כאן למידע נוסף על התגית ‘rua’.`;
l.ToolSelect.DMARC.AmberCheck   = `אתם כרגע ברמה השנייה ומקבלים דוחות. אנא הקפידו לעיין בדוחות, לבצע את ההתאמות המתאימות ולהעביר בהקדם לדחייה (reject). לחצו כאן למידע נוסף על כלי הדיווח.`;
l.ToolSelect.DMARC.GreenCheck   = `אתם ברמה הגבוהה ביותר! אנא הקפידו להמשיך לעיין בדוחות ולבצע את ההתאמות המתאימות במידת הצורך.`;

l.Complete.Main.Title           = `סיימתם להגדיר את ה-DMARC`;
l.Complete.Main.check           = `האם לבדוק SPF, DKIM או DMARC בדומיין אחר?`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `חובה להזין את כתובת אתר האינטרנט`;
l.Complete.Main.valid           = `כתובת אתר האינטרנט אינה חוקית`;
l.Complete.Main.Next            = `הבא`;
l.Complete.Main.More            = `מידע נוסף על DMARC`;

l.SPF.Home.Title                = `מה זה SPF?`;
l.SPF.Home.SPF                  = `SPF הם ראשי התיבות של Sender Policy Framework (מסגרת מדיניות השולח). מדיניות זו אחראית למנוע זיוף (spoof) של כתובת השולח, וזאת על ידי הגדרת רשימה של שרתי דואר שמורשים לשלוח דוא"ל בשם הדומיין של הארגון. אם שרת הדואר אינו מוגדר ב-SPF, ההודעה נדחית (rejected) או שאינה מגיעה ליעדה (bounced).`;
l.SPF.Home.Start                = `אנא לחצו על הכפתור "הבא" כדי להתחיל ליצור מדיניות SPF.`;
l.SPF.Home.Next                 = `הבא`;

l.SPF.Authorization.Title       = `האם השרתים המפורטים להלן מורשים לשלוח דוא"ל עבור הדומיין של הארגון?`;
l.SPF.Authorization.First       = `ראשית, יש להגדיר במדיניות את רשימת שרתי הדואר הנכנס (MXes) של הדומיין שיש להם הרשאה גם לשלוח דואר עבור הדומיין.`;
l.SPF.Authorization.Current     = `הרשימה שלהלן כוללת את רשומות ה-DNS הנוכחיות עבור שרתי הדואר שמשויכים לארגון שלכם.`;
l.SPF.Authorization.Recommended = `מומלץ מאוד לבחור באפשרות כן מכיוון שהרשימה שלעיל צריכה להיות רשימת השרתים המורשים של הדומיין שלכם. ביצוע הפעולה יוסיף את התגית mx למדיניות ה-SPF.`;
l.SPF.Authorization.Yes         = `כן (מומלץ)`;
l.SPF.Authorization.No          = `לא`;
l.SPF.Authorization.Missing     = `אם מערכות מסוימות חסרות ברשימה או שאינן שרתי דוא"ל רגילים, הוסיפו אותן באפשרות הבאה.`;
l.SPF.Authorization.Next        = `הבא`;

l.SPF.Servers.Title             = `האם בדומיין שלכם קיימים שרתים נוספים שעשויים לשלוח או להעביר דוא"ל עבור דומיין זה?`;
l.SPF.Servers.Possible          = `קיימת אפשרות שלא כל שרתי הדואר בארגון מוגדרים ברשומות ה-DNS של הארגון. ייתכן שמדובר בשרתי בדיקות או ביישומים עם מערכות דוא"ל מובנות. במקרה זה, הוסיפו את שרתי הדומיינים או כתובות ה-IP הנוספים בתיבות שלהלן.`;
l.SPF.Servers.Others            = `יש להשתמש באפשרות זו רק אם יש מערכות פנימיות אחרות, מלבד שרתי הדוא"ל, שמורשות לשלוח דוא"ל עבור הדומיין.`;
l.SPF.Servers.Added             = `הוספת מערכות נוספות תגרום להוספת התגית 'a' למדיניות ה-SPF.`;
l.SPF.Servers.InputField        = `שם או IP של השרת`;
l.SPF.Servers.InputInvalid      = `חייב להיות שם דומיין או כתובת IP מלאים (FQDN)`;
l.SPF.Servers.Next              = `הבא`;

l.SPF.Domains.Title             = `האם קיימים דומיינים חיצוניים שייתכן שישלחו או יעבירו (relay) דואר עבור הארגון שלכם?`;
l.SPF.Domains.Include           = `מקטע זה יוסיף את התגית`;
l.SPF.Domains.Include2          = `include`;
l.SPF.Domains.Include3          = `למדיניות ה-SPF.`;
l.SPF.Domains.Trusted           = `יש להשתמש בסעיף רק אם הדומיין החיצוני מהימן לשלוח הודעות דוא"ל עבור הדומיין של הארגון שלכם. לדוגמה, מערכת ניהול קשרי לקוחות (CRM), ספק דואר בענן (גוגל, Office 365, Yahoo!), או ספק אבטחה בענן.`;
l.SPF.Domains.Note              = `שימו לב: אם אתם משתמשים בספק שירותי ענן עליכם לוודא מולו מהו הערך המתאים עבור הגדרה זו.`;
l.SPF.Domains.InputField        = `שם או IP של השרת`;
l.SPF.Domains.InputInvalid      = `חייב להיות שם דומיין או כתובת IP מלאים (FQDN)`;
l.SPF.Domains.Next              = `הבא`;

l.SPF.Strict.Title              = `באיזו רמת הקפדה (strict) השרתים צריכים לטפל בהודעות הדוא"ל?`;
l.SPF.Strict.Receiving          = `זהו המקטע האחרון במדיניות ה-SPF ובו מוגדרת באיזו רמת הקפדה צריכים השרתים המקבלים לטפל בהודעות הדוא"ל.`;
l.SPF.Strict.SoftFail           = `Soft Fail (כישלון 'רך') (מומלץ)`;
l.SPF.Strict.Fail               = `Fail (כישלון)`;
l.SPF.Strict.Neutral            = `Neutral (ניטרלי)`;
l.SPF.Strict.AllowAll           = `Allow All (אפשר הכל)`;
l.SPF.Strict.Next               = `הבא`;
l.SPF.Strict.Options            = `אפשרויות:`;
l.SPF.Strict.FailEx             = `כישלון או `;
l.SPF.Strict.FailEx2            = `- רק שרתי הדוא"ל של הדומיין (ואלו שבמקטעים a ו-include) מורשים לשלוח דוא"ל עבור הדומיין. כל השאר לא יכולים.`;
l.SPF.Strict.SoftEx             = `Soft Fail (כישלון רך) או `;
l.SPF.Strict.SoftEx2            = `- רק שרתי הדוא"ל של הדומיין (ואלו שבמקטעים a ו-include) מורשים לשלוח דוא"ל עבור הדומיין, אבל זה במעבר. כל השאר לא יכולים.`;
l.SPF.Strict.NeutralEx          = `ניטרלי או`;
l.SPF.Strict.NeutralEx2         = `- מציין במפורש שלא ניתן לומר שום דבר בנוגע לתקפות.`;
l.SPF.Strict.AllowEx            = `אפשר הכל או`;
l.SPF.Strict.AllowEx2           = `- כל מחשב מארח יכול לשלוח דוא"ל עבור הדומיין.`;
l.SPF.Strict.Warning1           = `לעולם `;
l.SPF.Strict.Warning2           = `אין`;
l.SPF.Strict.Warning3           = `להשתמש באפשרות זו`;
l.SPF.Strict.Recommended        = `מומלץ להשתמש ב-Soft Fail`;
l.SPF.Strict.MarkMail           = `הגדרה זו תסמן כל דואר שאינו עומד בקריטריונים שהוגדרו כדואר שאינו תואם (non-compliant).`;

l.SPF.Results.Title             = `להלן רשומת ה-SPF TXT שיש ליצור בשרת ה-DNS שלכם.`;
l.SPF.Results.Copy              = `העתק`;
l.SPF.Results.Copied            = `הועתק!`;
l.SPF.Results.DMARCGuide        = `דלג אל מדריך DMARC`;
l.SPF.Results.DKIMGuide         = `המשך אל מדריך DKIM`;
l.SPF.Results.OwnDNS            = `אם יש לכם שרת DNS משלכם, אנא צרו רשומות TXT למדיניות ה-SPF שלעיל.`;
l.SPF.Results.ThirdParty        = `אם אתם משתמשים ב-DNS של ספק חיצוני, אנא צרו רשומת TXT בהתאם להנחיות שתקבלו מהם.`;
l.SPF.Results.Reference         = `יש לעיין`;
l.SPF.Results.ResourcesPage     = `בדף המשאבים`;
l.SPF.Results.Information       = `לקבלת מידע כיצד ליצור רשומות TXT.`;
l.SPF.Results.NotUnderstood     = `אם ניסיתם לבצע את ההוראות אך לא הבנתם את הפעולות שיש לבצע או את המונחים המקצועיים שבמסמך זה, אנו ממליצים שתפנו לתמיכה הטכנית של הארגון או של רשם הדומיין שלכם, או ל-GCA Info`;
l.SPF.Results.Assistance        = `לקבלת סיוע נוסף.`;
l.SPF.Results.Implemented       = `לאחר שהטמעתם את רשומת ה-SPF, אנא השתמשו במאמת/בודק ה-SFP שלהלן:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `בדיקה`;
l.SPF.Results.SuccessDKIM       = `מעבר אל DKIM`;
l.SPF.Results.SuccessDMARC      = `מעבר אל DMARC`;
l.SPF.Results.NotQuite          = `עדיין לא שם.`;
l.SPF.Results.Wisdom            = `זה בסדר! החכמה היא המטרה הסופית של כל פעולה. אל תהססו להשתמש במשאבים שלנו או לפנות אלינו בדוא"ל ונוכל לסייע לכם בהטמעה. בשביל זה אנחנו כאן.`;

l.DKIM.Home.Title               = `מה זה DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM הם ראשי התיבות של DomainKeys Identified Mail. DKIM הוא מנגנון שנועד לאמת את הזהות של הדומיין המשויך להודעת דואר. מטרה זו מושגת באמצעות אימות שמשתמש בקריפטוגרפיה אסימטרית. ביסודו של דבר, DKIM יאמת את הודעת הדואר על ידי הוספת חתימה דיגיטלית לכותרת ההודעה. חשוב לציין ש-DKIM אינו מסנן הודעות דואר. הפרוטוקול מאפשר למסנני דואר זבל לקבוע את האותנטיות (מקוריות) של הודעת הדואר שנשלחה.`;
l.DKIM.Home.ClickNext           = `אנא לחצו על הכפתור "הבא" כדי להתחיל בתהליך יצירת מדיניות ה-DKIM.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `הבא`;

l.DKIM.Selector.Title           = `בורר (selector)`;
l.DKIM.Selector.Name            = `הבורר יקבע את שמה של רשומת ה-DKIM. שם הבורר יכול להיות כל דבר, אבל להלן כמה אפשרויות לבחירה.`;
l.DKIM.Selector.Define          = `הפעולה תגדיר את שם רשומת ה-TXT שתיווצר עבור מפתח ה-DKIM. השם יופיע כך:`;
l.DKIM.Selector.Example1        = `בורר (selector)`;
l.DKIM.Selector.Example2        = `שם דומיין`;
l.DKIM.Selector.Next            = `הבא`;

l.DKIM.Key.Title                = `מפתח ציבורי`;
l.DKIM.Key.Tag                  = `פעולה זו תוסיף את התגית 'p', שהיא מחרוזת המפתח בקובץ מפתח ציבורי שנוצר קודם לכן.`;
l.DKIM.Key.Keys                 = `כתבו כאן את המפתחות הציבוריים`;
l.DKIM.Key.Next                 = `הבא`;
l.DKIM.Key.Creating             = `יצירת מפתחות ה-DKIM:`;
l.DKIM.Key.TwoParts             = `ה-DKIM מורכב משני חלקים, המפתח הפרטי והמפתח הציבורי. יצירת המפתחות עבור ה-DKIM תשתנה בהתאם לספקי שירות הדוא"ל של הארגון שלכם.`;
l.DKIM.Key.External             = `אם אתם משתמש בשירות חיצוני גם עבור דואר וגם עבור DNS, ספק הדואר שלכם יצטרך לספק לכם את המפתח הציבורי. המפתח הציבורי הזה יפורסם כרשומת TXT ב-DNS. מרבית ספקי ה-DNS מגבילים את אורכה של רשומת ה-TXT ל-255 תווים, כך שייתכן שתצטרכו לפנות לספק כדי לבקש להגדיל את אורך הרשומה או כדי ליצור את רשומת ה-TXT. המפתח הפרטי מוחזק על ידי ספק הדואר ובדרך כלל אינו מסופק לארגון.`;
l.DKIM.Key.Internal             = `אם לארגון יש שרת דואר אלקטרוני משלו, צוות ה-IT של הארגון (או האחראי על הטמעת ה-DKIM) הוא זה שצריך ליצור את מפתחות ה-DKIM. ניתן למצוא באינטרנט מספר רב של מחוללי מפתח DKIM. אם תבחרו להשתמש באחד מאלה, יש לוודא שתוכלו למחוק את המפתח לאחר יצירתו ולהעתיק אותו לקבצים בצד שלכם.`;
l.DKIM.Key.OpenDKIM             = `ניתן ליצור מפתחות ללא צד שלישי דרך פרויקט קוד פתוח זמין בשם opendkim.`;
l.DKIM.Key.OpenDKIM2            = ` . Opendkim מציע מגוון כלים שיסייעו לכם ליצור את מפתח ה-DKIM.`;
l.DKIM.Key.OpenSSL              = `ניתן ליצור מפתחות DKIM גם בעזרת שימוש ב-OpenSSL. ניתן לבצע את הפעולה על ידי שימוש בגרסה העדכנית ביותר של OpenSSL והקלדת הפקודה הבאה עבור יצירת המפתח הפרטי:`;
l.DKIM.Key.OpenSSL2             = `פעולה זו תיצור קובץ מפתח פרטי בשם`;
l.DKIM.Key.OpenSSL3             = `לאחר מכן הקלידו את הפקודה הבאה כדי ליצור את המפתח הציבורי:`;
l.DKIM.Key.OpenSSL4             = `פקודה זו תיצור את המפתח הציבורי`;
l.DKIM.Key.OpenSSL5             = `על בסיס המפתח הפרטי שנוצר קודם לכן`;
l.DKIM.Key.OpenSSL6             = `העבירו את מפתח ה-DKIM הפרטי אל המיקום שצוין בהגדרת ה-DKIM. יש לוודא שהוא נמצא בתיקייה עם גישה מוגבלת.`;

l.DKIM.Results.Title            = `להלן רשומת ה-DKIM TXT שיש ליצור בשרת ה-DNS שלכם.`;
l.DKIM.Results.Copy             = `העתק`;
l.DKIM.Results.Copied           = `הועתק!`;
l.DKIM.Results.SPFGuide         = `חזרה למדריך ה-SPF`;
l.DKIM.Results.DMARCGuide       = `המשך למדריך ה-DMARC`;
l.DKIM.Results.OwnDNS           = `אם יש לכם שרת DNS משלכם, אנא צרו רשומות TXT למדיניות ה-DKIM שלעיל.`;
l.DKIM.Results.ThirdParty       = `אם אתם משתמשים ב-DNS של ספק חיצוני, אנא צרו רשומת TXT בהתאם להנחיות שתקבלו מהם.`;
l.DKIM.Results.Reference        = `יש לעיין`;
l.DKIM.Results.ResourcesPage    = `בדף המשאבים`;
l.DKIM.Results.Information      = `לקבלת מידע כיצד ליצור רשומות TXT.`;
l.DKIM.Results.NotUnderstood    = `אם ניסיתם לבצע את ההוראות אך לא הבנתם את הפעולות שיש לבצע או את המונחים המקצועיים שבמסמך זה, אנו ממליצים שתפנו לתמיכה הטכנית של הארגון או של רשם הדומיין שלכם, או ל-GCA Info`;
l.DKIM.Results.Assistance       = `לקבלת סיוע נוסף.`;
l.DKIM.Results.Note             = `שימו לב:`;
l.DKIM.Results.Support          = `מפתחות DKIM יכולים לתמוך עכשיו במפתחות של 2048 סיביות. עם זאת, רשומות DNS TXT אינן יכולות לתמוך בערכים של יותר מ-255 תווים. ניתן לעקוף זאת על ידי:`;
l.DKIM.Results.Support2         = `שימוש במפתח 1024 סיביות, או על ידי`;
l.DKIM.Results.Support3         = `יצירת רשומת TXT מרובת שורות`;
l.DKIM.Results.Support4         = `להלן דוגמה לרשומה מרובת שורות (הוספנו מרכאות כדי לפצל את מחרוזת הטקסט לשורות מרובות):`;

l.DMARC.Home.Title              = `מה זה DMARC?`;
l.DMARC.Home.Final              = `כעת, לאחר שיצרתם והטמעתם מדיניות SPF ו-DKIM, נוכל לעבור לשלב הסופי וליצור מדיניות DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (אימות, דיווח והתאמה של הודעה בהתבסס על דומיין, או באנגלית: Domain-based Message Authentication, Reporting & Conformance) הוא פרוטוקול אימות דוא"ל הכולל פונקציית דיווח שמאפשרת לשולחים ולמקבלים לשפר ולפקח על ההגנה על הדומיין מפני הודעות דוא"ל כוזבות. הטמעת DMARC תמנע משולחי דואר זבל/ספאמרים לזייף (spoofing) את כתובת השולח ("מאת") בהודעות דוא"ל. בהתאם להגדרות המדיניות של DMARC, כל הודעות הדואר שמקורן משרת דואר לא מורשה יועברו להסגר (quarantined) או יידחו. כל הודעות הספאם וההתחזות/דיוג (phishing) שמשתמשות בשם הדומיין של הארגון יועברו להסגר או יימחקו לפני שיגיעו ליעד (משתמש הקצה). ניתן להשתמש בדוחות לצורך ביצוע חקירות או לצרכי אכיפת החוק, אם הפעילות היא פלילית בטבעה.`;
l.DMARC.Home.Stronger           = `DMARC יחבר יחד את ה-SPF וה-DKIM שלכם כדי ליצור אימות דוא"ל חזק יותר לדומיין של הארגון שלכם.`;
l.DMARC.Home.ClickNext          = `אנא לחצו על הכפתור "הבא" כדי להתחיל בתהליך יצירת מדיניות ה-DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `הבא`;

l.DMARC.Policy.Title            = `מה צריכה להיות רמת המדיניות עבור DMARC?`;
l.DMARC.Policy.Levels           = `ב-DMARC ניתן להגדיר שלוש רמות מדיניות. והן:`;
l.DMARC.Policy.NoneEx           = `אף אחת (None) - קבלת דיווח על הודעות דואר חשודות אפשריות, אך כל הודעות הדואר נשלחות לתיבת הדואר הנכנס`;
l.DMARC.Policy.QuarantineEx     = `הסגר (Quarantine) - בדיקת ה-SPF/DKIM נכשלה ולכן ההודעה תישלח לתיקיית הספאם/זבל`;
l.DMARC.Policy.RejectEx         = `דחייה (Reject) - בדיקת ה-SPF/DKIM נכשלה וההודעה 'נופלת' ואינה מועברת כלל.`;
l.DMARC.Policy.None             = `אף אחת (מומלץ)`;
l.DMARC.Policy.Quarantine       = `הסגר (Quarantine)`;
l.DMARC.Policy.Reject           = `דחייה (Reject)`;
l.DMARC.Policy.Next             = `הבא`;
l.DMARC.Policy.Recommended1     = ``;
l.DMARC.Policy.Recommended2     = `מומלץ מאוד`;
l.DMARC.Policy.Recommended3     = `להתחיל עם "אף אחת" מכיוון שכך יהיה לכם זמן לפקח על הדוחות שתקבלו ולראות האם דואר לגיטימי עלול להיחסם, וזאת לפני שתעברו למדיניות הסגר או דחייה של DMARC.`;
l.DMARC.Policy.Tag              = `פעולה זו תוסיף את התגית`;
l.DMARC.Policy.Tag2             = `לרשומת ה-DMARC.`;

l.DMARC.Aggregates.Title        = `לאן לשלוח את הדוחות המצטברים (aggregate)?`;
l.DMARC.Aggregates.Opt          = `מקטע זה אינו חובה אך הוא מומלץ מאוד, והוא יוסיף את התגית`;
l.DMARC.Aggregates.Opt2         = `rua' למדיניות. -`;
l.DMARC.Aggregates.Opt3         = `מומלץ מאוד`;
l.DMARC.Aggregates.Opt4         = `להוסיף אותו למדיניות כדי שתוכלו לנתח ולעקוב אחר הנתונים. ניתן להגדיר שהדוחות יישלחו למספר כתובות.`;
l.DMARC.Aggregates.InputField   = `כתובת דוא"ל`;
l.DMARC.Aggregates.InputInvalid = `נא להזין כתובת דוא"ל חוקית`;
l.DMARC.Aggregates.Next         = `הבא`;
l.DMARC.Aggregates.Reports      = `הדוחות המצטברים (בתבנית XML) שיופקו במערכת יכילו מידע אודות הודעות הדואר שהועברו/נכשלו על ידי ה-SPF וה-DKIM. הדוחות יאפשרו לכם לראות אם יש בעיות אימות אפשריות ו/או פעילות דואר זבל שקשורות לארגון שלכם.`;
l.DMARC.Aggregates.Contains     = `הדוחות מכילים את המידע שלהלן`;
l.DMARC.Aggregates.HowToRead    = `כיצד לקרוא את דוחות ה-DMARC הראשונים שלכם`;
l.DMARC.Aggregates.By           = `(חלק 1) מאת איימי גורל):`;
l.DMARC.Aggregates.Info         = `מידע על ספק שירותי האינטרנט`;
l.DMARC.Aggregates.Desc         = `תיאור שורה אחר שורה של רשומת ה-DMARC של הארגון שלכם`;
l.DMARC.Aggregates.Summary      = `סיכום תוצאות האימות`;
l.DMARC.Aggregates.Important    = `חשוב לציין שהדוחות הללו לא יגיעו רק ממקור אחד. ייתכן שתקבלו בכל יום 10 או 100 (ואולי אלפי) דוחות (כמות הדוחות תלויה בכמות הודעות הדוא"ל שנשלחו).`;
l.DMARC.Aggregates.Difficult    = `מכיוון שכך, יהיה לכם קשה לבדוק כל דוח ידנית, ובמיוחד בגלל שהם בתבנית XML וחלקם יכולים להיות ארוכים מאוד.`;
l.DMARC.Aggregates.Readable     = `ניתן להפוך את הדוחות לקריאים יותר לעין אנושית בעזרת פיתוח ממיר XML או בעבודה עם ספק מסחרי. רוב הספקים המסחריים יספקו לכם מאגר 'קצה אחורי' (backend) שיהפוך את הדוחות לקריאים לעין אנושית וכן לספק רמות שונות של ניתוח והכוונה.`;
l.DMARC.Aggregates.Vendors      = `ספקי DMARC (שותפים של GCA):`;

l.DMARC.Forensics.Title         = `לאן לשלוח דוחות פורנזיים?`;
l.DMARC.Forensics.Opt           = `מקטע זה הוא אפשרות, ויוסיף את התגית`;
l.DMARC.Forensics.Opt2          = `ruf' למדיניות. דוחות XML אלה ייווצרו רק אם גם הצד המקבל מייצר דוחות מסוג זה. ייתכן שלא תוכלו לקבל דוחות פורנזיים. זה תלוי בתקנות שיתוף נתונים ופרטיות בין מדינות.`;
l.DMARC.Forensics.InputField    = `כתובת דוא"ל`;
l.DMARC.Forensics.InputInvalid  = `נא להזין כתובת דוא"ל חוקית`;
l.DMARC.Forensics.Next          = `הבא`;

l.DMARC.Subdomains.Title        = `מדיניות DMARC עבור תת-דומיינים?`;
l.DMARC.Subdomains.Define       = `מקטע זה יגדיר את רמת המדיניות עבור תת-דומיינים של הדומיין הארגוני שלכם. אם תגית זו`;
l.DMARC.Subdomains.Define2      = `אינה קיימת, המדיניות תופעל בהתאם למדיניות של הדומיין שברמה העליונה, בהתאם להגדרתו בשלב הראשון.`;
l.DMARC.Subdomains.None         = `אף אחת (None)`;
l.DMARC.Subdomains.Quarantine   = `הסגר (Quarantine)`;
l.DMARC.Subdomains.Reject       = `דחייה (Reject)`;
l.DMARC.Subdomains.Next         = `הבא`;
l.DMARC.Subdomains.Policy       = `מדיניות עבור דומיין. האפשרויות הן:`;
l.DMARC.Subdomains.NoneEx       = `אף אחת - לא ננקטה פעולה, אך נוצרה רשומה בדוח ה-DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `הסגר - הודעת הדוא"ל סומנה כדואר זבל.`;
l.DMARC.Subdomains.RejectEx     = `דחייה - ההודעה נמחקת.`;
l.DMARC.Subdomains.Recommended  = `אם יש לכם כמה תתי-דומיינים מומלץ להשאיר את ההגדרה "אף אחת". מומלץ להגדיר מדיניות DMARC נפרדת עבור כל תת-דומיין כך שלכולם תהיה תאימות למדיניות DMARC. אם אין לארגון תת-דומיינים, מומלץ להשתמש בדחייה.`;

l.DMARC.Options.Title           = `(אפשרות) האפשרויות הנותרות אינן חובה, אך ניתן לכוונן אותן במידת הצורך:`;
l.DMARC.Options.ReportGen       = `1. מתי צריך להיווצר דוח השגיאה`;
l.DMARC.Options.Default         = `ברירת מחדל`;
l.DMARC.Options.ReportPol       = `מגדיר מדיניות דיווח שגיאות.`;
l.DMARC.Options.DefaultsTo      = `אם לא הוגדר אחרת, ברירת המחדל היא`;
l.DMARC.Options.AllChecks       = `צור דוח עבור סוכן שליחת הדוא"ל (MTA) אם כל הבדיקות הבסיסיות נכשלו`;
l.DMARC.Options.AnyChecks       = `צור דוח עבור סוכן שליחת הדוא"ל (MTA) אם בדיקות בסיס כלשהן נכשלו`;
l.DMARC.Options.OtherValues     = `ערכים אחרים הם:`;
l.DMARC.Options.DKIMFails       = `צור דוח אם בדיקת DKIM נכשלת`;
l.DMARC.Options.SPFFails        = `צור דוח אם בדיקת SPF נכשלת`;
l.DMARC.Options.Relaxed         = `חלקי (Relaxed)`;
l.DMARC.Options.Strict          = `קפדני (Strict)`;
l.DMARC.Options.DKIMAlign       = `2. מצב התאמה (Alignment) עבור DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `מצב התאמה (Alignment) עבור DKIM. האפשרויות הן:`;
l.DMARC.Options.DKIMRelaxed     = `(חלקי) ברירת המחדל אם לא הוגדר אחרת. מאופשר עבור כל תת-דומיין המוגדר בכותרת DKIM.`;
l.DMARC.Options.DKIMStrict      = `(קפדני) שם הדומיין של השולח חייב להתאים בדיוק לדומיין שבכותרת ה-DKIM.`;
l.DMARC.Options.SPFAlign        = `3. מצב התאמה (Alignment) עבור SPF`;
l.DMARC.Options.SPFAlignOpt     = `מצב התאמה (Alignment) עבור SPF. האפשרויות הן:`;
l.DMARC.Options.SPFRelaxed      = `(חלקי) ברירת המחדל אם לא הוגדר אחרת.  מאופשר עבור כל תת דומיין.`;
l.DMARC.Options.SPFStrict       = `(קפדני) - שם הדומיין הארגוני בפקודה MAIL FROM (ב-SMTP) וב-from: header (בפריט הדואר) חייבים להתאים בדיוק`;
l.DMARC.Options.Percentage      = `4. אחוז ההודעות שכפופות למדיניות DMARC?`;
l.DMARC.Options.Filter          = `אחוז ההודעות שאמורות להיות מסוננות על ידי מדיניות DMARC.  יכול להיות מספר בין 1 ל-100. ברירת המחדל היא 100, והכוונה היא לכל ההודעות.`;
l.DMARC.Options.Formats         = `5. תבנית הפקת הדוחות`;
l.DMARC.Options.FormatsEx       = `מגדיר את התבנית שבה מופקים הדוחות.  ערכי התבנית הם:`;
l.DMARC.Options.RFC             = `ערך ברירת המחדל אם לא הוגדר אחרת.  התבנית מוגדרת על ידי RFC 5965.`;
l.DMARC.Options.RFC2            = `התבנית מוגדרת על ידי RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. מרווח דיווח (שניות)`;
l.DMARC.Options.SecondsDefault  = `שניות (ברירת מחדל)`;
l.DMARC.Options.DigitsOnly      = `נא להזין ספרות בלבד.`;
l.DMARC.Options.ReportIntEx     = `מגדיר את מרווחי הדיווח בשניות.  אם לא הוגדר אחרת, ברירת המחדל היא 86400 שניות, או 24 שעות. שימו לב שלא מובטח שהדוחות יישלחו על ידי סוכני קבלת דוא"ל (MTA).  המערכת מבצעת את מיטב המאמצים לשלוח את הדוחות.`;
l.DMARC.Options.HToS            = `ממיר שעות לשניות`;
l.DMARC.Options.Hours           = `24 שעות`;
l.DMARC.Options.To              = `ל-`;
l.DMARC.Options.Seconds         = `86400 שניות`;
l.DMARC.Options.Finish          = `סיום`;

l.DMARC.Results.Title           = `להלן רשומת ה-DMARC TXT שיש ליצור בשרת ה-DNS שלכם.`;
l.DMARC.Results.Display         = `הצג תוצאת רשומת DMARC TXT`;
l.DMARC.Results.Copy            = `העתק`;
l.DMARC.Results.Copied          = `הועתק!`;
l.DMARC.Results.SPFGuide        = `חזרה למדריך ה-SPF`;
l.DMARC.Results.DKIMGuide       = `חזרה למדריך DKIM`;
l.DMARC.Results.OwnDNS          = `אם יש לכם שרת DNS משלכם, נא ליצור רשומות TXT עבור מדיניות ה-DMARC שמופיעה למטה.`;
l.DMARC.Results.ThirdParty      = `אם אתם משתמשים ב-DNS של ספק חיצוני, אנא צרו רשומת TXT בהתאם להנחיות שתקבלו מהם. ברוב המקרים כל מה שהם צריכים הוא את שם השרת המארח (hostname) את, _dmarc.<domainname> ואת ערך הטקסט (המידע שנמצא בין המרכאות).`;
l.DMARC.Results.Reference       = `יש לעיין`;
l.DMARC.Results.ResourcesPage   = `בדף המשאבים`;
l.DMARC.Results.Information     = `לקבלת מידע כיצד ליצור רשומות TXT.`;
l.DMARC.Results.NotUnderstood   = `אם ניסיתם לבצע את ההוראות אך לא הבנתם את הפעולות שיש לבצע או את המונחים המקצועיים שבמסמך זה, אנו ממליצים שתפנו לתמיכה הטכנית של הארגון או של רשם הדומיין שלכם, או ל-GCA Info`;
l.DMARC.Results.Assistance      = `לקבלת סיוע נוסף.`;
l.DMARC.Results.Complete        = `הושלם`;
l.DMARC.Results.Implemented     = `לאחר שהטמעתם את רשומת ה-DMARC, אנא השתמשו במאמת/בודק ה-DMARC שלהלן:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `עדיין לא ממש שם.`;
l.DMARC.Results.Wisdom          = `זה בסדר! החכמה היא המטרה הסופית של כל פעולה. אל תהססו להשתמש במשאבים שלנו או לפנות אלינו בדוא"ל ונוכל לסייע לכם בהטמעה. בשביל זה אנחנו כאן.`;
l.DMARC.Results.Check           = `בדיקה`;
l.DMARC.Results.Congratulations = `ברכות`;

export default l;
