import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step1 = (props: any) => {
  const { t } = useTranslation()

  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('What is DKIM?')}</h2>
        <p>{t('DKIM.Home.Mechanism1')}</p>
        <p>{t('DKIM.Home.Mechanism2')}</p>
        <p>
            To setup DKIM, visit your email service provider's documenation regarding DKIM.
        </p>
        <p>
            For assistance in generating a DNS record for your DKIM public key, follow our guide below.
        </p>
        <div>
            <WizardButton
            text={t('DKIM DNS Record Guide')}
            onClick={() => props.nextStep()}
            />
        </div>
    </Step>
  )
}