import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step2 = (props: any) => {
  const { t } = useTranslation()
  const [missingDomain, setMissingDomain] = useState(false)
  const [missingSelector, setMissingSelector] = useState(false)
  const [missingKey, setMissingKey] = useState(false)

  const { domain, handleSetDomain, selector, handleSetSelector,publicKey, handleSetPublicKey} = props
  
  const handleFileChange = (e:any) => {
    const file = e.target.files[0];

    if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
        const content = event.target?.result as string | null;
        handleSetPublicKey(content);
    };
    reader.readAsText(file);
    }
  };

  const handleNextClick = () => {
    if(domain.length === 0){
      setMissingDomain(true)
    }
    else {
      setMissingDomain(false)
    }
    if(selector.length === 0){
      setMissingSelector(true)
    }
    else {
      setMissingSelector(false)
    }
    if(publicKey.length === 0){
      setMissingKey(true)
    }
    else {
      setMissingKey(false)
    }
    if(publicKey.length > 0 && selector.length > 0 && domain.length > 0){
      props.nextStep()
    }
  }


  return (
    <Step>
        <h2 style={{ fontWeight: 'bold' }}>{t('Configuring the DKIM record')}</h2>
        <p>
            Enter your domain below.
        </p>
        <input
            type='text'
            placeholder={t('yourdomain.com')}
            value={domain}
            onChange={(e) => handleSetDomain(e.target.value)}
            />
        <p>
            Determine a name for this DKIM record. Known as a selector, this will help with identifying keys.
        </p>
        <input
            type='text'
            placeholder={t('your-selector')}
            value={selector}
            onChange={(e) => handleSetSelector(e.target.value)}
            />
        <p>
            You will also need to generate a DKIM public-private key pair. This can be done with whichever tools you may prefer such as OpenDKIM. 
        </p>
        <p>
            You may include the generated public key here:     
        <input style={{marginLeft:'10px'}} type="file" accept=".pub" onChange={handleFileChange} /></p>
        <div>
            <WizardButton
            text={'Previous'}
            onClick={() => props.previousStep()}
            />
            <WizardButton
            text={t('Next')}
            onClick={() => handleNextClick()}
            />
        </div>
        <div>
          {(
            <div>
            {missingDomain ? (<p style={{ color: 'red',  marginLeft: '10px', marginTop: '8px' }} >Please enter your domain before proceeding.</p>) 
            :<p></p>}
            {missingSelector ? (<p style={{ color: 'red',  marginLeft: '10px', marginTop: '8px' }} >Please specify a selector before proceeding.</p>) 
            :<p></p>}
            {missingKey ? (<p style={{ color: 'red',  marginLeft: '10px', marginTop: '8px' }} >Please specify a public key before proceeding.</p>) 
            :<p></p>}
            </div>
          )}
        </div>
    </Step>
  )
}