import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'
import { Server } from '../Models'

export const Step3 = (props: any) => {


  const { t } = useTranslation()
  const { addServer, removeServer, servers } = props;

  return (
    <Step>
      <h2>{t('SPF.Servers.Title')}</h2>
      <div>{t('SPF.Servers.Possible')}</div>
      <div style={{ fontWeight: 'bold' }}>{t('SPF.Servers.Others')}</div>
      <div>{t('SPF.Servers.Added')}</div>

      {servers.map((el: Server) => (
        <div className='servers' key={el.id}>
          <input
            type='text'
            onChange={(e) => el.update(e.target.value)}
            placeholder={t('SPF.Servers.InputField')}
          />
          <button className='add hs-button primary' onClick={() => removeServer(el)}>
            -
          </button>
        </div>
      ))}

      <div className='servers'>
        <div />
        <button
          className='add hs-button primary'
          onClick={() => addServer()}
        >
          +
        </button>
      </div>

      <div style={{ marginTop: '20px' }}>{t('SPF.Authorization.Missing')}</div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
