import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step5 = (props: any) => {
  const { t } = useTranslation()
  const { handleSetStrictOption, strictOption} = props

  return (
    <Step>
      <h2>{t('SPF.Strict.Title')}</h2>
      <div>{t('SPF.Strict.Receiving')}</div>

      <select
        className='strict__select'
        onChange={(e) => handleSetStrictOption(e.target.value)}
        value={strictOption}
        id=''
      >
        <option value='~all'>{t('SPF.Strict.SoftFail')}</option>
        <option value='-all'>{t('SPF.Strict.Fail')}</option>
        <option value='?all'>{t('SPF.Strict.Neutral')}</option>
        <option value='+all'>{t('SPF.Strict.AllowAll')}</option>
      </select>

      <div>
        {t('SPF.Strict.Options')}

        <ul>
          <li>
            {t('SPF.Strict.FailEx')} "<strong>-all</strong>"{' '}
            {t('SPF.Strict.FailEx2')}
          </li>
          <li>
            {t('SPF.Strict.SoftEx')} "<strong>~all</strong>"{' '}
            {t('SPF.Strict.SoftEx2')}
          </li>
          <li>
            {t('SPF.Strict.NeutralEx')} "<strong>?all</strong>"{' '}
            {t('SPF.Strict.NeutralEx2')}
          </li>
          <li>
            {t('SPF.Strict.AllowEx')} "<strong>+all</strong>"{' '}
            {t('SPF.Strict.AllowEx2')}
            <strong>
              &nbsp;{t('SPF.Strict.Warning1')}
              <span style={{ textDecoration: 'underline' }}>
                {t('SPF.Strict.Warning2')}
              </span>
              &nbsp;
              {t('SPF.Strict.Warning3')}
            </strong>
          </li>
        </ul>
      </div>
      <div>
        {t('SPF.Strict.Recommended')} <strong>(~all)</strong>
        {t('SPF.Strict.MarkMail')}
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
