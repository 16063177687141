import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteListenerProps } from './Models'

const ScrollToTop: React.FC<RouteListenerProps>= ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>; 
};

export default ScrollToTop;
