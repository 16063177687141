let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC-Handbuch`;

l.Header.Main.SetupDMARC        = `EINRICHTUNG VON DMARC`;
l.Header.Main.HowItWorks        = `SO FUNKTIONIERT ES`;
l.Header.Main.AboutDMARC        = `ÜBER DMARC`;
l.Header.Main.Resources         = `RESSOURCEN`;
l.Header.Main.Contact           = `KONTAKT`;

l.Footer.Main.SetupDMARC        = `Einrichtung von DMARC`;
l.Footer.Main.HowItWorks        = `So funktioniert es`;
l.Footer.Main.AboutDMARC        = `Über DMARC`;
l.Footer.Main.Resources         = `Ressourcen`;
l.Footer.Main.Contact           = `Kontakt`;
l.Footer.Main.Copyright         = `Copyright ¬¨¬ © 2023 Global Cyber ​​Alliance`;
l.Footer.Main.PrivacyPolicy     = `Datenschutzrichtlinie`;
l.Footer.Main.Sitemap           = `Sitemap`;

l.Home.Main.EnterDomain         = `Geben Sie Ihre Domain ein,`;
l.Home.Main.ToStart             = `um die Einrichtung von DMARC zu starten.`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `Willkommen im Assistenten für die Einrichtung von DMARC. Der Zweck dieses Einrichtungsleitfadens ist es, Ihre Organisation durch den Prozess der Erstellung einer DMARC-Richtlinie sowie der Erstellung der  Richtlinien für das „Sender Policy Framework“ (SPF) und die „DomainKeys Identified Mail“ (DKIM) zu führen. Durch die Implementierung aller drei Richtlinien wird Ihre Organisation einen stärkeren E-Mail-Authentifizierungsmechanismus haben, welcher dazu beitragen wird, Ihre Marke zu schützen.`;
l.Home.Main.FirstStep           = `Der erste Schritt ist die Abfrage, ob Ihre Organisation bereits eine der drei Richtlinien verwendet.`;
l.Home.Main.InputWarning        = `Bitte geben Sie die Domain aus der E-Mail-Adresse Ihrer Organisation ein – genau so, wie sie nach dem @-Zeichen in der E-Mail-Adresse erscheint.`;
l.Home.Main.InputExample        = `Als Beispiel nehmen wir einmal die folgende E-Mail-Adresse: domainsecurityscanner@globalcyberalliance.org – hier lautet die Domäne, welche Sie in das Textfeld eingeben müssen, wie folgt: globalcyberalliance.org`;
l.Home.Main.InputDomain         = `Yourdomain.com`;
l.Home.Main.Required            = `Die Adresse der Website ist erforderlich.`;
l.Home.Main.valid               = `Die Website-Adresse ist nicht gültig.`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Für alle Benutzer von Google Apps und Office 365 gilt:`;
l.Home.Main.UserWarningText1    = `Bitte folgen Sie den Informationen, die Ihnen auf der Ressourcenseite für`;
l.Home.Main.UserWarningLink     = `Cloud-Service-Provider angeboten werden,`;
l.Home.Main.UserWarningText2    = `um SPF und DKIM zu implementieren. Sobald Sie damit fertig sind, verwenden Sie bitte diesen Leitfaden, um mit der Umsetzung der DMARC-Politik fortzufahren.`;
l.Home.Main.Submit              = `Abschicken`;
l.Home.Main.MoreInfo            = `Wenn Sie weitere Informationen über DMARC wünschen, dann laden wir Sie herzlich dazu ein, an einer unserer monatlichen DMARC-Webinar-Sitzungen teilzunehmen. Dieses Webinar wird die Grundlagen von DMARC abdecken und einen kurzen Überblick über den GCA DMARC-Einrichtungsleitfaden geben.`;
l.Home.Main.Webinar             = `Die monatliche Webinar-Sitzung zu GCA DMARC wird jeweils zweimal am dritten Mittwoch eines jeden Monats angeboten. Bitte melden Sie sich für das Datum und die Uhrzeit an, welche für Sie am besten geeignet sind, indem Sie auf den entsprechenden Link klicken:`;
l.Home.Main.SelectDate          = `Wählen Sie dieses Datum aus.`;

l.ToolSelect.Main.Title         = `Ergebnisse für`;
l.ToolSelect.Main.ResultStatus  = `Die nachstehende Tabelle zeigt an, welche Protokolle bereits implementiert wurden <i class='fas fa-check-circle' style='color: green'></i> oder noch nicht implementiert wurden <i class='fas fa-times-circle' style='color: red'></i>.`;
l.ToolSelect.Main.Protocol      = `Bitte wählen Sie eines der Protokolle aus, um den Einrichtungsleitfaden zu starten.`;
l.ToolSelect.Main.ShareButton   = `Wenn Sie die Ergebnisse mit anderen Leuten teilen möchten, dann klicken Sie einfach auf die Schaltfläche „Teilen“ – dadurch wird ein passender Link in die Zwischenablage kopiert werden.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `Der DKIM-Prüfbereich befindet sich noch im Aufbau. Die DKIM-Installationsanleitung ist jedoch trotzdem verfügbar, indem Sie die DKIM-Zeile auswählen.`;
l.ToolSelect.Main.NoDKIM        = `Wir konnten in der Liste der Standardauswahlen keinen DKIM-Datensatz finden, der mit Ihrer Domain verknüpft ist:`;
l.ToolSelect.Main.NoDKIM1       = `Wenn Sie die Auswahl kennen, dann können Sie hier nach dem DKIM-Datensatz scannen:`;
l.ToolSelect.Main.NoDKIM2       = `Oder wenn Sie eine E-Mail-Adresse angeben, dann senden wir Ihnen eine E-Mail an das angegebene Konto zu, auf die Sie anschließend antworten können – und wir scannen die E-Mail dann auf einen DKIM-Datensatz.`;
l.ToolSelect.Main.NoDKIMError   = `Diese E-Mail-Adresse ist nicht gültig, oder sie gehört nicht zur angegebenen Domain.`;
l.ToolSelect.Main.SelectorMsg   = `Scannen nach Auswahl!`;
l.ToolSelect.Main.SelectorError = `Auswahl nicht gefunden.`;
l.ToolSelect.Main.EmailMsg      = `Die E-Mail wurde gesendet! Bitte antworten Sie auf die E-Mail. Sobald die Antwort gesendet wurde, wird diese Seite aktualisiert werden und den Auswahlnamen nennen.`;
l.ToolSelect.Main.InputSelector = `Auswahl`;
l.ToolSelect.Main.InputEmail    = `User@example.com`;
l.ToolSelect.Main.Submit        = `Abschicken`;
l.ToolSelect.Main.Select        = `Wählen`;
l.ToolSelect.Main.Next          = `Weiter`;
l.ToolSelect.Main.Share         = `Teilen`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Sie haben die Einrichtung von DMARC abgeschlossen.`;
l.Complete.Main.check           = `Wollen Sie eine andere Domain auf SPF, DKIM oder DMARC überprüfen?`;
l.Complete.Main.InputDomain     = `Yourdomain.com`;
l.Complete.Main.Required        = `Die Adresse der Website ist erforderlich.`;
l.Complete.Main.valid           = `Die Website-Adresse ist nicht gültig.`;
l.Complete.Main.Next            = `Weiter`;
l.Complete.Main.More            = `Mehr über DMARC`;

l.SPF.Home.Title                = `Was ist SPF?`;
l.SPF.Home.SPF                  = `SPF steht für „Sender Policy Framework“. Diese Richtlinie sorgt dafür, dass die Absenderadresse nicht gefälscht werden kann, indem sie festlegt, welche Mailserver dazu berechtigt sind, E-Mails im Auftrag der Organisation zu versenden. Wenn der Mail-Server nicht in der SPF definiert ist, dann wird die zu sendende Nachricht abgelehnt oder zurückgeleitet.`;
l.SPF.Home.Start                = `Bitte klicken Sie auf die Schaltfläche „Weiter“, um den Vorgang zur Erstellung der SPF-Richtlinie zu starten.`;
l.SPF.Home.Next                 = `Weiter`;

l.SPF.Authorization.Title       = `Sind die unten aufgeführten Server berechtigt, E-Mails für die Domain der Organisation zu senden?`;
l.SPF.Authorization.First       = `Der erste Schritt der Richtlinienerstellung besteht darin, die Mailserver (MXes) der Domäne zu definieren, die für eingehende E-Mails zuständig sind und die zugleich dazu berechtigt sind, E-Mails im Namen dieser Domain zu versenden.`;
l.SPF.Authorization.Current     = `Die folgende Liste enthält die aktuellen DNS-Datensätze für die Mail-Server, die mit Ihrer Organisation verknüpft sind.`;
l.SPF.Authorization.Recommended = `Es wird dringend empfohlen, dass Ja ausgewählt wird, da die obige Liste die autorisierten Server für Ihre Domain enthalten sollte. Auf diese Weise wird das Tag mx zur SPF-Richtlinie hinzugefügt.`;
l.SPF.Authorization.Yes         = `Ja (empfohlen)`;
l.SPF.Authorization.No          = `Nein`;
l.SPF.Authorization.Missing     = `Wenn es Systeme gibt, die in dieser Liste fehlen, oder die keine Standard-Mailserver sind, dann sollten Sie diese Systeme beim nächsten Schritt hinzufügen.`;
l.SPF.Authorization.Next        = `Weiter`;

l.SPF.Servers.Title             = `Gibt es irgendwelche zusätzlichen Server innerhalb der Domain, die E-Mails für diese Domain versenden oder weiterleiten können?`;
l.SPF.Servers.Possible          = `Es besteht die Möglichkeit, dass nicht alle Mail-Server der Organisation auch in den DNS-Einträgen der Organisation definiert sind. Dies könnten z.B. Test-Server sein, oder auch Anwendungen mit integrierten Mail-Systemen. Wenn dies der Fall ist, dann fügen Sie bitte die zusätzlichen Server-Domains oder IP-Adressen in den folgenden Feldern hinzu.`;
l.SPF.Servers.Others            = `Diese Option sollte nur dann verwendet werden, wenn es außer den Mailservern auch noch andere interne Systeme gibt, die zum Versenden von E-Mails für die Domain berechtigt sind.`;
l.SPF.Servers.Added             = `Wenn Systeme hinzugefügt werden, dann wird dadurch ein Tag zur SPF-Richtlinie hinzugefügt.`;
l.SPF.Servers.InputField        = `Servername oder IP-Adresse`;
l.SPF.Servers.InputInvalid      = `Dies muss ein vollqualifizierter Domain-Name oder eine IP-Adresse sein.`;
l.SPF.Servers.Next              = `Weiter`;

l.SPF.Domains.Title             = `Gibt es irgendwelche externen Domains, die E-Mails für Ihre Organisation versenden oder weiterleiten können?`;
l.SPF.Domains.Include           = `In diesem Abschnitt wird das`;
l.SPF.Domains.Include2          = `„Einschließen“`;
l.SPF.Domains.Include3          = `-Tag der SPF-Richtlinie hinzugefügt werden.`;
l.SPF.Domains.Trusted           = `Diese Option sollte nur dann verwendet werden, wenn eine externe Domäne damit betraut wird, E-Mail-Nachrichten für Ihre Organisation zu versenden. Zum Beispiel das Kundenbeziehungs-Management-System, ein Cloud-Mail-Anbieter (Google, Office 365, Yahoo!) oder ein Cloud-Security-Anbieter.`;
l.SPF.Domains.Note              = `Bitte beachten Sie: Wenn Sie einen Cloud-Service-Anbieter verwenden, dann müssen Sie mit ihm zusammen den entsprechenden Wert für diese Einstellung festlegen.`;
l.SPF.Domains.InputField        = `Domain-Name oder IP-Adresse`;
l.SPF.Domains.InputInvalid      = `Dies muss ein vollqualifizierter Domain-Name oder eine IP-Adresse sein.`;
l.SPF.Domains.Next              = `Weiter`;

l.SPF.Strict.Title              = `Wie streng sollten sich die Server beim Umgang mit E-Mails an diese Richtlinie halten?`;
l.SPF.Strict.Receiving          = `Dies ist der letzte Schritt zur Einrichtung der SPF-Richtlinie, in dem festlegt wird, wie streng die E-Mail-empfangenden Server bei der Behandlung von E-Mails vorgehen sollten.`;
l.SPF.Strict.SoftFail           = `„Soft Fail“ (empfohlen)`;
l.SPF.Strict.Fail               = `„Fail“`;
l.SPF.Strict.Neutral            = `„Neutral“`;
l.SPF.Strict.AllowAll           = `„Allow All“`;
l.SPF.Strict.Next               = `Weiter`;
l.SPF.Strict.Options            = `Optionen:`;
l.SPF.Strict.FailEx             = `„Fail or“`;
l.SPF.Strict.FailEx2            = `Bedeutet, dass nur die Domain-Mail-Server (und die in den a- und include-Abschnitten) E-Mails für die Domain senden können. Alle anderen sind davon ausgeschlossen.`;
l.SPF.Strict.SoftEx             = `„Soft Fail or“`;
l.SPF.Strict.SoftEx2            = `Bedeutet, dass nur die Domain-Mail-Server (und die in den a- und include-Abschnitten) E-Mails für die Domain senden können, aber dies nur für den Übergang gilt. Alle anderen sind davon ausgeschlossen.`;
l.SPF.Strict.NeutralEx          = `„Neutral or“`;
l.SPF.Strict.NeutralEx2         = `Bedeutet ausdrücklich, dass nichts über die Gültigkeit ausgesagt werden kann.`;
l.SPF.Strict.AllowEx            = `„Allow All or“`;
l.SPF.Strict.AllowEx2           = `Bedeutet, dass jeder Host E-Mails für die Domain senden kann.`;
l.SPF.Strict.Warning1           = `Dies sollte`;
l.SPF.Strict.Warning2           = `niemals`;
l.SPF.Strict.Warning3           = `verwendet werden`;
l.SPF.Strict.Recommended        = `Es wird empfohlen, „Soft Fail“ zu verwenden.`;
l.SPF.Strict.MarkMail           = `Dies markiert die E-Mails als nicht konform, wenn sie nicht den festgelegten Kriterien entsprechen.`;

l.SPF.Results.Title             = `Unten ist der SPF-TXT-Eintrag, der auf Ihrem DNS-Server erstellt werden muss.`;
l.SPF.Results.Copy              = `Kopieren`;
l.SPF.Results.Copied            = `KOPIERT!`;
l.SPF.Results.DMARCGuide        = `Weiter zum DMARC-Handbuch`;
l.SPF.Results.DKIMGuide         = `Weiter zum DKIM-Handbuch`;
l.SPF.Results.OwnDNS            = `Wenn Sie Ihren eigenen DNS-Server haben, dann erstellen Sie bitte TXT-Einträge für die obige SPF-Richtlinie.`;
l.SPF.Results.ThirdParty        = `Wenn Sie einen Drittanbieter als DNS-Provider verwenden, dann folgen Sie bitte dessen Anweisungen zum Erstellen eines TXT-Eintrages.`;
l.SPF.Results.Reference         = `Bitte beachten Sie die`;
l.SPF.Results.ResourcesPage     = `Ressourcen-Seite`;
l.SPF.Results.Information       = `mit den Informationen zum Erstellen von TXT-Einträgen.`;
l.SPF.Results.NotUnderstood     = `Wenn Sie versucht haben, diese Anleitung zu befolgen, aber die einzelnen Schritte oder die verwendete Terminologie nicht verstanden haben, empfehlen wir Ihnen, dass Sie sich mit Ihrer IT-Support-Organisation, der Domänenregistrierungsstelle oder der GCA-Info in Verbindung setzen,`;
l.SPF.Results.Assistance        = `um weitere Hilfe zu erlangen.`;
l.SPF.Results.Implemented       = `Sobald der SPF-Eintrag implementiert wurde, verwenden Sie bitte die SPF-Validierung/Überprüfung weiter unten:`;
l.SPF.Results.YourDomain        = `Yourdomain.com`;
l.SPF.Results.Check             = `Prüfen`;
l.SPF.Results.SuccessDKIM       = `GEHEN SIE ZU DKIM`;
l.SPF.Results.SuccessDMARC      = `GEHEN SIE ZU DMARC`;
l.SPF.Results.NotQuite          = `Es klappt noch nicht so ganz.`;
l.SPF.Results.Wisdom            = `Das ist schon in Ordnung! Weisheit ist das letzte Ziel jeder Handlung. Bitte greifen Sie nach Belieben auf unsere Ressourcen zurück, die wir oben verlinkt haben – oder teilen Sie uns Ihr Problem mit. Wir können Ihnen dabei helfen, Ihre Implementierung praktisch umzusetzen. Dafür sind wir ja da.`;

l.DKIM.Home.Title               = `Was ist DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM steht für „DomainKeys Identified Mail“. DKIM ist ein Mechanismus, der zum Zwecke der Validierung der Identität einer Domäne konzipiert ist, die mit einer Mail-Nachricht verknüpft ist. Dies geschieht durch die Verwendung einer Authentifizierung, die asymmetrische Kryptographie verwendet. Im Prinzip authentifiziert DKIM eine Mail-Nachricht dadurch, dass dem Nachrichtenkopf eine digitale Signatur hinzugefügt wird. Es ist wichtig zu beachten, dass DKIM keine Mail-Nachrichten filtert. Es lässt die Option offen, SPAM-Filter einzusetzen, um die Echtheit der gesendeten Mail-Nachricht zu überprüfen.`;
l.DKIM.Home.ClickNext           = `Bitte klicken Sie auf die Schaltfläche „Weiter“, um den Vorgang der Erstellung der DKIM-Richtlinie zu starten.`;
l.DKIM.Home.YourDomain          = `Yourdomain.com`;
l.DKIM.Home.Next                = `Weiter`;

l.DKIM.Selector.Title           = `Auswahl`;
l.DKIM.Selector.Name            = `Die Auswahl definiert den Namen des DKIM-Datensatzes. Der Name der Auswahl kann beliebig gewählt werden, aber unten sind ein paar Optionen zur Auswahl aufgeführt.`;
l.DKIM.Selector.Define          = `Damit wird der Name des für den DKIM-Schlüssel erstellten TXT-Eintrages festgelegt. Dies wird wie folgt aussehen:`;
l.DKIM.Selector.Example1        = `Auswahl`;
l.DKIM.Selector.Example2        = `Domain-Name`;
l.DKIM.Selector.Next            = `Weiter`;

l.DKIM.Key.Title                = `Öffentlicher Schlüssel`;
l.DKIM.Key.Tag                  = `Dies wird das 'p' -Tag hinzufügen. Das ist die Schlüsselzeichenfolge in der öffentlichen Schlüsseldatei, die zuvor erzeugt wurde.`;
l.DKIM.Key.Keys                 = `Platzieren Sie die öffentlichen Schlüssel hier.`;
l.DKIM.Key.Next                 = `Weiter`;
l.DKIM.Key.Creating             = `Erstellen der DKIM-Schlüssel:`;
l.DKIM.Key.TwoParts             = `DKIM hat zwei Bestandteile, nämlich den privaten Schlüssel und den öffentlichen Schlüssel. Das Erstellen der Schlüssel für DKIM hängt davon ab, wer E-Mail-Dienste für Ihre Organisation bereitstellt.`;
l.DKIM.Key.External             = `Wenn Sie einen externen Dienst für E-Mails und DNS verwenden, dann muss der Mail-Provider Ihnen den öffentlichen Schlüssel zur Verfügung stellen. Dieser öffentliche Schlüssel wird dann als TXT-Datensatz in DNS veröffentlicht. Die meisten DNS-Anbieter begrenzen die Größe des TXT-Eintrages auf 255 Zeichen, so dass Sie möglicherweise mit dem Provider an einer individuellen Lösung arbeiten müssen, um die Größe zu erhöhen oder den TXT-Datensatz überhaupt erst zu erstellen. Der private Schlüssel wird vom E-Mail-Anbieter verwahrt und in der Regel nicht der Organisation zur Verfügung gestellt.`;
l.DKIM.Key.Internal             = `Wenn die Organisation einen eigenen E-Mail-Server unterhält, dann müssen die DKIM-Schlüssel vom IT-Personal (oder dem für die DKIM-Implementierung zuständigen Mitarbeiter) generiert werden. Es gibt im Internet mehrere DKIM-Schlüsselgeneratoren. Wenn Sie sich für einen Anbieter entscheiden müssen, dann stellen sie dabei zuerst sicher, dass Sie auch die Möglichkeit haben, den Schlüssel zu löschen, nachdem er generiert und in die entsprechenden Dateien auf Ihrem System kopiert wurde.`;
l.DKIM.Key.OpenDKIM             = `Um die Schlüssel ohne die Hilfe von Drittanbietern zu erstellen, wurde ein Open-Source-Projekt namens opendkim`;
l.DKIM.Key.OpenDKIM2            = `ins Leben gerufen. Opendkim enthält verschiedene Werkzeuge, die beim Erstellen des DKIM-Schlüssels sehr hilfreich sind.`;
l.DKIM.Key.OpenSSL              = `Eine weitere Möglichkeit besteht darin, OpenSSL zu verwenden, um die DKIM-Schlüssel zu erzeugen. Dies kann mit der aktuellsten Version von OpenSSL erfolgen, wobei man den folgenden Befehl für die Erzeugung eines privaten Schlüssels eingeben muss:`;
l.DKIM.Key.OpenSSL2             = `Dies erzeugt eine private Schlüsseldatei namens`;
l.DKIM.Key.OpenSSL3             = `Geben Sie anschließend den folgenden Befehl ein, um den öffentlichen Schlüssel zu generieren:`;
l.DKIM.Key.OpenSSL4             = `Dieser Befehl generiert den öffentlichen Schlüssel, und zwar`;
l.DKIM.Key.OpenSSL5             = `basierend auf dem zuvor erstellten privaten Schlüssel.`;
l.DKIM.Key.OpenSSL6             = `Verschieben Sie den privaten DKIM-Schlüssel an den Ort, der bei der DKIM-Installation angegeben wurde. Stellen Sie sicher, dass er sich in einem Ordner mit eingeschränkten Zugriffsrechten befindet.`;

l.DKIM.Results.Title            = `Unten ist der DKIM-TXT-Eintrag, der auf Ihrem DNS-Server erstellt werden muss.`;
l.DKIM.Results.Copy             = `Kopieren`;
l.DKIM.Results.Copied           = `KOPIERT!`;
l.DKIM.Results.SPFGuide         = `Rückkehr zum SPF-Handbuch`;
l.DKIM.Results.DMARCGuide       = `Weiter zum DMARC-Handbuch`;
l.DKIM.Results.OwnDNS           = `Wenn Sie Ihren eigenen DNS-Server haben, dann erstellen Sie bitte TXT-Einträge für die oben genannte DKIM-Richtlinie.`;
l.DKIM.Results.ThirdParty       = `Wenn Sie einen Drittanbieter als DNS-Provider verwenden, dann folgen Sie bitte dessen Anweisungen zum Erstellen eines TXT-Eintrages.`;
l.DKIM.Results.Reference        = `Bitte beachten Sie die`;
l.DKIM.Results.ResourcesPage    = `Ressourcen-Seite`;
l.DKIM.Results.Information      = `mit weiteren Informationen zum Erstellen von TXT-Einträgen.`;
l.DKIM.Results.NotUnderstood    = `Wenn Sie versucht haben, diese Anleitung zu befolgen, aber die einzelnen Schritte oder die verwendete Terminologie nicht verstanden haben, empfehlen wir Ihnen, dass Sie sich mit Ihrer IT-Support-Organisation, der Domänenregistrierungsstelle oder der GCA-Info in Verbindung setzen,`;
l.DKIM.Results.Assistance       = `um weitere Hilfe zu erlangen.`;
l.DKIM.Results.Note             = `Bitte beachten Sie:`;
l.DKIM.Results.Support          = `DKIM-Schlüssel können nun auch 2048-Bit-Schlüssel unterstützen. DNS-TXT-Einträge können jedoch keine Werte mit mehr als 255 Zeichen unterstützen. Sie können dies auf zwei Arten umgehen:`;
l.DKIM.Results.Support2         = `Mit einem 1024-Bit-Schlüssel, oder`;
l.DKIM.Results.Support3         = `Durch das Erstellen eines mehrzeiligen TXT-Eintrages.`;
l.DKIM.Results.Support4         = `Ein Beispiel eines mehrzeiligen Eintrages finden Sie weiter unten (die Anführungszeichen werden hinzugefügt, um den String in mehrere Zeilen aufzuteilen):`;

l.DMARC.Home.Title              = `Was ist DMARC?`;
l.DMARC.Home.Final              = `Nun, da die SPF- und DKIM-Richtlinien erstellt und umgesetzt wurden, können wir den letzten Schritt abschließen, um eine vollständige DMARC-Richtlinie zu erstellen.`;
l.DMARC.Home.Protocol           = `DMARC („Domain-based Message Authentication, Reporting & Conformance“) ist ein E-Mail-Authentifizierungsprotokoll, das eine Berichterstattungsfunktion enthält, die es Absendern und Empfängern ermöglicht, den Schutz der Domain vor betrügerischen E-Mails zu verbessern und zu überwachen. Die Implementierung von DMARC hindert Spammer daran, die Absender-Adresse bei E-Mail-Nachrichten zu fälschen (das sog. „Spoofing“). Abhängig von den DMARC-Richtlinieneinstellungen werden alle E-Mails, die von einem nicht autorisierten Mailserver stammen, entweder unter Quarantäne gestellt oder abgewiesen. Alle Spam- und Phishing-Nachrichten, die den Domainnamen einer Organisation verwenden, werden unter Quarantäne gestellt oder gelöscht, bevor sie ihr Ziel (den Endbenutzer) erreichen. Die erzeugten Berichte können für Nachforschungen, zur Verbesserung der Software oder auch für Strafverfolgungszwecke verwendet werden, wenn die vorgenommenen Handlungen krimineller Natur sind.`;
l.DMARC.Home.Stronger           = `DMARC bringt SPF und DKIM zusammen, um eine stärkere E-Mail-Authentifizierung für die Domain Ihrer Organisation zu generieren.`;
l.DMARC.Home.ClickNext          = `Bitte klicken Sie auf die Schaltfläche „Weiter“, um den Vorgang der Erstellung der DMARC-Richtlinie zu starten.`;
l.DMARC.Home.YourDomain         = `Yourdomain.com`;
l.DMARC.Home.Next               = `Weiter`;

l.DMARC.Policy.Title            = `Was sollte die Richtlinienebene für DMARC sein?`;
l.DMARC.Policy.Levels           = `Es gibt drei Richtlinienebenen, die für DMARC eingestellt werden können. Diese sind:`;
l.DMARC.Policy.NoneEx           = `Keine: Erstellt Berichte über möglicherweise verdächtige E-Mails, aber alle E-Mails werden trotzdem an den Posteingang der Endbenutzer gesendet.`;
l.DMARC.Policy.QuarantineEx     = `Quarantäne: Entspricht der „Fail“-SPF-/DKIM-Richtlinie – die verdächtige Nachricht wird in den Spam-/Junk-Ordner weitergeleitet.`;
l.DMARC.Policy.RejectEx         = `Ablehnen: Entspricht der „Fail“-SPF-/DKIM-Richtlinie – die verdächtige Nachricht wird hierbei aber gelöscht und überhaupt nirgends gespeichert.`;
l.DMARC.Policy.None             = `Keine (empfohlen)`;
l.DMARC.Policy.Quarantine       = `Quarantäne`;
l.DMARC.Policy.Reject           = `Ablehnen`;
l.DMARC.Policy.Next             = `Weiter`;
l.DMARC.Policy.Recommended1     = `Es wird`;
l.DMARC.Policy.Recommended2     = `dringendst empfohlen`;
l.DMARC.Policy.Recommended3     = `mit „Kein“ zu beginnen, da einem dies die Zeit verschafft, um die erzeugten Berichte zu lesen und festzustellen, ob legitime E-Mails gesperrt wurden, bevor die DMARC-Richtlinie auf „Quarantäne“ oder „Ablehnen“ umgestellt wird.`;
l.DMARC.Policy.Tag              = `Dies wird das Tag`;
l.DMARC.Policy.Tag2             = `zum DMARC-Eintrag hinzufügen.`;

l.DMARC.Aggregates.Title        = `Wohin sollen die gesammelten Berichte gesendet werden?`;
l.DMARC.Aggregates.Opt          = `Dies ist optional, es wird aber dringend empfohlen und bewirkt das Hinzufügen`;
l.DMARC.Aggregates.Opt2         = `des „rua“-Tags zur Richtlinie. Es wird`;
l.DMARC.Aggregates.Opt3         = `dringend empfohlen,`;
l.DMARC.Aggregates.Opt4         = `dass dies der Richtlinie für Analyse- und Überwachungszwecke hinzugefügt wird. Die Berichte können an mehrere Adressen gesendet werden.`;
l.DMARC.Aggregates.InputField   = `E-Mail-Adresse`;
l.DMARC.Aggregates.InputInvalid = `Bitte geben Sie eine gültige E-Mail-Adresse ein.`;
l.DMARC.Aggregates.Next         = `Weiter`;
l.DMARC.Aggregates.Reports      = `Die gesammelten Berichte (im XML-Format) werden mit Informationen darüber generiert werden, welche Mail-Nachrichten die Prüfung mit SPF und DKIM überstehen bzw. daran scheitern. Dies bietet für Ihre Organisation die Möglichkeit, potentielle Authentifizierungsprobleme und/oder Spamaktivitäten zu erkennen.`;
l.DMARC.Aggregates.Contains     = `Die Berichte enthalten folgendes:`;
l.DMARC.Aggregates.HowToRead    = `So lesen Sie Ihre ersten DMARC-Berichte`;
l.DMARC.Aggregates.By           = `(Teil 1) von Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `ISP-Informationen`;
l.DMARC.Aggregates.Desc         = `Zeilenweise Beschreibung des DMARC-Eintrages Ihrer Organisation`;
l.DMARC.Aggregates.Summary      = `Zusammenfassung der Authentifizierungsergebnisse`;
l.DMARC.Aggregates.Important    = `Es ist wichtig zu beachten, dass diese Berichte nicht aus nur einer Quelle stammen. Sie können unter Umständen zwischen zehn und hundert (möglicherweise auch Tausend) Berichte täglich erhalten (die Anzahl der geschriebenen Berichte ist von der Anzahl der gesendeten E-Mails abhängig).`;
l.DMARC.Aggregates.Difficult    = `Dies wird es schwierig machen, jeden Bericht manuell zu überprüfen, zumal sie im XML-Format vorliegen und einige Berichte ziemlich lang sein können.`;
l.DMARC.Aggregates.Readable     = `Diese Berichte können für Menschen lesbar gemacht werden, indem sie einen XML-Konverter entwickeln oder mit einem kommerziellen Anbieter zusammenarbeiten. Die meisten kommerziellen Anbieter werden ein Backend-Repository bieten, welches die Berichte menschlich lesbar macht, sowie verschiedene Stufen von Analyse- und Beratungsmodellen.`;
l.DMARC.Aggregates.Vendors      = `DMARC-Anbieter (GCA-Partner):`;

l.DMARC.Forensics.Title         = `Wohin sollen forensische Berichte gesendet werden?`;
l.DMARC.Forensics.Opt           = `Dies ist optional und wird das`;
l.DMARC.Forensics.Opt2          = `„ruf“-Tag zur Richtlinie hinzufügen. Diese XML-Berichte werden nur generiert, wenn die empfangende Seite diese Arten von Berichten generiert. Es besteht die Möglichkeit, dass Sie überhaupt keine forensischen Berichte erhalten. Dies kann aufgrund der Datenschutz- und Datennutzungsregeln in bestimmten Ländern der Fall sein.`;
l.DMARC.Forensics.InputField    = `E-Mail-Adresse`;
l.DMARC.Forensics.InputInvalid  = `Bitte geben Sie eine gültige E-Mail-Adresse ein.`;
l.DMARC.Forensics.Next          = `Weiter`;

l.DMARC.Subdomains.Title        = `DMARC-Politik für Subdomains?`;
l.DMARC.Subdomains.Define       = `Dies wird das Richtlinien-Niveau für Subdomains der Domäne Ihrer Organisation definieren. Wenn dieser Tag`;
l.DMARC.Subdomains.Define2      = `nicht vorhanden ist, dann werden diese Richtlinien der Richtlinie für die Top-Level-Domain folgen, die im ersten Schritt definiert wurde.`;
l.DMARC.Subdomains.None         = `Keiner`;
l.DMARC.Subdomains.Quarantine   = `Quarantäne`;
l.DMARC.Subdomains.Reject       = `Ablehnen`;
l.DMARC.Subdomains.Next         = `Weiter`;
l.DMARC.Subdomains.Policy       = `Richtlinie für die Domäne. Die folgenden Optionen sind verfügbar:`;
l.DMARC.Subdomains.NoneEx       = `Keine: Keine Maßnahmen ergreifen, aber den Vorgang im DMARC-Bericht aufzeichnen.`;
l.DMARC.Subdomains.QuarantineEx = `Quarantäne: Die verdächtige Mail wird als Spam markiert.`;
l.DMARC.Subdomains.RejectEx     = `Ablehnen: Die verdächtige Nachricht wird gelöscht.`;
l.DMARC.Subdomains.Recommended  = `Es wird empfohlen, diese Einstellung auf „Keine“ zu setzen, wenn Sie mehrere Subdomains haben. Es empfiehlt sich, für jede Subdomain separate DMARC-Richtlinien einzurichten, bis alle Subdomains mit der DMARC-Richtlinie übereinstimmen. Wenn jedoch keine Subdomains vorhanden sind, empfiehlt es sich, „Ablehnen“ zu verwenden.`;

l.DMARC.Options.Title           = `(Optional) Die restlichen Optionen sind nicht erforderlich, können aber ggf. angepasst werden:`;
l.DMARC.Options.ReportGen       = `1. Wann sollte der Fehlerbericht generiert werden`;
l.DMARC.Options.Default         = `Standard`;
l.DMARC.Options.ReportPol       = `Definiert die Richtlinie zur Fehlerberichterstattung.`;
l.DMARC.Options.DefaultsTo      = `Wenn nicht definiert, wird standardmäßig die Option der`;
l.DMARC.Options.AllChecks       = `Generierung eines Berichtes an den sendenden MTA gewählt, wenn mindestens eine der zugrunde liegenden Prüfungen fehlgeschlagen ist.`;
l.DMARC.Options.AnyChecks       = `Generierung eines Berichtes an den sendenden MTA gewählt, wenn mindestens eine der zugrunde liegenden Prüfungen fehlgeschlagen ist.`;
l.DMARC.Options.OtherValues     = `Andere Werte sind:`;
l.DMARC.Options.DKIMFails       = `Erstellung eines Berichtes, wenn die DKIM-Prüfung fehlschlägt.`;
l.DMARC.Options.SPFFails        = `Erstellung eines Berichtes, wenn die SPF-Prüfung fehlschlägt.`;
l.DMARC.Options.Relaxed         = `Entspannt`;
l.DMARC.Options.Strict          = `Streng`;
l.DMARC.Options.DKIMAlign       = `2. Ausrichtungsmodus für die DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Ausrichtungsmodus für die DKIM. Als Optionen sind verfügbar:`;
l.DMARC.Options.DKIMRelaxed     = `(Entspannt) Dies ist der Standard, wenn nichts anderes definiert wird. Ermöglicht jede im DKIM-Header definierte Subdomain.`;
l.DMARC.Options.DKIMStrict      = `(Streng) Der Domain-Name des Absenders muss mit der Domäne im DKIM-Header genau übereinstimmen.`;
l.DMARC.Options.SPFAlign        = `3. Ausrichtungsmodus für SPF`;
l.DMARC.Options.SPFAlignOpt     = `Ausrichtungsmodus für SPF. Als Optionen sind verfügbar:`;
l.DMARC.Options.SPFRelaxed      = `(Entspannt) Dies ist der Standard, wenn nichts anderes definiert wird. Erlaubt jede Subdomain.`;
l.DMARC.Options.SPFStrict       = `(Streng) Der Organisationsdomänenname im „MAIL FROM“-Befehl (in SMTP) und der „From:“-Header (in der E-Mail) müssen genau übereinstimmen.`;
l.DMARC.Options.Percentage      = `4. Prozentsatz der Nachrichten, die nach der DMARC-Richtlinie gefiltert werden sollen?`;
l.DMARC.Options.Filter          = `Der Prozentsatz der Nachrichten, die nach der DMARC-Richtlinie gefiltert werden sollen. Dies kann eine beliebige Zahl von 1 bis 100 sein. Der Standardwert ist 100, was alle Nachrichten bedeutet.`;
l.DMARC.Options.Formats         = `5. Format der gemeldeten Berichte`;
l.DMARC.Options.FormatsEx       = `Definiert das Format der erzeugten Berichte. Mögliche Formatwerte sind:`;
l.DMARC.Options.RFC             = `Der Standardwert, wenn nichts anderes definiert wurde. Das Format wird durch RFC-5965 definiert.`;
l.DMARC.Options.RFC2            = `Das Format wird durch RFC-5070 definiert.`;
l.DMARC.Options.ReportInt       = `6. Berichtsintervall (in Sekunden)`;
l.DMARC.Options.SecondsDefault  = `Sekunden (Standardeinstellung)`;
l.DMARC.Options.DigitsOnly      = `Bitte geben Sie nur Ziffern ein.`;
l.DMARC.Options.ReportIntEx     = `Definiert die Berichtsintervalle in Sekunden. Wenn nichts anderes definiert, dann ist die Voreinstellung 86.400 Sekunden, oder 24 Stunden. Bitte beachten Sie, dass nicht garantiert werden kann, dass Berichte durch die empfangenden MTA versendet werden. Die Berichte werden auf einer bestmöglichen Basis gesendet.`;
l.DMARC.Options.HToS            = `„Stunden zu Sekunden“-Konverter`;
l.DMARC.Options.Hours           = `24 Stunden`;
l.DMARC.Options.To              = `zu`;
l.DMARC.Options.Seconds         = `86.400 Sekunden`;
l.DMARC.Options.Finish          = `Fertig`;

l.DMARC.Results.Title           = `Unten ist der DMARC-TXT-Eintrag, der auf Ihrem DNS-Server erstellt werden muss.`;
l.DMARC.Results.Display         = `Anzeige des DMARC-TXT-Eintrages – Ergebnis`;
l.DMARC.Results.Copy            = `Kopieren`;
l.DMARC.Results.Copied          = `KOPIERT!`;
l.DMARC.Results.SPFGuide        = `Rückkehr zum SPF-Handbuch`;
l.DMARC.Results.DKIMGuide       = `Rückkehr zum DKIM-Handbuch`;
l.DMARC.Results.OwnDNS          = `Wenn Sie einen eigenen DNS-Server haben, dann erstellen Sie bitte TXT-Datensätze für die nachfolgende DMARC-Richtlinie.`;
l.DMARC.Results.ThirdParty      = `Wenn Sie einen Drittanbieter als DNS-Provider verwenden, dann folgen Sie bitte dessen Anweisungen zum Erstellen eines TXT-Eintrages. In den meisten Fällen benötigen sie nur den Hostnamen, _dmarc.<domainname> und den Textwert, der die zwischen den Anführungszeichen gefundenen Informationen enthält.`;
l.DMARC.Results.Reference       = `Bitte beachten Sie die`;
l.DMARC.Results.ResourcesPage   = `Ressourcen-Seite`;
l.DMARC.Results.Information     = `mit weiteren Informationen zum Erstellen von TXT-Datensätzen.`;
l.DMARC.Results.NotUnderstood   = `Wenn Sie versucht haben, diese Anleitung zu befolgen, aber die einzelnen Schritte oder die verwendete Terminologie nicht verstanden haben, empfehlen wir Ihnen, dass Sie sich mit Ihrer IT-Support-Organisation, der Domänenregistrierungsstelle oder der GCA-Info in Verbindung setzen,`;
l.DMARC.Results.Assistance      = `um weitere Hilfe zu erlangen.`;
l.DMARC.Results.Complete        = `Komplett`;
l.DMARC.Results.Implemented     = `Sobald der DMARC-Eintrag implementiert wurde, verwenden Sie bitte die DMARC-Validierung/Überprüfung weiter unten:`;
l.DMARC.Results.YourDomain      = `Yourdomain.com`;
l.DMARC.Results.NotQuite        = `Es klappt noch nicht so ganz.`;
l.DMARC.Results.Wisdom          = `Das ist schon in Ordnung! Weisheit ist das letzte Ziel jeder Handlung. Bitte greifen Sie nach Belieben auf unsere Ressourcen zurück, die wir oben verlinkt haben – oder teilen Sie uns Ihr Problem mit. Wir können Ihnen dabei helfen, Ihre Implementierung praktisch umzusetzen. Dafür sind wir ja da.`;
l.DMARC.Results.Check           = `Prüfen`;
l.DMARC.Results.Congratulations = `Herzlichen Glückwunsch!`;

export default l;
