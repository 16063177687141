import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepWizard from '../../Components/WizardWrapper'
import { WizardNav } from '../../Components/WizardNav'
import './SPF.scss'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { Step6 } from './Step6'
import { Server, Domain } from '../Models'

export const SPFGuide = (props: any) => {

  const[servers, setServers] = useState([
    new Server(),
  ])
  
  const[domains, setDomains] = useState([
    new Domain(),
  ])

  const[domain, setDomain] = useState("")
  const[authorized, setAuthorized] = useState(true)
  const[strictOption, setStrictOption] = useState( '~all')

  const addServer = () => {
    let servers_array = [...servers]
    servers_array.push(new Server())
    setServers(servers_array) 
  }

  const addDomain = () => {
    let domains_array = [...domains]
    domains_array.push(new Domain())
    setDomains(domains_array) 
  }

  const handleSetDomain = (newDomain: string) => {
    setDomain(newDomain)
  }

  const handleSetAuthorized = (newAuthorization: boolean) =>{
    setAuthorized(newAuthorization)
  }

  const removeServer = (server: Server ) => {
    let serverArray = [...servers]
    const index = serverArray.findIndex((el) => el.id === server.id);
    if (index !== -1) {
      serverArray.splice(index, 1);
    }
    setServers(serverArray);
  }

  const removeDomain = (domain: Domain ) => {
    let domainArray = [...domains]
    const index = domainArray.findIndex((el) => el.id === domain.id);
    if (index !== -1) {
      domainArray.splice(index, 1);
    }
    setDomains(domainArray);
  }

  const handleSetStrictOption = (strictOption:string) => {
    setStrictOption(strictOption)
  }

  const handleStartOver = () =>{
    setDomain('');
    setDomains([   
      new Domain(),
    ])
    setServers([   
      new Server(),
    ])
    setAuthorized(true)
    setStrictOption( '~all')

  }

  const getRecord = () => {
    let i
    let rec = 'v=spf1 '

    if (authorized) {
      rec += 'mx '
    }

    for (i = 0; i < servers.length; i++) {
      rec += 'a:' + servers[i].server + ' '
    }

    for (i = 0; i < domains.length; i++) {
      rec += 'include:' + domains[i].domain + ' '
    }
    rec += strictOption

    return rec
  }

  const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
  }

  const { t } = useTranslation()

  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
      style={{}}
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='wizard page-wrapper' data-section-bg-repeat='stretch'>
            <h1
              style={{
                textAlign: 'center',
                marginBottom: '30px',
                fontWeight: 'bold',
              }}
            >{t('SPF.Home.Title')}</h1>
            <StepWizard
              nav={<WizardNav />}
              isHashEnabled
              transitions={transitions}
            >
              <Step1 domain={domain} handleSetDomain={handleSetDomain} />
              <Step2 authorized={ authorized } handleSetAuthorized={handleSetAuthorized} />
              <Step3 servers={servers} addServer={addServer} removeServer={removeServer} />
              <Step4 domains={ domains } addDomain={addDomain} removeDomain={removeDomain} />
              <Step5 strictOption={strictOption} handleSetStrictOption={handleSetStrictOption} />
              <Step6 domain={domain} getRecord={getRecord} handleStartOver={handleStartOver} />
            </StepWizard>
          </div>
        </div>
      </div>
    </div>
  )
}