import React from 'react'
import { HeaderLeaderboard } from './styles'
import { Wizard } from './Wizard/Wizard'

export const HomePage = (props:any) => {
  return (
    <>
      <HeaderLeaderboard/>
      <Wizard/>
    </>
  )
}