let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC指南`;

l.Header.Main.SetupDMARC        = `设置DMARC`;
l.Header.Main.HowItWorks        = `运行方式`;
l.Header.Main.AboutDMARC        = `关于DMARC`;
l.Header.Main.Resources         = `资源`;
l.Header.Main.Contact           = `联系方式`;

l.Footer.Main.SetupDMARC        = `设置DMARC`;
l.Footer.Main.HowItWorks        = `运行方式`;
l.Footer.Main.AboutDMARC        = `关于DMARC`;
l.Footer.Main.Resources         = `资源`;
l.Footer.Main.Contact           = `联系方式`;
l.Footer.Main.Copyright         = `版权 ¬¨¬© 2016 全球网络联盟`;
l.Footer.Main.PrivacyPolicy     = `隐私政策`;
l.Footer.Main.Sitemap           = `站点地图`;

l.Home.Main.EnterDomain         = `输入您的域名`;
l.Home.Main.ToStart             = `开始DMARC设置`;
l.Home.Main.TagLine             = `做点什么。衡量一下。`;
l.Home.Main.Welcome             = `欢迎使用DMARC设置指南。本设置指南的目的，是在创建DMARC政策以及发送方策略框架（SPF）和域名秘钥识别邮件（DKIM）政策的过程中为您的机构提供指导。通过执行所有三项政策，您的机构将会拥有更加强大的邮件验证机制，从而帮助保护您的品牌。`;
l.Home.Main.FirstStep           = `第一步是确认您的机构是否采用这三项政策中的任何一项。`;
l.Home.Main.InputWarning        = `请根据您机构的电子邮件地址输入域名，应与电子邮件中符号@后的域名完全一致。`;
l.Home.Main.InputExample        = `以下列电子邮件地址为例：info@globalcyberalliance.org，需在文本框中输入的域名为 globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `网站地址必填`;
l.Home.Main.valid               = `网站地址无效`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google Apps和Office 365用户：`;
l.Home.Main.UserWarningText1    = `请按照资源页面为`;
l.Home.Main.UserWarningLink     = `云服务供应商提供的信息`;
l.Home.Main.UserWarningText2    = `执行SPF和DKIM。执行完毕后，请使用本指南继续执行DMARC政策。`;
l.Home.Main.Submit              = `提交`;
l.Home.Main.MoreInfo            = `如果您想获取有关DMARC的更多信息，我们邀请您参加我们的月度DMARC网络研讨会其中一次会议。本网络研讨会将谈及DMARC基础知识，简要概述GCA DMARC设置指南。`;
l.Home.Main.Webinar             = `月度GCA DMARC网络研讨会每月的第三个星期三举办两次。请点击下列相应链接，选取最适合您的日期和时间进行注册：`;
l.Home.Main.SelectDate          = `选择此日期`;

l.ToolSelect.Main.Title         = `结果`;
l.ToolSelect.Main.ResultStatus  = `下表说明哪项协议已经 <i class='fas fa-check-circle' style='color: green'></i> 或尚未 <i class='fas fa-times-circle' style='color: red'></i> 执行。`;
l.ToolSelect.Main.Protocol      = `请选择其中一项协议，开始设置指南。`;
l.ToolSelect.Main.ShareButton   = `如果您想分享结果，点击分享按钮即可，链接将会复制到剪贴板。`;
l.ToolSelect.Main.Selecting     = `选择协议还会显示当前记录（如有）。`;
l.ToolSelect.Main.DKIMWarning   = `DKIM检查部分仍在开发之中。然而，DKIM设置指南仍可通过选择DKIM行予以使用。`;
l.ToolSelect.Main.NoDKIM        = `给出默认选择器列表，我们无法找到与您的域名关联的DKIM记录。`;
l.ToolSelect.Main.NoDKIM1       = `如果您知道选择器，可在此扫描查找DKIM记录：`;
l.ToolSelect.Main.NoDKIM2       = `或者，如果您提供电子邮件地址，我们将向您的账户发送一份电子邮件，您可回复邮件，我们即会扫描邮件，查找DKIM记录。`;
l.ToolSelect.Main.NoDKIMError   = `电子邮件无效，或不属于所提供的域名。`;
l.ToolSelect.Main.SelectorMsg   = `扫描查找选择器！`;
l.ToolSelect.Main.SelectorError = `选择器未找到。`;
l.ToolSelect.Main.EmailMsg      = `电子邮件已发送！请回复电子邮件。回复发送之后，此页面即会刷新，并提供选择器名称。`;
l.ToolSelect.Main.InputSelector = `选择器`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `提交`;
l.ToolSelect.Main.Select        = `选择`;
l.ToolSelect.Main.Next          = `下一步`;
l.ToolSelect.Main.Share         = `分享`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `您已完成设置DMARC`;
l.Complete.Main.check           = `检查另一域名，查找SPF、DKIM或DMARC？`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `网站地址必填`;
l.Complete.Main.valid           = `网站地址无效`;
l.Complete.Main.Next            = `下一步`;
l.Complete.Main.More            = `有关DMARC的更多信息`;

l.SPF.Home.Title                = `什么是SPF？`;
l.SPF.Home.SPF                  = `SPF表示发送方策略框架。这项政策通过定义授权采用机构Äö√Ñ√¥s 域名发送邮件的邮件服务器，负责防止发送方地址被人假冒。如果邮件服务器未在SPF定义，那么信息则会被拒绝或退回。`;
l.SPF.Home.Start                = `请点击下一步按钮，开始SPF政策创建方法的流程。`;
l.SPF.Home.Next                 = `下一步`;

l.SPF.Authorization.Title       = `下列服务器获得授权采用机构域名发送电子邮件吗？`;
l.SPF.Authorization.First       = `政策的第一部分旨在定义获得授权用此域名发送邮件的域名收件服务器（MXes）。`;
l.SPF.Authorization.Current     = `下表含有与您的机构关联的邮件服务器当前的DNS记录。`;
l.SPF.Authorization.Recommended = `强烈建议选择Yes，因为上表应是您的域名的授权服务器。这样一来，mx标记将被加至SPF政策。`;
l.SPF.Authorization.Yes         = `是（推荐）`;
l.SPF.Authorization.No          = `否`;
l.SPF.Authorization.Missing     = `如果列表中有系统缺失，或非为标准邮件服务器，则将该等系统纳入下一选项。`;
l.SPF.Authorization.Next        = `下一步`;

l.SPF.Servers.Title             = `域中是否有任何其它服务器可为此域传送或转发邮件？`;
l.SPF.Servers.Possible          = `有可能并非所有机构邮件服务器都在机构Äö√Ñ√¥s DNS中定义。它们可能是测试服务器或内置邮件系统应用。如是这种情况，则请在下框中添加其它服务器域名或IP地址。 `;
l.SPF.Servers.Others            = `这仅应在有其它内部系统，而非邮件服务器，获得授权用该域名发送邮件的情况下才可使用。`;
l.SPF.Servers.Added             = `如果添加系统，则会将Äö√Ñ√≤a‚Äö√Ñ√¥标记加至SPF政策。`;
l.SPF.Servers.InputField        = `服务器名称或IP`;
l.SPF.Servers.InputInvalid      = `必须为完全合格的域名或IP地址`;
l.SPF.Servers.Next              = `下一步`;

l.SPF.Domains.Title             = `有任何外部域名可代您的机构传送或转发邮件吗？`;
l.SPF.Domains.Include           = `这一部分将添加`;
l.SPF.Domains.Include2          = `包括`;
l.SPF.Domains.Include3          = `SPF政策标记`;
l.SPF.Domains.Trusted           = `这仅应在外部域获得信任用您的机构Äö√Ñ√¥s域名发送邮件信息的情况下才可使用。例如，客户关系管理系统、云邮件提供商（Google、Office 365、Yahoo！)，或云安全提供商。`;
l.SPF.Domains.Note              = `请注意：如果您在使用云服务提供商，则必须与其合作，获取此设置适当的值。`;
l.SPF.Domains.InputField        = `域名或IP`;
l.SPF.Domains.InputInvalid      = `必须为完全合格的域名或IP地址`;
l.SPF.Domains.Next              = `下一步`;

l.SPF.Strict.Title              = `服务器处理电子邮件的严格程度应该怎样？`;
l.SPF.Strict.Receiving          = `这是SPF政策的最后一部分，将定义接收服务器处理电子邮件时的严格程度。`;
l.SPF.Strict.SoftFail           = `软失败（推荐）`;
l.SPF.Strict.Fail               = `失败`;
l.SPF.Strict.Neutral            = `中性`;
l.SPF.Strict.AllowAll           = `允许所有`;
l.SPF.Strict.Next               = `下一步`;
l.SPF.Strict.Options            = `选项：`;
l.SPF.Strict.FailEx             = `失败或`;
l.SPF.Strict.FailEx2            = `表示只有域名邮件服务器（以及a中的服务器，包括相关部分）允许用此域名发送邮件。所有其它服务器均被禁止。`;
l.SPF.Strict.SoftEx             = `软失败 或`;
l.SPF.Strict.SoftEx2            = `表示只有域名邮件服务器（以及a中的服务器，包括相关部分）允许用此域名发送邮件，但其处于过渡时期。所有其它服务器均被禁止。`;
l.SPF.Strict.NeutralEx          = `中性或`;
l.SPF.Strict.NeutralEx2         = `明确表示关于有效性不做任何陈述。`;
l.SPF.Strict.AllowEx            = `允许所有或`;
l.SPF.Strict.AllowEx2           = `表示任何主机都可用此域名发送邮件。`;
l.SPF.Strict.Warning1           = `这永远`;
l.SPF.Strict.Warning2           = `不应`;
l.SPF.Strict.Warning3           = `使用`;
l.SPF.Strict.Recommended        = `建议使用软失败`;
l.SPF.Strict.MarkMail           = `如其未满足定义标准，则会将邮件标记为不符合。`;

l.SPF.Results.Title             = `以下是必须在您的DNS服务器创建的SPF TXT记录。`;
l.SPF.Results.Copy              = `复制`;
l.SPF.Results.Copied            = `已复制！`;
l.SPF.Results.DMARCGuide        = `跳至DMARC指南`;
l.SPF.Results.DKIMGuide         = `继续至DKIM指南`;
l.SPF.Results.OwnDNS            = `如果您有自己的DNS服务器，则请为上述SPF政策创建TXT记录。`;
l.SPF.Results.ThirdParty        = `如果您在使用第三方DNS提供商，则请按其说明创建TXT记录。`;
l.SPF.Results.Reference         = `请参照`;
l.SPF.Results.ResourcesPage     = `资源页面`;
l.SPF.Results.Information       = `查阅有关如何创建TXT记录的信息。`;
l.SPF.Results.NotUnderstood     = `如果您已尝试据此操作，但尚不理解所要采取的步骤或使用的术语，建议您联系IT支持机构、域名注册商或GCA Info，`;
l.SPF.Results.Assistance        = `寻求进一步的帮助。`;
l.SPF.Results.Implemented       = `SPF记录执行后，请使用下列SPF验证/查验：`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `查验`;
l.SPF.Results.SuccessDKIM       = `前往DKIM`;
l.SPF.Results.SuccessDMARC      = `前往DMARC`;
l.SPF.Results.NotQuite          = `尚未全部完成。`;
l.SPF.Results.Wisdom            = `没问题！智慧是每次行动的最终目标。请尽管利用我们的上述资源或给我们留言，我们可以帮您执行。这就是我们在这里的目的。`;

l.DKIM.Home.Title               = `什么是DKIM？`;
l.DKIM.Home.Mechanism           = `DKIM表示域名秘钥识别邮件。DKIM是一种旨在验证与邮件信息关联的域名身份的机制，通过使用含有非对称密码的验证予以实现。一般来说，DKIM会在信息标头添加数字签名，以此验证邮件信息。必须注意，DKIM并不过滤邮件信息。它允许垃圾邮件过滤器决定所发送邮件信息的真伪。`;
l.DKIM.Home.ClickNext           = `请点击下一步按钮，开始DKIM政策创建方法的流程。`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `下一步`;

l.DKIM.Selector.Title           = `选择器`;
l.DKIM.Selector.Name            = `选择器将定义DKIM记录的名称。选择器名称随意，但可供选择的几个选项如下。`;
l.DKIM.Selector.Define          = `这将定义为DKIM秘钥创建的TXT记录的名称，它将显示如下：`;
l.DKIM.Selector.Example1        = `选择`;
l.DKIM.Selector.Example2        = `域名`;
l.DKIM.Selector.Next            = `下一步`;

l.DKIM.Key.Title                = `公开密钥`;
l.DKIM.Key.Tag                  = `这将添加‘p'标记，即之前生成的公开密钥文件中的秘钥字符串。`;
l.DKIM.Key.Keys                 = `将公开密钥放在这里`;
l.DKIM.Key.Next                 = `下一步`;
l.DKIM.Key.Creating             = `创建DKIM秘钥：`;
l.DKIM.Key.TwoParts             = `DKIM有两部分，私有秘钥和公开密钥。为DKIM创建密钥将因谁为您的机构提供电子邮件服务而有所不同。`;
l.DKIM.Key.External             = `如果您的邮件和DNS采用外部服务，那么，邮件提供商则需要为您提供公共秘钥。然后，该公共秘钥作为DNS中的TXT记录予以公布。大部分DNS提供商会将TXT记录的大小限制于255个字符，因此，您可能不得不与提供商合作，增加其大小或创建TXT记录。私有秘钥由邮件提供商持有，通常不提供给机构。`;
l.DKIM.Key.Internal             = `如果机构有其自己的电子邮件服务器，那么，DKIM秘钥则必须由IT员工（或负责执行DKIM的人员）生成。互联网上有多个DKIM秘钥生成器可供使用。如果您选择使用其中之一，只要确保您在其生成并复制到您的文件后可以选择删除秘钥即可。`;
l.DKIM.Key.OpenDKIM             = `若要不经第三方创建秘钥，有一个称为opendkim的开源项目`;
l.DKIM.Key.OpenDKIM2            = `可供使用。Opendkim包含各种不同的工具，用以帮助创建DKIM秘钥。`;
l.DKIM.Key.OpenSSL              = `另一个选项是使用OpenSSL生成DKIM秘钥。这可通过采用最新版本的OpenSSL、键入下列私有秘钥指令实现：`;
l.DKIM.Key.OpenSSL2             = `这将生成私有秘钥文件，称为`;
l.DKIM.Key.OpenSSL3             = `下一步，键入下列指令，生成公开密钥：`;
l.DKIM.Key.OpenSSL4             = `这个指令将生成公开密钥`;
l.DKIM.Key.OpenSSL5             = `以之前创建的私有秘钥为基础`;
l.DKIM.Key.OpenSSL6             = `将DKIM私有秘钥移至DKIM安装指定的位置。确保它在限制访问的文件夹中。`;

l.DKIM.Results.Title            = `下列为必须在您的DNS服务器上创建的DKIM TXT记录。`;
l.DKIM.Results.Copy             = `复制`;
l.DKIM.Results.Copied           = `已复制！`;
l.DKIM.Results.SPFGuide         = `返回SPF指南`;
l.DKIM.Results.DMARCGuide       = `继续使用DMARC指南`;
l.DKIM.Results.OwnDNS           = `如果您有自己的DNS服务器，则请为上述DKIM政策创建TXT记录。`;
l.DKIM.Results.ThirdParty       = `如果您在使用第三方DNS提供商，则请按其说明创建TXT记录。`;
l.DKIM.Results.Reference        = `请参照`;
l.DKIM.Results.ResourcesPage    = `资源页面`;
l.DKIM.Results.Information      = `查阅有关如何创建TXT记录的信息。`;
l.DKIM.Results.NotUnderstood    = `如果您已尝试据此操作，但尚不理解所要采取的步骤或使用的术语，建议您联系IT支持机构、域名注册商或GCA Info，`;
l.DKIM.Results.Assistance       = `寻求进一步的帮助。`;
l.DKIM.Results.Note             = `请注意：`;
l.DKIM.Results.Support          = `DKIM秘钥现可支持2048位秘钥。然而，DNS TXT记录无法支持超过255个字符的值。您可通过下列方式绕开：`;
l.DKIM.Results.Support2         = `采用1024位秘钥，或者`;
l.DKIM.Results.Support3         = `创建多行TXT记录`;
l.DKIM.Results.Support4         = `多行记录样例如下（添加引号，以将字符串断为多行）：`;

l.DMARC.Home.Title              = `什么是DMARC？`;
l.DMARC.Home.Final              = `既然SPF和DKIM政策已经创建并执行，我们现在可以继续最后一步，创建DMARC政策。`;
l.DMARC.Home.Protocol           = `DMARC（基于域的信息验证、报告及符合性）是一项电子邮件验证协议，包括允许发送人及接收人改进并监控域名保护以防欺诈邮件的报告功能。执行DMARC将会防止垃圾邮件制作者假冒邮件信息中的“发件人”地址。根据DMARC政策设置，任何源自未经授权的邮件服务器的邮件信息，都将被隔离或拒绝。所有采用机构域名的垃圾邮件和钓鱼信息，在其到达目的地（终端用户）之前都将被隔离或删除。所生成的报告可用于情报或执法之目的，如果相关活动本质上为犯罪行为。`;
l.DMARC.Home.Stronger           = `DMARC将SPF和DKIM结合在一起，为您机构的域名生成更强大的电子邮件验证机制。`;
l.DMARC.Home.ClickNext          = `请点击下一步按钮，开始DMARC政策创建方法的流程。`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `下一步`;

l.DMARC.Policy.Title            = `DMARC的政策层级应该怎样？`;
l.DMARC.Policy.Levels           = `有三个政策层级可为DMARC设定。它们是：`;
l.DMARC.Policy.NoneEx           = `无 - 报告潜在可疑的邮件信息，但所有邮件均发至收件箱`;
l.DMARC.Policy.QuarantineEx     = `隔离 - 未通过SPF/DKIM政策，信息则发至垃圾邮件/垃圾文件夹`;
l.DMARC.Policy.RejectEx         = `拒绝 - 未通过SPF/DKIM政策，信息则被删除，根本不传送`;
l.DMARC.Policy.None             = `无（推荐）`;
l.DMARC.Policy.Quarantine       = `隔离  `;
l.DMARC.Policy.Reject           = `拒绝  `;
l.DMARC.Policy.Next             = `下一步`;
l.DMARC.Policy.Recommended1     = `,"Based on the context, ""It is"" here is not necessary to translate."`;
l.DMARC.Policy.Recommended2     = `强力推荐`;
l.DMARC.Policy.Recommended3     = `以“无”开始，因为这将留有时间监控生成的报告，在将DMARC政策切换至隔离或拒绝之前，确定是否拦截合法邮件。`;
l.DMARC.Policy.Tag              = `这会将`;
l.DMARC.Policy.Tag2             = `标记加至DMARC记录。`;

l.DMARC.Aggregates.Title        = `聚合报告应发往何处？`;
l.DMARC.Aggregates.Opt          = `此为可选项，但强力推荐，它将`;
l.DMARC.Aggregates.Opt2         = `RUA标记加入政策。`;
l.DMARC.Aggregates.Opt3         = `强力推荐`;
l.DMARC.Aggregates.Opt4         = `将其加入政策，以用于分析和监控之目的。报告可发至多个地址。`;
l.DMARC.Aggregates.InputField   = `电子邮件地址`;
l.DMARC.Aggregates.InputInvalid = `请输入有效的电子邮件地址`;
l.DMARC.Aggregates.Next         = `下一步`;
l.DMARC.Aggregates.Reports      = `将生成含有关于哪些邮件信息通过/未通过SPF和DKIM的聚合报告（XML格式）。这将为您的机构对潜在验证问题和/或垃圾邮件活动提供可见性。`;
l.DMARC.Aggregates.Contains     = `报告含有下列内容`;
l.DMARC.Aggregates.HowToRead    = `如何阅读您的第一份DMARC报告`;
l.DMARC.Aggregates.By           = `（第1部分）作者Amy Gorrell）：`;
l.DMARC.Aggregates.Info         = `ISP信息`;
l.DMARC.Aggregates.Desc         = `逐行描述您机构的DMARC记录`;
l.DMARC.Aggregates.Summary      = `验证结果概述`;
l.DMARC.Aggregates.Important    = `必须注意，这些报告并非只是来自同一个来源。您有可能每天收到10至100（可能上千）份报告（生成的报告数量取决于发送到电子邮件量）。`;
l.DMARC.Aggregates.Difficult    = `这将使其难以人工审阅每份报告，尤其是由于其为XML格式以及某些报告可能过长。`;
l.DMARC.Aggregates.Readable     = `可通过开发XML转换器或与商业供应商合作，使这些报告可以人工阅读。大部分商业供应商将会提供可使报告人工阅读的后端信息库以及各种级别的分析和指导。`;
l.DMARC.Aggregates.Vendors      = `DMARC供应商（GCA合作伙伴）：`;

l.DMARC.Forensics.Title         = `鉴定报告应发往何处？`;
l.DMARC.Forensics.Opt           = `此为可选项，它将`;
l.DMARC.Forensics.Opt2          = `RUF标记加至政策。这些XML报告仅在接收方生成这类报告的情况下才会生成。您有可能不会收到任何鉴定报告。这是因各国隐私和数据分享规定所致。`;
l.DMARC.Forensics.InputField    = `电子邮件地址`;
l.DMARC.Forensics.InputInvalid  = `请输入有效的电子邮件地址`;
l.DMARC.Forensics.Next          = `下一步`;

l.DMARC.Subdomains.Title        = `子域DMARC政策`;
l.DMARC.Subdomains.Define       = `这将定义您的机构域名的子域政策级别。如果这个标记`;
l.DMARC.Subdomains.Define2      = `不存在，那么，其政策将会遵循顶级域名政策，这在第一步中已经定义。`;
l.DMARC.Subdomains.None         = `无`;
l.DMARC.Subdomains.Quarantine   = `隔离`;
l.DMARC.Subdomains.Reject       = `拒绝`;
l.DMARC.Subdomains.Next         = `下一步`;
l.DMARC.Subdomains.Policy       = `域名政策。可选项为：`;
l.DMARC.Subdomains.NoneEx       = `无 - 不采取行动，但在DMARC报告中予以记录。`;
l.DMARC.Subdomains.QuarantineEx = `隔离 - 邮件信息标记为垃圾邮件。`;
l.DMARC.Subdomains.RejectEx     = `拒绝 - 信息被删除。`;
l.DMARC.Subdomains.Recommended  = `如果您有多个子域，建议将其设置为“无”。最好为每个子域分别设置DMARC政策，直到所有子域完全符合DMARC政策。然而，如果没有子域存在，则建议使用“拒绝”。`;

l.DMARC.Options.Title           = `（可选）剩余的选项非为必需，但如果需要可以调整：`;
l.DMARC.Options.ReportGen       = `1、何时应生成错误报告`;
l.DMARC.Options.Default         = `默认`;
l.DMARC.Options.ReportPol       = `定义错误报告政策。`;
l.DMARC.Options.DefaultsTo      = `如未定义，默认为`;
l.DMARC.Options.AllChecks       = `如果任何基础检验失败，则向发送MTA生成报告`;
l.DMARC.Options.AnyChecks       = `如果任何基础检验失败，则向发送MTA生成报告`;
l.DMARC.Options.OtherValues     = `其它值为：`;
l.DMARC.Options.DKIMFails       = `如果DKIM检验失败，则生成报告`;
l.DMARC.Options.SPFFails        = `如果SPF检验失败，则生成报告`;
l.DMARC.Options.Relaxed         = `松散`;
l.DMARC.Options.Strict          = `严格`;
l.DMARC.Options.DKIMAlign       = `2、DKIM对位模式`;
l.DMARC.Options.DKIMAlignOpt    = `DKIM对位模式。可选项为：`;
l.DMARC.Options.DKIMRelaxed     = `（松散）如未定义，则为默认。允许DKIM标头中定义的任何子域。`;
l.DMARC.Options.DKIMStrict      = `（严格）发送人域名必须精确匹配DKIM标头中的域名。`;
l.DMARC.Options.SPFAlign        = `3、SPF对位模式`;
l.DMARC.Options.SPFAlignOpt     = `SPF对位模式。可选项为：`;
l.DMARC.Options.SPFRelaxed      = `（松散）如未定义，则为默认。允许任何子域。`;
l.DMARC.Options.SPFStrict       = `（严格） - 邮件发件人指令（SMTP）中的机构域名必须与发件人：标头（邮件项）精确匹配`;
l.DMARC.Options.Percentage      = `4、DMARC政策信息主题的比例？`;
l.DMARC.Options.Filter          = `%的信息需由DMARC政策过滤。可能为1至100的任何数量。默认为100，此为所有信息。`;
l.DMARC.Options.Formats         = `5、生成的报告格式`;
l.DMARC.Options.FormatsEx       = `定义所生成报告的格式。格式值为：`;
l.DMARC.Options.RFC             = `如未定义，则为默认值。格式由RFC 5965定义。`;
l.DMARC.Options.RFC2            = `格式由RFC 5070定义。`;
l.DMARC.Options.ReportInt       = `6、报告间隔（秒）`;
l.DMARC.Options.SecondsDefault  = `秒（默认）`;
l.DMARC.Options.DigitsOnly      = `请仅输入数字`;
l.DMARC.Options.ReportIntEx     = `定义以秒为单位的报告间隔。如未定义，默认为86400秒，或24小时。请注意，并不保证报告由接收MTA发送。报告将会尽力发送。`;
l.DMARC.Options.HToS            = `小时至秒转化器`;
l.DMARC.Options.Hours           = `24小时`;
l.DMARC.Options.To              = `至`;
l.DMARC.Options.Seconds         = `86400秒`;
l.DMARC.Options.Finish          = `完成`;

l.DMARC.Results.Title           = `下列为必须在您的DNS服务器创建的DMARC TXT记录。`;
l.DMARC.Results.Display         = `显示DMARC TXT记录结果`;
l.DMARC.Results.Copy            = `复制`;
l.DMARC.Results.Copied          = `已复制！`;
l.DMARC.Results.SPFGuide        = `返回SPF指南`;
l.DMARC.Results.DKIMGuide       = `返回DKIM指南`;
l.DMARC.Results.OwnDNS          = `如果您有自己的DNS服务器，那么，请在下面为DMARC政策创建TXT记录。`;
l.DMARC.Results.ThirdParty      = `如果您在使用第三方DNS提供者，则请按其说明创建TXT记录。大多数情况下，它们所需的一切只是主机名称 _dmarc.<domainname>和文本值，该信息显示于引号之间。`;
l.DMARC.Results.Reference       = `请参照`;
l.DMARC.Results.ResourcesPage   = `资源页面`;
l.DMARC.Results.Information     = `查阅有关如何创建TXT记录的信息。`;
l.DMARC.Results.NotUnderstood   = `如果您已尝试据此操作，但尚不理解所要采取的步骤或使用的术语，建议您联系IT支持机构、域名注册商或GCA Info，`;
l.DMARC.Results.Assistance      = `寻求进一步的帮助。`;
l.DMARC.Results.Complete        = `完成`;
l.DMARC.Results.Implemented     = `DMARC记录执行后，请使用下列DMARC验证/查验：`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `尚未全部完成，`;
l.DMARC.Results.Wisdom          = `没问题！智慧是每次行动的最终目标。请尽管利用我们的上述资源或给我们留言，我们可以帮您执行。这就是我们在这里的目的。`;
l.DMARC.Results.Check           = `查验`;
l.DMARC.Results.Congratulations = `恭喜`;

export default l;
