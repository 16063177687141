import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'
import { EmailModel } from '../Models'

export const Step3 = (props: any) => {
  const { t } = useTranslation()
  const {emails, addEmail, removeEmail} = props
  return (
    <Step>
      <h2>{t('DMARC.Aggregates.Title')}</h2>
      <div>
        {t('DMARC.Aggregates.Opt')} {t('DMARC.Aggregates.Opt2')}{' '}
        <strong>{t('DMARC.Aggregates.Opt3')}</strong>{' '}
        {t('DMARC.Aggregates.Opt4')}
      </div>

      <div>
        {emails.map((el: EmailModel) => (
          <div className='servers' key={el.id}>
            <input
              type='text'
              onChange={(e) => el.setEmail(e.target.value)}
              placeholder={el.email||t('DMARC.Aggregates.InputField')}
            />
            <button
              className='add hs-button primary'
              onClick={() => removeEmail(el)}
            >
              -
            </button>
          </div>
        ))}
        <div className='servers'>
          <div />
          <button
            className='add hs-button primary'
            onClick={() => addEmail()}
          >
            +
          </button>
        </div>
      </div>
      <div>
        <p>{t('DMARC.Aggregates.Reports')}</p>
        <p>
          {t('DMARC.Aggregates.Contains')} (
          <a
            target='_blank'
            rel='noreferrer'
            href='https://blog.returnpath.com/how-to-read-your-first-dmarc-reports-part-1/'
          >
            {t('DMARC.Aggregates.HowToRead')}
          </a>{' '}
          {t('DMARC.Aggregates.By')})
        </p>
        <ul>
          <li>{t('DMARC.Aggregates.Info')}</li>
          <li>{t('DMARC.Aggregates.Desc')}</li>
          <li>{t('DMARC.Aggregates.Summary')}</li>
        </ul>
        <p>{t('DMARC.Aggregates.Important')}</p>
        <p>{t('DMARC.Aggregates.Difficult')}</p>
        <p>{t('DMARC.Aggregates.Readable')}</p>
        <p>{t('DMARC.Aggregates.Vendors')}</p>
        <ul>
          <li>
            <a
              href='https://www.valimail.com/'
              target='_blank'
              rel='noreferrer'
            >
              ValiMail
            </a>
          </li>
          <li>
            <a href='https://www.agari.com/' target='_blank' rel='noreferrer'>
              Agari
            </a>
          </li>
          <li>
            <a href='https://dmarcian.com/' target='_blank' rel='noreferrer'>
              dmarcian
            </a>
          </li>
          <li>
            <a
              href='https://www.proofpoint.com/'
              target='_blank'
              rel='noreferrer'
            >
              ProofPoint
            </a>
          </li>
        </ul>
      </div>

      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Next')}
          onClick={() => props.nextStep()}
        />
      </div>
    </Step>
  )
}
