let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC 指南`;

l.Header.Main.SetupDMARC        = `设置 DMARC`;
l.Header.Main.HowItWorks        = `运作原理`;
l.Header.Main.AboutDMARC        = `关于 DMARC`;
l.Header.Main.Resources         = `资源 `;
l.Header.Main.Contact           = `联系我们`;

l.Footer.Main.SetupDMARC        = `设置 DMARC`;
l.Footer.Main.HowItWorks        = `运作原理`;
l.Footer.Main.AboutDMARC        = `关于 DMARC`;
l.Footer.Main.Resources         = `资源`;
l.Footer.Main.Contact           = `联系我们`;
l.Footer.Main.Copyright         = `版权所有¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `隐私政策`;
l.Footer.Main.Sitemap           = `网站地图`;

l.Home.Main.EnterDomain         = `输入您的域名`;
l.Home.Main.ToStart             = `开始 DMARC 设置`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `欢迎使用 DMARC 设置向导本设置向导旨在指导您的机构完成 DMARC 政策、发送人策略框架（SPF）政策，以及域名密钥邮件确认（DKIM）政策的制定。通过实施所有这三项政策，您的机构将能够落实一个更加强有力的邮件验证机制，帮助保护品牌。`;
l.Home.Main.FirstStep           = `第一步是确认您的机构是否正在使用这三项政策中的任何一项。`;
l.Home.Main.InputWarning        = `请输入来自您的机构的邮箱地址的域名，必须与邮箱地址中@后的内容完全一致。`;
l.Home.Main.InputExample        = `例如，对于这个邮箱地址，info@globalcyberalliance.org，应该在文本框中输入的域名应为 globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `网站地址为必填`;
l.Home.Main.valid               = `网站地址无效`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `谷歌应用和 Office 365 用户：`;
l.Home.Main.UserWarningText1    = `请关注资源页中为云服务提供商`;
l.Home.Main.UserWarningLink     = `提供的信息`;
l.Home.Main.UserWarningText2    = `以实施 SPF 和 DKIM。完成后，请使用该指南以继续实施 DMARC 政策。`;
l.Home.Main.Submit              = `提交`;
l.Home.Main.MoreInfo            = `若想要获得更多关于 DMARC 的信息，我们邀请您参加我们的每月 DMARC 网络研讨会之一。该网络研讨会将涵盖 DMARC 的基本信息，提供对 GCA DMARC 设置向导的概览。`;
l.Home.Main.Webinar             = `GCA DMARC 网络研讨会每月召开两次，在第三个星期三召开。请点击下面的恰当链接登记对您来说最合适的时间和日期：`;
l.Home.Main.SelectDate          = `选择这个日期`;

l.ToolSelect.Main.Title         = `...的结果`;
l.ToolSelect.Main.ResultStatus  = `下表指明了已经实施 <i class='fas fa-check-circle' style='color: green'></i> 或者尚未实施 <i class='fas fa-times-circle' style='color: red'></i> 的协议。`;
l.ToolSelect.Main.Protocol      = `请选择一个协议以启动设置向导。`;
l.ToolSelect.Main.ShareButton   = `若要分享结果，只需点击“分享”按钮，便可以将一个链接复制到剪贴板上。`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `DKIM 的检查部分仍在开发中。但是，仍然可以通过选择 DKIM 行获取 DKIM 设置指南。`;
l.ToolSelect.Main.NoDKIM        = `我们在给出的默认选择者列表中找不到与您的域名相关联的 DKIM 记录。`;
l.ToolSelect.Main.NoDKIM1       = `若您知道这个选择者，可以在这里扫描 DKIM 记录：`;
l.ToolSelect.Main.NoDKIM2       = `或者，若您提供一个邮箱地址，我们会向该帐户发送一封邮件，您可以回复该邮件，我们会为 DKIM 记录扫描邮件。`;
l.ToolSelect.Main.NoDKIMError   = `邮件无效或者不属于所提供的域名。`;
l.ToolSelect.Main.SelectorMsg   = `扫描选择者！`;
l.ToolSelect.Main.SelectorError = `未找到选择者。`;
l.ToolSelect.Main.EmailMsg      = `已发出邮件！请回复邮件。回复发出后，该页会刷新并且提供选择者名称。`;
l.ToolSelect.Main.InputSelector = `选择者`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `提交`;
l.ToolSelect.Main.Select        = `选择`;
l.ToolSelect.Main.Next          = `下一步`;
l.ToolSelect.Main.Share         = `共享`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `您已完成了 DMARC 的设置`;
l.Complete.Main.check           = `为 SPF、DKIM，或者 DMARC 勾选另一个域名？`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `网站地址为必填`;
l.Complete.Main.valid           = `网站地址无效`;
l.Complete.Main.Next            = `下一步`;
l.Complete.Main.More            = `更多关于 DMARC 的信息`;

l.SPF.Home.Title                = `什么是 SPF？`;
l.SPF.Home.SPF                  = `SPF 指发送者策略框架。该政策负责通过定义哪些邮件服务器获得代表机构‚Äö√Ñ√¥s 域名发送邮件的授权来防止发送者地址被假冒。若在 SPF 中未定义邮件服务器，则邮件会被拒收或者弹回。`;
l.SPF.Home.Start                = `请点击下一步按钮开始如何创建 SPF 政策的流程。`;
l.SPF.Home.Next                 = `下一步`;

l.SPF.Authorization.Title       = `下面列出的服务器是否经过授权可以为机构的域名发送邮件？`;
l.SPF.Authorization.First       = `该政策的第一部分用于定义也经过授权为该域名发送邮件的域名的收件服务器（MXes）。`;
l.SPF.Authorization.Current     = `以下列表包含针对与您的机构相关联的邮件服务器的当前DNS记录。`;
l.SPF.Authorization.Recommended = `强烈建议选择是 ，因为上列清单应为针对您的域名的授权服务器。这样做会将mx 标签添加至 SPF 政策。`;
l.SPF.Authorization.Yes         = `是（建议）`;
l.SPF.Authorization.No          = `否`;
l.SPF.Authorization.Missing     = `若该列表中有某些系统缺失或者不是标准邮件服务器，则将这些系统包含在下一选项中。`;
l.SPF.Authorization.Next        = `下一步`;

l.SPF.Servers.Title             = `该域名中是否有任何其它服务器可能可以为该域名提交或者转发邮件？`;
l.SPF.Servers.Possible          = `有可能在机构‚Äö√Ñ√¥s DNS 记录中并没有定义全部的机构邮件服务器。这些可能是拥有内置邮件系统的测试服务器或者应用。若是这样，则请在下框中添加额外的服务器域名或者 IP 地址。`;
l.SPF.Servers.Others            = `只有存在除邮件服务器之外的其它授权为该域名发送邮件的内部系统时才可以使用这个。”`;
l.SPF.Servers.Added             = `若添加了系统，则会添加‚Äö√Ñ√≤a‚Äö√Ñ√¥ 标签至 SPF 政策。`;
l.SPF.Servers.InputField        = `服务器名或者IP`;
l.SPF.Servers.InputInvalid      = `必须是完全具备资格的域名或者IP地址`;
l.SPF.Servers.Next              = `下一步`;

l.SPF.Domains.Title             = `是否有任何可以为您的机构提交或者转发邮件的外部域名？`;
l.SPF.Domains.Include           = `该部分会添加`;
l.SPF.Domains.Include2          = `包括`;
l.SPF.Domains.Include3          = `SPF 政策标签`;
l.SPF.Domains.Trusted           = `只有信任了一个外部域名为您的机构‚Äö√Ñ√¥s域名发送邮件时才可以使用。例如，客户关系管理系统、云邮件提供商（Google, Office 365, Yahoo!），或者云安全提供商。`;
l.SPF.Domains.Note              = `请注意：若您正在使用云服务提供商，则必须为了该设置的恰当价值与他们合作。`;
l.SPF.Domains.InputField        = `域名或者IP`;
l.SPF.Domains.InputInvalid      = `必须是完全具备资格的域名或者IP地址`;
l.SPF.Domains.Next              = `下一步`;

l.SPF.Strict.Title              = `服务器处理邮件的严格程度应如何？`;
l.SPF.Strict.Receiving          = `这是 SPF 政策的最后部分，将定义在处理邮件时接收服务器的严格程度。`;
l.SPF.Strict.SoftFail           = `故障弱化（建议）`;
l.SPF.Strict.Fail               = `故障`;
l.SPF.Strict.Neutral            = `中立`;
l.SPF.Strict.AllowAll           = `允许全部`; 
l.SPF.Strict.Next               = `下一步`;
l.SPF.Strict.Options            = `选项：`;
l.SPF.Strict.FailEx             = `故障或者 `;
l.SPF.Strict.FailEx2            = `意味着只有域名邮件服务器（以及在a中的服务器，包括部分）才能够为该域名发送邮件。禁止所有其它服务器。`;
l.SPF.Strict.SoftEx             = `故障弱化或者 `;
l.SPF.Strict.SoftEx2            = `意味着只有域名邮件服务器（以及在a中的服务器，包括部分）才能够为该域名发送邮件，但这正在过渡中。禁止所有其它服务器。`;
l.SPF.Strict.NeutralEx          = `中立或者 `;
l.SPF.Strict.NeutralEx2         = `明确表明，对于有效性没有什么可说的。`;
l.SPF.Strict.AllowEx            = `允许全部或者`;
l.SPF.Strict.AllowEx2           = `意味着，任何主机都可以为该域名发送邮件。`;
l.SPF.Strict.Warning1           = `绝不应`; 
l.SPF.Strict.Warning2           = `使用`;
l.SPF.Strict.Warning3           = `这个`;
l.SPF.Strict.Recommended        = `建议使用故障弱化`;
l.SPF.Strict.MarkMail           = `若未达到定义的条件，则会将邮件标记为不合规。`;

l.SPF.Results.Title             = `以下是 SPF TXT 记录，必须在您的 DNS 服务器上创建。`;
l.SPF.Results.Copy              = `复制`;
l.SPF.Results.Copied            = `已复制！`;
l.SPF.Results.DMARCGuide        = `跳至 DMARC 指南`;
l.SPF.Results.DKIMGuide         = `继续 DMARC 指南`;
l.SPF.Results.OwnDNS            = `若您有自己的 DNS 服务器，则请为上述 SPF 政策创建 TXT 记录。`;
l.SPF.Results.ThirdParty        = `若您使用的是第三方 DNS 提供商，则请遵循他们的指示创建一个 TXT 记录。`;
l.SPF.Results.Reference         = `请参考`;
l.SPF.Results.ResourcesPage     = `资源页`;
l.SPF.Results.Information       = `获取如何创建 TXT 记录的信息。`;
l.SPF.Results.NotUnderstood     = `若您已经尝试过遵循这个，但是尚未理解要采取的步骤或者使用的术语，建议您联系您的IT支持机构、域名登记员，或者 GCA 信息`;
l.SPF.Results.Assistance        = `获取进一步帮助。`;
l.SPF.Results.Implemented       = `实施了 SPF 记录后，请使用下面的 SPF 验证/检查器：`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `检查`;
l.SPF.Results.SuccessDKIM       = `前往 DKIM`;
l.SPF.Results.SuccessDMARC      = `前往 DMARC`;
l.SPF.Results.NotQuite          = `还有相当的距离。`;
l.SPF.Results.Wisdom            = `没关系！智慧是每个动作的最后目标。请随时充分使用我们的上述资源，或者给我们留言，我们能够帮助您实施。这就是我们存在的理由。`;

l.DKIM.Home.Title               = `什么是 DKIM？`;
l.DKIM.Home.Mechanism           = `DKIM 指的是域名密钥识别邮件。DKIM 是一种专为验证与邮件信息相关联的域名身份而设计的机制。通过使用运用非对称密码学的验证来实现。基本上来说，DKIM 会通过在邮件标头添加一个数字签名来验证邮件信息。必须注意的是，DKIM 不会对邮件信息进行过滤。这会让垃圾邮件过滤器确定正在发出的邮件的真实性。`;
l.DKIM.Home.ClickNext           = `请点击下一步按钮开始如何创建 DKIM 政策的流程。`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `下一步`;

l.DKIM.Selector.Title           = `选择者`;
l.DKIM.Selector.Name            = `选择者会定义 DKIM 记录的名称。选择者可以是任何名称，不过，以下是一些可供选择的选项。`;
l.DKIM.Selector.Define          = `这会定义将为 DKIM 密钥创建的 TXT 记录的名称。将显示为如下：`;
l.DKIM.Selector.Example1        = `选择者`;
l.DKIM.Selector.Example2        = `域名`;
l.DKIM.Selector.Next            = `下一步`;

l.DKIM.Key.Title                = `公共密钥`;
l.DKIM.Key.Tag                  = `这会添加“p”标签，这是之前生成的公共密钥文件中的关键字符串。`;
l.DKIM.Key.Keys                 = `将公共密钥放在这里`;
l.DKIM.Key.Next                 = `下一步`;
l.DKIM.Key.Creating             = `创建 DKIM 密钥：`;
l.DKIM.Key.TwoParts             = `DKIM 包含两部分，私人密钥和公共密钥。为 DKIM 创建密钥会根据谁向您的机构提供邮件服务而不同。`;
l.DKIM.Key.External             = `若您为邮件和DNS都使用的是一个外部服务，则邮件提供者将需要向您提供公共密钥。而后，这个公共密钥会作为DNS中的TXT记录发布。大多数 DNS 提供商会将 TXT 记录的长度限制为255个字符，因此，您可能会需要与提供商合作以增加长度或者创建 TXT 记录。私人密钥由邮件提供商持有，通常不向机构提供。`;
l.DKIM.Key.Internal             = `若机构拥有其自己的邮件服务器，则 DKIM 密钥必须由 IT 员工（或者负责 DKIM 实施的人）生成。互联网上提供多种 DKIM 密钥生成器。若您选择使用其中的一个，只需确保您可以在密钥生成并且将文件复制到您的用户端后选择删除密钥即可。`;
l.DKIM.Key.OpenDKIM             = `若要不通过第三方创建密钥，可以使用一个名为 Opendkim 的`;
l.DKIM.Key.OpenDKIM2            = `开源项目。Opendkim 包含各种各样的工具帮助创建 DKIM 密钥。`;
l.DKIM.Key.OpenSSL              = `另一个选项是使用 OpenSSL 生成 DKIM 密钥。可以通过使用最新版的 OpenSSL，并且为私人密钥输入以下命令来完成：`;
l.DKIM.Key.OpenSSL2             = `这会生成一个名为Next的私人密钥文件，`;
l.DKIM.Key.OpenSSL3             = `输入以下命令来生成私人密钥：`;
l.DKIM.Key.OpenSSL4             = `该命令会生成公共密钥`;
l.DKIM.Key.OpenSSL5             = `基于之前创建的私人密钥`;
l.DKIM.Key.OpenSSL6             = `将 DKIM 私人密钥移到 DKIM 安装指定的位置。确保位于一个拥有有限访问权限的文件夹。`;

l.DKIM.Results.Title            = `以下是 DKIM TXT 记录，必须在您的 DNS 服务器上创建。`;
l.DKIM.Results.Copy             = `复制`;
l.DKIM.Results.Copied           = `已复制！`;
l.DKIM.Results.SPFGuide         = `返回 SPF 指南`;
l.DKIM.Results.DMARCGuide       = `继续 DMARC 指南`;
l.DKIM.Results.OwnDNS           = `若有您自己的 DNS 服务器，则请为上述 DKIM 政策创建 TXT 记录。`;
l.DKIM.Results.ThirdParty       = `若您使用的是第三方 DNS 提供商，则请遵循他们的指示创建一个 TXT 记录。`;
l.DKIM.Results.Reference        = `请参考`;
l.DKIM.Results.ResourcesPage    = `资源页`;
l.DKIM.Results.Information      = `获取如何创建 TXT 记录的信息。`;
l.DKIM.Results.NotUnderstood    = `若您已经尝试过遵循这个，但是尚未理解要采取的步骤或者使用的术语，建议您联系您的IT支持机构、域名登记员，或者 GCA 信息`;
l.DKIM.Results.Assistance       = `获取进一步帮助。`;
l.DKIM.Results.Note             = `请注意：`;
l.DKIM.Results.Support          = `DKIM 密钥现在支持 2048-位密钥。但是，DNS TXT 记录不支持超过255个字符的值。您可以通过以下方式之一绕过这个：`;
l.DKIM.Results.Support2         = `使用1024-位密钥，或者`;
l.DKIM.Results.Support3         = `创建一条多行 TXT 记录`;
l.DKIM.Results.Support4         = `任何多行记录的例子均为如下（添加引号将字符串分割为多行）：`;

l.DMARC.Home.Title              = `什么是 DMARC？`;
l.DMARC.Home.Final              = `现在，已经创建并且实施了 SPF 和 DKIM 政策。我们可以继续进行最后一个步骤来创建一个 DMARC 政策。 `;
l.DMARC.Home.Protocol           = `DMARC（基于域名的信息验证、报告和一致性）是一种邮件验证协议，包括允许发送者和接收者改进及监控保护域名不受欺诈邮件损害的报告功能。实施 DMARC 能够防止垃圾邮件发送者冒用邮件信息上的“发件”地址。根据 DMARC 政策设置，任何来自未经授权邮件服务器的邮件信息都会被隔离或者拒绝。所有使用一个机构的域名的垃圾和钓鱼邮件，在送达目的地（终端用户）之前都会被隔离或者删除。若活动为犯罪性质，那么，生成的报告可用于情报目的或者可能用于执法目的。`;
l.DMARC.Home.Stronger           = `DMARC 会将 SPF 和 DKIM 集合到一起为您的机构的域名生成更加强有力的邮件验证。`;
l.DMARC.Home.ClickNext          = `请点击下一步按钮开始如何创建 DMARC 政策的流程。`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `下一步`;

l.DMARC.Policy.Title            = `针对 DMARC 的政策水平应是怎样的？`;
l.DMARC.Policy.Levels           = `可以为 DMARC 设定三个政策水平。它们是：`;
l.DMARC.Policy.NoneEx           = `无 - 报告可疑的邮件，但是，所有邮件都会被发送到收件箱中`;
l.DMARC.Policy.QuarantineEx     = `隔离 - SPF/DKIM 政策失效，而后，会将邮发送到垃圾邮件文件夹`;
l.DMARC.Policy.RejectEx         = `拒绝 - SPF/DKIM 政策失效，而后，邮件会被终止，不会被提交`;
l.DMARC.Policy.None             = `无（建议）`;
l.DMARC.Policy.Quarantine       = `隔离`;
l.DMARC.Policy.Reject           = `拒绝`;
l.DMARC.Policy.Next             = `下一步`;
l.DMARC.Policy.Recommended1     = `强烈`;
l.DMARC.Policy.Recommended2     = `建议`;
l.DMARC.Policy.Recommended3     = `以“无”开始，因为这会有时间对所生成的报告进行监控，在将 DMARC 政策切换至隔离或者拒绝之前确定是否会阻止正当邮件。`;
l.DMARC.Policy.Tag              = `这会将标签`;
l.DMARC.Policy.Tag2             = `添加至 DMARC 记录。`;

l.DMARC.Aggregates.Title        = `应将聚合报告发送到哪里？`;
l.DMARC.Aggregates.Opt          = `这是选项，但是强烈建议选择，并且，这会添加`;
l.DMARC.Aggregates.Opt2         = `rua 标签至政策。强烈`;
l.DMARC.Aggregates.Opt3         = `建议`;
l.DMARC.Aggregates.Opt4         = `将这个添加至政策用于分析和监控目的。可以将报告发送至多个地址。`;
l.DMARC.Aggregates.InputField   = `邮箱地址`;
l.DMARC.Aggregates.InputInvalid = `请输入一个有效的邮箱地址`;
l.DMARC.Aggregates.Next         = `下一步`;
l.DMARC.Aggregates.Reports      = `将生成一个聚合报告（XML格式），里面包括关于针对 SPF 和 DKIM 来说哪些邮件信息通过/失效的信息。这可以为您的机构提供对可能的验证问题和/或垃圾邮件活动的可见性。`;
l.DMARC.Aggregates.Contains     = `报告包含以下内容`;
l.DMARC.Aggregates.HowToRead    = `如何阅读您的首份 DMARC 报告`;
l.DMARC.Aggregates.By           = `（第1部分），由Amy Gorrell编制）：`;
l.DMARC.Aggregates.Info         = `ISP 信息`;
l.DMARC.Aggregates.Desc         = `对您的机构的 DMARC 记录的逐行描述`;
l.DMARC.Aggregates.Summary      = `验证结果摘要`;
l.DMARC.Aggregates.Important    = `必须注意，这些报告不会只来自一个来源。您每天都可能会收到10至100（可能数千）份报告（生成的报告数量取决于发出邮件的数量）。`;
l.DMARC.Aggregates.Difficult    = `这会使得很难手动查看每份报告，尤其是，由于使用的是 XML 格式，有些报告可能会很冗长。`;
l.DMARC.Aggregates.Readable     = `可以通过开发一个 XML 转换器或者与商业供应商合作使得人员可以阅读这些报告。大多数商业供应商会提供一个后端知识库使人员可以阅读报告，并且提供各种层次的分析和指导。`;
l.DMARC.Aggregates.Vendors      = `DMARC 供应商（GCA 合作伙伴）：`;

l.DMARC.Forensics.Title         = `应将司法鉴定报告发送到哪里？`;
l.DMARC.Forensics.Opt           = `这是选项，会为政策添加`;
l.DMARC.Forensics.Opt2          = `ruf 标签。只有接收方生成这些类别的报告时才会生成这些 XML 报告。可能您会收不到任何司法鉴定报告。这是因为各国之间的隐私和数据共享规定。`;
l.DMARC.Forensics.InputField    = `邮箱地址`;
l.DMARC.Forensics.InputInvalid  = `请输入一个有效的邮箱地址`;
l.DMARC.Forensics.Next          = `下一步`;

l.DMARC.Subdomains.Title        = `针对子域名的 DMARC 政策？`;
l.DMARC.Subdomains.Define       = `这会为您的机构域名的子域名确定政策水平。若该标签`;
l.DMARC.Subdomains.Define2      = `不存在，则该政策会遵循在第一步中定义了的顶级域名的政策。`;
l.DMARC.Subdomains.None         = `无`;
l.DMARC.Subdomains.Quarantine   = `隔离`;
l.DMARC.Subdomains.Reject       = `拒绝`;
l.DMARC.Subdomains.Next         = `下一步`;
l.DMARC.Subdomains.Policy       = `域名政策。选项为：`;
l.DMARC.Subdomains.NoneEx       = `无 - 不采取行动，但会记录在 DMARC 报告中。`;
l.DMARC.Subdomains.QuarantineEx = `隔离 - 所有邮件会被标记为垃圾邮件。`;
l.DMARC.Subdomains.RejectEx     = `拒绝 - 会删除信息。`;
l.DMARC.Subdomains.Recommended  = `若有多个子域名，则建议将这个设定为“无”。最好是为各个子域名设置单独的 DMARC 政策，直到所有子域名都遵循 DMARC 政策。但是，若不存在子域名，则建议使用“拒绝”。`;

l.DMARC.Options.Title           = `（选项）不要求其余选项，但可以在需要时调节：`;
l.DMARC.Options.ReportGen       = `1. 什么时候应该生成错误报告`;
l.DMARC.Options.Default         = `默认`;
l.DMARC.Options.ReportPol       = `定义错误报告政策。`;
l.DMARC.Options.DefaultsTo      = `若未定义，则默认为`;
l.DMARC.Options.AllChecks       = `若任何基础检查失败，则生成报告以发送 MTA`;
l.DMARC.Options.AnyChecks       = `若任何基础检查失败，则生成报告以发送 MTA`;
l.DMARC.Options.OtherValues     = `其它值为：`;
l.DMARC.Options.DKIMFails       = `若 DKIM 检查失败，则生成一份报告`;
l.DMARC.Options.SPFFails        = `若 SPF 检查失败，则生成一份报告`;
l.DMARC.Options.Relaxed         = `放松`;
l.DMARC.Options.Strict          = `严格`;
l.DMARC.Options.DKIMAlign       = `2. 针对 DKIM 的对齐模式`;
l.DMARC.Options.DKIMAlignOpt    = `针对 DKIM 的对齐模式。选项为：`;
l.DMARC.Options.DKIMRelaxed     = `若拒绝，则为默认值（放松）。允许在 DKIM 标头中定义的任何子域名。`;
l.DMARC.Options.DKIMStrict      = `（严格）发件人的域名必须与 DKIM 标头中的域名完全匹配。`;
l.DMARC.Options.SPFAlign        = `3. 针对 SPF 的对齐模式`;
l.DMARC.Options.SPFAlignOpt     = `针对 SPF 的对齐模式。选项为：`;
l.DMARC.Options.SPFRelaxed      = `（放松）- 若未定义，则为默认值。允许任何子域名。`;
l.DMARC.Options.SPFStrict       = `（严格） -  MAIL FROM 命令（SMTP）中的机构域名和格式：标头（在邮件项中）必须完全匹配`;
l.DMARC.Options.Percentage      = `4. 依据 DMARC 政策的邮件主题百分比？`;
l.DMARC.Options.Filter          = `% 的邮件受到依据 DMARC 政策过滤的制约。可以是从1到100的任何数字。默认值为100，这是所有邮件。`;
l.DMARC.Options.Formats         = `5. 所生成的报告的格式`;
l.DMARC.Options.FormatsEx       = `定义所生成的报告的格式。格式值为：`;
l.DMARC.Options.RFC             = `若未定义，则为默认值。格式由 RFC 5965 定义。`;
l.DMARC.Options.RFC2            = `格式由 RFC 5070 定义。`;
l.DMARC.Options.ReportInt       = `6. 报告间隔（秒）`;
l.DMARC.Options.SecondsDefault  = `秒（默认）`;
l.DMARC.Options.DigitsOnly      = `请仅输入数字`;
l.DMARC.Options.ReportIntEx     = `定义以秒为单位的报告间隔。若未定义，则默认值为86400秒，或者24小时。请注意，不保证接收 MTAs 会收到报告。将尽最大努力发送报告。`;
l.DMARC.Options.HToS            = `小时-秒钟转换器`;
l.DMARC.Options.Hours           = `24小时`;
l.DMARC.Options.To              = `至`;
l.DMARC.Options.Seconds         = `86400 秒`;
l.DMARC.Options.Finish          = `完成`;

l.DMARC.Results.Title           = `以下是  DMARC TXT 记录，必须在您的 DNS 服务器上创建。`;
l.DMARC.Results.Display         = `显示 DMARC TXT 记录结果`;
l.DMARC.Results.Copy            = `复制`;
l.DMARC.Results.Copied          = `已复制！`;
l.DMARC.Results.SPFGuide        = `返回 SPF 指南`;
l.DMARC.Results.DKIMGuide       = `返回 DKIM 指南`;
l.DMARC.Results.OwnDNS          = `如果您有自己的DNS伺服器，那麼，請在下面為DMARC政策創建TXT記錄。`;
l.DMARC.Results.ThirdParty      = `若您使用的是第三方 DNS 提供商，则请遵循他们的指示创建一个 TXT 记录。在大多数情况下，他们所需的就是主机名，_dmarc.<domainname> 和文本值，这些信息可以在引号之间找到。`;
l.DMARC.Results.Reference       = `请参考`;
l.DMARC.Results.ResourcesPage   = `资源页`;
l.DMARC.Results.Information     = `获取如何创建 TXT 记录的信息。`;
l.DMARC.Results.NotUnderstood   = `若您已经尝试过遵循这个，但是尚未理解要采取的步骤或者使用的术语，则建议您联系您的IT支持机构、域名登记员，或者 GCA 信息`;
l.DMARC.Results.Assistance      = `获取进一步帮助。`;
l.DMARC.Results.Complete        = `完成`;
l.DMARC.Results.Implemented     = `实施了 DMARC 记录后，请使用下面的 DMARC 验证/检查器：`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `还有相当的距离。`;
l.DMARC.Results.Wisdom          = `没关系！智慧是每个动作的最后目标。请随时充分使用我们的上述资源，或者给我们留言，我们能够帮助您实施。这就是我们存在的理由。`;
l.DMARC.Results.Check           = `检查`;
l.DMARC.Results.Congratulations = `祝贺您`;

export default l;
