import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepWizard from '../../Components/WizardWrapper'
import { WizardNav } from '../../Components/WizardNav'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'

export const BIMIGuide = () => {
  const { t } = useTranslation()
  const[domain, setDomain] = useState('')
  const[logoPath, setLogoPath] = useState('')
  const[certPath, setCertPath] = useState('')
  const [isValid, setIsValid] = useState(false);
  const [startVerify, setStartVerify] = useState(false)

  const handleSetDomain = (newDomain: string) => {
    setDomain(newDomain)
  }
    
  const handleSetLogoPath = (newLogoPath: string) => {
    setLogoPath(newLogoPath)
  }
  
  const handleSetCertPath = (newCertPath: string) => {
    setCertPath(newCertPath)
  }

  const handleSetIsValid = (newIsValid: boolean) => {
    setIsValid(newIsValid)
  }

  const handleSetStartVerify = (newStartVerify: boolean) => {
    setStartVerify(newStartVerify)
  }

  const getRecord = () => {
    let rec = 'v=BIMI1; l=' + logoPath +'; a=' + certPath + ';'
 
    return rec
  }

  const handleStartOver = () => {
    setDomain('');
    setLogoPath('');
    setCertPath('');
    setIsValid(false);
    setStartVerify(false);
  }

  const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
  }

  return (
    <div
      id='av_section_4'
      className='avia-section main_color avia-section-huge avia-no-border-styling  avia-bg-style-scroll  avia-builder-el-36  el_after_av_section  el_before_av_section   container_wrap fullsize'
    >
      <div className='container'>
        <div className='template-page content  av-content-full alpha units'>
          <div className='wizard page-wrapper' data-section-bg-repeat='stretch'>
            <h1
              style={{
                textAlign: 'center',
                marginBottom: '30px',
                fontWeight: 'bold',
              }}
            >{t('What is BIMI?')}</h1>
            <StepWizard
              nav={<WizardNav />}
              isHashEnabled
              transitions={transitions}
            >
              <Step1 domain={domain} handleSetDomain={handleSetDomain}/>
              <Step2 logoPath={logoPath} handleSetLogoPath={handleSetLogoPath} isValid={isValid} handleSetIsValid={handleSetIsValid} startVerify={startVerify} handleSetStartVerify={handleSetStartVerify} />
              <Step3 certPath={certPath} handleSetCertPath={handleSetCertPath} />
              <Step4 domain={domain} getRecord={getRecord} handleStartOver={handleStartOver} />
            </StepWizard>
          </div>
        </div>
      </div>
    </div>
  )
}
