import { nanoid } from 'nanoid'

export class Domain {
    id?: any | null
    domain?:string | null
    constructor(){
        this.id = nanoid()
        this.domain = ''
        this.update = this.update.bind(this)
    }
    update(d: string) {
        this.domain = d
    }
}

export class EmailModel {
    id?: any | null;
    email?: string | null;
    constructor(){
    this.id = nanoid()
    this.email = ''
    }
    setEmail(e: string) {
    this.email = e
    }
}

export class Server {
    id?: any | null
    server?: string | null
    constructor(){
        this.id = nanoid()
        this.server =  ''
        this.update = this.update.bind(this)
    }
    update(s: string) {
        this.server = s
    }
}