let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC 가이드`;

l.Header.Main.SetupDMARC        = `DMARC 설정`;
l.Header.Main.HowItWorks        = `작동 방식`;
l.Header.Main.AboutDMARC        = `DMARC 소개`;
l.Header.Main.Resources         = `리소스`;
l.Header.Main.Contact           = `문의`;

l.Footer.Main.SetupDMARC        = `DMARC 설정`;
l.Footer.Main.HowItWorks        = `작동 방식`;
l.Footer.Main.AboutDMARC        = `DMARC 소개`;
l.Footer.Main.Resources         = `리소스`;
l.Footer.Main.Contact           = `문의`;
l.Footer.Main.Copyright         = `저작권 ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `개인정보 보호정책`;
l.Footer.Main.Sitemap           = `사이트맵`;

l.Home.Main.EnterDomain         = `도메인을 입력하고`;
l.Home.Main.ToStart             = `DMARC 설정을 시작하세요`;
l.Home.Main.TagLine             = `무언가를 해야 합니다. 측정하세요.`;
l.Home.Main.Welcome             = `DMARC 설정 가이드에 오신 것을 환영합니다. 설정 가이드의 목적은 발신자 정책 프레임워크(SPF) 및 도메인키 인증 메일(DKIM)에 대한 정책뿐만 아니라 DMARC 정책의 생성 과정을 안내하는 것입니다. 세 가지 정책을 모두 구현함으로써 귀사는 브랜드를 보호할 수 있는 강력한 이메일 인증 메커니즘을 갖추게 됩니다.`;
l.Home.Main.FirstStep           = `첫 번째 단계는 귀사가 세 가지 정책 중 하나를 사용하고 있는지 확인하는 것입니다.`;
l.Home.Main.InputWarning        = `이메일 주소의 @ 기호 다음에 표시된 귀사 이메일의 도메인을 정확하게 입력하세요.`;
l.Home.Main.InputExample        = `"예를 들면, info@globalcyberalliance.org의 경우 텍스트 박스에 입력할 도메인은 globalcyberalliance.org입니다"`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `웹사이트 주소가 필요합니다`;
l.Home.Main.valid               = `웹사이트 주소가 유효하지 않습니다`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `구글 앱 및 오피스 365 사용자:`;
l.Home.Main.UserWarningText1    = `클라우드 서비스 제공업체에 대한`;
l.Home.Main.UserWarningLink     = `리소스 페이지에 제공된 정보에 따라`;
l.Home.Main.UserWarningText2    = ` SPF 및 DKIM을 구현하세요. 완료되면 이 가이드를 사용하여 DMARC 정책 구현을 계속 진행하세요.`;
l.Home.Main.Submit              = `제출`;
l.Home.Main.MoreInfo            = `DMARC에 대한 자세한 내용을 원할 경우 월간 DMARC 웨비나에 참석하세요. 이 웨비나는 DMARC의 기본 사항을 다루고 GCA DMARC 설정 가이드에 대한 간략한 개요를 제공합니다.`;
l.Home.Main.Webinar             = `월간 GCA DMARC 웨비나 세션은 매월 셋째 수요일에 두 번 진행됩니다. 아래의 해당 링크를 클릭하여 가장 적합한 날짜와 시간을 등록하세요:`;
l.Home.Main.SelectDate          = `이 날짜 선택`;

l.ToolSelect.Main.Title         = `다음에 대한 결과:`;
l.ToolSelect.Main.ResultStatus  = `아래 표는 어떤 프로토콜이 구현되었는지 <i class='fas fa-check-circle' style='color: green'></i> 또는 구현되지 않았는지 <i class='fas fa-times-circle' style='color: red'></i> 를 나타냅니다.`;
l.ToolSelect.Main.Protocol      = `설정 가이드를 시작하려면 프로토콜 중 하나를 선택하세요.`;
l.ToolSelect.Main.ShareButton   = `결과를 공유하려면 공유 버튼을 클릭하세요. 링크가 클립보드에 복사됩니다.`;
l.ToolSelect.Main.Selecting     = `프로토콜을 선택하면 사용 가능한 경우 현재 레코드도 표시됩니다.`;
l.ToolSelect.Main.DKIMWarning   = `DKIM 검사 부분은 아직 개발 중입니다. 그러나 DKIM 설정 가이드는 DKIM 행을 선택하여 계속 사용할 수 있습니다.`;
l.ToolSelect.Main.NoDKIM        = `기본 선택기 목록에서 도메인과 연결된 DKIM 레코드를 찾을 수 없습니다.`;
l.ToolSelect.Main.NoDKIM1       = `선택기를 알고 있는 경우 다음에서 DKIM 레코드를 스캔할 수 있습니다:`;
l.ToolSelect.Main.NoDKIM2       = `또는 이메일 주소를 제공하면 회신할 수 있는 계정으로 이메일을 보내고 이메일에서 DKIM 레코드를 스캔합니다.`;
l.ToolSelect.Main.NoDKIMError   = `이메일이 유효하지 않거나 제공된 도메인에 속해 있지 않습니다.`;
l.ToolSelect.Main.SelectorMsg   = `선택기 스캔 중!`;
l.ToolSelect.Main.SelectorError = `선택기를 찾을 수 없습니다.`;
l.ToolSelect.Main.EmailMsg      = `이메일을 전송했습니다! 이메일에 회신하시기 바랍니다. 회신 이메일이 전송되면 이 페이지가 새로 고쳐지고 선택기 이름이 제공됩니다.`;
l.ToolSelect.Main.InputSelector = `선택기`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `제출`;
l.ToolSelect.Main.Select        = `선택`;
l.ToolSelect.Main.Next          = `다음`;
l.ToolSelect.Main.Share         = `공유`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `DMARC 설정을 완료했습니다`;
l.Complete.Main.check           = `"SPF, DKIM 또는 DMARC와 관련하여 다른 도메인을 검사하시겠습니까?"`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `웹사이트 주소가 필요합니다`;
l.Complete.Main.valid           = `웹사이트 주소가 유효하지 않습니다`;
l.Complete.Main.Next            = `다음`;
l.Complete.Main.More            = `DMARC에 대한 추가 정보`;

l.SPF.Home.Title                = `SPF란 무엇입니까?`;
l.SPF.Home.SPF                  = `SPF는 발신자 정책 프레임워크의 약자입니다. 이 정책은 조직의 도메인을 대신하여 메일을 전송할 권한이 있는 메일 서버를 정의함으로써 발신자 주소가 스푸핑되는 것을 방지합니다. 메일 서버가 SPF에 정의되어 있지 않으면 메시지는 거부되거나 반송됩니다.`;
l.SPF.Home.Start                = `다음 버튼을 클릭하여 SPF 정책의 생성 방법 과정을 시작하세요.`;
l.SPF.Home.Next                 = `다음`;

l.SPF.Authorization.Title       = `아래에 나열된 서버가 조직의 도메인을 대신하여 이메일을 전송할 권한이 있습니까?`;
l.SPF.Authorization.First       = `정책의 첫 번째 부분은 해당 도메인의 메일을 전송할 권한이 있는 도메인의 수신 메일 서버(MX)를 정의하는 것입니다.`;
l.SPF.Authorization.Current     = `아래 목록은 귀사와 관련된 메일 서버의 현재 DNS 레코드를 포함하고 있습니다.`;
l.SPF.Authorization.Recommended = `위 목록이 도메인의 승인된 서버여야 하므로 예를 선택하는 것이 좋습니다. 이렇게 하면 mx 태그가 SPF 정책에 추가됩니다.`;
l.SPF.Authorization.Yes         = `예 (권장)`;
l.SPF.Authorization.No          = `아니요`;
l.SPF.Authorization.Missing     = `목록에서 누락되어 있거나 표준 메일 서버가 아닌 시스템이 있는 경우 해당 시스템을 다음 옵션에 포함시키세요.`;
l.SPF.Authorization.Next        = `다음`;

l.SPF.Servers.Title             = `메일을 전달하거나 릴레이할 수 있는 추가 서버가 도메인에 있습니까?`;
l.SPF.Servers.Possible          = `조직의 모든 메일 서버가 DNS 레코드에 정의되어 있지 않을 가능성이 있습니다. 이들은 메일 시스템이 내장된 테스트 서버 또는 응용프로그램일 수 있습니다. 이러한 경우 추가 서버 도메인 또는 IP 주소를 아래 박스에 추가하세요.`;
l.SPF.Servers.Others            = `"도메인 메일을 전송할 권한이 있는 메일 서버 이외의 다른 내부 시스템이 있는 경우에만 사용해야 합니다. """`;
l.SPF.Servers.Added             = `시스템이 추가되면 ‚Äö√Ñ√≤a‚Äö√Ñ√¥ 태그가 SPF 정책에 추가됩니다.`;
l.SPF.Servers.InputField        = `서버 이름 또는 IP`;
l.SPF.Servers.InputInvalid      = `정규화된 도메인 이름 또는 IP 주소여야 합니다`;
l.SPF.Servers.Next              = `다음`;

l.SPF.Domains.Title             = `조직의 메일을 전달하거나 릴레이할 수 있는 외부 도메인이 있습니까?`;
l.SPF.Domains.Include           = `이 섹션은`;
l.SPF.Domains.Include2          = `SPF 정책의 포함 태그를`;
l.SPF.Domains.Include3          = `추가합니다.`;
l.SPF.Domains.Trusted           = `"외부 도메인이 조직의 도메인을 대신하여 메일 메시지를 전송하도록 신뢰할 수 있는 경우에만 사용해야 합니다. 예를 들면, 고객 관리 시스템, 클라우드 메일 제공업체(구글, 오피스 365, 야후!) 또는 클라우드 보안 제공업체."`;
l.SPF.Domains.Note              = `참고 사항: 클라우드 서비스 제공업체를 사용하는 경우 이 설정에 적합한 값을 문의해야 합니다.`;
l.SPF.Domains.InputField        = `도메인 이름 또는 IP`;
l.SPF.Domains.InputInvalid      = `정규화된 도메인 이름 또는 IP 주소여야 합니다`;
l.SPF.Domains.Next              = `다음`;

l.SPF.Strict.Title              = `서버가 이메일을 얼마나 엄격하게 처리해야 합니까?`;
l.SPF.Strict.Receiving          = `SPF 정책의 마지막 섹션으로서 이메일을 처리할 때 수신 서버가 얼마나 엄격해야 하는지를 정의합니다.`;
l.SPF.Strict.SoftFail           = `소프트 페일 (권장)`;
l.SPF.Strict.Fail               = `페일`;
l.SPF.Strict.Neutral            = `중립`;
l.SPF.Strict.AllowAll           = `모두 허용`;
l.SPF.Strict.Next               = `다음`;
l.SPF.Strict.Options            = `옵션:`;
l.SPF.Strict.FailEx             = `페일 또는`;
l.SPF.Strict.FailEx2            = `도메인 메일 서버(및 a와 포함 섹션에 있는 서버)만이 도메인의 메일을 전송할 수 있음을 의미합니다. 다른 모든 것은 금지되어 있습니다.`;
l.SPF.Strict.SoftEx             = `소프트 페일 또는`;
l.SPF.Strict.SoftEx2            = `"도메인 메일 서버(및 a와 포함 섹션에 있는 서버)만이 도메인의 메일을 전송할 수 있음을 의미하지만, 이행 중에 있습니다. 다른 모든 것은 금지되어 있습니다."`;
l.SPF.Strict.NeutralEx          = `중립 또는`;
l.SPF.Strict.NeutralEx2         = `유효성에 대해 아무것도 말할 수 없다는 것을 명시적으로 의미합니다.`;
l.SPF.Strict.AllowEx            = `모두 허용 또는`;
l.SPF.Strict.AllowEx2           = `모든 호스트가 도메인의 메일을 전송할 수 있음을 의미합니다.`;
l.SPF.Strict.Warning1           = `이것은`;
l.SPF.Strict.Warning2           = `절대로`;
l.SPF.Strict.Warning3           = `사용해서는 안 됩니다`;
l.SPF.Strict.Recommended        = `소프트 페일을 사용할 것을 권장합니다`;
l.SPF.Strict.MarkMail           = `이렇게 하면 메일이 정의된 기준을 충족시키지 못할 경우 비준수 메일로 표시됩니다.`;

l.SPF.Results.Title             = `아래는 DNS 서버에서 생성되어야 하는 SPF TXT 레코드입니다.`;
l.SPF.Results.Copy              = `복사`;
l.SPF.Results.Copied            = `복사되었습니다!`;
l.SPF.Results.DMARCGuide        = `DMARC 가이드로 건너뛰기`;
l.SPF.Results.DKIMGuide         = `DMARC 가이드 계속하기`;
l.SPF.Results.OwnDNS            = `자체 DNS 서버가 있는 경우 위의 SPF 정책에 대한 TXT 레코드를 생성하세요.`;
l.SPF.Results.ThirdParty        = `제3자 DNS 제공업체를 사용하는 경우 해당 업체의 TXT 레코드 생성 지침을 따르세요.`;
l.SPF.Results.Reference         = `TXT 레코드 생성 방법에 대한 정보는`;
l.SPF.Results.ResourcesPage     = `리소스 페이지를`;
l.SPF.Results.Information       = `참조하세요.`;
l.SPF.Results.NotUnderstood     = `"절차에 따라 진행하려고 했지만 방법이나 사용된 용어를 이해하지 못한 경우 IT 지원팀, 도메인 등록기관 또는 GCA Info에"`;
l.SPF.Results.Assistance        = `문의하시기 바랍니다.`;
l.SPF.Results.Implemented       = `SPF 레코드가 구현되면 아래의 SPF 유효성 확인/검사기를 사용하세요:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `검사`;
l.SPF.Results.SuccessDKIM       = `DKIM으로 이동`;
l.SPF.Results.SuccessDMARC      = `DMARC으로 이동`;
l.SPF.Results.NotQuite          = `아직 완전하지 않았습니다.`;
l.SPF.Results.Wisdom            = `괜찮습니다! 지혜는 모든 행동의 최종 목표입니다. 위의 리소스를 사용하거나 당사에 문의하시면 도움을 받으실 수 있습니다. 언제든지 도움을 드리겠습니다.`;

l.DKIM.Home.Title               = `DKIM이란 무엇입니까?`;
l.DKIM.Home.Mechanism           = `DKIM은 도메인키 인증 메일의 약자입니다. DKIM은 메일 메시지와 관련된 도메인 ID의 유효성을 검사하기 위해 설계된 메커니즘입니다. 이는 비대칭 암호화를 사용하는 인증을 통해 수행됩니다. 기본적으로 DKIM은 메시지 헤더에 디지털 서명을 추가하여 메일 메시지를 인증합니다. DKIM이 메일 메시지를 필터링하지 않는다는 점에 유의하시기 바랍니다. DKIM은 스팸 필터가 전송되는 메일 메시지의 진위를 결정하도록 합니다.`;
l.DKIM.Home.ClickNext           = `다음 버튼을 클릭하여 DKIM 정책의 생성 방법 과정을 시작하세요.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `다음`;

l.DKIM.Selector.Title           = `선택기`;
l.DKIM.Selector.Name            = `"선택기는 DKIM 레코드의 이름을 정의합니다. 선택기 이름은 무엇이든 될 수 있지만, 선택할 수 있는 몇 가지 옵션이 아래에 있습니다."`;
l.DKIM.Selector.Define          = `이렇게 하면 DKIM 키에 대해 생성될 TXT 레코드의 이름이 정의됩니다. 이는 다음과 같이 나타납니다:`;
l.DKIM.Selector.Example1        = `선택기`;
l.DKIM.Selector.Example2        = `도메인 이름`;
l.DKIM.Selector.Next            = `다음`;

l.DKIM.Key.Title                = `공개 키`;
l.DKIM.Key.Tag                  = `이전에 생성된 공개 키 파일의 키 문자열인 'p' 태그가 추가됩니다.`;
l.DKIM.Key.Keys                 = `공개 키를 여기에 배치하세요`;
l.DKIM.Key.Next                 = `다음`;
l.DKIM.Key.Creating             = `DKIM 키 생성하기:`;
l.DKIM.Key.TwoParts             = `DKIM은 개인 키와 공개 키 두 부분으로 구성되어 있습니다. DKIM 키 생성은 조직에 이메일 서비스를 제공하는 업체에 따라 다릅니다.`;
l.DKIM.Key.External             = `메일과 DNS 모두에 대해 외부 서비스를 사용하는 경우 메일 제공업체는 공개 키를 제공해야 합니다. 공개 키는 DNS에서 TXT 레코드로 게시됩니다. 대부분의 DNS 제공업체는 TXT 레코드의 크기를 255자로 제한하므로 크기를 늘리거나 TXT 레코드를 생성하려면 제공업체에 문의해야 합니다. 개인 키는 메일 제공업체가 보유하며 일반적으로 조직에 제공되지 않습니다.`;
l.DKIM.Key.Internal             = `조직에 자체 이메일 서버가 있는 경우 DKIM 키는 IT 담당자(또는 DKIM 구현 담당자)에 의해 생성되어야 합니다. 여러 개의 DKIM 키 생성기가 인터넷에 있습니다. 이들 중 하나를 사용하기로 한 경우 키가 생성되고 사용자 파일에 복사된 후에 키를 삭제할 수 있는 옵션이 있는지 확인하세요.`;
l.DKIM.Key.OpenDKIM             = `제3자 없이 키를 생성하려면 opendkim이라는 오픈 소스 프로젝트를`;
l.DKIM.Key.OpenDKIM2            = `사용할 수 있습니다. Opendkim은 DKIM 키를 생성하는 데 도움이 되는 다양한 도구를 포함하고 있습니다.`;
l.DKIM.Key.OpenSSL              = `또 다른 옵션은 OpenSSL을 사용하여 DKIM 키를 생성하는 것입니다. 이는 OpenSSL의 최신 버전을 사용하고 개인 키에 대해 다음 명령어를 입력하여 수행할 수 있습니다:`;
l.DKIM.Key.OpenSSL2             = `그러면 다음과 같은 개인 키 파일이 생성됩니다:`;
l.DKIM.Key.OpenSSL3             = `그리고 다음 명령어를 입력하여 공개 키를 생성합니다:`;
l.DKIM.Key.OpenSSL4             = `이 명령어는 공개 키를 생성합니다`;
l.DKIM.Key.OpenSSL5             = `이는 이전에 생성된 개인 키를 기반으로 합니다`;
l.DKIM.Key.OpenSSL6             = `DKIM 설치에서 지정한 위치로 DKIM 키를 이동합니다. 액세스가 제한된 폴더에 있는지 확인하세요.`;

l.DKIM.Results.Title            = `아래는 DNS 서버에서 생성되어야 하는 DKIM TXT 레코드입니다.`;
l.DKIM.Results.Copy             = `복사`;
l.DKIM.Results.Copied           = `복사되었습니다!`;
l.DKIM.Results.SPFGuide         = `SPF 가이드로 돌아가기`;
l.DKIM.Results.DMARCGuide       = `DMARC 가이드 계속하기`;
l.DKIM.Results.OwnDNS           = `자체 DNS 서버가 있는 경우 위의 DKIM 정책에 대한 TXT 레코드를 생성하세요.`;
l.DKIM.Results.ThirdParty       = `제3자 DNS 제공업체를 사용하는 경우 해당 업체의 TXT 레코드 생성 지침을 따르세요.`;
l.DKIM.Results.Reference        = `TXT 레코드 생성 방법에 대한 정보는`;
l.DKIM.Results.ResourcesPage    = `리소스 페이지를`;
l.DKIM.Results.Information      = `참조하세요.`;
l.DKIM.Results.NotUnderstood    = `"절차에 따라 진행하려고 했지만 방법이나 사용된 용어를 이해하지 못한 경우 IT 지원팀, 도메인 등록기관 또는 GCA Info에"`;
l.DKIM.Results.Assistance       = `문의하시기 바랍니다.`;
l.DKIM.Results.Note             = `참고 사항:`;
l.DKIM.Results.Support          = `이제 DKIM 키는 2048비트 키를 지원할 수 있습니다. 그러나 DNS TXT 레코드는 255자를 초과하는 값을 지원할 수 없습니다. 다음 중 한 가지 방법으로 이 문제를 우회할 수 있습니다:`;
l.DKIM.Results.Support2         = `1024비트 키를 사용하거나`;
l.DKIM.Results.Support3         = `다중 라인 TXT 레코드를 생성합니다`;
l.DKIM.Results.Support4         = `다중 라인 레코드의 예는 다음과 같습니다 (따옴표는 문자열을 다중 라인으로 나누기 위해 추가됩니다):`;

l.DMARC.Home.Title              = `DMARC란 무엇입니까?`;
l.DMARC.Home.Final              = `이제 SPF 및 DKIM 정책이 생성되고 구현되었습니다. 마지막 단계로 이동하여 DMARC 정책을 생성할 수 있습니다.`;
l.DMARC.Home.Protocol           = `"DMARC(도메인 기반의 메시지 인증, 보고 및 준수)는 이메일 인증 프로토콜로서 발신자와 수신자가 사기성 이메일로부터 도메인 보호를 향상시키고 모니터링할 수 있도록 하는 보고 기능을 포함합니다. DMARC의 구현은 스패머가 메일 메시지의 ""발신자"" 주소를 스푸핑하는 것을 방지합니다. DMARC 정책 설정에 따라 인증되지 않은 메일 서버에서 발송된 모든 메일 메시지는 격리되거나 거부됩니다. 조직의 도메인 이름을 사용하는 모든 스팸 및 피싱 메일은 목적지(최종 사용자)에 도달하기 전에 격리되거나 삭제됩니다. 활동이 범죄성을 지닐 경우 생성된 보고서는 정보 수집 또는 법 집행을 목적으로 사용될 수 있습니다."`;
l.DMARC.Home.Stronger           = `DMARC는 SPF 및 DKIM을 함께 사용하여 조직의 도메인에 대해 더욱 강력한 이메일 인증을 생성합니다.`;
l.DMARC.Home.ClickNext          = `다음 버튼을 클릭하여 DMARC 정책의 생성 방법 과정을 시작하세요.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `다음`;

l.DMARC.Policy.Title            = `DMARC의 정책 수준은 무엇이 되어야 합니까?`;
l.DMARC.Policy.Levels           = `DMARC는 세 가지 정책 수준을 설정할 수 있으며 다음과 같습니다:`;
l.DMARC.Policy.NoneEx           = `"없음 - 의심스러운 메일 메시지를 보고하지만, 모든 메일은 받은편지함으로 전송됩니다"`;
l.DMARC.Policy.QuarantineEx     = `격리 - SPF/DKIM 정책에 위배되는 경우 메시지는 스팸/정크 폴더로 전송됩니다`;
l.DMARC.Policy.RejectEx         = `거부 - SPF/DKIM 정책에 위배되는 경우 메시지는 삭제되어 전달되지 않습니다`;
l.DMARC.Policy.None             = `없음 (권장)`;
l.DMARC.Policy.Quarantine       = `격리`;
l.DMARC.Policy.Reject           = `거부`;
l.DMARC.Policy.Next             = `다음`;
l.DMARC.Policy.Recommended1     = `없음으로`;
l.DMARC.Policy.Recommended2     = `시작할 것을`;
l.DMARC.Policy.Recommended3     = `강력히 권장합니다. 이렇게 하면 DMARC 정책을 격리 또는 거부로 전환하기 전에 생성된 보고서를 모니터링하고 합법적인 메일이 차단되는지 확인할 수 있습니다.`;
l.DMARC.Policy.Tag              = `이렇게 하면`;
l.DMARC.Policy.Tag2             = `DMARC 레코드에 태그가 추가됩니다.`;

l.DMARC.Aggregates.Title        = `집합 보고서는 어디로 전송되어야 합니까?`;
l.DMARC.Aggregates.Opt          = `이것은 선택 사항이지만 강력히 권장되며`;
l.DMARC.Aggregates.Opt2         = `rua 태그를 정책에 추가합니다.`;
l.DMARC.Aggregates.Opt3         = `분석 및 모니터링 목적을 위해`;
l.DMARC.Aggregates.Opt4         = `정책에 추가할 것을 강력히 권장합니다. 보고서는 여러 주소로 전송될 수 있습니다.`;
l.DMARC.Aggregates.InputField   = `이메일 주소`;
l.DMARC.Aggregates.InputInvalid = `유효한 이메일 주소를 입력하세요`;
l.DMARC.Aggregates.Next         = `다음`;
l.DMARC.Aggregates.Reports      = `집합 보고서(XML 형식)가 생성되며 어떤 메일 메시지가 SPF 및 DKIM를 패스/페일하는지에 대한 정보가 포함됩니다. 이를 통해 조직에 대한 가능한 인증 문제 및/또는 스팸 활동을 확인할 수 있습니다.`;
l.DMARC.Aggregates.Contains     = `보고서는 다음 내용을 포함합니다`;
l.DMARC.Aggregates.HowToRead    = `첫 번째 DMARC 보고서를 읽는 방법`;
l.DMARC.Aggregates.By           = `(1부) Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `ISP 정보`;
l.DMARC.Aggregates.Desc         = `조직의 DMARC 레코드에 대한 라인별 설명`;
l.DMARC.Aggregates.Summary      = `인증 결과 요약`;
l.DMARC.Aggregates.Important    = `보고서들이 하나의 출처에서 나오는 것이 아니라는 점에 유의해야 합니다. 잠재적으로 매일 10~100개(수천 개도 가능)의 보고서를 받을 수 있습니다(생성된 보고서의 수는 전송된 이메일의 양에 따라 달라집니다).`;
l.DMARC.Aggregates.Difficult    = `특히 보고서가 XML 형식이고 일부 보고서는 내용이 많을 수 있어 각 보고서를 수작업으로 검토하기가 어렵습니다.`;
l.DMARC.Aggregates.Readable     = `이러한 보고서들은 XML 변환기를 개발하거나 민간 공급업체와의 협력을 통해 사람이 읽을 수 있도록 만들 수 있습니다. 대부분의 민간 공급업체는 보고서를 사람이 읽을 수 있게 만들 뿐만 아니라 다양한 수준의 분석과 지침을 제공하는 백엔드 저장소를 제공합니다.`;
l.DMARC.Aggregates.Vendors      = `DMARC 공급업체 (GCA 파트너):`;

l.DMARC.Forensics.Title         = `포렌식 보고서는 어디로 전송되어야 합니까?`;
l.DMARC.Forensics.Opt           = `이것은 선택 사항이며`;
l.DMARC.Forensics.Opt2          = `ruf 태그를 정책에 추가합니다. XML 보고서는 수신자 측에서 이러한 유형의 보고서를 생성하는 경우에만 생성됩니다. 포렌식 보고서를 받지 못할 가능성도 있습니다. 이는 국가 간의 개인정보 보호 및 데이터 공유 규정 때문입니다.`;
l.DMARC.Forensics.InputField    = `이메일 주소`;
l.DMARC.Forensics.InputInvalid  = `유효한 이메일 주소를 입력하세요`;
l.DMARC.Forensics.Next          = `다음`;

l.DMARC.Subdomains.Title        = `서브도메인에 대한 DMARC 정책?`;
l.DMARC.Subdomains.Define       = `조직 도메인의 서브도메인에 대한 정책 수준을 정의합니다. 이 태그가`;
l.DMARC.Subdomains.Define2      = `없으면 정책은 첫 번째 단계에서 정의된 최상위 도메인에 대한 정책을 따릅니다.`;
l.DMARC.Subdomains.None         = `없음`;
l.DMARC.Subdomains.Quarantine   = `격리`;
l.DMARC.Subdomains.Reject       = `거부`;
l.DMARC.Subdomains.Next         = `다음`;
l.DMARC.Subdomains.Policy       = `도메인 정책. 옵션은 다음과 같습니다:`;
l.DMARC.Subdomains.NoneEx       = `"없음 - 어떠한 조치도 취하지 않지만, DMARC 보고서에 기록됩니다."`;
l.DMARC.Subdomains.QuarantineEx = `격리 - 메일 메시지가 스팸으로 표시됩니다.`;
l.DMARC.Subdomains.RejectEx     = `거부 - 메시지가 삭제됩니다.`;
l.DMARC.Subdomains.Recommended  = `서브도메인이 여러 개인 경우 없음으로 설정하는 것이 좋습니다. 모든 서브도메인이 DMARC 정책을 준수할 때까지 각 서브도메인에 대해 별도의 DMARC 정책을 설정하는 것이 가장 좋습니다. 그러나 서브도메인이 없는 경우 거부를 사용하는 것이 좋습니다.`;

l.DMARC.Options.Title           = `(선택 사항) 나머지 옵션은 필수 사항은 아니지만 필요한 경우 조정할 수 있습니다:`;
l.DMARC.Options.ReportGen       = `1. 오류 보고서의 생성 시기`;
l.DMARC.Options.Default         = `기본값`;
l.DMARC.Options.ReportPol       = `오류 보고 정책을 정의합니다.`;
l.DMARC.Options.DefaultsTo      = `정의되지 않은 경우 기본값은 다음과 같습니다:`;
l.DMARC.Options.AllChecks       = `기본 검사가 실패한 경우 발신 MTA에 보고서를 생성합니다`;
l.DMARC.Options.AnyChecks       = `기본 검사가 실패한 경우 발신 MTA에 보고서를 생성합니다`;
l.DMARC.Options.OtherValues     = `다른 값은 다음과 같습니다:`;
l.DMARC.Options.DKIMFails       = `DKIM 검사에 실패한 경우 보고서를 생성합니다`;
l.DMARC.Options.SPFFails        = `SPF 검사에 실패한 경우 보고서를 생성합니다`;
l.DMARC.Options.Relaxed         = `완화`;
l.DMARC.Options.Strict          = `엄격`;
l.DMARC.Options.DKIMAlign       = `2. DKIM의 정렬 모드`;
l.DMARC.Options.DKIMAlignOpt    = `DKIM의 정렬 모드. 옵션은 다음과 같습니다:`;
l.DMARC.Options.DKIMRelaxed     = `(완화) 정의되지 않은 경우의 기본값. DKIM 헤더에 정의된 모든 서브도메인을 허용합니다.`;
l.DMARC.Options.DKIMStrict      = `(엄격) 발신자의 도메인 이름이 DKIM 헤더의 도메인과 정확히 일치해야 합니다.`;
l.DMARC.Options.SPFAlign        = `3. SPF의 정렬 모드`;
l.DMARC.Options.SPFAlignOpt     = `SPF의 정렬 모드. 옵션은 다음과 같습니다:`;
l.DMARC.Options.SPFRelaxed      = `(완화) - 정의되지 않은 경우의 기본값. 모든 서브도메인을 허용합니다.`;
l.DMARC.Options.SPFStrict       = `(엄격) - (SMTP의) MAIL FROM 명령어의 조직 도메인 이름과 (메일 항목의) from: 헤더가 정확히 일치해야 합니다`;
l.DMARC.Options.Percentage      = `4. DMARC 정책이 적용되는 메시지의 비율?`;
l.DMARC.Options.Filter          = `DMARC 정책에 의해 필터링된 메시지의 비율. 1에서 100 사이의 숫자로 표시됩니다. 기본값은 100이며 모든 메시지를 의미합니다.`;
l.DMARC.Options.Formats         = `5. 생성된 보고서의 형식`;
l.DMARC.Options.FormatsEx       = `생성된 보고서의 형식을 정의합니다. 형식값은 다음과 같습니다:`;
l.DMARC.Options.RFC             = `정의되지 않은 경우의 기본값. 형식이 RFC 5965에 의해 정의됩니다.`;
l.DMARC.Options.RFC2            = `형식이 RFC 5070에 의해 정의됩니다.`;
l.DMARC.Options.ReportInt       = `6. 보고 간격 (초)`;
l.DMARC.Options.SecondsDefault  = `초 (기본값)`;
l.DMARC.Options.DigitsOnly      = `숫자만 입력하세요`;
l.DMARC.Options.ReportIntEx     = `보고 간격을 초 단위로 정의합니다. 정의되지 않은 경우 기본값은 86400초 또는 24시간입니다. 보고서가 수신 MTA에 의해 반드시 전송되는 것이 아님에 유의하시기 바랍니다. 보고서는 최선의 방식으로 전송됩니다.`;
l.DMARC.Options.HToS            = `시간/초 변환기`;
l.DMARC.Options.Hours           = `24시간`;
l.DMARC.Options.To              = `to`;
l.DMARC.Options.Seconds         = `86400초`;
l.DMARC.Options.Finish          = `끝내기`;

l.DMARC.Results.Title           = `아래는 DNS 서버에서 생성되어야 하는 DMARC TXT 레코드입니다.`;
l.DMARC.Results.Display         = `DMARC TXT 레코드 결과 표시`;
l.DMARC.Results.Copy            = `복사`;
l.DMARC.Results.Copied          = `복사되었습니다!`;
l.DMARC.Results.SPFGuide        = `SPF 가이드로 돌아가기`;
l.DMARC.Results.DKIMGuide       = `DKIM 가이드로 돌아가기`;
l.DMARC.Results.OwnDNS          = `자체 DNS 서버를 가지고 있다면 다음의 DMARC 정책을 위한 TXT 기록을 생성하십시오.`;
l.DMARC.Results.ThirdParty      = `제3자 DNS 제공업체를 사용하는 경우 해당 업체의 TXT 레코드 생성 지침을 따르세요. 대부분의 경우 호스트 이름 _dmarc.<domainname> 및 텍스트값(따옴표 사이에 있는 정보)이 필요합니다.`;
l.DMARC.Results.Reference       = `TXT 레코드 생성 방법에 대한 정보는`;
l.DMARC.Results.ResourcesPage   = `리소스 페이지를`;
l.DMARC.Results.Information     = `참조하세요.`;
l.DMARC.Results.NotUnderstood   = `"절차에 따라 진행하려고 했지만 방법이나 사용된 용어를 이해하지 못한 경우 IT 지원팀, 도메인 등록기관 또는 GCA Info에"`;
l.DMARC.Results.Assistance      = `문의하시기 바랍니다.`;
l.DMARC.Results.Complete        = `완료`;
l.DMARC.Results.Implemented     = `DMARC 레코드가 구현되면 아래의 DMARC 유효성 확인/검사기를 사용하세요:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `아직 완전하지 않았습니다.`;
l.DMARC.Results.Wisdom          = `괜찮습니다! 지혜는 모든 행동의 최종 목표입니다. 위의 리소스를 사용하거나 당사에 문의하시면 도움을 받으실 수 있습니다. 언제든지 도움을 드리겠습니다.`;
l.DMARC.Results.Check           = `검사`;
l.DMARC.Results.Congratulations = `축하합니다`;

export default l;
