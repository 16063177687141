import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardButton } from '../../Components/WizardButton'
import { Step } from '../Step'

export const Step6 = (props: any) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const { domain, getRecord, handleStartOver } = props

  return (
    <Step>
      <h2>{t('SPF.Results.Title')}</h2>

      <div className='results-input'>
        <div className='results-row h'>
          <div>{t('Global.Type')}</div>
          <div>{t('Global.Name')}</div>
          <div>{t('Global.Content')}</div>
        </div>
        <div className='results-row'>
          <div>
            <input type='text' readOnly value={'TXT'} />
          </div>
          <input type='text' readOnly value={domain} />
          <input type='text' disabled value={getRecord()} />
          <div style={{ alignItems: 'center' }}>
            <button
              className={`hs-button ${copied ? 'copied' : ''}`}
              onClick={() => {
                setCopied(true)
                navigator.clipboard.writeText(getRecord())
                setTimeout(() => {
                  setCopied(false)
                }, 2000)
              }}
            >
              {copied ? t('SPF.Results.Copied') : t('SPF.Results.Copy')}
            </button>
          </div>
        </div>
      </div>

      <div>{t('SPF.Results.OwnDNS')}</div>
      <div>{t('SPF.Results.ThirdParty')}</div>
      <div>
        {t('SPF.Results.NotUnderstood')} (
        <a href='mailto:domainsecurityscanner@globalcyberalliance.org'>
          domainsecurityscanner@globalcyberalliance.org
        </a>
        ) {t('SPF.Results.Assistance')}
      </div>
      <div className='buttons'>
        <WizardButton
          text={t('SPF.Home.Previous')}
          onClick={() => props.previousStep()}
        />
        <WizardButton
          text={t('SPF.Home.Restart')}
          onClick={() =>{
            handleStartOver()
            props.firstStep()}}
        />
      </div>
    </Step>
  )
}
