let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `Guide DMARC`;

l.Header.Main.SetupDMARC        = `Configurer DMARC`;
l.Header.Main.HowItWorks        = `PRINCIPE DE FONCTIONNEMENT`;
l.Header.Main.AboutDMARC        = `À PROPOS DE DMARC`;
l.Header.Main.Resources         = `RESSOURCES`;
l.Header.Main.Contact           = `CONTACT`;

l.Footer.Main.SetupDMARC        = `Configurer DMARC`;
l.Footer.Main.HowItWorks        = `Principe de fonctionnement`;
l.Footer.Main.AboutDMARC        = `À propos de DMARC`;
l.Footer.Main.Resources         = `Ressources`;
l.Footer.Main.Contact           = `Contact`;
l.Footer.Main.Copyright         = `Copyright ¬¨¬© 2023 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Politique de confidentialité`;
l.Footer.Main.Sitemap           = `Plan du site`;

l.Home.Main.EnterDomain         = `Saisissez votre domaine`;
l.Home.Main.ToStart             = `pour commencer la configuration de la politique  DMARC`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `Bienvenue au guide de configuration de DMARC. L'objectif de ce guide de configuration est d'orienter votre entreprise à travers le processus de création d'une politique DMARC ainsi que des politiques Sender Policy Framework (SPF) et DomainKeys Identified Mail (DKIM). Avec la mise en place de ces trois politiques, votre entreprise disposera d'un mécanisme d'authentification des messages plus fort pour protéger la marque.`;
l.Home.Main.FirstStep           = `La première étape consiste à vérifier si votre entreprise utilise l'une de ces trois politiques.`;
l.Home.Main.InputWarning        = `Veuillez saisir le domaine de messagerie de votre entreprise, tel qu'il apparaît après le symbole @ dans l'adresse e-mail.`;
l.Home.Main.InputExample        = `Par exemple, dans l'adresse e-mail domainsecurityscanner@globalcyberalliance.org, le domaine à saisir dans le champ de texte est globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `L'adresse du site web est une information nécessaire`;
l.Home.Main.valid               = `L'adresse du site web n'est pas valide`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Utilisateurs Google Apps et Office 365 :`;
l.Home.Main.UserWarningText1    = `Veuillez suivre les renseignements donnés sur la page de ressources pour`;
l.Home.Main.UserWarningLink     = `les fournisseurs de services de Cloud Computing`;
l.Home.Main.UserWarningText2    = `afin de mettre en place les politiques SPF et DKIM. Une fois terminé, veuillez utiliser ce guide pour poursuivre la mise en œuvre de la politique DMARC.`;
l.Home.Main.Submit              = `Envoyer`;
l.Home.Main.MoreInfo            = `Si vous souhaitez plus d'informations sur DMARC, nous vous invitons à assister à l'un de nos webinaires mensuels sur le sujet. Ce webinaire abordera les notions de base de la politique DMARC et vous donnera un bref aperçu du guide de configuration DMARC de GCA.`;
l.Home.Main.Webinar             = `Le webinaire mensuel DMARC de GCA a lieu deux fois le troisième mercredi de chaque mois. Veuillez vous inscrire à la date et l'heure qui vous conviennent le mieux en cliquant sur le lien correspondant ci-dessous :`;
l.Home.Main.SelectDate          = `Choisir cette date`;

l.ToolSelect.Main.Title         = `Résultats pour`;
l.ToolSelect.Main.ResultStatus  = `Le tableau suivant indique quels protocoles ont <i class='fas fa-check-circle' style='color: green'></i> ou n'ont pas <i class='fas fa-times-circle' style='color: red'></i> été mis en place.`;
l.ToolSelect.Main.Protocol      = `Veuillez sélectionner l'un des protocoles pour lancer le guide de configuration.`;
l.ToolSelect.Main.ShareButton   = `Si vous souhaitez partager les résultats, cliquez simplement sur le bouton Partager et le lien sera copié dans le presse-papiers.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `La partie de vérification de DKIM est encore en cours de développement. Cependant, le guide de configuration DKIM est toujours disponible dans la ligne DKIM.`;
l.ToolSelect.Main.NoDKIM        = `Nous n'avons pas trouvé d'enregistrement DKIM associée à votre domaine à partir d'une liste de sélecteurs par défaut:`;
l.ToolSelect.Main.NoDKIM1       = `Si vous connaissez le sélecteur, vous pouvez rechercher l'enregistrement DKIM ici :`;
l.ToolSelect.Main.NoDKIM2       = `ou bien saisissez une adresse e-mail et nous y enverrons un message auquel vous pourrez répondre pour nous permettre de rechercher un enregistrement DKIM associée à l'e-mail.`;
l.ToolSelect.Main.NoDKIMError   = `L'adresse e-mail n'est pas valide ou n'appartient pas au domaine saisi.`;
l.ToolSelect.Main.SelectorMsg   = `Recherche de sélecteur !`;
l.ToolSelect.Main.SelectorError = `Sélecteur non trouvé.`;
l.ToolSelect.Main.EmailMsg      = `E-mail envoyé ! Merci d'y répondre. Une fois la réponse envoyée, cette page sera actualisée et fournira le nom du sélecteur.`;
l.ToolSelect.Main.InputSelector = `Sélecteur`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Envoyer`;
l.ToolSelect.Main.Select        = `Sélectionner`;
l.ToolSelect.Main.Next          = `Suivant`;
l.ToolSelect.Main.Share         = `Partager`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Vous avez terminé la configuration de la politique DMARC`;
l.Complete.Main.check           = `Vérifier un autre domaine pour SPF, DKIM ou DMARC ?`;
l.Complete.Main.InputDomain     = `bourdonnai.com`;
l.Complete.Main.Required        = `L'adresse du site web est une information nécessaire`;
l.Complete.Main.valid           = `L'adresse du site web n'est pas valide`;
l.Complete.Main.Next            = `Suivant`;
l.Complete.Main.More            = `En savoir plus sur DMARC`;

l.SPF.Home.Title                = `Qu'est-ce que SPF ?`;
l.SPF.Home.SPF                  = `SPF est l'acronyme de Sender Policy Framework. Cette politique permet d'éviter l'usurpation de l'adresse de l'expéditeur en définissant les serveurs de messagerie autorisés à envoyer du courrier au nom du domaine de l'entreprise. Si le serveur de messagerie n'est pas défini dans le SPF, le message est alors rejeté ou renvoyé.`;
l.SPF.Home.Start                = `Veuillez cliquer sur le bouton Suivant pour commencer le processus de création de la politique SPF.`;
l.SPF.Home.Next                 = `Suivant`;

l.SPF.Authorization.Title       = `Les serveurs indiqués ci-dessous sont-ils autorisés à envoyer des messages pour le domaine de l'entreprise ?`;
l.SPF.Authorization.First       = `La première partie de la politique consiste à définir les serveurs de messagerie entrants (MXes) qui sont autorisés à envoyer des messages pour le domaine.`;
l.SPF.Authorization.Current     = `La liste ci-dessous contient les registres DNS actuels des serveurs de messagerie associés à votre entreprise.`;
l.SPF.Authorization.Recommended = `Il est fortement recommandé de sélectionner Oui car la liste ci-dessus devrait être celle des serveurs autorisés pour votre domaine. Ce faisant, la balise  mx sera ajoutée à la politique SPF. `;
l.SPF.Authorization.Yes         = `Oui (Recommandé)`;
l.SPF.Authorization.No          = `Non`;
l.SPF.Authorization.Missing     = `Si vous constatez que des systèmes sont absents de la liste ou s'il existe des systèmes différents des serveurs de messagerie standards, veuillez les inclure dans la prochaine option.`;
l.SPF.Authorization.Next        = `Suivant`;

l.SPF.Servers.Title             = `D'autres serveurs dans le domaine peuvent-ils livrer ou relayer des messages pour ce domaine ?`;
l.SPF.Servers.Possible          = `Il est possible que tous les serveurs de messagerie de l'entreprise ne soient pas définis dans les registres DNS de celle-ci. Il peut exister des serveurs de test ou des applications avec des systèmes de messagerie intégrés. Dans ce cas, veuillez ajouter les domaines ou adresses IP du serveur supplémentaire dans les champs ci-dessous.`;
l.SPF.Servers.Others            = `Cette option ne doit être utilisée que s'il existe des systèmes internes autorisés à envoyer des messages pour le domaine, autres que les serveurs de messagerie. »`;
l.SPF.Servers.Added             = `Si des systèmes sont ajoutés, la balise ‚Äö√Ñ√≤a‚Äö√Ñ√¥ sera ajoutée à la politique SPF.`;
l.SPF.Servers.InputField        = `Nom du serveur ou adresse IP`;
l.SPF.Servers.InputInvalid      = `Doit être un nom de domaine complet ou une adresse IP`;
l.SPF.Servers.Next              = `Suivant`;

l.SPF.Domains.Title             = `Existe-t-il d'autres domaines qui peuvent livrer ou relayer des messages pour votre entreprise ?`;
l.SPF.Domains.Include           = `Cette section ajoutera la balise`;
l.SPF.Domains.Include2          = `inclure`;
l.SPF.Domains.Include3          = `de la politique SPF.`;
l.SPF.Domains.Trusted           = `Cette option ne doit être utilisée que si un domaine externe est chargé d'envoyer des messages au nom du domaine de votre entreprise. Par exemple, le système de gestion des relations clients, le fournisseur de messagerie dans le Cloud (Google, Office 365, MacBook!) ou le fournisseur de sécurité dans le Cloud.`;
l.SPF.Domains.Note              = `À noter : Si vous utilisez un fournisseur de messagerie dans le Cloud, vous devez travailler avec ce dernier pour déterminer la valeur appropriée de ce paramètre.`;
l.SPF.Domains.InputField        = `Nom de domaine ou IP`;
l.SPF.Domains.InputInvalid      = `Doit être un nom de domaine complet ou une adresse IP`;
l.SPF.Domains.Next              = `Suivant`;

l.SPF.Strict.Title              = `Avec quelle rigidité les serveurs doivent-ils traiter les messages ?`;
l.SPF.Strict.Receiving          = `Il s'agit de la dernière section de la politique SPF, qui définira avec quelle rigidité les serveurs doivent-ils traiter les messages.`;
l.SPF.Strict.SoftFail           = `Échec en douceur (Recommandé)`;
l.SPF.Strict.Fail               = `Échec`;
l.SPF.Strict.Neutral            = `Neutre`;
l.SPF.Strict.AllowAll           = `Tout autoriser `;
l.SPF.Strict.Next               = `Suivant`;
l.SPF.Strict.Options            = `Options :`;
l.SPF.Strict.FailEx             = `Échec ou `;
l.SPF.Strict.FailEx2            = `signifie que seuls les serveurs de messagerie du domaine (et ceux des sections a et d'inclusion) sont autorisés à envoyer des messages pour le domaine. Tous les autres sont interdits.`;
l.SPF.Strict.SoftEx             = `Échec en douceur ou `;
l.SPF.Strict.SoftEx2            = `signifie que seuls les serveurs de messagerie du domaine (et ceux des sections a et d'inclusion) sont autorisés à envoyer des messages pour le domaine, mais qu'il est en transition. Tous les autres sont interdits.`;
l.SPF.Strict.NeutralEx          = `Neutre ou `;
l.SPF.Strict.NeutralEx2         = `signifie explicitement que rien ne peut être dit à propos de la validité des messages.`;
l.SPF.Strict.AllowEx            = `Tout autoriser ou`;
l.SPF.Strict.AllowEx2           = `signifie que n'importe quel hôte peut envoyer des messages pour le domaine.`;
l.SPF.Strict.Warning1           = `Cette option ne devrait `;
l.SPF.Strict.Warning2           = `jamais`;
l.SPF.Strict.Warning3           = `être utilisée`;
l.SPF.Strict.Recommended        = `Il est plutôt recommandé d'utiliser l'échec en douceur`;
l.SPF.Strict.MarkMail           = `Cette option marquera le message comme non conforme s'il ne répond pas aux critères définis.`;

l.SPF.Results.Title             = `Voici l'enregistrement TXT SPF qui doit être créé sur votre serveur DNS.`;
l.SPF.Results.Copy              = `Copier`;
l.SPF.Results.Copied            = `COPIÉ !`;
l.SPF.Results.DMARCGuide        = `Passer au guide DMARC`;
l.SPF.Results.DKIMGuide         = `Avancer vers le guide DKIM`;
l.SPF.Results.OwnDNS            = `Si vous disposez de votre propre serveur DNS, veuillez créer des enregistrements TXT pour la politique SPF ci-dessus.`;
l.SPF.Results.ThirdParty        = `Si vous utilisez un fournisseur DNS tiers, veuillez suivre ses consignes pour créer un enregistrement TXT.`;
l.SPF.Results.Reference         = `Veuillez vous reporter à la`;
l.SPF.Results.ResourcesPage     = `page de ressources`;
l.SPF.Results.Information       = `pour plus d'informations sur la façon de créer des enregistrements TXT.`;
l.SPF.Results.NotUnderstood     = `Si vous avez essayé de suivre ces directives mais n'avez pas compris les étapes à suivre ou la terminologie utilisée, nous vous recommandons de contacter votre service d'assistance informatique, votre fournisseur de domaine ou le service GCA Info`;
l.SPF.Results.Assistance        = `pour obtenir de l'aide.`;
l.SPF.Results.Implemented       = `Une fois que l'enregistrement SPF est mis en place, veuillez utiliser le vérificateur SPF ci-dessous:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `Vérifier`;
l.SPF.Results.SuccessDKIM       = `ALLER À DKIM`;
l.SPF.Results.SuccessDMARC      = `ALLER À DMARC`;
l.SPF.Results.NotQuite          = `Pas encore.`;
l.SPF.Results.Wisdom            = `C'est bon ! La sagesse est l'objectif final de toute action. N'hésitez pas à utiliser nos ressources, ci-dessus, ou à nous envoyer un message pour nous demander de l'aide sur la mise en œuvre. Nous sommes là pour cela.`;

l.DKIM.Home.Title               = `Qu'est-ce que DKIM ?`;
l.DKIM.Home.Mechanism           = `DKIM est l'acronyme de DomainKeys Identified Mail. DKIM est un mécanisme conçu pour valider l'identité d'un domaine associé à un message. Cela est réalisé en utilisant une authentification par cryptographie réattribuée. Pour simplifier, DKIM authentifie un message en ajoutant une signature numérique à son en-tête. Il convient de noter que DKIM ne filtre pas les messages. Il permet aux filtres de SPAM de déterminer l'authenticité du message envoyé.`;
l.DKIM.Home.ClickNext           = `Veuille cliquer sur le bouton Suivant pour commencer le processus de création d'une politique DKIM.`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `Suivant`;

l.DKIM.Selector.Title           = `Sélecteur`;
l.DKIM.Selector.Name            = `Le sélecteur définira le nom de l'enregistrement DKIM. Le nom du sélecteur est libre, mais voici quelques options parmi lesquelles choisir.`;
l.DKIM.Selector.Define          = `Cela déterminera le nom de l'enregistrement TXT à créer pour la clé DKIM. Celle-ci apparaîtra de la manière suivante :`;
l.DKIM.Selector.Example1        = `sélecteur`;
l.DKIM.Selector.Example2        = `nom de domaine`;
l.DKIM.Selector.Next            = `Suivant`;

l.DKIM.Key.Title                = `Clé publique`;
l.DKIM.Key.Tag                  = `Cela ajoutera la balise « p », qui est la chaîne de caractères dans le fichier de clé publique généré précédemment.`;
l.DKIM.Key.Keys                 = `Placez les clés publiques ici`;
l.DKIM.Key.Next                 = `Suivant`;
l.DKIM.Key.Creating             = `Créer les clés DKIM :`;
l.DKIM.Key.TwoParts             = `DKIM possède deux parties, la clé privée et la clé publique. La création des clés pour DKIM variera selon le fournisseur de service de messagerie de votre entreprise.`;
l.DKIM.Key.External             = `Si vous utilisez un service tiers pour votre messagerie et votre DNS, le fournisseur de messagerie devra vous fournir la clé publique. Celle-ci est ensuite publiée comme enregistrement TXT dans le DNS. La plupart des fournisseurs de DNS limitent la taille de l'enregistrement TXT à 255 caractères, et vous pourrez avoir à collaborer avec le fournisseur pour en augmenter la taille ou créer l'enregistrement TXT. La clé privée est détenue par le fournisseur de messagerie et n'est généralement pas divulguée à l'entreprise.`;
l.DKIM.Key.Internal             = `Si l'organisation possède son propre serveur de messagerie, les clés DKIM doivent être générées par le personnel informatique (ou celui responsable de l'implantation de DKIM). Différents générateurs de clé DKIM sont disponibles sur Internet. Si vous choisissez d'en utiliser un, assurez-vous que vous avez la possibilité de supprimer la clé après l'avoir générée et copiée dans vos propres fichiers.`;
l.DKIM.Key.OpenDKIM             = `Pour créer les clés sans l'aide d'un tiers, un projet open source appelé opendkim`;
l.DKIM.Key.OpenDKIM2            = `est à votre disposition. Opendkim contient différents outils pour faciliter la création de la clé DKIM.`;
l.DKIM.Key.OpenSSL              = `Une autre option consiste à utiliser OpenSSL pour générer les clés DKIM. Cela est possible en utilisant la dernière version d'OpenSSL et en saisissant la commande suivante pour la clé privée :`;
l.DKIM.Key.OpenSSL2             = `Cela génèrera un fichier de clé privée appelé`;
l.DKIM.Key.OpenSSL3             = `Tapez ensuite la commande suivante pour générer la clé publique :`;
l.DKIM.Key.OpenSSL4             = `Cette commande génèrera la clé publique`;
l.DKIM.Key.OpenSSL5             = `à partir de la clé privée déjà créée`;
l.DKIM.Key.OpenSSL6             = `Déplacez la clé privée DKIM vers l'emplacement indiqué dans l'installation DKIM. Assurez-vous que le dossier possède des droits d'accès limités.`;

l.DKIM.Results.Title            = `Voici l'enregistrement TXT DKIM qui doit être créé sur votre serveur DNS.`;
l.DKIM.Results.Copy             = `Copier`;
l.DKIM.Results.Copied           = `COPIÉ !`;
l.DKIM.Results.SPFGuide         = `Revenir au guide SPF`;
l.DKIM.Results.DMARCGuide       = `Continuer vers le guide DMARC`;
l.DKIM.Results.OwnDNS           = `Si vous disposez de votre propre serveur DNS, veuillez créer des enregistrements TXT pour la politique DKIM ci-dessus.`;
l.DKIM.Results.ThirdParty       = `Si vous utilisez un fournisseur DNS tiers, veuillez suivre ses consignes pour créer un enregistrement TXT.`;
l.DKIM.Results.Reference        = `Veuillez vous reporter à la`;
l.DKIM.Results.ResourcesPage    = `page de ressources`;
l.DKIM.Results.Information      = `pour plus d'informations sur la façon de créer des enregistrements TXT.`;
l.DKIM.Results.NotUnderstood    = `Si vous avez essayé de suivre ces directives mais n'avez pas compris les étapes à suivre ou la terminologie utilisée, nous vous recommandons de contacter votre service d'assistance informatique, votre fournisseur de domaine ou le service GCA Info`;
l.DKIM.Results.Assistance       = `pour obtenir de l'aide.`;
l.DKIM.Results.Note             = `À noter :`;
l.DKIM.Results.Support          = `Les clés DKIM prennent maintenant en charge les clés 2048 bits. Cependant, les enregistrements TXT DNS n'acceptent pas plus de 255 caractères. Vous pouvez contourner cette limitation en :`;
l.DKIM.Results.Support2         = `utilisant une clé de 1024 bits ou`;
l.DKIM.Results.Support3         = `en créant un enregistrement TXT sur plusieurs lignes`;
l.DKIM.Results.Support4         = `Voici un exemple d'enregistrement sur plusieurs lignes ci-dessous (des guillemets sont ajoutés pour couper la chaîne de caractères en plusieurs lignes) :`;

l.DMARC.Home.Title              = `Qu'est-ce que DMARC ?`;
l.DMARC.Home.Final              = `Maintenant que les politiques SPF et DKIM ont été créées et mises en place. Nous pouvons passer à la dernière étape pour créer une politique DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (acronyme de Romain-based Message Authentication, Reporting & Conformance) est un protocole d'authentification des messages qui inclut une fonction de signalement permettant à l'expéditeur et au destinataire d'améliorer et de suivre la protection du domaine contre les e-mails frauduleux. La mise en place de la politique DMARC empêchera les spammeurs de falsifier l'adresse du champ « De : » dans les messages. Selon les paramètres de la politique DMARC, tous les messages provenant d'un serveur de messagerie non autorisé seront mis en quarantaine ou rejetés. Tous les messages de spam et de phishing utilisant le nom de domaine d'une entreprise seront mis en quarantaine ou supprimés avant d'atteindre leur destination (l'utilisateur final). Les rapports générés peuvent être utilisés à des fins de renseignement ou pour engager des actions en justice si l'activité est de nature criminelle.`;
l.DMARC.Home.Stronger           = `DMARC réunira SPF et DKIM pour générer une authentification de messagerie plus forte pour le domaine de votre entreprise.`;
l.DMARC.Home.ClickNext          = `Veuillez cliquer sur le bouton Suivant pour commencer le processus de création d'une politique DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `Suivant`;

l.DMARC.Policy.Title            = `Quel devrait être le niveau de politique de DMARC ?`;
l.DMARC.Policy.Levels           = `Trois niveaux de politique peuvent être définis pour DMARC. Les voici :`;
l.DMARC.Policy.NoneEx           = `Aucun - signale les messages suspects mais tous arrivent à destination`;
l.DMARC.Policy.QuarantineEx     = `Quarantaine - en cas de non-respect des critères de la politique SPF/DKIM, le message est envoyé dans le dossier SPAM/indésirables`;
l.DMARC.Policy.RejectEx         = `Rejeter - en cas de non-respect des critères de la politique SPF/DKIM, le message est abandonné et non livré`;
l.DMARC.Policy.None             = `Aucun (Recommandé)`;
l.DMARC.Policy.Quarantine       = `Quarantaine`;
l.DMARC.Policy.Reject           = `Rejeter`;
l.DMARC.Policy.Next             = `Suivant`;
l.DMARC.Policy.Recommended1     = `Il est`;
l.DMARC.Policy.Recommended2     = `fortement recommandé`;
l.DMARC.Policy.Recommended3     = `de commencer au niveau Aucun, car cela vous donnera le temps de suivre les rapports générés et déterminer si du courrier légitime sera bloqué avant de basculer la politique DMARC sur Quarantaine ou Rejeter.`;
l.DMARC.Policy.Tag              = `Cela ajoutera la`;
l.DMARC.Policy.Tag2             = `balise à l'enregistrement DMARC.`;

l.DMARC.Aggregates.Title        = `Où les rapports consolidés doivent-ils être envoyés ?`;
l.DMARC.Aggregates.Opt          = `La réponse est facultative mais fortement recommandée, et ajoutera la balise`;
l.DMARC.Aggregates.Opt2         = `rua à la politique. Il est`;
l.DMARC.Aggregates.Opt3         = `fortement recommandé`;
l.DMARC.Aggregates.Opt4         = `de l'ajouter à la politique à des fins d'analyse et de suivi. Les rapports peuvent être envoyés à plusieurs adresses.`;
l.DMARC.Aggregates.InputField   = `Adresse électronique`;
l.DMARC.Aggregates.InputInvalid = `Veuillez saisir une adresse e-mail valide`;
l.DMARC.Aggregates.Next         = `Suivant`;
l.DMARC.Aggregates.Reports      = `Les rapports consolidés (au format XML) seront générés avec des renseignements sur les messages qui sont acceptés ou rejetés selon les politiques SPF et DKIM. Cela apporte de la visibilité sur de possibles problèmes d'authentification ou de spam pour votre entreprise.`;
l.DMARC.Aggregates.Contains     = `Les rapports contiennent les éléments suivants :`;
l.DMARC.Aggregates.HowToRead    = `comment lire vos premiers rapports DMARC`;
l.DMARC.Aggregates.By           = `(Partie 1) par Amy Gorrell) :`;
l.DMARC.Aggregates.Info         = `Information sur le fournisseur d'accès`;
l.DMARC.Aggregates.Desc         = `Description ligne par ligne de l'enregistrement DMARC de votre entreprise`;
l.DMARC.Aggregates.Summary      = `Résumé des résultats de l'authentification`;
l.DMARC.Aggregates.Important    = `Il est important de noter que ces rapports ne viendront pas seulement d'une source. Vous pourriez potentiellement recevoir 10 à 100 (et peut-être 1000) rapports quotidiens (le nombre de rapports émis dépend de la quantité de messages envoyés).`;
l.DMARC.Aggregates.Difficult    = `Il sera donc difficile d'analyser manuellement chaque rapport, en particulier car ils sont au format XML et que certains rapports peuvent être longs.`;
l.DMARC.Aggregates.Readable     = `Ces rapports peuvent être rendus lisibles en développant un convertisseur XML ou en travaillant avec un éditeur commercial. La plupart d'entre eux fourniront un référentiel de stockage qui rendra les rapports lisibles et fournira différents niveaux d'analyse et de conseils.`;
l.DMARC.Aggregates.Vendors      = `Éditeurs DMARC (partenaires GCA) :`;

l.DMARC.Forensics.Title         = `Où les rapports d'enquête doivent-ils être envoyés ?`;
l.DMARC.Forensics.Opt           = `La réponse est facultative et ajoutera la balise`;
l.DMARC.Forensics.Opt2          = `ruf à la politique. Ces rapports XML ne seront générés que si le destinataire génère ce type de rapports. Il est possible que vous ne receviez aucun rapport d'enquête. Cela est lié aux réglementations sur la confidentialité et le partage des données entre les pays.`;
l.DMARC.Forensics.InputField    = `Adresse e-mail`;
l.DMARC.Forensics.InputInvalid  = `Veuillez saisir une adresse e-mail valide`;
l.DMARC.Forensics.Next          = `Suivant`;

l.DMARC.Subdomains.Title        = `Politique DMARC pour les sous-domaines ?`;
l.DMARC.Subdomains.Define       = `Celle-ci définira le niveau de politique pour les sous-domaines du domaine de votre entreprise. Si cette balise`;
l.DMARC.Subdomains.Define2      = `n'est pas présente, alors la politique suivra celle du domaine de plus haut niveau, qui a été définie à l'étape 1.`;
l.DMARC.Subdomains.None         = `Aucun`;
l.DMARC.Subdomains.Quarantine   = `Quarantaine`;
l.DMARC.Subdomains.Reject       = `Rejeter`;
l.DMARC.Subdomains.Next         = `Suivant`;
l.DMARC.Subdomains.Policy       = `Politique pour le domaine. Les options sont :`;
l.DMARC.Subdomains.NoneEx       = `Aucun - pas d'action, mais signalement dans le rapport DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `Quarantaine - le message est marqué comme spam.`;
l.DMARC.Subdomains.RejectEx     = `Rejeter - le message est supprimé.`;
l.DMARC.Subdomains.Recommended  = `Il est recommandé de définir ce paramètre à Aucun si vous avez plusieurs sous-domaines. Il est conseillé de configurer différentes politiques DMARC pour chaque sous-domaine jusqu'à ce qu'ils soient tous conformes à la politique DMA RC. Cependant, en l'absence de sous-domaine, il est recommandé d'utiliser le niveau Rejeter.`;

l.DMARC.Options.Title           = `(Facultatif) Les options restantes ne sont pas obligatoires mais peuvent être ajustées si nécessaire :`;
l.DMARC.Options.ReportGen       = `1. Quand le rapport d'erreurs doit-il être généré ?`;
l.DMARC.Options.Default         = `Valeur par défaut`;
l.DMARC.Options.ReportPol       = `définit la politique de signalement des erreurs.`;
l.DMARC.Options.DefaultsTo      = `En l'absence de définition, la valeur par défaut est`;
l.DMARC.Options.AllChecks       = `Générer un rapport auprès du MTA expéditeur en cas d'échec des vérifications sous-jacentes`;
l.DMARC.Options.AnyChecks       = `Générer un rapport auprès du MTA expéditeur en cas d'échec des vérifications sous-jacentes`;
l.DMARC.Options.OtherValues     = `Les autres valeurs sont :`;
l.DMARC.Options.DKIMFails       = `Générer un rapport si la vérification DKIM échoue`;
l.DMARC.Options.SPFFails        = `Générer un rapport si la vérification SPF échoue`;
l.DMARC.Options.Relaxed         = `Laxiste`;
l.DMARC.Options.Strict          = `Strict`;
l.DMARC.Options.DKIMAlign       = `2. Mode d'alignement pour DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Mode d'alignement pour DKIM. Les options sont :`;
l.DMARC.Options.DKIMRelaxed     = `(laxiste) par défaut en l'absence de définition. Autorise tout sous-domaine défini dans l'en-tête DKIM.`;
l.DMARC.Options.DKIMStrict      = `(strict) le nom de domaine de l'expéditeur doit correspondre exactement à celui dans l'en-tête DKIM.`;
l.DMARC.Options.SPFAlign        = `3. Mode d'alignement pour SPF`;
l.DMARC.Options.SPFAlignOpt     = `Mode d'alignement pour SPF. Les options sont :`;
l.DMARC.Options.SPFRelaxed      = `(laxiste) par défaut en l'absence de définition.  Autorise tout sous-domaine.`;
l.DMARC.Options.SPFStrict       = `(strict) - le nom de domaine de l'entreprise dans la commande MAIL FROM (en SMTP) et dans l'en-tête from: (dans le message) doivent être identiques`;
l.DMARC.Options.Percentage      = `4. Pourcentage de messages soumis à la politique DMARC ?`;
l.DMARC.Options.Filter          = `% de messages soumis au filtrage de la politique DMARC.  Valeur quelconque entre 1 et 100. La valeur par défaut est 100, pour « tous les messages ».`;
l.DMARC.Options.Formats         = `5. Format des rapports générés`;
l.DMARC.Options.FormatsEx       = `Définit le format des rapports générés.  Les valeurs du format sont :`;
l.DMARC.Options.RFC             = `la valeur par défaut n'est pas définie.  Le format est défini par la norme RFC 5965.`;
l.DMARC.Options.RFC2            = `Le format est défini par la norme RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Intervalle des rapports (secondes)`;
l.DMARC.Options.SecondsDefault  = `secondes (défaut)`;
l.DMARC.Options.DigitsOnly      = `Veuillez ne saisir que des chiffres`;
l.DMARC.Options.ReportIntEx     = `Définit l'intervalle des rapports en secondes.  En l'absence de définition, la valeur par défaut est de 86400 secondes ou 24 heures. Veuillez noter qu''il n'est pas garanti que les rapports soient envoyés par les MTA destinataires.  Les rapports sont envoyés sur la base du meilleur effort possible.`;
l.DMARC.Options.HToS            = `Convertisseurs d'heures en secondes`;
l.DMARC.Options.Hours           = `24 heures`;
l.DMARC.Options.To              = `=`;
l.DMARC.Options.Seconds         = `86400 secondes`;
l.DMARC.Options.Finish          = `Terminer`;

l.DMARC.Results.Title           = `Voici l'enregistrement TXT DMARC à créer sur votre serveur DNS.`;
l.DMARC.Results.Display         = `Afficher le résultat de l'enregistrement TXT DMARC`;
l.DMARC.Results.Copy            = `Copier`;
l.DMARC.Results.Copied          = `COPIÉ !`;
l.DMARC.Results.SPFGuide        = `Revenir au guide SPF`;
l.DMARC.Results.DKIMGuide       = `Revenir au guide DKIM`;
l.DMARC.Results.OwnDNS          = `Si vous avez votre propre serveur DNS, veuillez créer des enregistrements TXT pour la politique DMARC ci-dessous.`;
l.DMARC.Results.ThirdParty      = `Si vous utilisez un fournisseur DNS tiers, veuillez suivre ses consignes pour créer un enregistrement TXT. Dans la plupart des cas, tout ce qu'il faudra est le nom d'hôte, _dmarc.<nomdedomaine> et la valeur du texte, qui est l'information qui se trouve entre les guillemets.`;
l.DMARC.Results.Reference       = `Veuillez vous reporter à la`;
l.DMARC.Results.ResourcesPage   = `page de ressources`;
l.DMARC.Results.Information     = `pour plus d'informations sur la façon de créer des enregistrements TXT.`;
l.DMARC.Results.NotUnderstood   = `Si vous avez essayé de suivre ces directives mais n'avez pas compris les étapes à suivre ou la terminologie utilisée, nous vous recommandons de contacter votre service d'assistance informatique, votre fournisseur de domaine ou le service GCA Info`;
l.DMARC.Results.Assistance      = `pour obtenir de l'aide.`;
l.DMARC.Results.Complete        = `Effectué`;
l.DMARC.Results.Implemented     = `Une fois que l'enregistrement DMARC est mis en place, veuillez utiliser le vérificateur DMARC ci-dessous :`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `Pas encore.`;
l.DMARC.Results.Wisdom          = `C'est bon ! La sagesse est l'objectif final de toute action. N'hésitez pas à utiliser nos ressources, ci-dessus, ou à nous envoyer un message pour nous demander de l'aide sur la mise en oeuvre. Nous sommes là pour cela.`;
l.DMARC.Results.Check           = `Vérifier`;
l.DMARC.Results.Congratulations = `Félicitations`;

export default l;
