import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'
import { StepResults } from './Pages/Results'
import './transitions.scss'
import './Wizard.scss'
import './loader.scss'
import { WizardNav } from '../../Components/WizardNav'
import { Step1 } from './Pages/Step1'
import { Step2 } from './Pages/Step2'

export const Wizard = () => {
  const[bulkScanning, setBulkScanning] = useState(false)
  const[domains, setDomains] = useState("")
  const[domain, setDomain] = useState("")
  const[results, setResults] = useState<any[]>([])
  const[resultError, setResultError] = useState(false)
  const[share, setShare] = useState(false)
  const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
    
  }

  const toggleBulkScanning = () => {
    setBulkScanning(!bulkScanning)
  }

  const handleResetBulkScanning = () => {
    setBulkScanning(false)
  }

  const handleResetResults = () => {
    setResults([])
    setResultError(false)
  }

  const handleSetDomains = (domains: string) => {
    setDomains(domains)
  }

  const handleSetDomain = (domain:string) =>{
    setDomain(domain)
  }

  const  getResults = async (domains: string) => {
    if (!domains) {
      return false
    }

    let res: any
    let errorOccurred = false;
    try{
      if (bulkScanning) {
        const split_domains = domains.split('\n').filter((el) => el.length > 0)
        res =  await scanMultiple(split_domains)
        if (res) {
          const updatedResults = res.map(processAdvice)
          setResults(updatedResults)
        }
      } else {
        res =  await scan(domains)
        if (res) {
          const updatedResult = processAdvice(res)
          errorOccurred = !updatedResult.advice;
          setResults([updatedResult])
        }
      }
    } catch (error) {
      console.error('There was an error while processing your domain: '+ error);
      errorOccurred = true;
    }

    if (errorOccurred){
      setResultError(true)
      return false
    } else {
      return res
    }
  }

  const url = 'https://dss.globalcyberalliance.org/'
  const getUrl = (path: string ) => {
    return url+path
  }
  const scan = async (domain: string) => {
    // if domain contains @, then it's an email address and we need to extract the domain
    if (domain.includes('@')) {
        domain = domain.split('@')[1]
    }
    try {
      const res = await fetch(getUrl(`api/v1/scan/${domain}`))
      if(res.ok){
        return res.json()
      }else{
        const error = await res.json()
        console.error(`There was an error while processing your domain ${error.message}. Error Status code: ${res.status}`)
        throw new Error(`There was an error while processing your domain: ${error.message}. Error Status code: ${res.status}`);
      }
    } catch (error) {
      console.error('There was an error while processing your domain: '+ error);
      throw error;
    }
  }

  const scanMultiple = async (domains: string[]) =>{
    // remove any email addresses from the list of domains
    domains = domains.map((domain) => {
        if (domain.includes('@')) {
            return domain.split('@')[1]
        }
        return domain
    })

    const domainsBody = JSON.stringify({
      "domains": domains
    })

    try{
      const res = await fetch(getUrl(`api/v1/scan`), {
        method: 'post',
        cache: 'no-cache',
        mode: 'cors',
        body: domainsBody,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if(res.ok){
        const body = await res.json()
        return body.results
      }else{
        const error = await res.json()
        console.error(`There was an error while processing your domains: ${error.message}. Error Status code: ${res.status}`)
        throw new Error(`There was an error while processing your domains: ${error.message}. Error Status code: ${res.status}`);
      }
    } catch (error) {
      console.error('There was an error while processing your domains: '+ error);
      throw error;
    }
  }

  function processAdvice(record: any) {
    let updatedRecord = record;

    // If there is an error in the scan result, add it to the advice object
    if (updatedRecord.scanResult && updatedRecord.scanResult.error) {
      const errors = updatedRecord.scanResult.error.split('; ');
      const errorObject = errors.reduce((obj: any, error: string) => {
        let [key, value] = error.split(':');
        if (value === undefined) {
          value = key;
          key = 'generalError';
        }
        obj[key] = obj[key] || [];
        obj[key].push(value);
        return obj;
      }, {});

      updatedRecord.advice = updatedRecord.advice || {};
      for (const key in errorObject) {
        updatedRecord.advice[key] = errorObject[key];
      }
    }

    if (updatedRecord.advice && updatedRecord.advice.mx) {
      updatedRecord.advice.tls = [...updatedRecord.advice.mx];
      delete updatedRecord.advice.mx;

      for (const key in updatedRecord.advice) {
        if (key !== "tls" && Array.isArray(updatedRecord.advice[key])) {
          const adviceArray = updatedRecord.advice[key];
          // Move TLS advice entries to tls field
          updatedRecord.advice.tls.unshift(
            ...adviceArray.filter((item: string) => item.toLocaleLowerCase().includes("tls"))
          );
          // Remove TLS advice entries from original field
          updatedRecord.advice[key] = adviceArray.filter(
            (item: string) => !item.includes("tls")
          );
        }
      }
    }

    return updatedRecord;
  }

  const toggleShare = () => {
    setShare(!share)
  }

  return (
    <div className='container'>
      <div className='template-page content  av-content-full alpha units'>
        <div
          className='wizard page-wrapper blue-radial-gradient guide'
          style={{ marginTop: '40px' }}
        >
          <StepWizard
            nav={<WizardNav />}
            isHashEnabled
            transitions={transitions} // comment out for default transitions
          >
            <Step1
            domains={domains}
            handleSetDomains={handleSetDomains}
            getResults={getResults}
            bulkScanning={bulkScanning}
            toggleBulkScanning={toggleBulkScanning} />
            <Step2
            resultError={resultError}
            bulkScanning={bulkScanning}
            handleSetDomains={handleSetDomains}
            handleSetDomain={handleSetDomain}
            handleResetBulkScanning={handleResetBulkScanning}
            handleResetResults={handleResetResults}
            />
            <StepResults
              domain={domain}
              domains={domains}
              bulkScanning={bulkScanning}
              handleSetDomain={handleSetDomain}
              handleSetDomains={handleSetDomains}
              results={results}
              toggleShare={toggleShare}
              share={share}
              handleResetBulkScanning={handleResetBulkScanning}
              handleResetResults={handleResetResults}
            />
          </StepWizard>
        </div>
      </div>
    </div>
  )
}
